<template>
  <div class="page-root">
    <div class="header">
      <img class="logo" src="@/assets/CAVIN_Logo_black.png" height="67" />
    </div>

    <div class="content">
      <form class="card" @submit.prevent="submit">
        <div class="card-title">メールアドレス再設定</div>

        <div class="label">現在のメールアドレス</div>
        <input class="input" type="email" required placeholder="info@cavin.jp" v-model="email" disabled/>

        <div class="label">新しいメールアドレス</div>
        <input class="input" type="email" required placeholder="info@cavin.jp" v-model="newEmail" />

        <div v-if="error" class="label registered">{{ $t("email_already_registered") }}</div>

        <button class="button" type="submit">認証メールを再送信</button>
      </form>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      newEmail: "",
      user_type: "",
      error: false,
      axiosOptions: {}
    };
  },
  async mounted() {
    this.email = this.$store.state.auth.email;
    this.user_type = this.$store.state.auth.user_type;
  },
  methods: {
    async submit() {
      const user = {
        email: this.newEmail,
      };

      this.axiosOptions = {
        headers: {
          Authorization: this.access_token
        }
      };

      await this.$api.put("/users", { user, is_reconfirmation: true }, this.axiosOptions);

      this.$store.commit("setData", { email: user.email });

      this.$router.push({
        path: "/users/send_email",
        query: {
          type: this.user_type,
          email: this.newEmail
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 40px;
  .logo {
    display: block;
    margin: 0 auto;
  }
  .logo-caption {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
}
.content {
  max-width: 600px;
  margin: 0 auto;
}
.card {
  padding: 30px 20px 50px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  color: #333;
}
.card-title {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}
.label {
  margin: 40px auto 0;
  max-width: 450px;
  font-size: 16px;
}
.registered {
  color: red;
}
.input {
  margin: 0 auto;
  display: block;
  max-width: 450px;
}
.button {
  margin: 40px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  background-color: #007e57;
  border-radius: 3px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  outline: none;
}
.note {
  margin-top: 20px;
  font-size: 16px;
}
.link {
  color: inherit;
  text-decoration: underline;
}
</style>
