/**
 * @typedef FlowerGenus
 * @type {object}
 * @property {number} id
 * @property {string} name
 * @property {string} image_url
 */

/**
 * @typedef Option
 * @type {object}
 * @property {number} value
 * @property {string} text
 */

export default {
  namespaced: true,
  state: {
    /** @type {FlowerGenus} */
    list: {},
    listArr: [],
    /** @type {Option} */
    options: []
  },
  mutations: {
    setData(state, payload) {
      state.list = payload.list;
      state.options = payload.options;
      state.listArr = Object.keys(state.list)
        .map(Number)
        .sort((a, b) => a - b);
    }
  },
  actions: {
    async getFlowerGenus({ commit, state }) {
      const res = await this._vm.$http("get", "flower_genus");
      if (res.status === 200) {
        const newData = { ...state };
        res.result.flower_genuses.forEach(item => {
          newData.list[item.id] = item;

          if (!newData.options.find(x => x.value === item.id)) {
            newData.options.push({ value: item.id, text: item.name, name_furigana: item.name_furigana });
          }
        });

        commit("setData", newData);
      }

      return true;
    },
    async postFlowerGenus({ commit, dispatch, state }, data) {
      data = {
        flower_genus: { name: data.name },
        image: { data: data.data }
      };

      const res = await this._vm.$http("post", "flower_genus", data);

      if (res.status === 201) {
        const { id } = res.result.flower_genus;
        const newData = { ...state };

        newData.list[id] = {
          id,
          name: data.flower_genus.name,
          image_url: data.image.data
        };

        newData.options.push({ value: id, text: newData.list[id].name });

        commit("setData", newData);
        dispatch("setToast", { type: "success", msg: "added" }, { root: true });

        return id;
      }

      return null;
    }
  },
  getters: {
    options(state) {
      return state.options;
    },
    name: state => genusId => state.list[genusId] && state.list[genusId].name
  }
};
