export default {
  namespaced: true,
  state: {
    draft: null,
    message: "",
    drivers: [],
    areas: []
  },
  mutations: {
    setDraft(state, payload) {
      state.draft = payload;
    },
    setMessage(state, payload) {
      state.message = payload;
    },
    setDrivers(state, payload) {
      state.drivers = payload;
    },
    setAreas(state, payload) {
      state.areas = payload;
    },
  },
  actions: {
    getAreas({ commit }) {
      return new Promise(resolve => {
        this._vm.$http("get", "delivery/areas").then(res => {
          if (res.status === 200) {
            commit("setAreas", res.result.areas);
          }
          resolve(true);
        });
      });
    },
    getDrivers({ commit }) {
      return new Promise(resolve => {
        this._vm.$http("get", "delivery/drivers").then(res => {
          if (res.status === 200) {
            commit("setDrivers", res.result.drivers);
          }
          resolve(true);
        });
      });
    }
  },
  getters: {
    getState(state) {
      return state;
    }
  }
};
