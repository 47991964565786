<template>
  <div class="product-detail-card">
    <div class="product-detail-card__head">
      <time :datetime="$date.YYYYMMDD(new Date(flowerHarvest.deliveryDate))" class="product-detail-card__harvest-date">
        {{ getFormattedDate(flowerHarvest.deliveryDate) }}
      </time>
      <Button outlined width="130px" :on-click="showConversation">
        <div class="justify-button-content">
          <img :src="require('@/assets/message.svg')" />
          <span>メッセージ</span>
        </div>
      </Button>
    </div>
    <div class="info-container">
      <div class="info-container-inner">
        <div class="farm-name">{{ flowerHarvest.flower.farm.name }}</div>
        <div class="flower-genus">
          <MstTextTag :text="flowerHarvest.flower.flowerGenus" size="sm" color="gray-50" rounded />
        </div>
        <div class="flower-specie-name">{{ flowerHarvest.flower.flowerSpecie }}</div>
        <div class="flower-tags">
          <MstTextTag :text="`${flowerHarvest.stemSize}cm`" size="sm" />
        </div>
      </div>
    </div>
    <div class="payment-container">
      <div class="payment-container-inner">
        <div class="lot-count">1口{{ flowerHarvest.minCount }}本から</div>
        <div class="product-lowest-price">
          {{ $n(flowerHarvest.salesPriceWithoutDeliveryFee) }}<span class="unit">円</span>
        </div>
      </div>
      <div class="payment-container-inner">
        <div class="lot-count">{{ $t("review") }}</div>
        <star-rating
          :rating="flowerHarvest.flower.reviewMark"
          :increment="0.01"
          :star-size="12"
          :padding="2"
          active-color="#DFCE0F"
          inactive-color="#D9D9D9"
          :show-rating="false"
        ></star-rating>
        <div>
          <span class="text-xl font-weight-bold">{{ flowerHarvest.flower.reviewMark.toFixed(1) }}</span>
          <span class="text-sm font-weight-bold">/5.0</span>
          <span class="text-sm">{{ ` (${flowerHarvest.flower.totalReviewCount}${$t("piece")})` }}</span>
        </div>
      </div>
      <Counter v-if="isNormalOrderItemsExistsInCart" :disabled="isPurchaseDeadlineExpired" :min="0" :max="maxLotCount" v-model="valueModel" />
      <Button v-else heavy :disabled="isAddCartDisabled || isPurchaseDeadlineExpired" width="130px" :on-click="addDraftOrder">
        <div class="justify-button-content">
          <img :src="require('@/assets/cart-whiteline.svg')" />
          <span>カート追加</span>
        </div>
      </Button>
    </div>
    <div class="purchase-deadline">
      <span class="purchase-deadline-label">購入期限: </span>
      <span class="purchase-deadline-content">{{ flowerHarvest.purchaseDeadline }}</span>
    </div>
  </div>
</template>

<script>
import Button from "@/components/shared/v2/Button.vue";
import Counter from "@/components/shared/v2/Counter.vue";
import { MstTextTag } from "@/components/master";
import StarRating from "vue-star-rating";

export default {
  components: {
    Button,
    Counter,
    MstTextTag,
    StarRating,
  },
  props: {
    flowerHarvest: {
      type: Object,
      required: true
    },
    showConversation: {
      type: Function,
      required: true
    },
    addDraftOrder: {
      type: Function,
      required: true
    },
    isAddCartDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isAlreadyCartIn: false,
      timeoutId: -1,
      isPurchaseDeadlineExpired: this.flowerHarvest.purchaseDeadlineExpired,
    };
  },

  methods: {
    getFormattedDate(date) {
      const dateObj = new Date(date);
      return `${this.$date.MMDD(dateObj, "/")}（${this.$date.getDayOfWeek(dateObj)}）`;
    },
    async editCountHandler(newLotCount) {
      class OrderItemPutError extends Error {
        constructor(status, message) {
          super(message);
          this.status = status;
          this.name = "OrderItemPutError";
        }
      }

      const data = {
        id: this.normalOrderItem.id,
        lotCount: newLotCount,
        subtotalCount: newLotCount * this.minLot,
        flowerHarvestId: this.normalOrderItem.flower_harvest.id,
      };
      try {
        const res_status = await this.$store.dispatch("orders/changeCount", data);
        if (res_status === 521) throw new OrderItemPutError(521, "Failed to change count");
        if (res_status !== 200) throw new Error("Failed to change count");
        this.$store.dispatch("orders/notifyChangeCount");
      } catch (e) {
        if (e.status === 521) {
          this.$store.dispatch("orders/notifyUnbuyableAreaError");
        } else {
          this.$store.dispatch("orders/notifyChangeCountError");
        }
      }
    },
  },

  computed: {
    isFromCart() {
      return this.$router.referrer.path === "/shop/cart";
    },
    isNormalOrderItemsExistsInCart() {
      return !!this.normalOrderItem;
    },
    normalOrderItem() {
      return this.$store.getters["orders/normalOrderItem"](this.$route.params.productId);
    },
    minLot() { return this.normalOrderItem.harvest_min_count; },
    maxLotCount() {
      return this.normalOrderItem.flower_harvest.stock_count / this.normalOrderItem.harvest_min_count;
    },
    valueModel: {
      get() {
        return this.normalOrderItem.lot_count;
      },
      set(value) {
        this.normalOrderItem.lot_count = value;
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(async () => {
          if (value === 0) {
            this.$store.dispatch("orders/deleteOrderItem", this.normalOrderItem);
          } else {
            await this.editCountHandler(value);
            this.$store.dispatch("orders/getDraftOrder");
          }
        }, 800);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.product-detail-card {
  background-color: #fff;
  overflow: hidden;
  min-height: 200px;

  @media screen and (max-width: 960px) {
    min-height: 0;
  }
}

.product-detail-card__head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.product-detail-card__harvest-date {
  display: inline-block;
  border-radius: 2px;
  padding: 4px 14px 5px;
  background: #375293;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.justify-button-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.info-container {
  display: flex;
  justify-content: space-between;
  padding: 6px 0 14px;
}

.info-container-inner {
  width: 100%;
}

.payment-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid variables.$color-gray-100;
  border-bottom: 1px solid variables.$color-gray-100;
  padding: 8px 0;
}

.lot-count {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #555;
  margin-bottom: 5px;
}

.farm-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flower-genus {
  line-height: 1;
}

.flower-specie-name {
  overflow: hidden;
  margin-top: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.flower-tags {
  margin-top: 4px;
  line-height: 1;
}

.product-lowest-price {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #DD5055;
  line-height: 17px;
  overflow-wrap: anywhere;

  &>.delivery-fee,
  &>.unit {
    font-size: 12px;
    line-height: 15px;
  }
}

.product-earliest-delivery-date {
  font-weight: bold;
  font-size: 14px;
  color: #555555;
  font-style: normal;
  line-height: 17px;
}

.product-green-container {
  padding: 0 5px;
  height: 28px;
  background: #00796b;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.product-delivery-note {
  padding: 8px;
  background: #eeeeee;

  font-weight: 500;
  font-size: 14px;

  color: #01579b;
}

.purchase-deadline {
  margin-top: 8px;
  color: #535E68;
}

.purchase-deadline-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 14.52px;
}

.purchase-deadline-content {
  font-weight: 600;
  font-size: 14px;
  line-height: 16.94px;
}
</style>
