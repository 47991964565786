<template>
  <div class="page-root">
    <div class="header">
      <img class="logo" src="@/assets/CAVIN_Logo_black.png" height="41" />
    </div>

    <div class="content">
      <div class="card">
        <ExclamationTriangleIcon class="icon" />
        <div class="card-title">メール認証に失敗しました。</div>
        <div class="message">
          処理中に問題が発生しました。<br>
          お手数ですが、再度お試しください。
        </div>
      </div>

      <router-link class="button" to="/">TOPに戻る</router-link>
    </div>
  </div>
</template>

<script>
import ExclamationTriangleIcon from "@/components/icons/ExclamationTriangleIcon.vue";

export default {
  components: {
    ExclamationTriangleIcon
  },

  data() {
    return {
      email: this.$route.query.email
    };
  },

  methods: {
    send() {
      this.$router.push({
        path: "/users/send_email",
        query: {
          email: this.email
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-root {
  background-color: #eee;
}

.header {
  margin-bottom: 40px;

  .logo {
    display: block;
    margin: 0 auto;
  }
}

.content {
  max-width: 720px;
  margin: 0 auto;
}

.card {
  padding: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  color: #333;
  text-align: center;
}

.icon {
  color: #E38C00;
}

.card-title {
  margin-top: 16px;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

.message {
  margin-top: 30px;
  color: #555;
  font-size: 16px;
  text-align: center;
}

.button {
  margin: 40px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  border: #007E57 1px solid;
  background-color: #fff;
  border-radius: 3px;
  color: #007E57;
  font-size: 18px;
  font-weight: bold;
  outline: none;
}
</style>
