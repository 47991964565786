<template>
  <!-- eslint-disable -->

  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="color"
      d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM6.084 19.536C6.6 18.456 9.744 17.4 12 17.4C14.256 17.4 17.412 18.456 17.916 19.536C16.284 20.832 14.232 21.6 12 21.6C9.768 21.6 7.716 20.832 6.084 19.536ZM19.632 17.796C17.916 15.708 13.752 15 12 15C10.248 15 6.084 15.708 4.368 17.796C3.09318 16.1326 2.40157 14.0957 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 14.184 20.856 16.188 19.632 17.796ZM12 4.8C9.672 4.8 7.8 6.672 7.8 9C7.8 11.328 9.672 13.2 12 13.2C14.328 13.2 16.2 11.328 16.2 9C16.2 6.672 14.328 4.8 12 4.8ZM12 10.8C11.004 10.8 10.2 9.996 10.2 9C10.2 8.004 11.004 7.2 12 7.2C12.996 7.2 13.8 8.004 13.8 9C13.8 9.996 12.996 10.8 12 10.8Z"
    />
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String
  }
};
</script>

<style scoped>

</style>
