<template>
  <div>
    <div>
      <v-btn @click="open" outlined><v-icon>mdi-plus-circle-outline</v-icon>{{ $t("custom_harvest_date") }}</v-btn>
    </div>
    <custom-dialog
      v-if="dialogOpen"
      @close="close()"
      :title="$t('custom_harvest_date')"
    >
    <template v-slot:text>
      <table class="detail-table">
        <tbody>
          <tr>
            <th>
              終了日
            </th>
            <td>
              <calendar-field v-model="inputDate" :deliveryDateList="deliveryDateList"></calendar-field>
            </td>
          </tr>

          <tr>
            <th>
              曜日
            </th>
            <td>
              <v-btn-toggle
                v-model="toggle_multiple"
                color="teal"
                multiple
              >
                <v-btn v-for="w in allowWdayList" :key="w" :value="w">{{ weekdays[w] }}</v-btn>
              </v-btn-toggle>
            </td>
          </tr>

        </tbody>
      </table>
    </template>

    <template v-slot:actions>
      <v-row class="buttons">
        <v-col sm="6" cols="12" class="d-flex justify-center align-center">
          <Button type="save" :disabled="toggle_multiple.length < 1" @click="save()" />
        </v-col>
      </v-row>
    </template>
    </custom-dialog>
  </div>
</template>

<script>
import CustomDialog from "@/components/shared/CustomDialog.vue";
import Button from "@/components/farm/harvest/ActionButton.vue";
import CalendarField from "@/components/shared/CalendarField.vue";

export default {
  components: { CustomDialog, Button, CalendarField },
  props: {
    harvestDate: {
      type: String,
      default: ""
    },
    data: {
      type: Object,
      default: null
    },
    allowWdayList: {
      type: Array,
      default: () => ([0, 1, 2, 3, 4, 5, 6])
    }
  },
  data() {
    return {
      dialogOpen: false,
      inputDate: "",
      toggle_multiple: [],
      deliveryDateList: [],
      weekdays: {
        0: "日",
        1: "月",
        2: "火",
        3: "水",
        4: "木",
        5: "金",
        6: "土"
      }
    };
  },
  methods: {
    open() {
      this.dialogOpen = true;
      this.inputDate = this.harvestDate;
    },
    save() {
      const payload = {
        end: this.inputDate,
        weekdays: this.toggle_multiple
      };

      if (this.inputDate === null) {
        this.$emit("edit", this.harvestDate, "harvest_date");
      } else {
        this.$emit("edit", payload, "harvest_date");
      }
      this.dialogOpen = false;
    },
    close() {
      this.dialogOpen = false;
      this.$emit("close", this.dialogOpen);
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.setFlowerHarvest();

        this.dialogOpen = true;
      }
    },
    inputDate() {
      if (this.inputDate === null) this.toggle_multiple = [];
    },
    toggle_multiple() {
      const payload = {
        end: this.inputDate,
        weekdays: this.toggle_multiple
      };

      if (this.inputDate === null) {
        this.$emit("edit", this.harvestDate, "harvest_date");
      } else {
        this.$emit("edit", payload, "harvest_date");
      }
    }
  }
};
</script>

<style  lang="scss" scoped>
@media only screen and (min-width: 600px) {
  .detail-table {
    margin: 0;
    & td:nth-child(1) {
      width: 100px;
    }
  }
}

.detail-table {
  width: 100%;
  table-layout: fixed;

  & th {
    background: white;
    border-bottom: 1px solid #cccccc;
    width: 60px;
  }

  & td {
    background: white;
    border-bottom: 1px solid #cccccc;
    padding: 20px 5px 20px 5px;
  }

  & .v-select {
    margin-bottom: -15px !important;
  }

  & td:nth-child(1) {
    width: 100px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9rem;
  }

  & tr:nth-last-child(1) td,
  & tr:nth-last-child(1) th {
    border: none;
  }
}
</style>
