<template>
  <v-container class="page-container">
    <div class="non-table-container pa-4">
      <h1 class="text-center my-6">{{ $t("farmFlowersGenera") }}</h1>

      <div class="d-flex justify-end mb-4" v-if="!mobile">
        <MstButton width="140px" class="btn bg-secondary" to="/farm/flowers/new" color="#173169">{{ label }}</MstButton>
      </div>

      <div class="search-board">
        <div class="d-flex bg-back-gray search-container align-center">
          <SearchIcon color="gray" size="16" />
          <input class="search-button" placeholder="キーワードから探す" @input="onChangeSearch" :value="searchString" />
        </div>
        <div class="search-result" v-if="searchString !== ''">
          <div class="mx-4 pa-2" @click="onHandleSearchTap(item.genus_name, item.id)" v-for="item in searchResult" :key="item.id">
            <div class="my-2 d-flex justify-space-between align-center">
              <div class="d-flex">
                <span class="badge-gray mr-2">{{ item.category }}</span>
                <span>{{ item.genus_name }}</span>
              </div>
              <span class="bagde-red w-100px flex-shrink-0 flex-grow-0" v-if="!item.isWorking">{{ $t('hidden') }}</span>
            </div>
            <div class="font-weight-bold text-overflow-dot">{{ item.specie_name }}</div>
          </div>
          <div v-if="searchResult.length === 0">
            <div class="mx-4 pa-2">{{ $t('no_products_matching') }}</div>
          </div>
        </div>
      </div>

    </div>

    <div class="mobile-add-button" v-if="mobile">
      <MstFloatingButton :label="label" icon="mdi-plus" color="#173169" labelPosition="outside" to="/farm/flowers/new" />
    </div>

    <div class="my-8 table">
      <div class="d-flex pa-4">
        <div class="flex-shrink-1 flex-grow-1">{{ $t("item") }}</div>
        <div class="w-100px">{{ $t("flower_count") }}</div>
        <div class="w-100px">{{ $t("on_sale") }}</div>
        <div class="table-action"> </div>
      </div>
      <div class="d-flex pa-4 table-row pointer" @click="onHandleTap(item.id)" v-for="item in items" :key="item.id">
        <div class="flex-shrink-1 flex-grow-1 font-weight-bold text-overflow-dot" style=""> {{ item.genus_name }} </div>
        <div class="w-100px flex-shrink-0 flex-shrink-0"> {{ item.flower_count }}{{ $t("piece") }} </div>
        <div class="w-100px flex-shrink-0 flex-shrink-0"> {{ item.flower_harvest_count }}{{ $t("piece") }} </div>
        <div class="table-action"> <ArrowRightIcon size="12" color="black" /> </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import SearchIcon from "@/components/icons/SearchIcon.vue";
import { MstFloatingButton, MstButton } from "@/components/master";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";

export default {
  components: { SearchIcon, MstFloatingButton, ArrowRightIcon, MstButton },
  data() {
    return {
      searchString: "",
      searchResult: [],
      mobile: this.$vuetify.breakpoint.xs,
      items: [],
      searchItems: [],
      label: "商品登録"
    };
  },
  created() {
    this.$api.get("/harvests/flower_genus").then(response => {
      this.items = response.result.flower_genus.map(genus => ({
        id: genus.id, genus_name: genus.name, flower_count: genus.flower_count, flower_harvest_count: genus.flower_harvest_count
      }));
      this.$store.dispatch("flowers/getFlowers").then(this.createTableData);
    });
  },
  methods: {
    async createTableData() {
      this.searchItems = [];
      const { flowers } = this.$store.state;

      flowers.listArr.forEach(id => {
        const f = flowers.list[id];
        this.searchItems.push({
          id: f.id,
          category: f.category.name,
          genus_name: f.flowerSpecie.flowerGenus.name,
          specie_name: f.flowerSpecie.name,
          flower_type: f.flower_type,
          isWorking: f.isWorking
        });
      });
    },
    onHandleTap(id) {
      this.$router.push(`/farm/flower_genera/${id}/flowers`);
    },
    onHandleSearchTap(genusName, flowerId) {
      const tapGenus = this.items.filter(item => item.genus_name === genusName);
      this.$router.push(`/farm/flower_genera/${tapGenus[0].id}/flowers/${flowerId}`);
    },
    onChangeSearch(event) {
      this.searchString = event.target.value;
      this.searchResult = this.searchItems.filter(item => item.genus_name.includes(this.searchString) || item.specie_name.includes(this.searchString));
    }
  }
};
</script>

<style lang="scss">
.page-container {
  max-width: variables.$container-max-width;
}

.table {
  text-align: left;
  .table-row:nth-of-type(even) {
    background-color: #f5f5f5;
  }
  .w-100px {
    width: 100px;
  }
  .table-action {
    width: 20px;
    text-align: right;
  }
}

.text-overflow-dot {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mobile-add-button {
  position: fixed;
  bottom: 100px;
  right: 15px;
  z-index: 100;
}

.search-container {
  padding: 12px;
  border-radius: 4px;
}

.search-button {
  margin-left: 10px;
  width: 100%;
}

.search-button:focus {
  outline: none;
}

.search-board {
  position: relative;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, .1);
}

.search-result {
  width: 100%;
  position: absolute;
  background-color: white;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, .1);
  overflow: auto !important;
  max-height: 250px;
}

.badge-gray {
  background-color: #D7E4EB;
  border-radius: 100px;
  padding: 2px 10px 2px 10px;
}

.bagde-red {
  border: 1px solid red;
  border-radius: 100px;
  color: red;
  padding: 2px 10px 2px 10px;
  font-size: 12px;
}
</style>
