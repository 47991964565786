import { render, staticRenderFns } from "./ProductContainer.vue?vue&type=template&id=081b807c&scoped=true&"
import script from "./ProductContainer.vue?vue&type=script&lang=js&"
export * from "./ProductContainer.vue?vue&type=script&lang=js&"
import style0 from "./ProductContainer.vue?vue&type=style&index=0&id=081b807c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "081b807c",
  null
  
)

export default component.exports