<template>
  <v-container class="d-md-flex ml-auto mr-auto mt-5 mb-5">
    <div class="mb-5 flex-grow-1 main-content">
      <div class="d-flex align-center justify-start tabs">
        <router-link
          to="/farm/setting/account"
          class="d-flex align-center justify-center tab"
          :class="{ active: activeIndex === 1 }"
        >{{ $t("account_info") }}</router-link>
      </div>

      <div class="pl-4 pr-4 pt-10 pb-10 form-container">
        <div class="ml-auto mr-auto form-content">
          <slot />
        </div>
      </div>
    </div>

  </v-container>
</template>

<script>
export default {
  props: {
    activeIndex: Number
  }
};
</script>

<style lang="scss" scoped>
.main-content {
  max-width: 790px;
}

.tab {
  font-size: 1rem;
  width: 180px;
  height: 50px;
  color: #777;
  border-radius: 5px 0px;
  border: 1px solid #ccc;
  border-bottom-color: #fff;
  margin-right: 10px;
  cursor: pointer;
  &.active {
    background-color: #fff;
    font-weight: bold;
    color: #111;
  }
}

.form-container {
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
}

.form-content {
  max-width: 450px;
}
.v-btn {
  color: white;
  font-weight: bold;
  width: 140px;
  border-radius: 3px;
}
.v-btn.blue-btn {
  background-color: #5faedb !important;
}
.v-btn.grey-btn {
  background-color: #ccc !important;
}
</style>
