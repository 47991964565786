<template>
  <!-- eslint-disable -->

	<svg :height="size" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.33333 16.8333C6.66389 16.8333 6.94117 16.7213 7.16517 16.4973C7.38839 16.2741 7.5 15.9972 7.5 15.6667C7.5 15.3361 7.38839 15.0588 7.16517 14.8348C6.94117 14.6116 6.66389 14.5 6.33333 14.5C6.00278 14.5 5.7255 14.6116 5.5015 14.8348C5.27828 15.0588 5.16667 15.3361 5.16667 15.6667C5.16667 15.9972 5.27828 16.2741 5.5015 16.4973C5.7255 16.7213 6.00278 16.8333 6.33333 16.8333ZM6.33333 12.1667C6.66389 12.1667 6.94117 12.0547 7.16517 11.8307C7.38839 11.6074 7.5 11.3306 7.5 11C7.5 10.6694 7.38839 10.3922 7.16517 10.1682C6.94117 9.94494 6.66389 9.83333 6.33333 9.83333C6.00278 9.83333 5.7255 9.94494 5.5015 10.1682C5.27828 10.3922 5.16667 10.6694 5.16667 11C5.16667 11.3306 5.27828 11.6074 5.5015 11.8307C5.7255 12.0547 6.00278 12.1667 6.33333 12.1667ZM6.33333 7.5C6.66389 7.5 6.94117 7.388 7.16517 7.164C7.38839 6.94078 7.5 6.66389 7.5 6.33333C7.5 6.00278 7.38839 5.7255 7.16517 5.5015C6.94117 5.27828 6.66389 5.16667 6.33333 5.16667C6.00278 5.16667 5.7255 5.27828 5.5015 5.5015C5.27828 5.7255 5.16667 6.00278 5.16667 6.33333C5.16667 6.66389 5.27828 6.94078 5.5015 7.164C5.7255 7.388 6.00278 7.5 6.33333 7.5ZM9.83333 16.8333H16.8333V14.5H9.83333V16.8333ZM9.83333 12.1667H16.8333V9.83333H9.83333V12.1667ZM9.83333 7.5H16.8333V5.16667H9.83333V7.5ZM2.83333 21.5C2.19167 21.5 1.64217 21.2717 1.18483 20.8152C0.728278 20.3578 0.5 19.8083 0.5 19.1667V2.83333C0.5 2.19167 0.728278 1.64217 1.18483 1.18483C1.64217 0.728278 2.19167 0.5 2.83333 0.5H19.1667C19.8083 0.5 20.3578 0.728278 20.8152 1.18483C21.2717 1.64217 21.5 2.19167 21.5 2.83333V19.1667C21.5 19.8083 21.2717 20.3578 20.8152 20.8152C20.3578 21.2717 19.8083 21.5 19.1667 21.5H2.83333Z" :fill="color"/>
	</svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>
