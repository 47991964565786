<template>
  <div>
    <v-alert
      text
      color="info"
    >
      <h3 class="text-h5">
        お知らせ
      </h3>

      <slot name="contents" />

      <v-divider
        class="my-4 info"
        style="opacity: 0.22"
      ></v-divider>

      <v-row
        align="center"
        no-gutters
      >
        <slot name="actions" />
      </v-row>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    btnColor: {
      type: String,
      default: ""
    }
  }
};
</script>
