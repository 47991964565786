<template>
  <SettingLayout :active-index="1" :is-edit.sync="isEdit" @save="save">
    <div class="mt-4">{{ $t("external_app") }}</div>
    <div class="d-flex justify-space-between align-center mt-4">
      <img
        src="../../assets/line.png"
        :style="`width: 55px; ${!user.line_linked && 'opacity: 0.3'}`"
      />
      <div>
        <div>{{ $t("line_app") }}</div>
        <div style="color: #BDBDBD;">{{ $t("click_for_details") }}</div>
      </div>
      <div v-if="user.line_linked">
        <v-btn class="line-linked" disabled>{{ $t("linked") }}</v-btn>
      </div>
      <div v-else>
        <v-btn
          class="green"
          style="width: 100px"
          :href="lineUrl"
          target="_blank"
        >{{ $t("link_acc") }}</v-btn>
      </div>
    </div>
    <div class="mt-4">{{ $t("business_registration_number") }}</div>
    <MstTextField class="mt-4" :disabled="!this.isEdit" :value="regNumber" @input="(e) => handleInputChange(e, 'regNumber')"/>
    <MstButton class="mt-4" :outlined="outlined" :disabled="!canRegNumberSave()" @click="save_edit()">{{ this.registerButtonName }}</MstButton>

    <div class="mt-4">{{ $t("destination") }}</div>
    <div class="mt-2" style="color: #68727B;">{{ $t("destination_postal_code") }}</div>
    <div class="mt-2 postal-code">
      <MstTextField v-model="postalCode1" type="number" width="45px" @input="(e) => handleInputChange(e, 'postalCode1')"/>
      <p style="padding-top: 4px;">-</p>
      <MstTextField v-model="postalCode2" type="number" width="60px" @input="(e) => handleInputChange(e, 'postalCode2')"/>
    </div>
    <p v-if="postalCodeHint" class="postal-code__hint">
      {{ postalCodeHint }}
    </p>

    <div class="mt-2" style="color: #68727B;">{{ $t("destination_address") }}</div>
    <MstTextArea class="mt-2" :value="address" @input="(e) => handleInputChange(e, 'address')"/>
    <MstButton class="mt-4" :disabled="!canPostSave()" @click="save_post()">{{ this.postButtonName }}</MstButton>
  </SettingLayout>
</template>

<script>
import SettingLayout from "@/components/farm/setting/SettingLayout.vue";
import { MstTextArea, MstTextField, MstButton } from "@/components/master";

export default {
  components: { SettingLayout, MstTextArea, MstTextField, MstButton },

  data() {
    return {
      changed: "",
      outlined: false,
      isEdit: false,
      isRegistered: false,
      regNumber: "",
      name: "",
      mail: "",
      tel: "",
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      user: { farm: {} },
      registerButtonName: "",
      postButtonName: "",
      postalCode1: "",
      postalCode2: "",
      address: "",
      isPostRegistered: false,
      postalCodeHint: ""
    };
  },

  async mounted() {
    // Invoice RegNumber
    const { result } = await this.$http2("get", "users/profile");
    this.user = result.user;
    this.regNumber = this.user.farm.invoice_issuer_registration_number !== null ? this.user.farm.invoice_issuer_registration_number : this.regNumber;
    this.isRegistered = this.user.farm.invoice_issuer_registration_number !== null && this.user.farm.invoice_issuer_registration_number !== "";
    if (this.isRegistered) {
      this.registerButtonName = this.$t("update");
      this.outlined = true;
    } else {
      this.registerButtonName = this.$t("register");
      this.isEdit = true;
      this.outlined = false;
    }
    // PostCode Info
    const postalCode = this.user.farm.destination_postal_code !== null ? this.user.farm.destination_postal_code : "";
    this.postalCode1 = postalCode ? postalCode.split("-")[0] : "";
    this.postalCode2 = postalCode ? postalCode.split("-")[1] : "";
    this.address = this.user.farm.destination_address !== null ? this.user.farm.destination_address : "";
    this.isPostRegistered = this.user.farm.destination_postal_code !== null && this.user.farm.destination_address !== null;
    if (this.isPostRegistered) {
      this.postButtonName = this.$t("save");
    } else {
      this.postButtonName = this.$t("register");
    }
  },

  methods: {
    handleInputChange(val, type) {
      switch (type) {
        case "regNumber":
          this.regNumber = val;
          break;
        case "postalCode1":
          if (val.length > 3) {
            this.postalCodeHint = this.$t("destination_postal_code_hint");
            break;
          }
          this.postalCode1 = val;
          this.postalCodeHint = "";
          break;
        case "postalCode2":
          if (val.length > 4) {
            this.postalCodeHint = this.$t("destination_postal_code_hint");
            break;
          }
          this.postalCode2 = val;
          this.postalCodeHint = "";
          break;
        case "address":
          this.address = val;
          break;
        default:
          break;
      }
    },
    canRegNumberSave() {
      return this.regNumber !== "";
    },
    canPostSave() {
      return this.address !== ""
        && this.postalCode1 !== ""
        && this.postalCode2 !== ""
        && this.postalCode1.length === 3
        && this.postalCode2.length === 4;
    },
    save_edit() {
      if (this.isEdit) {
        this.save();
      } else {
        this.isEdit = true;
        this.registerButtonName = this.$t("save");
        this.outlined = false;
      }
    },
    async save() {
      await this.$http("put", `farms/${this.user.farm.id}`, {
        invoice_issuer_registration_number: this.regNumber
      }).then(res => {
        this.changed = this.isRegistered ? "編集" : "登録";
        if (res.status === 200) {
          setTimeout(() => {
            this.$store.dispatch("setMstSnackBar", { msg: `事業者登録番号が正常に${this.changed}されました。`, color: "#21920F" }, { root: true });
            this.$store.dispatch("mstSnackOn", {}, { root: true });
          }, 0);
          this.isRegistered = true;
          this.isEdit = false;
          this.registerButtonName = this.$t("update");
          this.outlined = true;
        } else {
          setTimeout(() => {
            this.$store.dispatch("setMstSnackBar", { msg: `${this.changed}に失敗しました。`, color: "#FFBC3A" }, { root: true });
            this.$store.dispatch("mstSnackOn", {}, { root: true });
          }, 0);
        }
      });
    },
    async save_post() {
      await this.$http("put", `farms/${this.user.farm.id}`, {
        destination_postal_code: `${this.postalCode1}-${this.postalCode2}`,
        destination_address: this.address
      }).then(res => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$store.dispatch("setMstSnackBar", { msg: "更新しました。", color: "#21920F" }, { root: true });
            this.$store.dispatch("mstSnackOn", {}, { root: true });
          }, 0);
          this.isPostRegistered = true;
          this.postButtonName = this.$t("save");
        } else {
          setTimeout(() => {
            this.$store.dispatch("setMstSnackBar", { msg: "更新に失敗しました。", color: "#FFBC3A" }, { root: true });
            this.$store.dispatch("mstSnackOn", {}, { root: true });
          }, 0);
        }
      });
    },
  },

  computed: {
    lineUrl() {
      return process.env.VUE_APP_X_LINE_URL;
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  display: block;
  font-size: 12px;
  color: #777;

  &:not(:first-child) {
    margin-top: 16px;
  }
}

.text {
  padding: 13px 10px;
}

.postal-code {
  display: flex;
  flex-direction: row;
  gap: 10px;
  input {
    border-radius: 4px;
    border: 1px solid variables.$color-gray-200;
    padding: 6px 8px;
    font-size: 16px;
    height: 33px;
    outline: none;
    transition: border 0.3s ease;
  }
}
.postal-code__hint {
  font-size: variables.$font-size-xs;
  line-height: 1.4;
  color: variables.$color-red-500;
}
</style>
