<template>
<div :class="['wrapper', isShow ? 'show' : '']">
  <div class="d-flex">
    <div class="col-4">
      <p class="label-item">{{ $t("shipping_date") }}</p>
    </div>
    <div class="col-8">
      <template>
        <div
          @click="onClickCalendarIcon()"
          class="period"
        >
          <div>{{ formattedPeriod }}</div>
          <img
            class="calendar-icon" :src="require('@/assets/calendar-icon-gray.svg')"
          />
        </div>
        <DateRangePicker
          v-if="isCalendarShow"
          :startDate="startHarvestDate"
          :endDate="endHarvestDate"
          @close="closeCalendar"
          @confirm="confirmPeriod"
        />
        <div class="select-day-of-week-wrapper">
          <div
            @click="onClickDayOfWeek(day.number)"
            v-for="day in isHarvestDaysOfWeek" :key="day.number">
            <div :class="['day-of-week border-right', day.include ? 'active' : '']">
            {{  $t(day.i18Label) }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <div class="d-flex">
    <div class="col-4">
      <p class="label-item">{{ $t("stem_size") }}</p>
    </div>
    <div class="col-8">
      <template>
        <MstTextField v-model="stemSize" type="number" width="100px" @input="handleChangeStemSize($event)">
          <span class="unit-margin">cm</span>
        </MstTextField>
      </template>
    </div>
  </div>
  <div class="d-flex">
    <div class="col-4">
      <p class="label-item">{{ $t("min_count") }}</p>
    </div>
    <div class="col-8">
      <template>
        <MstTextField v-model="minCount" type="number" width="100px" @input="handleChangeMinCount($event)">
          <span class="unit-margin">{{ $t("volumn") }}</span>
        </MstTextField>
      </template>
    </div>
  </div>
  <div class="d-flex">
    <div class="col-4">
      <p class="label-item">{{ $t("set_count") }}</p>
    </div>
    <div class="col-8">
      <template>
        <MstTextField v-model="setCount" type="number" width="100px"  @input="handleChangeSetCount($event)" >
          <span class="unit-margin">{{ $t("lot") }}</span>
        </MstTextField>
      </template>
    </div>
  </div>
  <button
    @click="onClickFilterButton()"
    class="filter-button">絞り込み</button>
</div>
</template>

<script>
import dayjs from "dayjs";
import { MstTextField } from "@/components/master";
import DateRangePicker from "@/components/shared/DateRangePicker.vue";
import sanitizeQueryObject from "@/helpers/sanitizeQueryObject";

export default {
  components: {
    MstTextField,
    DateRangePicker
  },
  props: {
    isShow: Boolean,
  },
  data() {
    return {
      isCalendarShow: false,
      isHarvestDaysOfWeek: [],
      startHarvestDate: dayjs().toDate(),
      endHarvestDate: dayjs().toDate(),
      stemSize: "",
      minCount: "",
      setCount: "",
    };
  },
  created() {
    const minCount = this.$route.query.min_count;
    const setCount = this.$route.query.set_count;
    const stemSize = this.$route.query.stem_size;
    const startHarvestDate = this.$route.query.start_harvest_date;
    const endHarvestDate = this.$route.query.end_harvest_date;
    const workingWdays = this.$store.getters.workingWdays;
    const daysOfWeekLabels = { 0: "short_sunday", 1: "short_monday", 2: "short_tuesday", 3: "short_wednesday", 4: "short_thursday", 5: "short_friday", 6: "short_saturday" };
    const daysOfWeek = this.$route.query.days_of_week;
    const parsedDaysOfWeek = daysOfWeek ? daysOfWeek.split(",").map(Number) : [];
    const isDaysOfWeekSpecified = parsedDaysOfWeek.length > 0;
    this.isHarvestDaysOfWeek = workingWdays.map(day => ({
      i18Label: daysOfWeekLabels[day],
      number: day,
      include: isDaysOfWeekSpecified ? parsedDaysOfWeek.includes(day) : true,
    }));
    // クエリパラメーターの値をステートにセット
    if (minCount) this.minCount = minCount;
    if (setCount) this.setCount = setCount;
    if (stemSize) this.stemSize = stemSize;
    if (startHarvestDate) this.startHarvestDate = dayjs(startHarvestDate).toDate();
    if (endHarvestDate) this.endHarvestDate = dayjs(endHarvestDate).toDate();
  },
  computed: {
    farmDeadline() {
      return this.$store.getters.getFarmDeadline;
    },
    formattedPeriod() {
      if (!this.startHarvestDate && !this.endHarvestDate) return "";

      const startFormatDate = dayjs(this.startHarvestDate).format("YYYY/MM/DD(ddd)");
      const endFormatDate = dayjs(this.endHarvestDate).format("YYYY/MM/DD(ddd)");

      return `${startFormatDate} ~ ${endFormatDate}`;
    },
  },
  methods: {
    onClickCalendarIcon() {
      this.isCalendarShow = true;
      document.body.style.overflow = "hidden";
    },
    closeCalendar() {
      this.isCalendarShow = false;
      document.body.style.overflow = "auto";
    },
    confirmPeriod(start, end) {
      this.startHarvestDate = start;
      this.endHarvestDate = end;
      this.isCalendarShow = false;
      document.body.style.overflow = "auto";
    },
    handleChangeStartHarvestDate(value) {
      this.startHarvestDate = value;
    },
    handleChangeEndHarvestDate(value) {
      this.endHarvestDate = value;
    },
    handleChangeMinCount(value) {
      this.minCount = value;
    },
    handleChangeSetCount(value) {
      this.setCount = value;
    },
    handleChangeStemSize(value) {
      this.stemSize = value;
    },
    onClickDayOfWeek(dayOfWeekNumber) {
      const targetDayIndex = this.isHarvestDaysOfWeek.findIndex(day => day.number === dayOfWeekNumber);
      this.isHarvestDaysOfWeek[targetDayIndex].include = !this.isHarvestDaysOfWeek[targetDayIndex].include;
    },
    onClickFilterButton() {
      this.filterOptionItems = [];

      const daysOfWeek = this.isHarvestDaysOfWeek.filter(day => day.include).map(day => day.number);

      let startFormatDate = dayjs(this.startHarvestDate);
      let endFormatDate = dayjs(this.endHarvestDate);
      if (startFormatDate.toDate() < dayjs(this.farmDeadline).toDate()) startFormatDate = dayjs(this.farmDeadline);
      if (endFormatDate.toDate() < dayjs(this.farmDeadline).toDate()) endFormatDate = dayjs(this.farmDeadline);

      const params = {
        start_harvest_date: startFormatDate.format("YYYY-MM-DD"),
        end_harvest_date: endFormatDate.format("YYYY-MM-DD"),
        min_count: this.minCount,
        set_count: this.setCount,
        stem_size: this.stemSize,
        days_of_week: daysOfWeek.join(",")
      };
      const query = sanitizeQueryObject(params, ["start_harvest_date", "end_harvest_date", "min_count", "set_count", "stem_size", "days_of_week"]);

      this.$router.push({ query });
    },
  }
};
</script>

<style lang="scss" scoped>

.show {
  height: auto !important;
  padding: 16px 8px;
}
.wrapper {
  margin: 16px 12px 0 12px;
  overflow: hidden;
  height: 0;
  background-color: #F4F6F8;
  transition: height 0.4s;

  .period {
    position: relative;
    height: 56px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #D7D7D7;
    display: flex;
    padding: 2px 8px;
    line-height: 26px;
    .calendar-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
    }
  }

  .select-day-of-week-wrapper {
    margin-top: 8px;
    display: flex;
    width: 144px;
    height: 48px;
    border-radius: 4px;
    outline: 1px solid #BFC3C6;
    overflow: hidden;

    .day-of-week {
      width: 48px;
      line-height: 48px;
      text-align: center;
      background: #F4F6F8;
      transition: all 0.4s;
    }

    .active {
      background: #315198;
      color: #ffffff;
      transition: all 0.4s;
    }

    .border-right {
      border-right: 1px solid #BFC3C6;
    }
  }

  .unit-margin {
    margin-left: 8px;
  }

  .filter-button {
    width: 100%;
    background: #173169;
    border-radius: 4px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    color: white;
  }
}
</style>
