<template>
  <ul class="conversationList">
    <li v-for="conversation in conversations" :key="conversation.id" class="conversationList__item">
      <router-link :to="`/${currentUserType.toLowerCase()}/conversations/${conversation.id}`" class="conversationList__inner">
        <div v-if="getTo(conversation).image && getTo(conversation).image.image_url" class="conversationList__imageWrapper">
          <img :src="getTo(conversation).image.image_url" width="50" height="50" alt="" class="conversationList__image" />
        </div>
        <div class="conversationList__content">
          <div class="conversationList__header">
            <strong class="conversationList__name color-black-900 text-md font-weight-bold">{{ getTo(conversation).name }}</strong>
            <time
              :datetime="getFormattedDate(conversation.last_message.created_at, 'YYYYMMDD')"
              class="conversationList__date color-gray-600 text-sm"
            >
              {{ getFormattedDate(conversation.last_message.created_at) }}
            </time>
          </div>
          <div class="conversationList__contentInner">
            <p class="conversationList__message color-gray-600 text-sm">
              {{ getLastMessage(conversation) }}
            </p>
            <MstTextTag
              v-if="conversation.unread_messages_count"
              :text="String(conversation.unread_messages_count)"
              size="sm"
              circle
              class="conversationList__badge"
            />
          </div>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { MstTextTag } from "@/components/master";

export default {
  name: "ConversationList",
  components: { MstTextTag },
  props: {
    conversations: { type: Array, required: true },
    currentUserType: { type: String, validates: value => ["Shop", "Farm"].includes(value), required: true },
  },
  methods: {
    getTo(conversation) {
      return this.currentUserType === "Shop" ? conversation.farm : conversation.shop;
    },
    getFormattedDate(d, format = "MMDD") {
      const date = new Date(d);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return `${this.$date[format](date)} ${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
    },
    getLastMessage(conversation) {
      const lastMessage = conversation.last_message;
      if (lastMessage.body) return lastMessage.body;
      if (lastMessage.sender_type === this.currentUserType) return "あなたが画像を送信しました";
      return `${this.getTo(conversation).name}が画像を送信しました。`;
    },
  },
};
</script>

<style lang="scss" scoped>
.conversationList {
  padding: 0;
}

.conversationList__item {
  display: block;
  list-style-type: none;
}

.conversationList__inner {
  display: flex;
  align-items: center;
  gap: 0 13px;
  padding: 8px 16px;
  width: 100%;
  text-decoration: none;
  outline: none;
  transition: background 0.3s ease;

  &:hover,
  &:focus-visible {
    background: variables.$color-gray-50;
  }
}

.conversationList__image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  vertical-align: top;
  object-fit: cover;
}

.conversationList__content {
  width: 100%;
}

.conversationList__header {
  position: relative;
  margin-bottom: 3px;
  padding-right: 104px;
}

.conversationList__date {
  position: absolute;
  right: 0;
}

.conversationList__message {
  overflow: hidden;
  margin: 0;
  white-space: nowrap;
  width: 18em;
  max-width: 88%;
  text-overflow: ellipsis;
}

.conversationList__contentInner {
  position: relative;
}

.conversationList__badge {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -11px;
}
</style>
