<template>
  <div>
    <div>
      <v-btn class="green ga-farm-add_standard" style="margin-right: .5rem;" @click="open"><v-icon>mdi-plus-circle-outline</v-icon>{{ $t("add_another_standard") }}</v-btn>
    </div>
    <custom-dialog
      v-if="dialogOpen"
      @close="close()"
      :title="title"
    >
    <template v-slot:text>
      <table class="detail-table">
        <tbody>
          <tr>
            <th>
              出品日
            </th>
            <td>
              <span v-if="typeof flowerHarvest.harvest_date === 'string'">{{ harvestDate }}</span>
              <div v-else>
                {{ harvestDate }} 〜 {{ flowerHarvest.harvest_date && flowerHarvest.harvest_date.end  }}<br>
                <div v-if="flowerHarvest.harvest_date && flowerHarvest.harvest_date.weekdays">
                  毎週<span v-for="d in flowerHarvest.harvest_date.weekdays" :key="d">{{ weekdays[d] }}曜日</span>
                </div>
              </div>
              <custom-harvest-date-dialog @edit="onEdit($event, 'harvest_date')" :harvestDate="harvestDate" :allowWdayList="allowWdayList" />
            </td>
          </tr>

          <tr>
            <th>
              商品<br>
              <RequiredOrOptionChip v-if="type === 'edit'" type="required"/>
            </th>
            <td class="align-center">
              <v-select
                v-model="flowerHarvest.flower_id"
                :items="flowers"
                item-value="value"
                item-text="text"
                label="商品を選択する"
                dense
                :readonly="readonly"
                @change="changeFlowerHandler"
              ></v-select>
            </td>
          </tr>
          <tr>
            <th>
              価格<br>
              <RequiredOrOptionChip v-if="type === 'edit'" type="required"/>
            </th>
            <td class="d-flex justify-end align-center">
              <numericInput
                required
                id="price"
                placeholder="50"
                v-model.number="flowerHarvest.price"
                :readonly="readonly"
                @edit="onEdit($event, 'price')"
              />
              <span class="mx-2">円</span>
            </td>
          </tr>

          <tr>
            <th>
              ロット<br>本数<br>
              <RequiredOrOptionChip v-if="type === 'edit'" type="required"/>
            </th>
            <td class="d-flex justify-end align-center">
              <numericInput
                required
                id="min_count"
                placeholder="5"
                v-model.number="flowerHarvest.min_count"
                :readonly="readonly"
                @edit="onEdit($event, 'min_count')"
              />
              <span class="mx-2">本</span>
            </td>
          </tr>

          <tr>
            <th>
              口数<br>
              <RequiredOrOptionChip v-if="type === 'edit'" type="required"/>
            </th>
            <td class="d-flex justify-end align-center">
              <numericInput
                required
                id="set_count"
                placeholder="5"
                v-model.number="flowerHarvest.set_count"
                :readonly="readonly"
                @edit="onEdit($event, 'set_count')"
              />
              <span class="mx-2">口</span>
            </td>
          </tr>

          <tr>
            <th>
              出品<br>総本数<br>
              <RequiredOrOptionChip v-if="type === 'edit'" type="required"/>
            </th>
            <td class="d-flex justify-end align-center">
              <numericInput
                required
                id="confirmed_count"
                placeholder="100"
                v-model.number="confirmedCount"
                readonly
                @edit="onEdit($event, 'confirmed_count')"
              />
              <span class="mx-2">本</span>
            </td>
          </tr>

          <tr>
            <th>
              全長<br>
              <RequiredOrOptionChip v-if="type === 'edit'" type="required"/>
            </th>
            <td class="d-flex justify-end align-center">
              <numericInput
                required
                id="stem_size"
                placeholder="60"
                v-model.number="flowerHarvest.stem_size"
                :readonly="readonly"
                @edit="onEdit($event, 'stem_size')"
              />
              <span class="mx-2">cm</span>
            </td>
          </tr>

          <tr>
            <th>
              備考欄<br>
              <RequiredOrOptionChip v-if="type === 'edit'" type="option"/>
            </th>
            <td>
              <v-textarea
                v-model="flowerHarvest.note"
                filled
                auto-grow
                rows="4"
                row-height="30"
                shaped
                placeholder="購入時に気をつけることや、お花の知識を記入してください。"
                label="備考欄"
                :readonly="readonly"
                @keyup="onEdit($event.target.value, 'note')"
                @blur="onEdit($event.target.value, 'note')"
              ></v-textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <template v-slot:actions>
      <v-row class="buttons">
        <v-col sm="6" cols="12" class="d-flex justify-center align-center">
          <Button v-if="readonly" type="delete" />
          <Button v-if="readonly" type="edit" @click="edit()" />
          <Button v-if="!readonly && type === 'edit' && data" type="return" @click="returnShow()" />
          <Button v-if="readonly && flowerHarvest.status === 'on_sale' && isCurrentDateHarvest" type="update_not_on_sale" @click="updateSalesStatus('not_on_sale')"/>
          <Button v-if="readonly && flowerHarvest.status === 'not_on_sale' && isCurrentDateHarvest" type="update_on_sale" @click="updateSalesStatus('on_sale')"/>
          <Button v-if="!readonly" type="save" :disabled="!canSave()" @click="save()" />
          <Button v-if="!readonly" type="copy" :disabled="!canCopy" @click="copy()" />
        </v-col>
      </v-row>
    </template>
    </custom-dialog>
  </div>
</template>

<script>
import CustomDialog from "@/components/shared/CustomDialog.vue";
import numericInput from "@/components/shared/numericInput.vue";
import Button from "@/components/farm/harvest/ActionButton.vue";
import CustomHarvestDateDialog from "@/components/farm/harvest/CustomHarvestDateDialog.vue";
import RequiredOrOptionChip from "@/components/shared/form/RequiredOrOptionChip.vue";

export default {
  components: { CustomDialog, numericInput, Button, CustomHarvestDateDialog, RequiredOrOptionChip },
  props: {
    harvestDate: {
      type: String,
      default: ""
    },
    dialogOpen: {
      type: Boolean,
      default: false
    },
    allowWdayList: {
      type: Array,
      default: () => ([0, 1, 2, 3, 4, 5, 6])
    }
  },
  data() {
    return {
      type: "show",
      canCopy: false,
      value: 0,
      readonly: false,
      editContentLength: 0,
      flowerHarvest: {
        id: null,
        confirmed_count: null,
        min_count: null,
        set_count: null,
        price: null,
        flower_id: null,
        harvest_date: null,
        stem_size: null,
        note: null,
        status: "on_sale"
      },
      previousHarvest: {},
      weekdays: {
        0: "日",
        1: "月",
        2: "火",
        3: "水",
        4: "木",
        5: "金",
        6: "土"
      },
      editContent: {}
    };
  },
  methods: {
    open() {
      this.clearFlowerHarvest();
      this.$store.dispatch("flowers/getFlowers", this.workingFlowerIds);
      this.flowerHarvest.harvest_date = this.harvestDate;
      this.$emit("open");
    },
    onEdit(e, key) {
      const index = this.getIndex;
      let value = e;
      if (!["status", "note", "harvest_date"].includes(key)) {
        value = Number(e);
      }
      const event = {
        target: {
          value
        }
      };
      this.$emit("on-edit", event, index, key);
      this.editContentLength++;
      this.flowerHarvest[key] = value;

      if (["min_count", "set_count"].includes(key)) {
        const confirmed_count_value = this.flowerHarvest.min_count * this.flowerHarvest.set_count;
        this.$emit("on-edit", { target: { value: confirmed_count_value } }, index, "confirmed_count");
        this.flowerHarvest.confirmed_count = confirmed_count_value;
      }
    },
    async changeFlowerHandler(e) {
      this.onEdit(e, "flower_id");
      await this.$api.get(`flowers/${this.flowerHarvest.flower_id}/flower_harvests`)
        .then(res => {
          this.previousHarvest = res.result.flower_harvest;
          this.canCopy = true;
        });
    },
    save() {
      this.$emit("edit-handler");
      this.editContentLength = 0;
      this.clearFlowerHarvest();
      this.type = "show";
    },
    copy() {
      this.flowerHarvest.confirmed_count = this.previousHarvest.confirmed_count;
      this.flowerHarvest.min_count = this.previousHarvest.min_count;
      this.flowerHarvest.set_count = this.previousHarvest.confirmed_count / this.previousHarvest.min_count;
      this.flowerHarvest.price = this.previousHarvest.price;
      this.flowerHarvest.stem_size = this.previousHarvest.stem_size;
      this.flowerHarvest.note = this.previousHarvest.note;
      /* 固定値 */
      this.canCopy = false;
    },
    updateSalesStatus(status) {
      if (!["on_sale", "not_on_sale"].includes(status)) return;
      const index = this.getIndex;
      const event = {
        target: {
          value: status
        }
      };
      this.$emit("on-edit", event, index, "status");
      this.$emit("edit-handler");
    },
    close() {
      this.editContent = {};
      this.editContentLength = 0;
      this.previousHarvest = {};
      this.clearFlowerHarvest();
      this.$emit("close", this.dialogOpen);
      this.type = "show";
      this.canCopy = false;
    },
    clearFlowerHarvest() {
      Object.keys(this.flowerHarvest).forEach(key => {
        this.flowerHarvest[key] = null;
      });
    },
    setFlowerHarvest() {
      this.flowerHarvest.flower_id = this.data.flower_id;
      this.flowerHarvest.harvest_date = this.harvestDate;
      this.flowerHarvest.status = this.data.status;

      if (this.isCurrentDateHarvest) {
        this.flowerHarvest.id = this.data.id;
        this.flowerHarvest.confirmed_count = this.data.confirmed_count;
        this.flowerHarvest.min_count = this.data.min_count;
        this.flowerHarvest.set_count = this.data.set_count;
        this.flowerHarvest.price = this.data.price;
        this.flowerHarvest.stem_size = this.data.stem_size;
        this.flowerHarvest.note = this.data.note;
      }
    },
    edit() {
      this.$emit("changeReadOnly");
      this.type = "edit";
    },
    returnShow() {
      this.$emit("changeReadOnly");
      this.type = "show";
    },
    canSave() {
      if (this.isCurrentDateHarvest) return this.editContentLength && this.stemSizeValidater > 0 && this.priceValidater;
      return this.flowerHarvest.confirmed_count && this.flowerHarvest.min_count && this.flowerHarvest.set_count && this.flowerHarvest.flower_id && this.flowerHarvest.stem_size
      && this.flowerHarvest.harvest_date
      && this.priceValidater;
    }
  },
  computed: {
    flowers() {
      const flowers = [];
      this.workingFlowerIds.map(id => flowers.push({ text: this.$store.state.flowers.list[id].flower_specie.name, value: id }));
      return flowers;
    },
    workingFlowerIds() {
      return this.$store.state.flowers.listArr.filter(arr => this.$store.state.flowers.list[arr].is_working === true);
    },
    flower() {
      if (!this.flowerHarvest.flower_id) return false;
      return this.$store.getters["flowers/getFlower"](this.flowerHarvest.flower_id);
    },
    getIndex() {
      if (!this.data) return -1;
      const target_harvest = this.$store.state.flowerHarvest.list.find(item => item.id === this.data.id && item.flower_id === this.data.flower_id);
      return this.$store.state.flowerHarvest.list.indexOf(target_harvest);
    },
    priceValidater() {
      return this.flowerHarvest.price && this.flowerHarvest.price > 0;
    },
    title() {
      if (!this.data) return this.$t("add_another_standard");

      if (this.isCurrentDateHarvest) return "出品情報";

      return "出品する";
    },
    confirmedCount() {
      return this.flowerHarvest.min_count * this.flowerHarvest.set_count;
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.setFlowerHarvest();
      }
    },
    flowerHarvest: {
      handler (harvest) {
        this.canSave();
        if (Object.keys(this.previousHarvest).length > 0) {
          this.onEdit(harvest.price, "price");
          this.onEdit(harvest.min_count, "min_count");
          this.onEdit(harvest.set_count, "set_count");
          this.onEdit(harvest.stem_size, "stem_size");
          this.onEdit(harvest.harvest_date, "harvest_date");
          this.onEdit(harvest.note, "note");
        }
      },
      deep: true
    }
  }
};
</script>

<style  lang="scss" scoped>
@media only screen and (min-width: 600px) {
  .detail-table {
    margin: 0;
    & td:nth-child(1) {
      width: 100px;
    }
  }
}

.detail-table {
  width: 100%;
  table-layout: fixed;

  & th {
    background: white;
    border-bottom: 1px solid #cccccc;
    width: 60px;
  }

  & td {
    background: white;
    border-bottom: 1px solid #cccccc;
    padding: 20px 5px 20px 5px;
  }

  & .v-select {
    margin-bottom: -15px !important;
  }

  & td:nth-child(1) {
    width: 100px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9rem;
  }

  & tr:nth-last-child(1) td,
  & tr:nth-last-child(1) th {
    border: none;
  }
}
</style>
