<template>
  <SettingLayout
    tab="account"
    @edit="edit"
    :on-submit="submit"
    :is-edit.sync="isEdit"
    :disabled="!user.id"
  >
    <template v-if="isEdit">
      <SettingTextInput
        :label="$t('email')"
        type="email"
        required
        placeholder="info@cavin.jp"
        v-model="email"
      />

      <SettingTextInput :label="$t('new_pwd')" type="password" v-model="newPassword" />

      <SettingTextInput
        :label="$t('confirm_new_pwd')"
        type="password"
        :semi-required="!!newPassword"
        :match="newPassword"
        :mismatch-message="$t('pwd_mismatch')"
        v-model="newPasswordConfirm"
      />
    </template>

    <template v-else>
      <SettingTextView :label="$t('email')">{{ user.email }}</SettingTextView>
      <SettingTextView :label="$t('pwd')">{{ user.id && "********" }}</SettingTextView>
    </template>

  <div v-if="mobile">
    <v-divider />
    <div class="mt-4">{{ $t("external_app") }}</div>
    <div class="d-flex justify-space-between align-center mt-4">
      <img
        src="../../../assets/line.png"
        :style="`width: 55px; ${!user.line_linked && 'opacity: 0.3'}`"
      />
      <div>
        <div>{{ $t("line_app") }}</div>
        <div style="color: #BDBDBD;">{{ $t("click_for_details") }}</div>
      </div>
      <div v-if="user.line_linked">
        <v-btn class="line-linked" disabled>{{ $t("linked") }}</v-btn>
      </div>
      <div v-else>
        <v-btn
          class="green"
          style="width: 100px"
          :href="lineUrl"
          target="_blank"
        >{{ $t("link_acc") }}</v-btn>
      </div>
    </div>

  </div>
  </SettingLayout>
</template>

<script>
import SettingLayout from "@/components/shop/settings/SettingLayout.vue";
import SettingTextInput from "@/components/shop/settings/SettingTextInput.vue";
import SettingTextView from "@/components/shop/settings/SettingTextView.vue";

export default {
  components: {
    SettingLayout,
    SettingTextInput,
    SettingTextView
  },

  data() {
    return {
      isEdit: false,
      email: "",
      newPassword: "",
      newPasswordConfirm: "",
      representative_name: "",
      phone: "",
      user: { shop: {} }
    };
  },

  async mounted() {
    const { result } = await this.$http2("get", "users/profile");
    this.user = result.user;
  },

  methods: {
    edit() {
      this.email = this.user.email;
      this.newPassword = "";
      this.newPasswordConfirm = "";
    },

    async submit() {
      const user = {
        email: this.email
      };

      if (this.newPassword) {
        user.password = this.newPassword;
        user.password_confirmation = this.newPasswordConfirm;
      }

      await this.$api.put("/users", { user }).then(res => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$store.dispatch("setMstSnackBar", { msg: "更新しました。", color: "#21920F" }, { root: true });
            this.$store.dispatch("mstSnackOn", {}, { root: true });
          }, 0);
          this.$store.commit("setData", { email: user.email });
          this.user = { ...this.user, ...user };
        } else {
          setTimeout(() => {
            this.$store.dispatch("setMstSnackBar", { msg: "更新に失敗しました。", color: "#FFBC3A" }, { root: true });
            this.$store.dispatch("mstSnackOn", {}, { root: true });
          }, 0);
        }
      });

      this.isEdit = false;
    }
  },

  computed: {
    lineUrl() {
      return process.env.VUE_APP_X_LINE_URL;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    }
  }
};
</script>

<style lang="scss" scoped>
.line-linked {
  background: #eeeeee;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 3px;
  color: #bdbdbd;
}
</style>
