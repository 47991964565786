<template>
  <Detail/>
</template>

<script>
import Detail from "@/components/farm/flower_harvests/detail.vue";

export default {
  components: {
    Detail
  }
};
</script>
