<template>
  <SettingLayout :active-index="0" :is-edit.sync="isEdit" @save="save">
    <div v-if="isEdit">
      <label class="label">{{ $t("representative_name") }}</label>
      <input type="text" v-model="name" placeholder="山田太郎" />

      <label class="label">{{ $t("phone_number") }}</label>
      <input type="text" v-model="tel" placeholder="12345678901" />

      <label class="label">{{ $t("q_running_farm") }}</label>
      <div class="d-flex flex-column align-start">
        <div class="d-flex flex-row align-center mt-3 selectable" v-on:click="toggleInDoor">
          <span class="checkbox mr-2" :class="{active: inDoor}"></span>
          <label class="checkbox-label">{{ $t("in_door") }}</label>
        </div>
        <div class="d-flex flex-row align-center mt-2 selectable" v-on:click="toggleOutDoor">
          <span class="checkbox mr-2" :class="{active: outDoor}"></span>
          <label class="checkbox-label">{{ $t("out_door") }}</label>
        </div>
      </div>
      <label class="label mt-7">{{ $t("q_answer_the_questions") }}</label>
      <div class="box d-flex flex-column mt-2">
        <div class="box-header d-flex align-center pl-3">{{ $t("in_door") }}</div>
        <div class="box-body d-flex flex-column justify-space-between">
          <div class="d-flex flex-column mb-7">
            <label class="box-label">{{ $t("q_running_farm") }}</label>
            <div class="d-flex align-end">
              <input type="number" v-model="numberOfHouse" />
              <span class="unit ml-1">個</span>
            </div>
          </div>
          <div class="d-flex flex-column">
            <label class="box-label">{{ $t("q_size_of_farm") }}</label>
            <div class="d-flex align-end">
              <input type="number" v-model="sizeOfInDoorMin" />
              <span class="unit ml-1 mr-3">㎡</span>
              <p class="black--text mr-3">〜</p>
              <input type="number" v-model="sizeOfInDoorMax" />
              <span class="unit ml-1 mr">㎡</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box d-flex flex-column mt-2">
        <div class="box-header d-flex align-center pl-3">{{ $t("out_door") }}</div>
        <div class="box-body d-flex flex-column justify-space-between">
          <div class="d-flex flex-column">
            <label class="box-label">{{ $t("q_size_of_farm") }}</label>
            <div class="d-flex align-end">
              <input type="number" v-model="sizeOfOutDoorMin" />
              <span class="unit ml-1 mr-3">㎡</span>
              <p class="black--text mr-3">〜</p>
              <input type="number" v-model="sizeOfOutDoorMax" />
              <span class="unit ml-1 mr">㎡</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <label class="label">{{ $t("representative_name") }}</label>
      <div class="pl-2">{{ this.name || "未記入" }}</div>

      <label class="label">{{ $t("phone_number") }}</label>
      <div class="pl-2">{{ this.tel || "未記入" }}</div>

      <div class="d-flex flex-column align-start">
        <div class="d-flex flex-row align-center mt-3 selectable ml-2">
          <span class="checkbox mr-2" :class="{active: inDoor}"></span>
          <label class="checkbox-label">{{ $t("in_door") }}</label>
        </div>
        <div class="d-flex flex-row align-center mt-2 selectable ml-2">
          <span class="checkbox mr-2" :class="{active: outDoor}"></span>
          <label class="checkbox-label">{{ $t("out_door") }}</label>
        </div>
      </div>
      <div class="box d-flex flex-column mt-2">
        <div class="box-header d-flex align-center pl-3">{{ $t("in_door") }}</div>
        <div class="box-body d-flex flex-column justify-space-between">
          <div class="d-flex flex-column mb-7">
            <label class="box-label">{{ $t("number_of_vinyl_house") }}</label>
            <div class="d-flex align-end">
              <h3 class="grey--text text--darken-3">{{ numberOfHouse }}</h3>
              <span class="unit ml-1">個</span>
            </div>
          </div>
          <div class="d-flex flex-column">
            <label class="box-label">{{ $t("size_of_farm") }}</label>
            <div class="d-flex align-end">
              <h3 class="grey--text text--darken-3">{{ sizeOfInDoorMin }}</h3>
              <span class="unit ml-1 mr-3">㎡</span>
              <p class="black--text mr-3">〜</p>
              <h3 class="grey--text text--darken-3">{{ sizeOfInDoorMax }}</h3>
              <span class="unit ml-1 mr">㎡</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box d-flex flex-column mt-2">
        <div class="box-header d-flex align-center pl-3">屋外</div>
        <div class="box-body d-flex flex-column justify-space-between">
          <div class="d-flex flex-column">
            <label class="box-label">{{ $t("size_of_farm") }}</label>
            <div class="d-flex align-end">
              <h3 class="grey--text text--darken-3">{{ sizeOfOutDoorMin }}</h3>
              <span class="unit ml-1 mr-3">㎡</span>
              <p class="black--text mr-3">〜</p>
              <h3 class="grey--text text--darken-3">{{ sizeOfOutDoorMax }}</h3>
              <span class="unit ml-1 mr">㎡</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SettingLayout>
</template>

<script>
import SettingLayout from "@/components/farm/setting/SettingLayout.vue";

export default {
  components: { SettingLayout },

  data() {
    return {
      isEdit: false,
      name: "",
      tel: "",
      inDoor: false,
      outDoor: false,
      numberOfHouse: 0,
      sizeOfInDoorMin: 0,
      sizeOfInDoorMax: 0,
      sizeOfOutDoorMin: 0,
      sizeOfOutDoorMax: 0
    };
  },

  methods: {
    save() {
      this.isEdit = false;
    },
    toggleInDoor() {
      this.inDoor = !this.inDoor;
    },
    toggleOutDoor() {
      this.outDoor = !this.outDoor;
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  display: block;
  font-size: 16px;
  color: #111;

  &:not(:first-child) {
    margin-top: 16px;
  }
}
.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background: #ffffff;
  border: 2px solid #cccccc;
  &.active {
    background: #48ae84;
  }
}
.checkbox-label {
  font-size: 14px;
  color: #555;
}
.text {
  padding: 13px 10px;
}
.selectable {
  cursor: pointer;
}
.box {
  width: 450px;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  background-color: #eee;
}
.box-header {
  width: 100%;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  background-color: #777;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.box-body {
  margin: 22px 20px;
}
.box-label {
  font-size: 16px;
  color: #555;
}
.unit {
  font-size: 16px;
  color: #333;
}
.box input {
  width: 100px;
  height: 36px;
  padding: 8px;
}
</style>
