import axios from "axios";

const host = process.env.VUE_APP_URL;
const host_v2 = process.env.VUE_APP_URL_V2;
let activeConn = 0;

/**
 * HTTP requests
 * @param {string} method GET | POST | PUT | DELETE
 * @param {string} url    Desired URL
 * @param {any}    data   Body post content
 * @returns {Promise} Answer from server
 */

export default {
  install(Vue, store) {
    Vue.prototype.$http = function(method, url, data, setBusy = true) {
      url = `${host}/${url}`;

      if (setBusy) store.dispatch("setBusy", true);
      activeConn++;

      let http;
      switch (method) {
        case "get":
          http = axios.get(url, data);
          break;
        case "post":
          http = axios.post(url, data);
          break;
        case "put":
          http = axios.put(url, data);
          break;
        case "delete":
          http = axios.delete(url);
          break;

        default:
          break;
      }

      return new Promise(resolve => {
        http
          .then(res => {
            // eslint-disable-next-line no-console
            console.log("[HTTP]...", method, url, res);

            activeConn--;
            if (!activeConn && setBusy) { store.dispatch("setBusy", false); }

            if (!res.data) res.data = { status: res.status };

            resolve(res.data);
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log("[Axios err]...", err);

            activeConn--;
            if (setBusy) store.dispatch("setBusy", false);

            if (err.response && err.response.status === 401) {
              store.dispatch("logout");
            }

            resolve({});
          });
      });
    };

    Vue.prototype.$http2 = function(method, url, data, setBusy = true) {
      url = `${host_v2}/${url}`;

      if (setBusy) store.dispatch("setBusy", true);
      activeConn++;

      let http;
      switch (method) {
        case "get":
          http = axios.get(url, data);
          break;
        case "post":
          http = axios.post(url, data);
          break;
        case "put":
          http = axios.put(url, data);
          break;
        case "delete":
          http = axios.delete(url);
          break;

        default:
          break;
      }

      return new Promise(resolve => {
        http
          .then(res => {
            // eslint-disable-next-line no-console
            console.log("[HTTP]...", method, url, res);

            activeConn--;
            if (!activeConn && setBusy) { store.dispatch("setBusy", false); }

            if (!res.data) res.data = { status: res.status };

            resolve(res.data);
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log("[Axios err]...", err);

            activeConn--;
            if (setBusy) store.dispatch("setBusy", false);

            if (err.response && err.response.status === 401) {
              store.dispatch("logout");
            }

            resolve({});
          });
      });
    };
  }
};
