<template>
  <SettingLayout
    :key="updated"
    tab="shipping_address"
    :on-submit="submit"
    :is-edit.sync="isEdit"
    :disabled="!user.id"
    :readonly="true"
  >
    <div class="d-flex flex-wrap align-center">
      <v-card flat>
        <v-tabs v-model="tab" @change="changeHandler" color="#00796B" show-arrows>
          <v-tab
            v-for="(location, i) in locations"
            :key="location.id"
          >{{ $t("delivery_address") }}{{ i+1 }}</v-tab>
        </v-tabs>
      </v-card>

    </div>

    <div class="mt-6">
      <template v-if="isEdit">
        <SettingTextInput
          :label="$t('store_name')"
          required
          placeholder="CAVINフラワーショップ"
          v-model="location.name"
        />

        <SettingTextInput
          label="電話番号"
          required
          id="phone"
          placeholder="000-0000-0000"
          v-model="location.phone"
        />

        <SettingTextInput
          label="郵便番号 (ハイフン無し)"
          required
          id="zip"
          placeholder="8100041"
          v-model="location.postal_code"
        />
        <SettingTextInput
          id="prefecture"
          :error="prefectureInvalid"
          label="都道府県名"
          required
          placeholder="福岡県"
          v-model="location.prefecture"
        />
        <SettingTextInput
          id="municipality"
          label="市町村名"
          required
          placeholder="福岡市"
          v-model="location.municipality"
        />
        <SettingTextInput
          id="address"
          label="住所"
          required
          placeholder="中央区白金45-2"
          v-model="location.address"
        />
        <SettingTextInput
          id="building"
          label="建物名"
          placeholder="CAVINビル 202号"
          v-model="location.building_name"
        />
        <SettingTextInput
          id="when_absent"
          label="不在時の納品場所"
          placeholder="屋根の下"
          v-model="location.when_absent"
        />

        <div v-if="!location.default && !isAdd" style="text-align:right" class="mt-5">
          <v-btn color="error" @click="delDialog = true" outlined>
            <v-icon dense>mdi-trash-can</v-icon>
            {{ $t("del_shipping_address") }}
          </v-btn>
        </div>
      </template>
      <template v-else>
        <SettingTextView :label="$t('store_name')">{{ location.name }}</SettingTextView>
        <SettingTextView label="電話番号">{{ location.phone }}</SettingTextView>
        <SettingTextView label="郵便番号 (ハイフン無し)">{{ location.postal_code }}</SettingTextView>
        <SettingTextView label="都道府県名">{{ location.prefecture }}</SettingTextView>
        <SettingTextView label="市町村名">{{ location.municipality }}</SettingTextView>
        <SettingTextView label="住所">{{ location.address }}</SettingTextView>
        <SettingTextView label="建物名">{{ location.building_name }}</SettingTextView>
        <SettingTextView label="不在時の配送場所">{{ location.when_absent }}</SettingTextView>
      </template>
    </div>

    <v-dialog v-model="delDialog" width="500">
      <v-card>
        <v-card-title class="headline" primary-title>{{ $t("confirm") }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text class="mt-4">{{ $t("delivery_address") }}{{ tab }}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="del">{{ $t("confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </SettingLayout>
</template>

<script>
import SettingLayout from "@/components/shop/settings/SettingLayout.vue";
import SettingTextInput from "@/components/shop/settings/SettingTextInput.vue";
import SettingTextView from "@/components/shop/settings/SettingTextView.vue";

export default {
  components: { SettingLayout, SettingTextInput, SettingTextView },
  data() {
    return {
      delDialog: false,
      isEdit: false,
      isAdd: false,
      prefectureInvalid: false,
      tab: 0,
      locationId: null,
      location: {},
      updated: 0,
      locationFields: [
        "default",
        "name",
        "phone",
        "postal_code",
        "prefecture",
        "municipality",
        "address",
        "building_name",
        "when_absent"
      ]
    };
  },

  watch: {
    isEdit() {
      if (!this.isEdit) {
        this.isAdd = false;
        this.setLocation(this.tab);
      }
    }
  },

  async created() {
    await this.$store.dispatch("getProfile", "shop");

    this.setLocation(0);
  },

  methods: {
    add() {
      this.location = {};
      this.locationId = null;
      this.isEdit = true;
      this.isAdd = true;
      this.delDialog = false;
    },
    async submit() {
      if (this.isAdd) {
        this.prefectureInvalid = false;
        if (!this.location.prefecture) {
          this.prefectureInvalid = true;
          return;
        }

        const res = await this.$store.dispatch("postLocation", {
          ...this.location,
          default: false
        });

        if (res) {
          this.tab = res;
          this.setLocation(res);
        }
      } else {
        const content = {};
        const oldLocation = this.$store.state.user.shop.locations[this.tab + 1];

        Object.keys(this.location).forEach(x => {
          if (oldLocation[x] !== this.location[x]) {
            content[x] = this.location[x];
          }
        });

        if (Object.keys(content).length) {
          await this.$store.dispatch("putLocation", {
            id: this.locationId,
            content
          });

          this.isEdit = false;
        }
      }
    },
    async del() {
      await this.$store.dispatch("delLocation", this.locationId);

      this.tab = 0;

      this.reset();
    },
    changeHandler(e) {
      this.setLocation(e);
    },
    setLocation(idx) {
      this.reset();

      const location = this.locations[idx];

      if (location) {
        this.locationId = location.id;
        this.locationFields.forEach(x => {
          this.location[x] = location[x];
        });
      }
      this.updated++;
    },
    reset() {
      this.location = {};
      this.locationId = null;
      this.isEdit = false;
      this.isAdd = false;
      this.delDialog = false;
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    locations() {
      if (!this.$store.state.user.shop.locations) return [];
      return this.$store.state.user.shop.locations.filter(x => !x.default);
    },
    hideAdd() {
      return this.$store.getters.isAddressLimit;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    }
  }
};
</script>

<style scoped>
</style>
