<template>
  <div>
    <v-dialog v-model="open" @click:outside="$emit('close')" width="500" scrollable>
      <v-card>
        <v-card-title v-if="title" class="headline lighten-2" primary-title>
          <div>{{ title }}</div>
          <div class="ml-auto">
            <v-icon @click="$emit('close')">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="pa-2">
          <slot name="text"/>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="!hideActions" class="card-actions">
          <slot name="actions"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: true
    };
  }
};
</script>

<style>
.headline {
  background-color: rgb(240, 240, 240);
}

.card-actions {
  height: 70px;
  background-color: rgb(240, 240, 240);
}
</style>
