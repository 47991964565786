<template>
  <v-list-item :style="disabled && 'background: #F5F5F5'" @click="$emit('change', menu)">
    <v-list-item-content>
      <div class="d-flex justify-space-between" :style="disabled && 'color: #BDBDBD'">
        <div class="align-center justify-center d-flex">{{ name }}</div>

        <div v-if="menu === 'date' && hasDateValue(value)" class="filter-mob-font">
          <div v-if="value">{{ localeDate(value, 'select_full') }}</div>
        </div>
        <div v-else-if="value && menu === 'stem_size'" class="filter-mob-font">
          <div v-if="!value.min && !value.max" class="grey--text text--darken-3">{{ $t("unspecified") }}</div>
          <div v-else>
          {{ value.min ? value.min : 0 }} {{ $t("size_label" ) }}〜{{ value.max ? value.max : 0 }} {{ $t("size_label" ) }}
          </div>
        </div>
        <div v-else-if="value && menu != 'date'" class="filter-mob-font">{{ value }}</div>
        <div v-else class="item-unselected">{{ $t("unselected") }}</div>
      </div>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn icon>
        <v-icon :color="disabled ? 'grey lighten-1':''">mdi-chevron-right</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { localeDate } from "@/plugins/i18n";

export default {
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Array, Date, Object],
      default: null
    },
    menu: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    localeDate,
    hasDateValue(value) {
      return value;
    }
  }
};
</script>

<style scope>
.item-unselected {
  color: #999999
}
</style>
