<template>
  <!-- eslint-disable -->

  <svg class="pointer" :height="size" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.326017 1.00199L5.19802 5.99998L0.326018 10.998C0.238795 11.0873 0.189965 11.2072 0.189965 11.332C0.189965 11.4568 0.238795 11.5767 0.326018 11.666C0.368382 11.7092 0.418948 11.7436 0.474754 11.767C0.53056 11.7905 0.590485 11.8026 0.651018 11.8026C0.711551 11.8026 0.771475 11.7905 0.827281 11.767C0.883087 11.7436 0.933653 11.7092 0.976018 11.666L6.16002 6.34898C6.25103 6.25561 6.30197 6.13038 6.30197 5.99998C6.30197 5.86959 6.25103 5.74436 6.16002 5.65098L0.977017 0.333985C0.934621 0.290442 0.883931 0.255833 0.82794 0.232201C0.771949 0.20857 0.711791 0.196396 0.651017 0.196396C0.590243 0.196396 0.530085 0.20857 0.474094 0.232201C0.418103 0.255833 0.367413 0.290442 0.325017 0.333985C0.237795 0.423283 0.188964 0.543158 0.188964 0.667986C0.188964 0.792813 0.237795 0.912687 0.325017 1.00199L0.326017 1.00199Z" :fill="color"/>
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>
