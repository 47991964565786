<template>
  <div ref="container" class="mstImageCarousel" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
    <div
      :class="styledMainClasses"
      :style="mainStyled"
      class="mstImageCarousel__main"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <transition name="request">
        <div v-if="requestOnly && current === 0" class="mstImageCarousel__requestOnly">
          リクエストのみ可能
        </div>
      </transition>

      <ul :style="styled" class="mstImageCarousel__images">
        <li
          v-for="(image, i) in images"
          :key="i"
          :id="`${componentId}-image`"
          :aria-hidden="String(i !== current)"
          :aria-labelledby="`${componentId}-indicator`"
          role="tabpanel"
          class="mstImageCarousel__imageItem"
        >
          <img :src="image" alt="" class="mstImageCarousel__image" />
        </li>
      </ul>
    </div>

    <div v-if="!hideArrow && length > 1" class="mstImageCarousel__controller">
      <button type="button" aria-label="前へ" class="mstImageCarousel__controllerButton -prev" @click="handleClickController(-1)">
        <MstIcon type="chevron-left" size="24px" />
      </button>
      <button type="button" aria-label="次へ" class="mstImageCarousel__controllerButton -next" @click="handleClickController(1)">
        <MstIcon type="chevron-right" size="24px" />
      </button>
    </div>

    <div v-if="!hideIndicator && length > 1" class="mstImageCarousel__indicator">
      <button
        ref="indicator"
        v-for="(_, i) in images"
        :key="i"
        :id="`${componentId}-indicator`"
        :aria-controls="`${componentId}-image`"
        :aria-selected="String(i === current)"
        :tabindex="i === current ? 0 : -1"
        type="button"
        role="tab"
        class="mstImageCarousel__indicatorButton"
        @focus="handleFocusIndicator"
        @blur="handleBlurIndicator"
        @keydown="handleKeyDownIndicator"
        @click="handleClickIndicator(i)"
      >
        {{ i + 1 }}
      </button>
    </div>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import { MstIcon } from "@/components/master";

export default {
  name: "MstImageCarousel",
  components: { MstIcon },
  props: {
    items: { type: Array, required: true },
    aspect: { type: String, default: "13 / 15" },
    interval: { type: Number, default: 6000 },
    id: { type: String },
    hideArrow: { type: Boolean },
    hideIndicator: { type: Boolean },
    auto: { type: Boolean },
    requestOnly: { type: Boolean },
  },
  data() {
    return {
      current: 0,
      containerWidth: 0,
      cullTime: null,
      swipe: {
        start: 0,
        move: 0,
        diff: 0,
        isSwipe: false,
      },
    };
  },
  computed: {
    componentId() {
      return this.id ? this.id : `carousel-${nanoid()}`;
    },
    images() {
      const items = JSON.parse(JSON.stringify(this.items));

      if (this.requestOnly) items.unshift(items[0]);

      return items;
    },
    length() {
      return this.images.length;
    },
    styledMainClasses() {
      const classes = [];
      if (this.requestOnly && this.current === 0) classes.push("is-requestOnly");
      return classes;
    },
    mainStyled() {
      const styled = {};
      styled.aspectRatio = this.aspect;
      return styled;
    },
    styled() {
      const styled = {};
      if (this.swipe.isSwipe) styled.transition = "none";
      styled.transform = `translateX(${-(this.containerWidth * this.current - this.swipe.diff)}px)`;
      return styled;
    },
  },
  watch: {
    windowWidth() {
      this.setContainerWidth();
    },
  },
  mounted() {
    this.setContainerWidth();
    this.setAutoRotate();
  },
  beforeDestroy() {
    this.stopAutoRotate();
  },
  methods: {
    setAutoRotate() {
      if (!this.auto) return;
      this.cullTime = window.setInterval(() => {
        this.current = (this.current + 1) % this.length;
      }, this.interval);
    },
    stopAutoRotate() {
      if (!this.auto) return;
      window.clearInterval(this.cullTime);
      this.cullTime = null;
    },
    setContainerWidth() {
      this.containerWidth = this.$refs.container ? this.$refs.container.clientWidth : 0;
    },
    handleClickController(addNumber) {
      this.stopAutoRotate();

      if (addNumber === 1 && this.current === this.length - 1) {
        this.current = 0;
      } else if (addNumber === -1 && this.current === 0) {
        this.current = this.length - 1;
      } else {
        this.current += addNumber;
      }

      this.setAutoRotate();
    },
    handleClickIndicator(number) {
      this.current = number;
    },
    handleFocusIndicator() {
      this.stopAutoRotate();
    },
    handleBlurIndicator() {
      if (this.cullTime) return;
      this.setAutoRotate();
    },
    handleKeyDownIndicator(event) {
      switch (event.key) {
        case "ArrowLeft":
          this.current = this.current > 0 ? this.current - 1 : this.length - 1;
          break;
        case "ArrowRight":
          this.current = this.current < this.length - 1 ? this.current + 1 : 0;
          break;
        case "Home":
          this.current = 0;
          break;
        case "End":
          this.current = this.length - 1;
          break;
        default:
          break;
      }

      this.$refs.indicator[this.current].focus();
    },
    handleTouchStart(event) {
      event.preventDefault();
      this.swipe.start = event.touches[0].pageX;
      this.swipe.isSwipe = true;

      this.stopAutoRotate();
    },
    handleTouchMove(event) {
      if (event.touches.length > 1 || (event.scale && event.scale !== 1)) return;
      event.preventDefault();
      this.swipe.move = event.touches[0].pageX;

      const diff = this.swipe.move - this.swipe.start;
      this.swipe.diff = (this.current === 0 && diff > 0) || (this.current === this.length - 1 && diff < 0) ? diff / 6 : diff;
    },
    handleTouchEnd() {
      if (this.current > 0 && this.swipe.diff > 60) {
        this.current -= 1;
      }

      if (this.current < this.length - 1 && this.swipe.diff < -60) {
        this.current += 1;
      }

      this.swipe.start = 0;
      this.swipe.move = 0;
      this.swipe.diff = 0;
      this.swipe.isSwipe = false;

      this.setAutoRotate();
    },
    handleMouseEnter() {
      this.stopAutoRotate();
    },
    handleMouseLeave() {
      this.setAutoRotate();
    },
  },
};
</script>

<style lang="scss" scoped>
.mstImageCarousel {
  position: relative;
}

.mstImageCarousel__main {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: variables.$color-black-900;
}

.mstImageCarousel__requestOnly {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  font-size: 32px;
  font-weight: bold;
  color: variables.$color-white;
}

.mstImageCarousel__images {
  display: flex;
  padding: 0;
  height: 100%;
  list-style-type: none;
  transition: transform 0.3s ease;
}

.mstImageCarousel__imageItem {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  background: variables.$color-black-900;
}

.mstImageCarousel__image {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
}

.mstImageCarousel__controllerButton {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -25px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: rgba(11, 11, 11, 0.7);
  color: variables.$color-white;

  &.-prev {
    left: 12px;
  }

  &.-next {
    right: 12px;
  }
}

.mstImageCarousel__indicator {
  position: absolute;
  bottom: 9px;
  z-index: 5;
  display: flex;
  justify-content: center;
  gap: 0 8px;
  width: 100%;
}

.mstImageCarousel__indicatorButton {
  overflow: hidden;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  background: rgba(255, 255, 255, 0.2);
  white-space: nowrap;
  text-indent: 10em;
  transition: background 0.3s ease;

  &[aria-selected='true'] {
    background: variables.$color-white;
  }
}

.request-enter-active,
.request-leave-active {
  transition: opacity 0.3s ease;
}

.request-enter,
.request-leave-to {
  opacity: 0;
}
</style>
