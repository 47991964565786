<template>
  <div>
    <div v-if="!isFirstHarvestOnCurrentDate || !flowerHarvest.is_status_editable" :class="[`border-${getStatus.status}`]" style="padding: 0 .5rem; white-space: nowrap;">
      <span :class="[`color-${getStatus.status}`, `font-weight-bold`, `text-caption`]"> {{ getStatus.label }}</span>
    </div>
    <div v-else>
      <v-switch
        v-model="getStatus.isOnSale"
        @change="$emit('change', getStatus.isOnSale)"
        color="#007e57"
      >
        <template v-slot:label>
          <span class="text-caption nopwrap">{{ getStatus.label }}</span>
        </template>
      </v-switch>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    flowerHarvest: Object
  },
  computed: {
    isOnSale() {
      return this.flowerHarvest.status === "on_sale";
    },
    isNotOnSale() {
      return this.flowerHarvest.status === "not_on_sale";
    },
    isFirstHarvestOnCurrentDate() {
      return !!this.flowerHarvest.id;
    },
    isJustOnSale() {
      return this.isOnSale && this.isFirstHarvestOnCurrentDate;
    },
    getStatus() {
      if (!this.isFirstHarvestOnCurrentDate) {
        return {
          isOnSale: false,
          status: "not_on_sale",
          label: this.$t("not_on_sale")
        };
      }
      if (this.isJustOnSale) {
        return {
          isOnSale: true,
          status: "on_sale",
          label: this.$t("on_sale")
        };
      }
      if (this.isNotOnSale) {
        return {
          isOnSale: false,
          status: "not_on_sale",
          label: this.$t("stop_on_sale")
        };
      }

      return {
        isOnSale: false,
        status: "not_on_sale",
        label: this.$t("not_on_sale")
      };
    }
  }
};
</script>

<style scoped>
.border-on_sale {
  border: 2px solid #38A64D;
}

.border-not_on_sale {
  border: 1px solid #666;
}

.color-on_sale {
  color: #38A64D;
}

.color-not_on_sale {
  color: #666;
}
.nopwrap {
  white-space: nowrap;
}
</style>
