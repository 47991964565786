<template>
  <div class="counter-container">
    <button class="step minus" :disabled="disabledMin" @click="handleClick(-1)"><span>-</span></button>
    <input class="number" type="number" v-model="valueModel" :min="min" :max="max" :required="required"
      @keypress="validate" @blur="handleBlur" :disabled="disabled"/>
    <button class="step plus" :disabled="disabledMax" @click="handleClick(1)"><span>+</span></button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: undefined
    },
    max: {
      type: Number,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", Number(value));
      }
    },
    disabledMin() {
      return this.disabled || (!Number.isNaN(Number(this.min)) && this.valueModel <= this.min);
    },
    disabledMax() {
      return this.disabled || (!Number.isNaN(Number(this.max)) && this.valueModel >= this.max);
    }
  },
  methods: {
    handleClick(step) {
      if (this.min && this.valueModel === this.min && step === -1) return;
      if (this.max && this.valueModel === this.max && step === 1) return;
      this.valueModel += step;
    },
    validate(e) {
      if (!(e.keyCode >= 48 && e.keyCode <= 57)) e.preventDefault();
    },
    handleBlur() {
      if (this.min > this.valueModel) {
        this.valueModel = this.min;
        return;
      }
      if (this.max < this.valueModel) {
        this.valueModel = this.max;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.counter-container {
  display: flex;

  .number {
    width: 38px;
    height: 38px;
    text-align: center;
  }

  &>.step {
    width: 36px;
    height: 38px;
    border: 1px solid #315198;
    color: #315198;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;

    &:disabled {
      cursor: cursor;
      color: #fff;
      background-color: #ddd !important;
      border-color: #fff;
    }

    &.minus {
      border-radius: 8px 0 0 8px;
    }

    &.plus {
      border-radius: 0 8px 8px 0;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
