<template>
  <div class="conversations">
    <MstTextField v-model="search" type="search" leading="magnify" placeholder="キーワードから探す" class="conversations__search" />
    <template v-if="isInitialized">
      <ConversationList v-if="conversations.length" :conversations="conversations" :current-user-type="currentUserType" />
      <p v-else class="conversations__empty">メッセージは見つかりませんでした。</p>
    </template>
  </div>
</template>

<script>
import { MstTextField } from "@/components/master";
import { ConversationList } from "@/components/domains/conversations";

export default {
  components: { MstTextField, ConversationList },
  data() {
    return {
      search: "",
      isInitialized: false,
    };
  },
  computed: {
    conversations() {
      const conversations = this.$store.getters["conversations/conversations"];
      if (!this.search) return conversations;
      return conversations.filter(conversation => {
        if (this.currentUserType === "Shop") {
          return conversation.farm.name.toLowerCase().search(this.search.toLowerCase()) >= 0;
        }
        return conversation.shop.name.toLowerCase().search(this.search.toLowerCase()) >= 0;
      });
    },
    currentUserType() {
      return this.$route.matched[1].name;
    },
  },
  async created() {
    await this.$store.dispatch("conversations/resetLastId");
    await this.$store.dispatch("conversations/getConversations");
    this.isInitialized = true;
  },
};
</script>

<style lang="scss" scoped>
.conversations {
  margin: 17px auto 0;
  max-width: 670px;
}

.conversations__search {
  margin-bottom: 16px;
  padding: 0 15px;
}

.conversations__empty {
  padding: 32px 0;
  text-align: center;
  font-size: 14px;
  color: variables.$color-gray-700;
}
</style>
