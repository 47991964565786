<template>
  <v-icon :color="color" :size="size">mdi-history</v-icon>
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>
