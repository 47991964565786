<template>
  <!-- eslint-disable -->
  
	<svg :width="size" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 0L0.796706 4.97996L5.87957 7.23915L16.5764 2.03266L12 0ZM17.8919 2.61725L7.19506 7.82374L12 9.96114L23.2033 4.97996L17.8919 2.61725ZM0 5.8264V16.9092L11.4442 22V10.9172L0 5.8264ZM24 5.8264L12.5558 10.9172V22L24 16.9092V5.8264Z" :fill="color"/>
	</svg>

  
  
  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>
