<template>
  <v-expansion-panels multiple v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <slot name="title" />
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot name="content" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    isDefaultOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      panel: this.isDefaultOpen ? [0] : []
    };
  }
};
</script>

<style lang="scss" scoped>
.v-expansion-panels {
  border-top: 2px solid #EAEAEA;
  border-radius: 0px;

  &:last-child{
    border-bottom: 2px solid #EAEAEA;
  }
}

.v-expansion-panel {
  &::before{
    box-shadow: none;
  }
}

.v-expansion-panel-header {
  padding: 28px 15px;
}

.v-expansion-panel-header::v-deep .mdi:before{
  color: #315198;
}

.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 15px 20px;
}
</style>
