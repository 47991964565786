<template>
  <div>
  <div v-for="item in list" :key="item.id" class="mb-2">
    <v-card @click="clickHandler(item.id)"
    >
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title
            class="text-subtitle-1"
            v-text="item.name"
          ></v-card-title>
        </div>
      </div>
    </v-card>
  </div>
  </div>
</template>

<script>

export default {
  props: {
    list: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs
    };
  },

  methods: {
    clickHandler(key) {
      if (key) {
        this.$router.push(
          `/shop/products?flower_genus_id=${key}`
        );
      }
    }
  }
};
</script>

<style lang="scss">
</style>
