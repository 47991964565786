<template>
  <article>
      <group-data-table
        :title="'集荷情報'"
        :items="from"
        :type="'from'"
        :isAdmin="isAdmin"
        :headers="fromTableHeaders"
        :viewType="viewType"
      ></group-data-table>
      <group-data-table
        :title="'配送情報'"
        :items="to"
        :type="'to'"
        :isAdmin="isAdmin"
        :headers="toTableHeaders"
        :viewType="viewType"
      ></group-data-table>
  </article>
</template>

<script>
import GroupDataTable from "@/components/driver/information/GroupDataTable.vue";

export default {
  components: {
    GroupDataTable,
  },
  props: {
    to: Object,
    from: Object,
    isAdmin: Boolean,
    viewType: String
  },
  computed: {
    driverType() {
      return this.$store.state.user.driver && this.$store.state.user.driver.driver_type;
    },
    isShippingDriver() {
      return this.driverType === "shipping";
    },
    isDeliveryDriver() {
      return this.driverType === "delivery";
    },
    isAdminDriver() {
      return this.driverType === "admin";
    },
    fromTableHeaders() {
      const headers = [
        { value: "status", text: "", width: "5px", class: "px-2" },
        { value: "id", text: "ID", width: "5px", class: "px-2" },
        { value: "item_name", text: "商品名", width: "100px", class: "px-2" },
        { value: "subtotal_count", text: "本数", width: "50px", class: "px-2" }
      ];
      if (this.isShippingDriver) {
        headers.push({ value: "address", text: "配送エリア", width: "5px", class: "px-2" });
      } else if (this.isAdminDriver) {
        headers.push({ value: "area_name", text: "", width: "50px", class: "px-2" });
      }
      headers.push({ value: "qr_read_status", text: "", width: "30px", class: "px-2" });
      return headers;
    },
    toTableHeaders() {
      const headers = [
        { value: "status", text: "", width: "5px", class: "px-2" },
        { value: "id", text: "ID", width: "5px", class: "px-2" },
        { value: "item_name", text: "商品名", width: "100px", class: "px-2" },
        { value: "subtotal_count", text: "本数", width: "50px", class: "px-2" }
      ];
      if (this.isShippingDriver) {
        headers.push({ value: "farm_name", text: "生産者", width: "10px", class: "px-2" });
      } else if (this.isAdminDriver) {
        headers.push({ value: "area_name", text: "", width: "50px", class: "px-2" });
      }
      headers.push({ value: "qr_read_status", text: "", width: "30px", class: "px-2" });
      return headers;
    },
  }
};
</script>

<style scoped></style>
