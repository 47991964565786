<template>
  <div class="maintenance">
    <div class="maintenance__logo-wrapper"><img class="maintenance__logo" :src="require('@/assets/CAVIN_Logo_black.png')" /></div>
    <h1 class="maintenance__title">ただいまメンテナンス中です</h1>
    <p class="maintenance__term">期間：2023年1月17日22:00〜24:00</p>
    <p class="maintenance__message">大変ご迷惑をおかけしておりますが、メンテナンス終了までしばらくお待ちください。</p>
  </div>
</template>

<script>
export default {
  name: "Maintenance",
};
</script>

<style lang="scss" scoped>
.maintenance {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  padding: 0 12px;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.maintenance__logo {
  width: 40%;
}

.maintenance__title {
  margin: 24px 0 0;
  font-size: 2.2rem;
  line-height: 1;
}

.maintenance__term {
  margin: 26px 0 0;
  font-size: 1.4rem;
}

.maintenance__message {
  margin: 14px 0 0;
  font-size: 1.4rem;
}
</style>
