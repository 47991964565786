/**
 * @typedef Flower
 * @type {object}
 * @property {number} id
 * @property {string} created_at
 * @property {string} flower_type
 * @property {number} farm_id
 * @property {string} image_url
 * @property {object} flowerSpecie
 * @property {object} flowerSpecie.id
 * @property {string} flowerSpecie.name
 * @property {string} flowerSpecie.imageUrl
 * @property {object} flowerSpecie.flowerGenus
 * @property {number} flowerSpecie.flowerGenus.id
 * @property {string} flowerSpecie.flowerGenus.name
 */

import logger from "../helpers/logger";
import directUpload from "../helpers/directUpload";

const maxRawSize = 1e7;

const formatData = data => {
  const finalData = {
    flower: {
      flower_type: "single"
    }
  };

  if (data.specieId) finalData.flower.flower_specie_id = data.specieId;
  if (data.categoryId) finalData.flower.category_id = data.categoryId;
  if (data.imgs) {
    finalData.images = data.imgs.filter(x => !x.id).map(x => (x.file));
  }

  if (!Object.keys(finalData.flower).length) delete finalData.flower;

  return finalData;
};

const createListArr = mapList => {
  const list = Object.keys(mapList)
    .map(id => mapList[id]);

  return list.sort((a, b) => {
    // v1>v2混在対応
    if (!("flowerSpecie" in a)) {
      a.flowerSpecie = a.flower_specie;
      a.flowerSpecie.flowerGenus = a.flowerSpecie.flower_genus;
    }
    if (!("flowerSpecie" in b)) {
      b.flowerSpecie = b.flower_specie;
      b.flowerSpecie.flowerGenus = b.flowerSpecie.flower_genus;
    }

    if (a.flowerSpecie.flowerGenus.name > b.flowerSpecie.flowerGenus.name) return 1;

    if (a.flowerSpecie.flowerGenus.id === b.flowerSpecie.flowerGenus.id) {
      if (a.flowerSpecie.name > b.flowerSpecie.name) return 1;

      if (a.flowerSpecie.id > b.flowerSpecie.id) {
        if (a.id > b.id) return 1;
      }
    }

    return -1;
  }).map(flower => flower.id);
};

export default {
  namespaced: true,
  state: {
    /** @type {Flower} */
    list: {},
    listArr: [],
    options: []
  },
  mutations: {
    setData(state, payload) {
      state.list = payload.list;
      state.options = payload.options;
      state.listArr = createListArr(state.list);
    }
  },
  actions: {
    getFlowers({ commit, state }, idArray) {
      return new Promise(resolve => {
        let url;
        if (idArray && idArray.length === 1) {
          // 単一の ID がある場合は、showアクションにマッピング
          url = `farm/flowers/${idArray[0]}`;
        } else {
          // 複数の IDの場合は、indexアクションにマッピング
          url = idArray ? `farm/flowers?ids=${idArray.join(",")}` : "farm/flowers";
        }

        this._vm.$http2("get", url).then(res => {
          if (res.status === 200) {
            const newData = { ...state };

            res.result.flowers.forEach(item => {
              newData.list[item.id] = item;

              if (!newData.options.find(x => x.value === item.id)) {
                newData.options.push({ value: item.id, text: item.flowerSpecie.name });
              }
            });

            commit("setData", newData);
          }

          resolve(true);
        });
      });
    },
    getFlowersByfarmId({ commit, state }, farmId) {
      return new Promise(resolve => {
        this._vm.$http("get", `farms/${farmId}/flowers`).then(res => {
          if (res.status === 200) {
            const newData = { ...state };

            newData.list = [];

            res.result.flowers.forEach(item => {
              newData.list[item.id] = item;

              if (!newData.options.find(x => x.value === item.id)) {
                newData.options.push({ value: item.id, text: item.flowerSpecie.name });
              }
            });

            commit("setData", newData);
          }

          resolve(true);
        });
      });
    },
    async postFlower({ commit, dispatch, state }, data) {
      const finalData = formatData(data);
      dispatch("setBusy", true, { root: true });

      if (finalData.images && finalData.images.length) {
        const isExceedImageExists = finalData.images.find(image => image.size > maxRawSize);
        if (isExceedImageExists) {
          dispatch("setToast", { type: "error", msg: "画像は10MB以内で選択してください" }, { root: true });
          dispatch("setBusy", false, { root: true });
          return null;
        }
        finalData.images = await directUpload(finalData.images, this._vm.$http);
      }

      if (process.env.VUE_APP_ENV !== "production") {
        logger("[Body]", finalData);
      }

      const res = await this._vm.$http2("post", "farm/flowers", finalData, false);

      dispatch("setBusy", false, { root: true });

      if (res.status === 201) {
        const { id } = res.result.flower;
        const newData = { ...state };

        newData.list[id] = {
          id,
          ...res.result.flower
        };

        // commit("setData", newData);
        return id;
      }

      return null;
    },

    async putFlower({ commit, dispatch, state }, { flower_id, data }) {
      const finalData = formatData(data);
      dispatch("setBusy", true, { root: true });

      if (finalData.images && finalData.images.length) {
        const isExceedImageExists = finalData.images.find(image => image.size > maxRawSize);
        if (isExceedImageExists) {
          dispatch("setToast", { type: "error", msg: "画像は10MB以内で選択してください" }, { root: true });
          dispatch("setBusy", false, { root: true });
          return null;
        }
        finalData.images = await directUpload(finalData.images, this._vm.$http);
      }

      const res = await this._vm.$http("put", `flowers/${flower_id}`, finalData, false);
      dispatch("setBusy", false, { root: true });
      if (res.status === 200) {
        const newData = { ...state };

        newData.list[flower_id] = {
          ...newData.list[flower_id],
          ...finalData.flower
        };

        commit("setData", newData);
        dispatch("setToast", { type: "success", msg: "更新しました" }, { root: true });
        return true;
      }

      return false;
    },

    async putFlowerIsWorking({ dispatch }, data) {
      const res = await this._vm.$http("put", "flowers/update_working", data);
      if (res.status === 200) {
        dispatch("setToast", { type: "success", msg: "更新しました" }, { root: true });
        return true;
      }

      return false;
    },
  },
  getters: {
    getFlower: state => id => state.list[id]
  }
};
