/**
 * @typedef Category
 * @type {object}
 * @property {number} id
 * @property {string} name
 */

/**
 * @typedef Option
 * @type {object}
 * @property {number} value
 * @property {string} text
 */

export default {
  namespaced: true,
  state: {
    /** @type {Category} */
    list: {},
    listArr: [],
    /** @type {Option} */
    options: []
  },
  mutations: {
    setData(state, payload) {
      state.list = payload.list;
      state.options = payload.options;
      state.listArr = Object.keys(state.list)
        .map(Number)
        .sort((a, b) => a - b);
    }
  },
  actions: {
    async getCategories({ commit, state }) {
      const res = await this._vm.$http("get", "categories");
      if (res.status === 200) {
        const newData = { ...state };
        res.result.categories.forEach(item => {
          newData.list[item.id] = item;

          if (!newData.options.find(x => x.value === item.id)) {
            newData.options.push({ value: item.id, text: item.name });
          }
        });

        commit("setData", newData);
      }

      return true;
    }
  },
  getters: {
    options(state) {
      return state.options;
    },
    name: state => categoryId => state.list[categoryId] && state.list[categoryId].name
  }
};
