<template>
  <label class="mstSwitch">
    <input v-model="checkedModel" v-bind="$attrs" type="checkbox" class="mstSwitch__field" />
  </label>
</template>

<script>
export default {
  name: "MstSwitch",
  inheritAttrs: false,
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    checked: { type: Boolean, required: true },
  },
  computed: {
    checkedModel: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$emit("change", value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mstSwitch {
  position: relative;
  display: inline-block;
  border-radius: 11px;
  width: 42px;
  height: 22px;
  background: variables.$color-gray-200;
  cursor: pointer;
  transition: background 0.3s ease;

  &::after {
    position: absolute;
    top: 2px;
    left: 2px;
    content: "";
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background: variables.$color-white;
    transition: transform 0.3s ease;
  }

  &:has(:checked) {
    background: variables.$color-brand-secondary;

    &::after {
      transform: translateX(20px);
    }
  }

  &:has(:disabled) {
    cursor: not-allowed;
    background: variables.$color-gray-100;

    &:has(:checked) {
      background: #8b93a6;
    }
  }
}

.mstSwitch__field {
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 5px;
  opacity: 0;
}
</style>
