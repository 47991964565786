<template>
  <div class="inputForm">
    <p v-if="errorMessage" role="alert" class="inputForm__error">
      {{ errorMessage }}
    </p>
    <div class="inputForm__inner">
      <div class="inputForm__actions">
        <label class="inputForm__upload">
          <input type="file" accept="image/*" class="inputForm__uploadInput" @change="handleSubmitImage" />
          <MstIcon type="image-outline" />
        </label>
      </div>
      <div class="inputForm__textInput">
        <textarea ref="textarea" v-model="modelValue" :placeholder="placeholder" class="inputForm__textField text-md" />
        <MstButton
          :disabled="disabled || !modelValue"
          type="submit"
          width="74px"
          size="sm"
          color="blue-900"
          class="inputForm__submit"
          @click="handleSubmit"
        >
          <MstIcon type="send-outline" size="16px" class="inputForm__sendIcon" />
          送信
        </MstButton>
      </div>
    </div>
  </div>
</template>

<script>
import { MstIcon, MstButton } from "@/components/master";

const maxSize = 10e6;

export default {
  name: "InputForm",
  components: { MstIcon, MstButton },
  props: {
    value: { type: String, required: true },
    placeholder: { type: String, default: "メッセージを入力" },
    disabled: { type: Boolean },
  },
  data() {
    return {
      errorMessage: "",
    };
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  watch: {
    modelValue() {
      this.$nextTick(() => {
        this.setTextareaHeight();
      });
    },
  },
  methods: {
    setTextareaHeight() {
      this.$refs.textarea.style.height = "";
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`;
    },
    handleSubmit() {
      this.errorMessage = "";
      this.$emit("submit:message");
    },
    handleSubmitImage(event) {
      if (!event.target.files.length) return;
      const reader = new FileReader();

      reader.onload = readerEvent => {
        const base64 = readerEvent.currentTarget.result;
        this.$emit("submit:image", base64);
      };

      const image = event.target.files[0];

      if (image.size > maxSize) {
        this.errorMessage = "画像は最大10MBまでです。";
      } else {
        this.errorMessage = "";
        reader.readAsDataURL(event.target.files[0]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inputForm__inner {
  display: flex;
  align-items: flex-end;
  gap: 0 10px;
  padding: 9px 8px;
  background: variables.$color-white;
}

.inputForm__upload {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: variables.$color-white;
  color: variables.$color-blue-900;
  transition: background 0.3s ease;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background: variables.$color-gray-50;
  }
}

.inputForm__uploadInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 5px;
  opacity: 0;
}

.inputForm__textInput {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0 6px;
  width: 100%;
}

.inputForm__textField {
  border-radius: 4px;
  padding: 4px 10px;
  width: 100%;
  height: 32px;
  max-height: 100px;
  resize: none;
  background: variables.$color-gray-100;
  outline: none;
  z-index: 100;

  &::placeholder {
    color: variables.$color-gray-400;
  }
}

.inputForm__submit {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 0 4px;
  line-height: 1;
  z-index: 100;
}

.inputForm__error {
  margin: 0;
  padding: 8px 12px 2px;
  font-size: variables.$font-size-sm;
  color: variables.$color-red-500;
}
</style>
