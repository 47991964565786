<template>
  <div>
    <div class="d-flex justify-end mx-4 my-3">
      <MstButton class="btn-add" @click="uploadDialog = true">品種を{{ $t('add') }}</MstButton>
    </div>

    <div v-if="uploadDialog" class="dialog-overlay">
      <div class="dialog">
        <div class="pa-8">
          <div class="d-flex align-center justify-center font-weight-bold">
            <span class="">品種を追加する</span>
          </div>
          <div class="mt-2">
            <p class="mb-1">{{ $t("flower_specie") }}</p>
            <input class="form-input" :value="name" @input="event => { name = event.target.value; this.speciesError = '' } "/>
            <p class="error-text mt-1" v-if="speciesError !== ''">{{ speciesError }}</p>
          </div>
          <div class="mt-6">
            <p class="mb-1">{{ $t("kana") }}</p>
            <input :placeholder="$t('only_kana')" class="form-input" :value="nameFurigana" @input="onChangeNameFurigana"/>
            <p class="error-text mt-1" v-if="furiganaError !== ''">{{ furiganaError }}</p>
          </div>
          <div class="action-board">
            <MstButton class="btn-cancel flex-1" @click="closeDialog()">{{ $t("cancel") }}</MstButton>
            <MstButton class="btn-save flex-1" @click="save">{{ $t("save") }}</MstButton>
          </div>
        </div>

      </div>
      <div class="mt-2"></div>
    </div>
  </div>
</template>

<script>
import { MstButton } from "@/components/master";

export default {
  components: { MstButton },
  props: {
    genusId: {
      type: Number,
      default: null
    },
    edit: {
      type: Number,
      default: null
    }
  },
  watch: {
    edit(specieId) {
      const specie = this.$store.state.flowerSpecie.list[specieId];
      this.name = specie && specie.name;
    }
  },
  data() {
    return {
      name: null,
      nameFurigana: null,
      uploadDialog: false,
      img: null,
      furiganaError: "",
      speciesError: ""
    };
  },
  created() {
    const newImage = this.$store.getters["flowerSpecie/newImage"];
    if (newImage !== null) {
      this.img = newImage.image_url;
    }
  },
  methods: {
    imageHandler(e) {
      this.img = e;
    },
    clearImage() {
      this.img = null;
      this.$store.dispatch("flowerSpecie/setNewImage", null);
    },
    closeDialog() {
      this.clearImage();
      this.nameFurigana = null;
      this.name = null;
      this.uploadDialog = false;
    },
    async save() {
      if (!this.name) { this.speciesError = this.$t("species_input"); }
      if (!this.nameFurigana) { this.furiganaError = this.$t("kana_input"); }
      if (!this.genusId) return;

      if (this.edit && (this.name || this.nameFurigana || this.img)) {
        const data = {};
        if (this.name) data.name = this.name;
        if (this.nameFurigana) data.nameFurigana = this.nameFurigana;
        if (this.img) data.data = this.img;

        await this.$store.dispatch("flowerSpecie/putFlowerSpecie", {
          specieId: this.specieId,
          data
        });

        this.$emit("finish", this.edit);

        return;
      }

      if (!this.genusId || !this.name || !this.nameFurigana) { return; }

      if (!this.nameFurigana.match(/^[ァ-ヶー]+$/)) return;

      const postData = {
        name: this.name,
        name_furigana: this.nameFurigana
      };
      if (this.img) postData.data = this.img;

      const id = await this.$store.dispatch("flowerSpecie/postFlowerSpecie", {
        genusId: this.genusId,
        data: postData,
      });

      if (!this.img) {
        this.$store.dispatch("flowerSpecie/setIsCreatedWithoutImage", true);
      }

      this.$emit("finish", id);
      this.uploadDialog = false;
    },
    canSave() {
      if (!this.genusId || !this.name || !this.nameFurigana) { return false; }
      if (!this.nameFurigana.match(/^[ァ-ヶー]+$/)) return false;

      return true;
    },
    onChangeNameFurigana(event) {
      this.nameFurigana = event.target.value;
      this.furiganaError = this.nameFurigana.match(/^[ァ-ヶー]+$/) ? "" : this.$t("kana_error");
    }
  }
};
</script>

<style lang="scss" scoped>
.specie {
  &-btn {
    text-align: right;
    margin-top: -20px;
  }
}

.border-1 {
  border-width: 1px !important;
}

.form-input {
  font-size: 16px;
  padding: 6px 8px 6px 8px;
  border: 1px solid #7D7D7D;
  border-radius: 4px;
  width: 100%;
}

.btn-save {
  color: white !important;
  background-color: #173169 !important;
}
.btn-add {
  background-color: white !important;
  color: #173169 !important;
  border: 1px solid #173169 !important;
  padding: 0 16px;
  width: 150px !important;
  font-weight: 400 !important;
}

.btn-cancel {
  background-color: white !important;
  color: #173169 !important;
  border: 1px solid #173169 !important;
}

.action-board {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.flex-1 {
  flex: 1;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background: white;
  border-radius: 8px;
  max-width: 344px;
  width: 100%;
}
</style>
