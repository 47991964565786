<template>
  <div>
    <div class="mstDatePicker">
      <input
        type="text"
        class="mstDatePicker_viewer"
        v-model="formattedDate"
        :id="datePickerViewerId"
        :aria-invalid="!!error"
        :aria-describedby="describedBy"
        readonly
      />
      <input
        type="date"
        class="mstDatePicker__input"
        :min="min"
        :max="max"
        v-model="modelValue"
        :id="datePickerInputId"
        @focus="updateFormattedDate"
        @blur="updateFormattedDate"
        @change="updateFormattedDate"
      />
    </div>
    <p v-if="hint" :id="`${componentId}-hint`" class="mstDatePicker__hint">
      {{ hint }}
    </p>
    <p v-if="error" :id="`${componentId}-error`" role="alert" aria-live="assertive" class="mstDatePicker__error">
      {{ error }}
    </p>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import { formattedJPDate } from "@/util/date";

export default {
  name: "MstDatePicker",
  props: {
    value: { type: String, required: true },
    id: { type: String },
    hint: { type: String },
    error: { type: String },
    min: { type: String },
    max: { type: String },
  },
  computed: {
    componentId() {
      return this.id || `select-${nanoid()}`;
    },
    modelValue: {
      get() {
        return this.value || new Date();
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    datePickerViewerId() {
      return this.id || `date-picker-viewer-${nanoid()}`;
    },
    datePickerInputId() {
      return this.id || `date-picker-input-${nanoid()}`;
    },
    formattedDate() {
      return formattedJPDate(this.value);
    },
    describedBy() {
      const ids = [];
      if (this.hint) ids.push(`${this.componentId}-hint`);
      if (this.error) ids.push(`${this.componentId}-error`);
      return ids.join(" ");
    },
  },
  methods: {
    updateFormattedDate(e) {
      switch (e.type) {
        case "focus":
          this.$emit("focus", e);
          break;
        case "change":
          this.$emit("change", e);
          break;
        case "blur":
          this.$emit("blur", e);
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mstDatePicker_viewer {
  border-radius: 4px;
  border: 1px solid variables.$color-gray-200;
  padding: 6px 8px;
  width: 100%;
  font-size: 16px;
  line-height: 1.2;
  outline: none;
  transition: border 0.3s ease;

  &:focus {
    border-color: variables.$color-blue-900;
  }
}

.mstDatePicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: -32px;
}

input[type="date"] {
  border: none;
  box-sizing: border-box;
  outline: 0;
  position: relative;
  width: 100%;
  border: none;
  top: -32px;
  opacity: 0;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.mstDatePicker__input {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  width: 100%;
}

.mstDatePicker__hint {
  margin-top: 8px;
  font-size: variables.$font-size-xs;
  line-height: 1.4;
  color: variables.$color-gray-600;
}

.mstDatePicker__error {
  margin-top: 8px;
  font-size: variables.$font-size-xs;
  line-height: 1.4;
  color: variables.$color-red-500;
}

</style>
