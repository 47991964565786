<template>
  <div class="body">
    <div class="content d-flex flex-column align-center">
      <div class="logo pb-2">
        <img :src="require('@/assets/CAVIN_Logo_black.png')" :style="mobile ? 'width: 160px' : 'width: 300px'" />
      </div>

      <span class="page-title">{{ $t("login") }}</span>

      <div class="card justify-center align-center">
        <p v-if="invalid" style="color: red;">{{ $t("login_error") }}</p>
        <div style="width: 100%;">
          <span class="label">{{ $t("email") }}</span>
          <v-text-field ref="email" type="text" v-model="content.email" @keyup="keyupHandler" class="mt-2" :error="invalid" autofocus outlined />
        </div>

        <div class="mt-2" style="width: 100%;">
          <span class="label">{{ $t("password") }}</span>
          <v-text-field
            ref="password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ?'mdi-eye':'mdi-eye-off'"
            class="mt-2 mb-0"
            v-model="content.password"
            @keyup="keyupHandler"
            @click:append=togglePasswordVisibility()
            outlined :error="invalid"
            />
        </div>

        <v-btn class="bg-secondary" @click="login" style="width: 100%;">
          <span style="color: white; font-weight: bold;">{{ $t("login") }}</span>
        </v-btn>
      </div>
    </div>

    <div v-if="isUnpaid" class="modal">
      <div class="modal-content">
        <div class="card dialog-card">
          <button class="close-btn" @click="closeUnpaidDialog()">
            <img class="close-img" :src="require('@/assets/close_modal.svg')"/>
          </button>
          <div class="dialog-desc">
            <p class="dialog-text">{{ $t("login_limit_message1") }}</p>
            <p class="dialog-text">{{ $t("login_limit_message2") }}</p>
          </div>
          <a :href="contactLineUrl">
            <MstButton class="btn bg-secondary" color="#173169">{{ $t("line_contact") }}</MstButton>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MstButton } from "@/components/master";

export default {
  data() {
    return {
      showPassword: false,
      invalid: false,
      content: {},
      isUnpaid: false
    };
  },
  components: {
    MstButton
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    keyupHandler(e) {
      if (e.key === "Enter") this.login();
    },
    login() {
      const { email, password } = this.content;

      if (!email) {
        this.$refs.email.focus();
        return;
      }

      if (!password) {
        this.$refs.password.focus();
        return;
      }

      this.$store.dispatch("login", { email, password }).then(res => {
        if (res) {
          if (res.unpaid) {
            this.isUnpaid = true;
          } else {
            this.invalid = false;
            sessionStorage.setItem("isSignedInRecently", true);
            this.$router.push(`/${res.toLowerCase()}`);
          }
        } else {
          this.invalid = true;
        }
      });
    },
    closeUnpaidDialog() {
      this.isUnpaid = false;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  }
};
</script>

<style lang="scss" scoped>
.body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.content {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}

.logo {
  margin-top: 65px;
}

@media only screen and (max-width: 600px) {
  $width: 90%;
  .card {
    padding: 1rem;
    width: $width;
  }
}

@media only screen and (min-width: 600px) {
  $width: 40rem;
  .card {
    padding: 2rem 4rem 2rem 4rem;
    width: $width;
  }
}

.card {
  margin-top: 50px;
  height: 100%;
}

.page-title {
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}

.label {
  font: Medium 1rem Noto Sans CJK JP;
  color: #111111;
}

.dialog-card {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 234px;
  width: 344px;
  margin: auto;
}

.dialog-desc {
  margin: 16px 0px;
}

.dialog-text {
  font-size: 16px;
  margin-bottom: 0px;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 0 solid #888;
  border-radius: 8px;
  max-width: 344px;
}

.close-btn {
  display: flex;
  flex-direction: row-reverse;
  margin: 5px;
}

.close-img {
  position: relative;
}
</style>
