<template>
  <div class="cart-complete-page pt-4">
    <PageContainer class="cart-complete-page-max-width" v-if="data">
      <header>
        <p class="complete-message">注文が確定しました。<br>ありがとうございます。</p>
        <p class="order-number">注文番号: {{ data.order.id }}</p>
        <p class="delivery-date">最短お届け予定日: {{ $d(data.earliest_date, 'date') }}({{ $d(data.earliest_date, 'day') }})</p>
      </header>

      <div class="body">
        <div class="notice">
<!--          <p>ただいま、ご購入の確認メールを送信させていただきました。</p>-->
<!--          <p>-->
<!--            確認メールが届かない場合、以下の理由が考えられます。<br>-->
<!--            ・ドメイン指定等、受信制限設定がされている。<br>-->
<!--            ・ゴミ箱や迷惑メール等、受信フォルダ以外の箇所に届いてしまっている。<br>-->
<!--            ・通信環境による遅延。<br>-->
<!--            以上をご確認の上、CAVIN公式LINEにてお問い合わせください。-->
<!--          </p>-->
          <p>今後ともご愛顧いただきますようよろしくお願い申し上げます。</p>
        </div>
      </div>

      <footer>
        <Button class="button" to="/shop/products">TOPに戻る</Button>
        <Button class="button" outlined :to="officialLineUrl">
          <img :src="require('@/assets/line.png')" />
          <span>公式LINEでお問い合わせ</span>
        </Button>
      </footer>
    </PageContainer>
  </div>
</template>

<script>
import PageContainer from "@/components/shop/PageContainer.vue";
import Button from "@/components/shared/v2/Button.vue";

export default {
  components: {
    PageContainer,
    Button
  },

  data() {
    return {
      data: null
    };
  },

  async mounted() {
    const { result } = await this.$api.get("/orders/complete");
    this.data = result;

    this.$datalayer.sendData(result.order);

    this.$store.dispatch("orders/getDraftOrder");
  },
  computed: {
    officialLineUrl() {
      return process.env.VUE_APP_X_OFFICIAL_SHOP_LINE_URL;
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-complete-page {
  background-color: #fff;

  .cart-complete-page-max-width{
    max-width: 480px;
  }
}

header{
  text-align: center;

  .complete-message{
    margin: 16px 0 0;

    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #191D38;
  }

  .delivery-date {
    margin: 32px 0 0;

    color: #315198;
    font-size: 20px;
    font-weight: bold;
  }

  .order-number {
    margin: 8px 0 0;

    font-size: 20px;
    font-weight: bold;
    color: #555;
  }
}

.body {
  margin: 32px 0 0;

  .notice{
    margin: 0 auto;

    font-size: 16px;
    color: #777;
  }
}

footer{
  margin: 32px 0 0;

  .button {
    margin: 24px 0 0;

    height: 48px !important;
    width: 100%;

    display: flex;
    position: relative;

    span{
      line-height: 34px;
    }

    img{
      height: 34px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

</style>
