<template>
  <v-menu offset-y class="d-flex align-center" >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        slot="activator"
        v-model="textValue"
        prepend-inner-icon="mdi-calendar"
        placeholder="年/月/日"
        class="shrink mx-4"
        readonly
        :clearable="clearable"
      />
    </template>
     <!-- eslint-disable -->
    <calendar v-model="value" :deliveryDateList="deliveryDateList" />
    <!-- eslint-enable -->
  </v-menu>
</template>

<script>
import Calendar from "@/components/shared/Calendar.vue";

export default {
  components: {
    Calendar
  },
  props: {
    value: {
      type: String
    },
    rules: [],
    deliveryDateList: {
      type: Array
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value() {
      this.textValue = this.value;
    }
  },
  computed: {
    textValue: {
      get() {
        return (this.value) ? this.value.replace(/-/g, "/") : this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style scoped>
</style>
