export default {
  install(Vue) {
    Vue.prototype.$date = {
      MMDD(d, padding = "/") {
        const month = d.getMonth() + 1;
        const day = d.getDate();
        return month + padding + day;
      },
      YYYYMD(d, padding = "-") {
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const day = d.getDate();
        return year + padding + month + padding + day;
      },
      YYYYMMDD(d, padding = "-") {
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const day = d.getDate();
        return year + padding + (month < 10 ? `0${month}` : month) + padding + (day < 10 ? `0${day}` : day);
      },
      dateJp(d, includeTime) {
        if (!d) return "";

        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const day = d.getDate();
        let time = "";
        if (includeTime) {
          time = `${d.getHours()}:${d.getMinutes()}`;
        }

        return `${year}年${month}月${day}日 ${time}`;
      },
      compareDates(a, b) {
        if (a && b && this.YYYYMD(a) === this.YYYYMD(b)) {
          return true;
        }

        return false;
      },
      getDayOfWeek(date) {
        return ["日", "月", "火", "水", "木", "金", "土"][date.getDay()];
      }
    };
  }
};
