import Vue from "vue";

class WindowSize {
  constructor(delay = 80) {
    this._width = 0;
    this._height = 0;
    this._delay = delay;
    this._timer = null;
    this._handleResize = this.handleResize.bind(this);

    this.update();
    window.addEventListener("resize", this._handleResize);
  }

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }

  handleResize() {
    window.clearTimeout(this._timer);
    this._timer = window.setTimeout(() => {
      this.update();
    }, this._delay);
  }

  update() {
    this._width = window.innerWidth;
    this._height = window.innerHeight;
  }

  destroy() {
    window.removeEventListener("resize", this._handleResize);
  }
}

const windowSize = new WindowSize();

function install(vue) {
  if (install.isInstalled) return;
  install.isInstalled = true;

  const vm = new Vue({ data: { windowSize } });
  const mixin = {
    computed: {
      windowWidth() {
        return vm.windowSize.width;
      },
      windowHeight() {
        return vm.windowSize.height;
      },
    },
  };

  vue.mixin({
    mixins: [mixin],
  });
}

export default install;
