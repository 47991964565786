<template>
  <div class="page-root">
    <Header>
      <template v-slot:caption> 順次対応エリアを拡大中です。<br />郵便番号から対応エリアかどうか判別できます。 </template>
    </Header>
    <div class="page-contents">
      <v-subheader>花屋・生産者を選択する</v-subheader>
      <v-row>
        <div class="container">
          <div class="radio-tile-group">
            <div class="input-container">
              <input id="shop" class="radio-button" type="radio" v-model="user_type" name="radio" value="Shop" />
              <div class="radio-tile">
                <div class="icon shop-icon">
                  <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <!-- eslint-disable -->
                    <path
                      d="M13.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM9.8 8.9L7 23h2.1l1.8-8 2.1 2v6h2v-7.5l-2.1-2 .6-3C14.8 12 16.8 13 19 13v-2c-1.9 0-3.5-1-4.3-2.4l-1-1.6c-.4-.6-1-1-1.7-1-.3 0-.5.1-.8.1L6 8.3V13h2V9.6l1.8-.7"
                    />
                    <!-- eslint-enable -->
                  </svg>
                </div>
                <label for="shop" class="radio-tile-label">花屋</label>
              </div>
            </div>
            <div class="input-container">
              <input id="farm" class="radio-button" type="radio" name="radio" v-model="user_type" value="Farm" />
              <div class="radio-tile">
                <div class="icon farm-icon">
                  <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.18 9" />
                    <!-- eslint-disable -->
                    <path d="M21 16v-2l-8-5V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-8 5v2l8-2.5V19l-2 1.5V22l3.5-1 3.5 1v-1.5L13 19v-5.5l8 2.5z" />
                    <!-- eslint-enable -->
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </div>
                <label for="farm" class="radio-tile-label">生産者</label>
              </div>
            </div>
          </div>
        </div>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader>郵便番号を入力する</v-subheader>
          <form class="h-adr">
            <span class="p-country-name" style="display:none;">Japan</span>
            <the-mask class="input p-postal-code" size="8" maxlength="8" v-model="postal_code" :mask="['###-####']" placeholder="810-0006" type="tel" />
            <input type="text" class="visibility-hidden p-region" v-model="prefecture" /><br />
            <input type="text" class="visibility-hidden p-locality" v-model="municipality" /><br />
            <input type="text" class="visibility-hidden p-street-address p-extended-address" v-model="address" /><br />
          </form>
        </v-col>
      </v-row>
      <v-row>
        <v-btn color="#0E4DA4" block :disabled="disabled" @click="getAvailableAreas" style="margin:20px auto;">
          <span style="color: #fff">対応エリアか調べる</span>
          <v-icon v-if="!disabled" color="#fff">mdi-arrow-right</v-icon>
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import Header from "@/components/users/Header.vue";

export default {
  components: {
    Header,
    TheMask
  },
  data() {
    return {
      user_type: "Shop",
      postal_code: null,
      prefecture: "",
      municipality: "",
      address: ""
    };
  },
  computed: {
    disabled() {
      return !this.postal_code || this.postal_code.length !== 7;
    }
  },
  methods: {
    getAvailableAreas() {
      this.setAddress();

      const payload = {
        user_type: this.user_type,
        postal_code: this.postal_code,
        prefecture: this.prefecture,
        municipality: this.municipality,
        address: this.address
      };

      this.$store.dispatch("unuseableAreaUsers/getIsAvailableArea", { payload }).then(res => {
        if (res.result.is_available_area) {
          this.$router.push(`/users/sign_up?type=${this.user_type}`);
        } else {
          this.$router.push(`/users/not_available_area?type=${this.user_type}`);
        }
      });
    },
    setAddress() {
      this.prefecture = this.getInputValue("p-region");
      this.municipality = this.getInputValue("p-locality");
      this.address = this.getInputValue("p-street-address");
    },
    getInputValue(className) {
      return document.getElementsByClassName(className)[0].value;
    }
  },
  watch: {
    prefecture(e) {
      console.log(e);
    }
  }
};
</script>

<style lang="scss" scoped>
$primary-color: #0e4da4;

.page-contents {
  margin: 34px auto 0;
  display: grid;
  grid-template-columns: 400px;
  justify-content: center;

  input[type="tel"]:focus {
    border: 2px solid $primary-color;
    outline: 0;
  }

  .v-input--radio-group {
    width: 100%;
  }

  .visibility-hidden {
    visibility: hidden;
  }

  @media screen and (max-width: 1000px) {
    max-width: 600px;
    grid-template-columns: 1fr;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .v-subheader {
    font-size: 1rem;
    font-weight: bold;
  }

  .radio-tile-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .input-container {
      position: relative;
      height: 5rem;
      width: 7rem;
      margin: 1rem;

      .radio-button {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        margin: 0;
        cursor: pointer;
      }

      .radio-tile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border: 2px solid $primary-color;
        border-radius: 5px;
        padding: 1rem;
        transition: transform 300ms ease;
      }
      .icon svg {
        fill: $primary-color;
        width: 3rem;
        height: 3rem;
      }
      .radio-tile-label {
        text-align: center;
        font-size: 0.75rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $primary-color;
      }

      .radio-button:checked + .radio-tile {
        background-color: $primary-color;
        border: 2px solid $primary-color;
        color: white;
        transform: scale(1.1, 1.1);

        .icon svg {
          fill: white;
          background-color: $primary-color;
        }
        .radio-tile-label {
          color: white;
          background-color: $primary-color;
        }
      }
    }
  }
}
</style>
