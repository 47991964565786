<template>
  <div class="upload-container">
    <div @click="isDisable ? '' : $emit('click')" class="upload-btn mx-auto pointer" :class="{'disabled': isDisable}">{{ $t("add_image") }}</div>
  </div>
</template>

<script>
export default {
  props: {
    isDisable: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    inputHandler(e) {
      this.fileHandler(e.target.files[0]);
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-container {
  text-align: center;
  position: absolute;
  right: 20px;
  bottom: 10px;
}

.upload-btn {
  background: rgba(255, 255, 255, 0.7);
  width: 82px;
  height: 31px;
  padding: 8px 10px 8px 10px;
  border-radius: 16px;
  color: #191D38;
  border: 1px solid #191D38;
  font-family: Roboto;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -60px;
  left: -80px;
}

.upload-btn:hover {
  background-color:  rgb(220, 220, 220);
}

.upload-drag {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
}

.disabled {
  border: 1px solid #999999;
  background-color: #eeeeee;
  color: #aaaaaa;
}
</style>
