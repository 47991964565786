<template>
  <v-icon :color="color" :size="size">mdi-cart-outline</v-icon>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: String,
      default: "32"
    }
  }
};
</script>
