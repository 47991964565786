<template>
  <Detail />
</template>

<script>
import Detail from "@/components/farm/flowers/detail.vue";

export default {
  components: { Detail }
};
</script>

<style scoped>
</style>
