<template>
  <!-- eslint-disable -->

  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      :fill="color"
      d="M32,20A12,12,0,1,1,20,8,12,12,0,0,1,32,20ZM20.322,11.968a6.267,6.267,0,0,0-5.639,3.085.581.581,0,0,0,.131.787l1.679,1.273a.58.58,0,0,0,.806-.1c.864-1.1,1.457-1.732,2.773-1.732.988,0,2.211.636,2.211,1.595,0,.725-.6,1.1-1.574,1.644-1.138.638-2.644,1.432-2.644,3.419v.194a.581.581,0,0,0,.581.581h2.71a.581.581,0,0,0,.581-.581v-.064c0-1.377,4.025-1.435,4.025-5.161C25.961,14.1,23.049,11.968,20.322,11.968Zm-.322,12a2.226,2.226,0,1,0,2.226,2.226A2.228,2.228,0,0,0,20,23.968Z"
      transform="translate(-8 -8)"
    />
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String
  }
};
</script>

<style>
</style>
