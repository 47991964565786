import axios from "axios";
import store from "@/store";

export const client = axios.create({
  baseURL: process.env.VUE_APP_URL
});

client.interceptors.request.use(
  config => {
    if (store.state.auth.access_token) {
      config.headers.Authorization = store.state.auth.access_token;
    }
    store.dispatch("setBusy", true);
    return config;
  },
  err => {
    store.dispatch("setBusy", false);
    return Promise.reject(err);
  }
);

client.interceptors.response.use(
  res => {
    store.dispatch("setBusy", false);
    return res.data;
  },
  err => {
    if (err.response && err.response.status === 401) {
      store.dispatch("logout");
      window.location.href = "";
    }
    store.dispatch("setBusy", false);
    return Promise.reject(err);
  }
);

export const client2 = axios.create({
  baseURL: process.env.VUE_APP_URL_V2
});

client2.interceptors.request.use(
  config => {
    if (store.state.auth.access_token) {
      config.headers.Authorization = store.state.auth.access_token;
    }
    store.dispatch("setBusy", true);
    return config;
  },
  err => {
    store.dispatch("setBusy", false);
    return Promise.reject(err);
  }
);

client2.interceptors.response.use(
  res => {
    store.dispatch("setBusy", false);
    return res.data;
  },
  err => {
    if (err.response && err.response.status === 401) {
      store.dispatch("logout");
      window.location.href = "";
    }
    store.dispatch("setBusy", false);
    return Promise.reject(err);
  }
);

export default {
  install(Vue) {
    Vue.prototype.$api = client;
    Vue.prototype.$api2 = client2;
  }
};
