<template>
  <div
    class="radio-button d-flex align-center"
    :class="{ disabled, 'label-less': !label }"
    @click="!disabled && $emit('change', checkedValue)"
  >
    <div class="icon d-flex align-center justify-center" :class="{ checked: checkedValue === selected }" />
    <div v-if="label" class="label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "selected",
    event: "change"
  },

  props: {
    selected: {
      type: String,
      default: ""
    },
    checkedValue: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.radio-button {
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
  }

  &.label-less {
    width: 20px;
  }
}

.icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: #315198 1px solid;
  background-color: #fff;

  &.checked {
    &::after {
      display: block;
      content: "";
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #315198;
    }
  }
}

.label {
  margin-left: 10px;
  color: #5B5B5B;
  font-size: 14px;
}
</style>
