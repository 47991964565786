<template>
  <!-- eslint-disable -->

	<svg viewBox="0 0 64 64">
		<path :fill="color" d="M20.86 33a2 2 0 0 1-1.26-.45L8.74 23.58a2 2 0 1 1 2.54-3.09l10.86 8.93a2 2 0 0 1 .27 2.81 2 2 0 0 1-1.55.77Z"></path>
		<path :fill="color" d="M10 24a2 2 0 0 1-1.27-3.55l10.87-8.88a2 2 0 0 1 2.54 3.09l-10.86 8.92A2 2 0 0 1 10 24Z"></path>
		<path :fill="color" d="M39.57 52.89H10a2 2 0 1 1 0-4h29.57a12.43 12.43 0 0 0 0-24.85H10a2 2 0 1 1 0-4h29.57a16.43 16.43 0 0 1 0 32.85Z"></path>
	</svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: String,
      default: "22"
    }
  }
};
</script>
