import location from "@/helpers/location";
import flowerImage from "@/helpers/flowerImage";
import wearhouse from "@/helpers/wearhouse";

export default {
  namespaced: true,
  draft: null,
  state: {
    list: {},
    from: [],
    to: [],
    history: [],
    selectedDate: "",
    availableDateList: [],
    selectedList: {
      delivery: {
        from: [],
        to: []
      }
    },
    selectedOrderList: {},
    deliveryType: ""
  },
  mutations: {
    setDraft(state, payload) {
      state.draft = payload;
    },
    setData(state, payload) {
      state.list = payload;
    },
    setFrom(state, payload) {
      state.from = payload;
    },
    setTo(state, payload) {
      state.to = payload;
    },
    setHistory(state, payload) {
      state.history = payload;
    },
    setAvailableDateList(state, payload) {
      state.availableDateList = payload;
    },
    setSelectedList(state, payload) {
      state.selectedList = payload;
    },
    setSelectedOrderList(state, payload) {
      state.selectedOrderList = payload;
    },
    setDeliveryType(state, payload) {
      state.deliveryType = payload;
    }
  },
  actions: {
    getOrderItems({ dispatch, commit, rootState }, { shippingAreaIds, deliveryAreaIds }) {
      return new Promise(resolve => {
        let url = `delivery/order_items?date_from=${rootState.driverInformation.selectedDate}&date_to=${rootState.driverInformation.selectedDate}`;
        if (shippingAreaIds !== null) url = `${url}&shipping_area_ids=${shippingAreaIds}`;
        if (deliveryAreaIds !== null) url = `${url}&delivery_area_ids=${deliveryAreaIds}`;

        this._vm.$http("get", url).then(res => {
          if (res.status === 200) {
            commit("setData", res.result.order_items);

            const from_list = [];
            const to_list = [];
            const driver_type = rootState.user.driver.driver_type;
            res.result.order_items.forEach(item => {
              const from = {
                type: "from",
                status: item.status,
                name: `${item.farm.name} (${location(item.farm.locations[0])}) (<a href="tel:${item.farm.phone}">${item.farm.phone}</a>)`,
                address: location(item.order.location),
                when_absent: item.order.location.when_absent,
                subtotal_count: item.subtotal_count,
                id: item.id,
                farm_name: item.farm ? item.farm.name : "",
                item_name: `${item.flower.flower_specie.name}(${item.flower.flower_genus.name})`,
                specie_name: item.flower.flower_specie.name,
                genus_name: item.flower.flower_genus.name,
                delivery_date: item.delivery_date,
                flower_type: item.flower.flower_type,
                flower_image: flowerImage(item.flower),

                flower_harvest: {
                  id: item.flower_harvest.id,
                  stem_size: item.flower_harvest.stem_size,
                  note: item.flower_harvest.note
                },

                area_id: item.farm.locations[0] ? item.farm.locations[0].area?.id : "",
                area_name: item.order.location ? item.order.location.area?.drivers[0]?.name : ""
              };
              const to = {
                type: "to",
                status: item.status,
                name: driver_type === "shipping" ? wearhouse(item.order.location) : `${item.order.location.name}(${location(item.order.location)})`,
                address: location(item.order.location),
                when_absent: item.order.location.when_absent,
                subtotal_count: item.subtotal_count,
                id: item.id,
                farm_name: item.farm ? item.farm.name : "",
                item_name: `${item.flower.flower_specie.name}(${item.flower.flower_genus.name})`,
                specie_name: item.flower.flower_specie.name,
                genus_name: item.flower.flower_genus.name,
                delivery_date: item.delivery_date,
                flower_type: item.flower.flower_type,
                flower_image: flowerImage(item.flower),

                flower_harvest: {
                  id: item.flower_harvest.id,
                  stem_size: item.flower_harvest.stem_size,
                  note: item.flower_harvest.note
                },

                area_id: item.order.location ? item.order.location?.area?.id : "",
                area_name: item.order.location ? item.order.location?.area?.drivers[0]?.name : ""
              };
              from_list.push(from);
              to_list.push(to);
            });

            from_list.sort((a, b) => a.name.localeCompare(b.name));
            to_list.sort((a, b) => a.name.localeCompare(b.name));

            commit("setFrom", from_list);
            commit("setTo", to_list);
            dispatch("clearSelected");

            const histories = res.result.order_items.filter(item => ["delivered"].includes(item.status));
            commit("setHistory", histories);

            const available_date_list = res.result.delivery_dates;
            commit("setAvailableDateList", available_date_list);
          }
          resolve(true);
        });
      });
    },
    getLogisticsPdfOrderItems(_, { dateFrom, dateTo, shippingAreaId, deliveryAreaId }) {
      return new Promise(resolve => {
        let url = `delivery/order_items?date_from=${dateFrom}&date_to=${dateTo}`;
        if (shippingAreaId !== 0) url = `${url}&shipping_area_id=${shippingAreaId}`;
        if (deliveryAreaId !== 0) url = `${url}&delivery_area_id=${deliveryAreaId}`;

        this._vm.$http("get", url).then(res => {
          resolve(res.result.order_items);
        });
      });
    },
    setSelected({ commit, state }, { viewType, listType, selected }) {
      const newData = { ...state.selectedList };
      newData[viewType][listType] = selected;
      commit("setSelectedList", newData);
    },
    clearSelected({ commit }) {
      const newData = { delivery: { from: [], to: [] }, order: { from: [], to: [] } };
      commit("setSelectedList", newData);
    },
    filterHistories({ commit, state }, { type, name }) {
      const newData = [...state.history].filter(item => item.type !== type || (item.type === type && item.name.indexOf(name) !== -1));
      commit("setHistory", newData);
    },
    editStatus({ commit }, { transitionType, items }) {
      return new Promise(resolve => {
        const data = {
          transition_type: transitionType,
          order_item_ids: items
        };
        this._vm.$http("put", "delivery/order_items/transition", data).then(res => {
          if (res.status === 200) {
            commit("setDraft", res.result);
            resolve(true);
          }
        });
      });
    },
    setSelectedOrderItem({ commit, state }, { orderItem, type }) {
      const newData = { ...state.selectedOrderList, ...orderItem };
      commit("setSelectedOrderList", newData);
      if (type && type === "from") {
        commit("setDeliveryType", "ship");
      } else if (type && type === "to") {
        commit("setDeliveryType", "delivery");
      }
      const selectedCnt = Object.values(newData).filter(item => item.checked).length;
      if (selectedCnt === 0) {
        commit("setDeliveryType", "");
      }
    },
    clearSelectedOrderList({ commit }) {
      commit("setSelectedOrderList", {});
      commit("setDeliveryType", "");
    },
    updateDeliveryType({ commit }, { deliveryType }) {
      commit("setDeliveryType", deliveryType);
    }
  },
  getters: {
    getState(state) {
      return state;
    }
  }
};
