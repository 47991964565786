<template>
  <div>
    <router-link class="product-card" :to="`/shop/products/${flowerHarvest.id}`">
      <ProductImage :src="image" :not_in_stock="(flowerHarvest.stockCount == 0)" />

      <div class="content">
        <div class="text-caption farm_name">{{ flowerHarvest.farmName }}</div>
        <div class="name">{{ flowerHarvest.flowerSpecieName }}</div>
        <div class="d-flex my-1" v-if="flowerHarvest.stemSize != null">
          <MstTextTag :text="`${flowerHarvest.stemSize}cm`" size="sm" />
        </div>
        <div v-if="flowerHarvest.reviewMark != 0">
          <star-rating
            :rating="flowerHarvest.reviewMark"
            :increment="0.01"
            :star-size="12"
            :padding="2"
            active-color="#DFCE0F"
            inactive-color="#D9D9D9"
            :show-rating="false"
          ></star-rating>
          <div class="reviews-container">
            <span class="review-marks">{{ flowerHarvest.reviewMark.toFixed(1) }}</span>
            <span class="total-review-count">({{ flowerHarvest.totalReviewCount }}件)</span>
          </div>
        </div>

        <span class="request_only" v-if="flowerHarvest.requestOnly">{{ $t("request_only") }}</span>
        <div v-if="flowerHarvest.harvestDate">
          <div class="detail">
            <div class="description">
              <div class="min_count">
                <span>1{{ $t("lot") }}{{ flowerHarvest.minCount }}{{ $t("volumn") }}{{ $t("from") }} </span>
              </div>
              <div class="lowest_price">
                <span class="price_unit">1{{ $t("volumn") }}</span>
                <span class="price">{{ $n(flowerHarvest.salesPriceWithoutDeliveryFee) }}</span>
                <span class="price_unit">{{ $t("yen") }}</span>
              </div>
            </div>
            <div class="cartin">
              <v-btn v-if="!isInCart" :disabled="flowerHarvest.stockCount === 0" class="cartin_button" @click="addDraftOrder" fab icon v-on:click.prevent>
                <span class="cartin_button_inner">
                  <v-icon class="icon" color="#375293">mdi-plus</v-icon>
                </span>
              </v-btn>
              <v-btn v-else class="go_cart_button" fab icon to="/shop/cart">
                <span class="go_cart_button_inner">
                  <v-icon class="icon" color="white">mdi-cart-outline</v-icon>
                </span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <div class="counter mb-3 d-flex justify-center">
      <Counter v-if="isInCart" :min="0" :max="maxLotCount" v-model="valueModel" />
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import ProductImage from "@/components/shop/products/ProductImage.vue";
import Counter from "@/components/shared/v2/Counter.vue";
import { MstTextTag } from "@/components/master";

export const sizes = {
  "x-small": "10px",
  small: "12px",
};

export default {
  components: {
    ProductImage,
    Counter,
    MstTextTag,
    StarRating
  },
  props: {
    flowerHarvest: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      timeoutId: -1,
    };
  },
  computed: {
    image() {
      return this.flowerHarvest.flowerImageUrl;
    },
    isInCart() {
      return this.$store.getters["orders/harvestIsInCart"](this.flowerHarvest.id);
    },
    normalOrderItem() {
      return this.$store.getters["orders/normalOrderItem"](this.flowerHarvest.id);
    },
    minLot() { return this.normalOrderItem.harvest_min_count; },
    maxLotCount() {
      return this.normalOrderItem.flower_harvest.stock_count / this.normalOrderItem.harvest_min_count;
    },
    valueModel: {
      get() {
        return this.normalOrderItem.lot_count;
      },
      set(value) {
        this.normalOrderItem.lot_count = value;
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(async () => {
          if (value === 0) {
            this.$store.dispatch("orders/deleteOrderItem", this.normalOrderItem);
          } else {
            await this.editCountHandler(value);
            this.$store.dispatch("orders/getDraftOrder");
          }
        }, 800);
      }
    },
  },
  methods: {
    async addDraftOrder() {
      const eventName = "click_product_index_cta";
      this.$gtag.event(eventName, {
        flower_harvest_id: this.flowerHarvest.id,
      });

      await this.post("order_items", this.createRequestBody());
    },

    createRequestBody() {
      return {
        order_items: [{
          lot_count: 1,
          flower_harvest_id: this.flowerHarvest.id,
        }]
      };
    },
    async post(url = "/order_items", data) {
      class OrderItemCreateError extends Error {
        constructor(status, message) {
          super(message);
          this.status = status;
          this.name = "OrderItemCreateError";
        }
      }

      try {
        const resOrderItem = await this.$http("post", url, data);
        if (resOrderItem.status === 422) throw new OrderItemCreateError(422, "Failed to adding item into cart");
        if (resOrderItem.status === 521) throw new OrderItemCreateError(521, "Failed to adding item into cart");

        await this.$store.dispatch("orders/notifyAddCart");

        this.deliveryDate = "";
        this.harvestId = null;
      } catch (e) {
        if (e.status === 521) {
          this.$store.dispatch("orders/notifyUnbuyableAreaError");
        } else {
          this.$store.dispatch("orders/notifyAddToCartError");
        }
      } finally {
        await this.$store.dispatch("orders/getDraftOrder");
      }
    },
    async editCountHandler(newLotCount) {
      class OrderItemPutError extends Error {
        constructor(status, message) {
          super(message);
          this.status = status;
          this.name = "OrderItemPutError";
        }
      }

      const data = {
        id: this.normalOrderItem.id,
        lotCount: newLotCount,
        subtotalCount: newLotCount * this.minLot,
        flowerHarvestId: this.normalOrderItem.flower_harvest.id,
      };
      try {
        const res_status = await this.$store.dispatch("orders/changeCount", data);
        if (res_status === 521) throw new OrderItemPutError(521, "Failed to change count");
        if (res_status !== 200) throw new Error("Failed to change count");
        this.$store.dispatch("orders/notifyChangeCount");
      } catch (e) {
        if (e.status === 521) {
          this.$store.dispatch("orders/notifyUnbuyableAreaError");
        } else {
          this.$store.dispatch("orders/notifyChangeCountError");
        }
        console.log(e);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
$secondary-color: #375293;

.product-card {
  display: block;
  background-color: #fff;
}

.content {
  padding: 8px;
}

.name {
  color: #111111;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;
}

.tag {
  background-color: #4A8FCF;
  border-radius: 1px;
  padding: 0px 2px;
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
  color: #FFFFFF;
  margin-right: 2px;
}

.genus-name {
  color: #333;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.color {
  margin: 8px 8px 8px 0;
}

.destination {
  font-size: 12px;
  color: #777;
  line-height: 20px;
  height: 60px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.detail {
  position: relative;
  border-top: 1px solid #EEEEEE;
  padding: 5px 32px 0 0;
  padding-top: 5px;
}

.description {
  width: 100%;
  color: #555;
  font-size: 12px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: end;

  .min_count {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #555555;
  }

  .price_unit {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #DD5055;
  }

  .price {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #DD5055;
    margin-left: 2px;
  }

  .delivery_charge {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #191D38;
  }

  .earliest_delivery_date {
    margin-bottom: 8px;

    &_wday {
      font-size: 10px;
      line-height: 12px;
    }
  }

  .lowest_price {
    overflow-wrap: anywhere;
    color: #FF8C71;

    &_break_line {
      display: inline-block;
    }

    &_tax {
      font-size: 10px;
      line-height: 12px;
    }
  }
}

.request_only {
  background-color: #FF8C71;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  opacity: .8;
  padding: 5px;
}

.farm_name {
  color: #999999;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cartin {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
}

.cartin_button,
.go_cart_button {
  width: 40px !important;
  height: 40px !important;
}

.cartin_button_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #375293;
  width: 20px;
  height: 20px;

  .icon {
    font-size: 16px;
  }
}

.go_cart_button_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  background: #375293;

  .icon {
    font-size: 12px;
  }
}

.reviews-container {
  font-size: 12px;
}

.review-marks {
  color: #191D38;
  font-weight: 700;
  line-height: 14.52px;
}
.total-review-count {
  padding: 10px;
  color: #191D38;
  font-weight: 400;
  line-height: 14.52px;
}
</style>
