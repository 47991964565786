<template>
  <div class="date-picker mb-1">
    <div v-resize="onResize" class="date-list flex-grow-1" ref="dateListRef" @scroll="onScroll">
      <v-card v-for="date in dateList" :key="date.toString()" class="card" :outlined="true"
        :class="{ selected: date === selected }"
        @click="$emit('change', toDateKey(date, dateList[0], dateList[dateList.length - 1]))">
        <div class="date">{{ $d(date, 'shortDate') }} ({{ $d(date, 'day') }})</div>
      </v-card>
    </div>
    <div class="monthly-select d-flex justify-space-between">
      <v-btn icon class="ml-5"  @click="scrolltoMonthly(-1)">
        <v-icon>mdi-chevron-left</v-icon>
        {{ $t("previous_month") }}
      </v-btn>
      <v-btn icon class="mr-5" @click="scrolltoMonthly(1)">
        {{ $t("next_month") }}
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/plugins/i18n";

export function addDate(date, value) {
  date = new Date(date);
  date.setDate(date.getDate() + value);
  return date;
}

export default {
  model: {
    prop: "selected",
    event: "change"
  },

  components: {
  },

  props: {
    selected: {
      type: String,
      default: null
    },
    allowWdayList: {
      type: Array,
      default: null
    },
  },

  data() {
    return {
      centerDate: this.nextDateKey(this.selected, 1, this.allowWdayList),
      length: 1,
      initDate: null
    };
  },

  mounted() {
    this.initDate = this.nextDateKey(null, 1, this.allowWdayList);
  },

  watch: {
    allowWdayList() {
      this.centerDate = this.nextDateKey(this.selected, 1, this.allowWdayList);
    }
  },

  computed: {
    dateList() {
      const list = [this.centerDate];
      let date1 = this.centerDate;
      let date2 = this.centerDate;

      let left = 0;
      let i = 0;
      while (i < this.length) {
        date2 = this.nextDateKey(addDate(date2, -1), -1, this.allowWdayList);

        if (date2 < this.initDate) {
          left = this.length - i;
          break;
        } else {
          list.unshift(date2);
          i++;
        }
      }

      let j = 0;
      while (j < this.length + left) {
        date1 = this.nextDateKey(addDate(date1, 1), 1, this.allowWdayList);

        list.push(date1);
        j += 1;
      }

      const defaultArea = this.$store.getters.getDefaultLocation.area;
      let result = [...list];

      // TODO: delivery_on_wednesdayがtrueの時に月初からの配列が生成されるので修正必須
      // 水曜日配送がオフの場合、特定配送日(special_delivery_days)に指定されていない水曜日の日付を除外
      if (!defaultArea?.delivery_on_wednesday) {
        result = list.filter(date => new Date(date).getDay() !== 3 || this.$store.getters.getSpecialDeliveryDays.includes(date));
      } else {
        const today = new Date();
        today.setHours(24, 0, 0, 0);
        this.$store.getters.getSpecialDeliveryDays.forEach(item => {
          const date = new Date(item);
          const center = new Date(this.centerDate);
          const nextMonthEnd = new Date(center.getFullYear(), center.getMonth() + 2, 0);
          if (date >= today && date <= nextMonthEnd) result.push(item);
        });
      }

      result.sort((a, b) => new Date(a) - new Date(b));
      return [...new Set(result)];
    }
  },
  methods: {
    onResize() {
      const width = this.$refs.dateListRef.offsetWidth;
      let i = 1;
      while (width > (i * 2 + 1) * 150) i++;
      this.length = i;
      this.length = 5;
      // setTimeout(() => {
      //   const element = this.$refs.dateListRef;
      //   element.scrollLeft = (element.scrollWidth - element.clientWidth) / 2;
      // }, 100);
    },
    scrolltoMonthly(direction = 1) {
      const date = new Date(this.centerDate);
      date.setMonth(date.getMonth() + direction);
      date.setDate(1);
      this.centerDate = this.nextDateKey(date, 1, this.allowWdayList);
      this.scrollTo();
    },
    toDateKey(date, startDate, endDate) {
      this.initDate = this.nextDateKey(null, 1, this.allowWdayList);
      if ((date === startDate || date === endDate) && (date !== this.initDate)) {
        this.centerDate = date;
        this.scrollTo();
      }
      return formatDate(date, "yyyy-mm-dd");
    },
    nextDateKey(date = null, step = 1, allowWdays) {
      date = date ? new Date(date) : new Date();
      if (!allowWdays || !allowWdays.length) return formatDate(date, "yyyy-mm-dd");
      const wdays = allowWdays && allowWdays.length > 0 ? allowWdays : [0, 1, 2, 3, 4, 5, 6];
      while (!wdays.includes(date.getDay())) {
        date = addDate(date, step);
      }
      return formatDate(date, "yyyy-mm-dd");
    },
    scrollTo() {
      const duration = 200;
      const element = this.$refs.dateListRef;
      const scrollPos = element.scrollLeft;
      const startTime = "now" in window.performance ? performance.now() : new Date().getTime();
      function scroll(timestamp) {
        const timeElapsed = timestamp - startTime;
        const progress = Math.min(Math.sin((timeElapsed / duration) * (Math.PI / 2)), 1);
        const maxScrollLeft = element.scrollWidth - element.clientWidth;
        element.scrollLeft = Math.min(maxScrollLeft - 1, Math.max(1, (maxScrollLeft - scrollPos) + (-maxScrollLeft / 2 + scrollPos) * (Math.round(progress * 10) / 10)));
        if (timeElapsed < duration) {
          window.requestAnimationFrame(scroll);
        }
      }
      window.requestAnimationFrame(scroll);
    },
    onScroll(e) {
      // eslint-disable-next-line no-unused-vars
      const { scrollLeft, offsetWidth, scrollWidth, clientWidth } = e.target;
      const maxScrollLeft = scrollWidth - clientWidth;
      if (scrollLeft === 0) {
        setTimeout(() => this.toDateKey(this.dateList[0], this.dateList[0], this.dateList[this.dateList.length - 1]), 500);
      } else if (scrollLeft === maxScrollLeft) {
        setTimeout(() => this.toDateKey(this.dateList[this.dateList.length - 1], this.dateList[0], this.dateList[this.dateList.length - 1]), 500);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$secondary-color: #375293;

.date-picker {
  padding: 0 16px;
  user-select: none;
  white-space: nowrap;

  @media screen and (max-width: 960px) {
    padding: 0;
  }
}

.date-list {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 5px;
  overflow: auto;
  overscroll-behavior: contain;
}

::-webkit-scrollbar {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.card {
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  text-align: center;
  color: #999999;
  cursor: pointer;

  &.selected {
    color: #fff;
    font-weight: bold;
    background-color: $secondary-color;
  }
}

.day {
  background-color: #F5F5F5;
  border-radius: 3px;
  font-size: 12px;
  line-height: 18px;
  color: #777;
  margin-bottom: 4px;

  @media screen and (max-width: 960px) {
    font-size: 14px;
    line-height: 24px;
  }

  .selected & {
    background-color: #339879;
    color: #fff;
  }
}

.date {
  font-size: 16px;
  line-height: 20px;

  @media screen and (max-width: 960px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.monthly-select {
  button {
    color: #191D38 !important;
  }
}

.move {
  width: 20px;
  height: 50px;
  fill: #333;
  cursor: pointer;

  &.prev .arrow {
    transform: scaleX(-1);
  }

  @media screen and (max-width: 960px) {
    width: 26px;
    height: 56px;
  }
}
</style>
