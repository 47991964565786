<template>
  <div class="mb-5">
    <div class="delivery-title">
      <section-title :text="title"></section-title>
      <div v-if="isSelectable" class="align-self-center">
        <div v-if="!isShowCheckBox" @click.self="switchShowCheckBox">選択</div>
        <div v-else @click.self="switchShowCheckBox">キャンセル</div>
      </div>

    </div>
    <v-card class="mb-1" outlined>
      <table style="width: 100%">
        <template v-if="viewType !== 'card'">
          <thead>
            <tr>
              <th v-for="header in headers"
                  :key="header.value">
                <span style="font-weight:bold;">{{ header.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <tbody else v-for="name in Object.keys(items)"
            :key="name">
          <template v-if="viewType !== 'card'">
            <tr class="order_name">
              <td :colspan="headers.length - 1">
                <span style="font-weight:bold;" v-html="name"></span>
              </td>
              <td class="check">
                <input type="checkbox"
                  v-if="isSelectable && isShowCheckBox"
                  v-bind:checked="selectStatusByUser[name]"
                  v-model="selectStatusByUser[name]"
                  @change="updateCheckByUser(name)"
                />
              </td>
            </tr>
          </template>
          <template v-if="viewType === 'card'">
            <tr class="order_name_card">
              <div style="font-weight:bold;" v-html="name"></div>
              <div class="check">
                <input type="checkbox"
                  v-if="isSelectable && isShowCheckBox"
                  v-bind:checked="selectStatusByUser[name]"
                  v-model="selectStatusByUser[name]"
                  @change="updateCheckByUser(name)"
                />
              </div>
            </tr>
          </template>
          <template v-if="viewType === 'card'">
            <tr v-for="item in items[name]" :key="item.id">
              <OrderCard :title="title" :type="type" :item="item" :is-show-check-box="isShowCheckBox" :is-selectable="isSelectable" :checked="item.is_checked" />
            </tr>
          </template>
          <template v-else>
            <tr v-for="item in items[name]" :key="item.id">
              <td>
                <DeliveryStatus :status="item.status" />
              </td>
              <td>{{ item.id }}</td>
              <td>{{ item.item_name }}</td>
              <td>{{ `${item.subtotal_count}&nbsp;本` }}</td>
              <td v-if="isAdmin">{{ item.area_name }}</td>
              <td v-if="isShippingDriver && type === 'from'">{{ item.area_name }}</td>
              <td v-if="(isShippingDriver && type === 'to')">{{ item.farm_name }}</td>
              <td class="check">
                <input type="checkbox" v-if="isSelectable && isShowCheckBox || item.is_qr" v-bind:checked="item.is_checked" v-model="item.is_checked" @change="updateCheck(item)"/>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </v-card>
  </div>
</template>

<script>
import SectionTitle from "@/components/driver/SectionTitle.vue";
import DeliveryStatus from "@/components/driver/information/DeliveryStatus.vue";
import OrderCard from "@/components/driver/information/OrderCard.vue";
import { formatDate } from "@/plugins/i18n";

export default {
  components: {
    SectionTitle,
    DeliveryStatus,
    OrderCard
  },
  data() {
    return {
      isShowCheckBox: false,
    };
  },
  props: {
    title: String,
    items: Object,
    type: String,
    isAdmin: Boolean,
    headers: Array,
    viewType: String
  },
  computed: {
    driverType() {
      return this.$store.state.user.driver && this.$store.state.user.driver.driver_type;
    },
    isShippingDriver() {
      return this.driverType === "shipping";
    },
    isDeliveryDriver() {
      return this.driverType === "delivery";
    },
    isListView() {
      return this.viewType === "list";
    },
    selectedOrderList() {
      return this.$store.state.driverInformation.selectedOrderList;
    },
    selectedOrderListCnt() {
      return Object.values(this.$store.state.driverInformation.selectedOrderList).filter(item => item.checked).length;
    },
    deliveryType() {
      return this.$store.state.driverInformation.deliveryType;
    },
    isSelectable() {
      const deliveryTypeSelected = this.deliveryType === "";
      const isShip = this.deliveryType === "ship" && this.type === "from";
      const isDelivery = this.deliveryType === "delivery" && this.type === "to";
      return deliveryTypeSelected || isShip || isDelivery;
    },
    selectStatusByUser() {
      const checks = {};
      Object.keys(this.items).forEach(name => {
        checks[name] = false;
        const checkedNum = this.items[name].filter(product => product.is_checked === true).length;
        if (checkedNum === this.items[name].length) checks[name] = true;
      });
      return checks;
    }
  },
  methods: {
    formatDate(d) {
      return formatDate(d, "yyyy-mm-dd");
    },
    switchShowCheckBox() {
      this.isShowCheckBox = !this.isShowCheckBox;
      if (!this.isShowCheckBox) {
        this.$store.dispatch("driverInformation/clearSelectedOrderList");
      }
    },
    updateCheck(item) {
      const selectedOrderList = { ...this.$store.state.driverInformation.selectedOrderList };
      if (selectedOrderList[item.id.toString()]) {
        selectedOrderList[item.id.toString()].checked = !selectedOrderList[item.id.toString()].checked;
      } else {
        selectedOrderList[item.id.toString()] = { checked: true, is_qr: false };
      }
      if (selectedOrderList[item.id.toString()].checked && this.deliveryType === "") {
        this.$store.dispatch("driverInformation/setSelectedOrderItem", { orderItem: selectedOrderList, type: this.type });
      } else {
        this.$store.dispatch("driverInformation/setSelectedOrderItem", { orderItem: selectedOrderList });
      }
    },
    updateCheckByUser(name) {
      const selectedOrderList = { ...this.$store.state.driverInformation.selectedOrderList };
      const checkStatus = this.selectStatusByUser[name];
      this.items[name].forEach(product => {
        const id = product.id.toString();
        if (selectedOrderList[id]) {
          selectedOrderList[id].checked = checkStatus;
        } else {
          selectedOrderList[id] = { checked: true, is_qr: false };
        }
      });
      if (this.deliveryType === "") {
        this.$store.dispatch("driverInformation/setSelectedOrderItem", { orderItem: selectedOrderList, type: this.type });
      } else {
        this.$store.dispatch("driverInformation/setSelectedOrderItem", { orderItem: selectedOrderList });
      }
    }
  }
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  font-size: 12px;
}
th, td {
  padding: 8px 10px !important;
  border: none;
  border-bottom: 1px solid #cccccc;
}
.delivery-title {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
}
.order_name {
  background-color: #d3d3d3 !important;
}
.order_name_card {
  background-color: #d3d3d3 !important;
  display: flex;
  padding: 8px 10px;
  justify-content: space-between;
  width: 100%;
}
.check {
  width: 35px;
  text-align: center;
}
</style>
