const storageKey = "CavinLocalStorage";
let data = null;

function loadStorage() {
  const d = window.localStorage.getItem(storageKey);
  if (d) {
    data = JSON.parse(d);
  } else {
    data = {};
  }
}

function dumpStorage() {
  window.localStorage.setItem(storageKey, JSON.stringify(data));
}

export default function db(key, value) {
  if (!data) {
    loadStorage();
  }

  return new Promise(resolve => {
    if (value === undefined) {
      resolve(data[key]);
    } else {
      data[key] = value;
      dumpStorage();
      resolve(true);
    }
  });
}
