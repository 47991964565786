<template>
  <div>
  <div v-for="item in list" :key="item.id" class="mb-2">
    <v-card @click="clickHandler(item.id)"
    >
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title
            class="text-subtitle-1"
            v-text="item.name"
          ></v-card-title>

          <v-card-subtitle v-text="item.flower_genus.name"></v-card-subtitle>
        </div>

        <v-avatar
          class="ma-3"
          size="125"
          tile
        >
          <v-img :src="item.image_url"></v-img>
        </v-avatar>
      </div>
    </v-card>
  </div>
  </div>
</template>

<script>
import { localeDate } from "@/plugins/i18n";

export default {
  props: {
    list: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs
    };
  },

  methods: {
    clickHandler(key) {
      if (key) {
        this.$router.push(
          `/shop/products?flower_specie_id=${key}`
        );
      }
    }
  },

  computed: {
    items() {
      return this.list.map(x => ({
        ...x,
        earliest_delivery_date_label: x.earliest_delivery_date
          ? localeDate(x.earliest_delivery_date, "select")
          : "-",
        image_url: x.thumbnail,
        disabled: !!x.earliest_delivery_date
      }));
    }
  }
};
</script>

<style lang="scss">
</style>
