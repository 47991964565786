<template>
  <div class="page-root">
    <div class="header">
      <img class="logo" src="@/assets/CAVIN_Logo_white.png" height="67" />
      <div class="logo-caption">CAVIN（キャビン）へようこそ</div>
    </div>

    <div class="page-title">
      新規会員登録画面
    </div>

    <div class="user-types">
      <router-link class="user-type" to="/users/sign_up?type=farm">
        <div class="name">農家/農園の方はこちら</div>
        <div class="note">お花の登録や料金設定</div>
        <ArrowCircleRightIcon class="arrow" />
      </router-link>
      <router-link class="user-type" to="/users/sign_up?type=shop">
        <div class="name">花屋の方はこちら</div>
        <div class="note">お花の購入やフィードバック</div>
        <ArrowCircleRightIcon class="arrow" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ArrowCircleRightIcon from "@/components/icons/ArrowCircleRightIcon.vue";

export default {
  components: {
    ArrowCircleRightIcon
  }
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 40px;

  .logo {
    display: block;
    margin: 0 auto;
  }

  .logo-caption {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
}

.page-title {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

.user-types {
  margin: 34px auto 0;
  display: grid;
  grid-template-columns: 400px 400px;
  grid-gap: 20px 40px;
  justify-content: center;

  @media screen and (max-width: 1000px) {
    max-width: 600px;
    grid-template-columns: 1fr;
  }
}

.user-type {
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  justify-content: space-between;
  grid-gap: 20px 0;
  padding: 30px 20px 30px 40px;
  background-color: #FFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  .name {
    color: #111;
    font-size: 24px;
    font-weight: bold;
  }

  .note {
    color: #555;
    font-size: 18px;
  }

  .arrow {
    grid-row: span 2;
    align-self: center;
    color: #ccc;
  }

  @media screen and (max-width: 1000px) {
    padding: 20px;

    .name {
      font-size: 20px;
    }

    .note {
      font-size: 16px;
    }
  }
}
</style>
