<template>
  <nav role="navigation" class="mstTheNavigation">
    <ul class="mstTheNavigation__items">
      <li v-for="item in items" :key="item.to" class="mstTheNavigation__item">
        <router-link :to="item.to" class="mstTheNavigation__link">
          <MstIcon :type="item.icon" size="24px" class="mstTheNavigation__icon" />
          <span class="mstTheNavigation__label">{{ item.label }}</span>
          <i v-if="item.badge" class="mstTheNavigation__badge">{{ item.badge }}</i>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { MstIcon } from "@/components/master";

export default {
  name: "MstTheNavigation",
  components: { MstIcon },
  props: {
    items: { type: Array, required: true },
  },
};
</script>

<style lang="scss" scoped>
.mstTheNavigation {
  border-top: 1px solid variables.$color-gray-500;
  padding: 7px 12px 15px;
  width: 100%;
  background: variables.$color-white;
}

.mstTheNavigation__items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 12px;
  padding: 0;
  list-style: none;
}

.mstTheNavigation__link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px 0;
  padding: 5px 6px;
  text-decoration: none;
  color: variables.$color-gray-600;

  &[aria-current="page"] {
    color: variables.$color-blue-900;
  }
}

.mstTheNavigation__label {
  display: inline-block;
  font-size: 12px;

  [aria-current="page"] & {
    font-weight: 600;
  }
}

.mstTheNavigation__badge {
  position: absolute;
  top: -1px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background: variables.$color-red-500;
  font-size: 10px;
  font-style: normal;
  line-height: 1;
  color: variables.$color-white;
}
</style>
