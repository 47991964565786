<template>
  <div class="mstTextField">
    <div class="mstTextField__field">
      <MstIcon v-if="leading" :type="leading" size="18px" class="mstTextField__leading" />
      <input
        v-model="modelValue"
        v-bind="$attrs"
        :id="componentId"
        :type="type"
        :aria-invalid="!!error"
        :aria-describedby="describedby"
        :style="styled"
        class="mstTextField__input"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
        @change="$emit('change', $event)"
      />
      <MstIcon v-if="trailing" :type="trailing" size="18px" class="mstTextField__trailing" />
      <slot />
    </div>
    <p v-if="hint" :id="`${componentId}-hint`" class="mstTextField__hint">
      {{ hint }}
    </p>
    <p v-if="error" :id="`${componentId}-error`" role="alert" aria-live="assertive" class="mstTextField__error">
      {{ error }}
    </p>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import { MstIcon } from "@/components/master";

export default {
  name: "MstTextField",
  inheritAttrs: false,
  components: { MstIcon },
  props: {
    value: { type: String, required: true },
    type: { type: String, default: "text" },
    id: { type: String },
    leading: { type: String },
    trailing: { type: String },
    hint: { type: String },
    error: { type: String },
    width: { type: String, default: "100%" },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    componentId() {
      return this.id || `field-${nanoid()}`;
    },
    describedby() {
      const ids = [];
      if (this.hint) ids.push(`${this.componentId}-hint`);
      if (this.error) ids.push(`${this.componentId}-error`);
      return ids.join(" ");
    },
    styled() {
      const styled = {};
      styled.width = this.width;
      return styled;
    },
  },
};
</script>

<style lang="scss" scoped>
.mstTextField__field {
  position: relative;

  &:has(.mstTextField__leading) {
    .mstTextField__input {
      padding-left: 32px;
    }
  }

  &:has(.mstTextField__trailing) {
    .mstTextField__input {
      padding-right: 32px;
    }
  }
}

.mstTextField__input {
  border-radius: 4px;
  border: 1px solid variables.$color-gray-200;
  padding: 6px 8px;
  width: 100%;
  font-size: 16px;
  line-height: 1.2;
  outline: none;
  transition: border 0.3s ease;

  &[type="search"] {
    border-color: variables.$color-gray-50;
    padding: 8px;
    background: variables.$color-gray-50;
  }

  &::placeholder {
    color: variables.$color-gray-600;
  }

  &:focus {
    border-color: variables.$color-blue-900;
  }
}

.mstTextField__leading,
.mstTextField__trailing {
  position: absolute;
  top: 50%;
  color: variables.$color-gray-600;
  transform: translateY(-50%);
}

.mstTextField__leading {
  left: 8px;
}

.mstTextField__trailing {
  right: 8px;
}

.mstTextField__hint {
  margin-top: 8px;
  font-size: variables.$font-size-xs;
  line-height: 1.4;
  color: variables.$color-gray-600;
}

.mstTextField__error {
  margin-top: 8px;
  font-size: variables.$font-size-xs;
  line-height: 1.4;
  color: variables.$color-red-500;
}
</style>
