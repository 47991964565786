<template>
  <div>
    <v-tabs
      v-model="tab"
      color="black"
      centered
      grow
      show-arrows
      height="40"
      active-class="font-weight-bold"
    >
      <v-tabs-slider :color="primaryColor"></v-tabs-slider>
      <v-tab :key="0" href="#tab-0" @click="search(null)" class="ga-shop-filter-categorytab-all">{{ $t("all_1") }} </v-tab>
      <v-tab
        @click="search(category)"
        :class="`ga-shop-filter-categorytab-${category.id}`"
        v-for="category in categories"
        :key="`tab${category.id}`"
        :href="`#tab-${category.id}`"
      >
        {{ category.name }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: null
    },
    onSearch: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      tab: "tab-0",
      primaryColor: "#375293",
      query: {
        key: "category",
        value: null
      },
    };
  },
  beforeUpdate() {
    this.tab = `tab-${this.$route.query.category_id}`;
  },
  methods: {
    search(category) {
      this.query.value = category;
      this.onSearch(this.query);
    },
    clearTab() {
      this.tab = "tab-0";
    },
    changeTab(category) {
      this.tab = `tab-${category ? category.id : 0}`;
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    userId() {
      return this.$store.state.user.id;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
