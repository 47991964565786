<template>
  <div class="full-root d-flex flex-column justify-center align-center">
    <slot />
  </div>
</template>

<script>
export default {

};
</script>

<style scoped>
.full-root {
  width: 100%;
  height: 100%;
}
</style>
