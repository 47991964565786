import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Users from "@/views/users/index.vue";
import UsersArea from "@/views/users/area.vue";
import NotAvailableArea from "@/views/users/not_available_area.vue";
import UnuseableAreaComplete from "@/views/users/unuseable_area_user/complete.vue";
import UsersNew from "@/views/users/new.vue";
import UsersSignUp from "@/views/users/sign_up.vue";
import UsersSendEmail from "@/views/users/send_email.vue";
import UsersWaitConfirmed from "@/views/users/wait_confirmed.vue";
import UsersConfirm from "@/views/users/confirm.vue";
import UsersReConfirmation from "@/views/users/reconfirmation.vue";
import UsersError from "@/views/users/error.vue";
import Shop from "@/views/shop/index.vue";
import Farm from "@/views/farm/index.vue";
import FarmHome from "@/views/farm/home.vue";
import Driver from "@/views/driver/index.vue";
import FarmLogin from "@/views/farm/login.vue";
import FarmSignup from "@/views/farm/signup.vue";
import FarmDashboard from "@/views/farm/dashboard.vue";
import FarmHarvest from "@/views/farm/harvests/index.vue";
import FarmHarvestDetail from "@/views/farm/harvests/detail.vue";
import FarmFlowerHarvest from "@/views/farm/flower_harvests/index.vue";
import FarmFlowerHarvestsByFlower from "@/views/farm/flowers/flower_harvests.vue";
import FarmFlowerHarvestNew from "@/views/farm/flower_harvests/new.vue";
import FarmFlowerHarvestDetail from "@/views/farm/flower_harvests/detail.vue";
import FarmFlowerHarvestEdit from "@/views/farm/flower_harvests/edit.vue";
import FarmFlowerHarvestMultiEdit from "@/views/farm/flower_harvests/multi_edit.vue";
import FarmOrderItems from "@/views/farm/order_items/index.vue";
import FarmOrderItem from "@/views/farm/order_items/order_item.vue";
import FarmShippingResults from "@/views/farm/shipping_results/index.vue";
import FarmShippingResult from "@/views/farm/shipping_results/shipping_result.vue";
import FarmPdfPicker from "@/views/farm/pdf_picker.vue";
import FarmFlowersGenera from "@/views/farm/flowers/index.vue";
import FarmFlower from "@/views/farm/flowers/flowers.vue";
import FarmHiddenFlowers from "@/views/farm/flowers/hiddenFlowers.vue";
import FarmFlowerDetail from "@/views/farm/flowers/detail.vue";
import FarmAccountSetting from "@/views/farm/account_setting.vue";
import FarmFarmSetting from "@/views/farm/farm_setting.vue";
import ShopLayout from "@/views/shop/layout.vue";
import ShopPdfPicker from "@/views/shop/pdf_picker.vue";
import ShopLogin from "@/views/shop/login.vue";
import ShopSignup from "@/views/shop/signup.vue";
import ShopProducts from "@/views/shop/products/index.vue";
import ShopProductsDetail from "@/views/shop/products/detail.vue";
import ShopFlowerSpecieIndex from "@/views/shop/flower_species/index.vue";
import ShopFlowerGenusIndex from "@/views/shop/flower_genus/index.vue";
import ShopOrders from "@/views/shop/orders/index.vue";
import ShopCollections from "@/views/shop/collections/index.vue";
import ShopCollectionsDetail from "@/views/shop/collections/detail.vue";
import ShopCart from "@/views/shop/cart/index.vue";
import ShopCartConfirmation from "@/views/shop/cart/confirmation.vue";
import ShopCartComplete from "@/views/shop/cart/complete.vue";
import ShopSettingsShop from "@/views/shop/settings/shop.vue";
import ShopSettingsAccount from "@/views/shop/settings/account.vue";
import ShopSettingsShippingAddress from "@/views/shop/settings/shipping_address.vue";
import DriverLogin from "@/views/driver/login.vue";
import DriverHome from "@/views/driver/home.vue";
import QrCodeReader from "@/views/driver/information/qrCodeReader.vue";
import DriverInformation from "@/views/driver/information/index.vue";
import DriverSettingsDriver from "@/views/driver/settings/driver.vue";
import DriverSettingsAccount from "@/views/driver/settings/account.vue";
import ConversationList from "@/views/common/conversations/index.vue";
import ConversationDetail from "@/views/common/conversations/detail.vue";
import Admin from "@/views/admin/index.vue";
import AdminLogin from "@/views/admin/login.vue";
import Maintenance from "@/views/Maintenance.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  { name: "maintenance", path: "/maintenance", component: Maintenance },
  { name: "home", path: "/", component: Home },
  {
    path: "/users",
    component: Users,
    children: [
      { name: "usersArea", path: "area", component: UsersArea },
      { name: "notAvailableArea", path: "not_available_area", component: NotAvailableArea },
      { name: "unuseableAreaComplete", path: "not_available_area/complete", component: UnuseableAreaComplete },
      { name: "usersNew", path: "new", component: UsersNew },
      { name: "usersSignUp", path: "sign_up", component: UsersSignUp },
      { name: "usersSendEmail", path: "send_email", component: UsersSendEmail },
      { name: "usersWaitConfirmed", path: "wait_confirmed", component: UsersWaitConfirmed },
      { name: "usersConfirm", path: "confirm", component: UsersConfirm, props: true },
      { name: "usersReConfirmation", path: "reconfirmation", component: UsersReConfirmation },
      { name: "usersError", path: "error", component: UsersError }
    ]
  },
  {
    path: "/shop",
    component: Shop,
    children: [
      {
        name: "Shop",
        path: "",
        redirect: "products",
        component: ShopLayout,
        children: [
          { name: "shopProducts", path: "products", component: ShopProducts },
          { name: "shopProductsDetail", path: "products/:productId", component: ShopProductsDetail },
          { name: "shopFlowerSpecieIndex", path: "flower_species", component: ShopFlowerSpecieIndex },
          { name: "shopFlowerGenusIndex", path: "flower_genus", component: ShopFlowerGenusIndex },
          { name: "shopOrders", path: "orders", component: ShopOrders },
          { name: "shopConversations", path: "conversations", component: ConversationList },
          { name: "shopConversationsDetail", path: "conversations/:conversationId", component: ConversationDetail },
          { name: "shopCollections", path: "collections", component: ShopCollections },
          { name: "shopCollectionsDetail", path: "collections/:collectionId", component: ShopCollectionsDetail },
          { name: "shopCart", path: "cart", component: ShopCart },
          { name: "shopCartConfirmation", path: "cart/confirmation", component: ShopCartConfirmation },
          { name: "shopCartComplete", path: "cart/complete", component: ShopCartComplete },
          { name: "shopSettings", path: "settings", redirect: "settings/shop" },
          { name: "shopSettingsShop", path: "settings/shop", component: ShopSettingsShop },
          { name: "shopSettingsAccount", path: "settings/account", component: ShopSettingsAccount },
          { name: "shopSettingsShippingAddress", path: "settings/shipping_address", component: ShopSettingsShippingAddress },
          { name: "shopPdfPicker", path: "pdf_picker", component: ShopPdfPicker }
        ]
      },
      { name: "shopLogin", path: "login", component: ShopLogin },
      { name: "shopSignup", path: "signup", component: ShopSignup },
      { name: "shopNotFound", path: "*", component: ShopLayout }
    ]
  },
  {
    path: "/farm",
    component: Farm,
    children: [
      {
        name: "Farm",
        path: "",
        component: FarmHome,
        redirect: "/farm/dashboard",
        children: [
          { name: "farmDashboard", path: "dashboard", component: FarmDashboard },
          { name: "farmHarvest", path: "harvests", component: FarmHarvest },
          { name: "farmHarvestDetail", path: "harvests/:flowerGenusId", component: FarmHarvestDetail },
          { name: "farmFlowerHarvest", path: "flower_harvests", component: FarmFlowerHarvest },
          { name: "farmFlowerHarvestsByFlower", path: "flowers/:flower_id/flower_harvests", component: FarmFlowerHarvestsByFlower },
          { name: "farmFlowerHarvestNew", path: "flower_harvests/new", component: FarmFlowerHarvestNew },
          { name: "farmFlowerHarvestDetail", path: "flower_harvests/:flower_harvest_id", component: FarmFlowerHarvestDetail },
          { name: "farmFlowerHarvestEdit", path: "flower_harvests/:flower_harvest_id/edit", component: FarmFlowerHarvestEdit },
          { name: "farmFlowerHarvestMultiEdit", path: "flower_harvests/:flower_harvest_ids/multi_edit", component: FarmFlowerHarvestMultiEdit },
          { name: "farmOrderItems", path: "order_items", component: FarmOrderItems },
          { name: "farmOrderItem", path: "order_items/:id", component: FarmOrderItem },
          { name: "farmConversations", path: "conversations", component: ConversationList },
          { name: "farmConversationsDetail", path: "conversations/:conversationId", component: ConversationDetail },
          { name: "farmFlowersGenera", path: "flower_genera", component: FarmFlowersGenera },
          { name: "farmFlower", path: "flower_genera/:flower_genera_id/flowers", component: FarmFlower },
          { name: "farmFlowerDetail", path: "flowers/new", component: FarmFlowerDetail },
          { name: "farmFlowerDetail", path: "flower_genera/:flower_genera_id/flowers/:flower_id?", component: FarmFlowerDetail },
          { name: "FarmHiddenFlowers", path: "flower_genera/:flower_genera_id/hidden_flowers", component: FarmHiddenFlowers },
          { name: "farmAccountSetting", path: "setting/account", component: FarmAccountSetting },
          { name: "farmFarmSetting", path: "setting/farm", component: FarmFarmSetting },
          { name: "farmPdfPicker", path: "pdf_picker", component: FarmPdfPicker },
          { name: "farmShippingResults", path: "shipping_results", component: FarmShippingResults },
          { name: "farmShippingResult", path: "shipping_result", component: FarmShippingResult }
        ]
      },
      { name: "farmLogin", path: "login", component: FarmLogin },
      { name: "farmSignup", path: "signup", component: FarmSignup },
      { name: "farmNotFound", path: "*", component: FarmHome }
    ]
  },
  {
    path: "/driver",
    component: Driver,
    children: [
      {
        path: "",
        component: DriverHome,
        children: [
          { path: "", redirect: "/driver/information" },
          { name: "qrCodeReader", path: "/driver/qr_code_reader", component: QrCodeReader },
          { name: "driverInformation", path: "information", component: DriverInformation },
          { name: "driverSettings", path: "settings", redirect: "settings/driver" },
          { name: "driverSettingsDriver", path: "settings/driver", component: DriverSettingsDriver },
          { name: "driverSettingsAccount", path: "settings/account", component: DriverSettingsAccount }
        ]
      },
      { name: "deliveryLogin", path: "login", component: DriverLogin }
    ]
  },
  {
    path: "/admin",
    component: Admin,
    children: [{ name: "adminLogin", path: "login", component: AdminLogin }]
  },
  {
    path: "/farm/order_items",
    beforeEnter: () => {
      window.location.href = "https://cavin.farm/farms/shipping";
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  }
});
router.beforeEach((to, from, next) => {
  router.referrer = from;
  next();
});

export default router;
