/**
 * @typedef Dashboard
 * @type {object}
 * @property {number} flower_harvests_count
 * @property {number} order_items_count
 * @property {number} unread_messages_count
 * @property {number} order_items_count_today
 */

export default {
  state: {
    confirmedOrderItemsLotCount: 0,
    requestOrderItemsLotCount: 0,
    unreadMessagesCount: 0,
    orderItemsLotCountToday: 0,
    flowersCount: 0,
    harvestsCount: 0,
    comingShippingDate: ""
  },
  mutations: {
    setData(state, payload) {
      Object.keys(payload).forEach(x => {
        state[x] = payload[x];
      });
    }
  },
  actions: {
    async getDashboardData({ commit }) {
      const res = await this._vm.$http2("get", "home");
      if (res.status === 200) {
        commit("setData", res.result);
        return true;
      }

      return false;
    }
  },
  getters: {}
};
