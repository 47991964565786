<template>
  <!-- eslint-disable -->

  <svg :width="size" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.3334 5.33332H18.8334V0.666656H0.166687V15.8333H2.50002C2.50002 17.77 4.06335 19.3333 6.00002 19.3333C7.93669 19.3333 9.50002 17.77 9.50002 15.8333H16.5C16.5 17.77 18.0634 19.3333 20 19.3333C21.9367 19.3333 23.5 17.77 23.5 15.8333H25.8334V9.99999L22.3334 5.33332ZM6.00002 17.5833C5.03169 17.5833 4.25002 16.8017 4.25002 15.8333C4.25002 14.865 5.03169 14.0833 6.00002 14.0833C6.96835 14.0833 7.75002 14.865 7.75002 15.8333C7.75002 16.8017 6.96835 17.5833 6.00002 17.5833ZM16.5 4.16666H14.1667V12.3333H11.8334V4.16666H9.50002V12.3333H7.16669V4.16666H4.83335V12.3333H2.50002V2.99999H16.5V4.16666ZM20 17.5833C19.0317 17.5833 18.25 16.8017 18.25 15.8333C18.25 14.865 19.0317 14.0833 20 14.0833C20.9684 14.0833 21.75 14.865 21.75 15.8333C21.75 16.8017 20.9684 17.5833 20 17.5833ZM18.8334 9.99999V7.08332H21.75L24.0367 9.99999H18.8334Z" :fill="color"/>
  </svg>


  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>
