<template>
  <div class="setting-text-view">
    <div class="label">{{ label }}</div>
    <div class="text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.setting-text-view {
  & + & {
    margin-top: 30px;
  }
}

.label {
  color: #777;
  font-size: 16px;
}

.text {
  color: #111;
  font-size: 16px;
  padding: 13px 10px;
  min-height: 50px;
}
</style>
