<template>
  <label class="mstCheckbox">
    <span class="mstCheckbox__check">
      <input
        v-bind="$attrs"
        v-model="checkedModel"
        :aria-checked="indeterminate ? 'mixed' : String(checkedModel)"
        type="checkbox"
        class="mstCheckbox__input"
      />
    </span>
    <span v-if="label" class="mstCheckbox__label text-sm">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: "MstCheckbox",
  inheritAttrs: false,
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    checked: { type: Boolean, required: true },
    indeterminate: { type: Boolean },
    label: { type: String },
  },
  computed: {
    checkedModel: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mstCheckbox {
  display: flex;
  gap: 0 8px;
  line-height: 1;
  cursor: pointer;
}

.mstCheckbox__check {
  position: relative;
  display: inline-block;
  border-radius: 2px;
  border: 2px solid variables.$color-gray-600;
  width: 18px;
  height: 18px;
  background: variables.$color-white;
  transition: border 0.3s ease, background 0.3s ease;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: "Material Design Icons";
    font-style: normal;
    font-weight: normal;
    color: variables.$color-white;
    opacity: 0;
    transform: translate(-50%, -53%);
  }

  &::before {
    content: "\F0374";
  }

  &::after {
    content: "\F012C";
  }

  &:has(:checked),
  &:has([aria-checked='mixed']) {
    background: variables.$color-brand-primary;
    border-color: variables.$color-brand-primary;
  }

  &:has(:checked) {
    &::after {
      opacity: 1;
    }
  }

  &:has([aria-checked='mixed']) {
    &::before {
      opacity: 1;
    }
  }
}

.mstCheckbox__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 5px;
  opacity: 0;
}

.mstCheckbox__label {
  color: variables.$color-black-500;
}
</style>
