<template>
  <SettingLayout
    :key="updated"
    tab="shop"
    :on-submit="submit"
    :is-edit.sync="isEdit"
    :disabled="!user.id"
    :readonly="true"
  >
    <template v-if="isEdit">
      <SettingTextInput
        :label="$t('store_name')"
        required
        placeholder="CAVINフラワーショップ"
        v-model="name"
      />
      <SettingTextInput
        :label="$t('representative_name')"
        type="text"
        required
        placeholder="山田太郎"
        v-model="representative_name"
      />
      <SettingTextInput
        label="電話番号"
        id="phone"
        required
        placeholder="000-0000-0000"
        v-model="phone"
      />
      <SettingTextInput
        label="郵便番号 (ハイフン無し)"
        id="zip"
        required
        placeholder="8100041"
        v-model="postal_code"
      />
      <SettingTextInput
        id="prefecture"
        label="都道府県名"
        required
        placeholder="福岡県"
        v-model="prefectureName"
      />

      <SettingTextInput
        label="市町村名"
        id="municipality"
        required
        placeholder="福岡市"
        v-model="municipality"
      />
      <SettingTextInput label="住所" id="address" required placeholder="中央区白金45-2" v-model="address" />
      <SettingTextInput
        label="建物名"
        id="building"
        placeholder="CAVINビル 202号"
        v-model="building_name"
      />
      <SettingTextInput
        label="不在時の納品場所"
        id="when_absent"
        placeholder="屋根の下"
        v-model="when_absent"
      />
    </template>

    <template v-else>
      <SettingTextView :label="$t('store_name')">{{ name }}</SettingTextView>
      <SettingTextView :label="$t('representative_name')">{{ representative_name }}</SettingTextView>
      <SettingTextView label="電話番号">{{ phone }}</SettingTextView>
      <SettingTextView label="郵便番号 (ハイフン無し)">{{ postal_code }}</SettingTextView>
      <SettingTextView label="都道府県名">{{ prefectureName }}</SettingTextView>
      <SettingTextView label="市町村名">{{ municipality }}</SettingTextView>
      <SettingTextView label="住所">{{ address }}</SettingTextView>
      <SettingTextView label="建物名">{{ building_name }}</SettingTextView>
      <SettingTextView label="不在時の配送場所">{{ when_absent }}</SettingTextView>
    </template>
  </SettingLayout>
</template>

<script>
import SettingLayout from "@/components/shop/settings/SettingLayout.vue";
import SettingTextInput from "@/components/shop/settings/SettingTextInput.vue";
import SettingTextView from "@/components/shop/settings/SettingTextView.vue";

export default {
  components: {
    SettingLayout,
    SettingTextInput,
    SettingTextView
  },

  data() {
    return {
      isEdit: false,
      updated: 0,
      shopFields: ["name", "representative_name"],
      locationFields: [
        "phone",
        "postal_code",
        "prefecture",
        "municipality",
        "address",
        "building_name",
        "when_absent"
      ]
    };
  },

  beforeMount() {
    this.shopFields.forEach(x => {
      this[x] = "";
    });
    this.locationFields.forEach(x => {
      this[x] = "";
    });
  },

  async mounted() {
    await this.$store.dispatch("getProfile", "shop");

    this.shopFields.forEach(x => {
      this[x] = this.user.shop[x];
    });

    this.locationFields.forEach(x => {
      if (this.defaultLocation) {
        this[x] = this.defaultLocation[x];
      }
    });

    this.updated++;
  },

  methods: {
    async submit() {
      const locationContent = {};

      this.locationFields.forEach(x => {
        if (!this.defaultLocation[x] || this[x] !== this.defaultLocation[x]) {
          locationContent[x] = this[x];
        }
      });

      console.log("[locationContent]...", locationContent);

      console.log("[locationContent]...", locationContent);
      if (Object.keys(locationContent).length) {
        // Set to default just in case
        locationContent.default = true;

        await this.$store.dispatch("putLocation", {
          id: this.defaultLocation.id,
          content: locationContent
        });
      }

      this.isEdit = false;
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    defaultLocation() {
      return this.$store.state.defaultLocation;
    },
    prefectureName() {
      return this.prefecture?.attributes?.name;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
