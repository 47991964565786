<template>
  <v-chip
    class="max-width-chip"
    small
    :color="status_ja[status].color"
    text-color="white"
  >
    {{ status_ja[status].label }}
  </v-chip>
</template>

<script>
export default {
  props: {
    status: String
  },
  data() {
    return {
      status_ja: {
        confirmed: {
          label: "未集荷",
          color: "#888",
        },
        shipped: {
          label: "集荷済",
          color: "#629FF2",
        },
        unloaded: {
          label: "拠点着",
          color: "orange",
        },
        picked: {
          label: "配送中",
          color: "blue",
        },
        delivered: {
          label: "納品済",
          color: "green",
        }
      }
    };
  },
};
</script>

<style scoped>
.max-width-chip {
  max-width: 60px;
}
</style>
