<template>
  <div class="harvest-main">
    <Datepicker class="mt-3" :selected="selectedDate" :allowWdayList="allowWdayList" @change="dateHandler" />

    <div class="d-flex justify-space-between pl-3 pr-3 mt-3">
      <div><span class="text-h6">{{ flowerGenusCount }}</span>件</div>
      <harvestDialog :harvestDate="selectedDate" :dialogOpen="dialogOpen" :allowWdayList="allowWdayList"
        @open="openHandler"
        @close="closeHandler"
        @on-edit="onEdit" @edit-handler="editHandler" @copy="onCopy"/>
    </div>

    <div class="ma-3" style="overflow-x: auto; border-bottom: 1px solid #dedede">
      <Table :headers="headers" :items="items"
        :edit="(edit && !dialogOpen)"
        :onClick="onClick"
        :hiddenLabel="true"
        :hideDefaultFooter="true"
        :currentDate="selectedDate"
      />
    </div>
  </div>
</template>

<script>
import Datepicker from "@/components/shared/Datepicker.vue";
import Table from "@/components/shared/Table.vue";
import harvestDialog from "@/components/farm/harvest/harvestDialog.vue";
import { formatDate } from "@/plugins/i18n";

export default {
  components: { Datepicker, Table, harvestDialog },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      headers: [
        "item",
        "flower_count",
        "flower_harvest_count",
        "chevron_right"
      ].map(x => ({ text: this.$t(x), value: x, width: x === "item" ? "120px" : "80px" })),
      items: [],
      selectedDate: null,
      edit: false,
      dialogOpen: false,
      copyAllDisabled: false,
      flowerGenusCount: 0,
      editContent: {},
      weekArray: [
        this.$t("short_sunday"),
        this.$t("short_monday"),
        this.$t("short_tuesday"),
        this.$t("short_wednesday"),
        this.$t("short_thursday"),
        this.$t("short_friday"),
        this.$t("short_saturday")
      ]
    };
  },
  computed: {
    today() {
      return this.$date.MMDD(new Date());
    },
    tomorrow() {
      const d = new Date().getTime() / 1000 + 84600;
      return this.$date.MMDD(new Date(d));
    },
    allowWdayList() {
      return this.$store.getters.workingWdays;
    }
  },
  watch: {
    selectedDate() {
      return this.selectedDate;
    }
  },
  created() {
    let date = new Date();
    if (this.$route.query.harvest_date) { date = new Date(this.$route.query.harvest_date); }
    const wdays = this.allowWdayList && this.allowWdayList.length > 0 ? this.allowWdayList : [0, 1, 2, 3, 4, 5, 6];
    while (!wdays.includes(date.getDay())) {
      date.setDate(date.getDate() + 1);
    }
    this.dateHandler(formatDate(date, "yyyy-mm-dd"));
  },
  methods: {
    loadData(date) {
      this.$api.get(`/harvests/flower_genus?date=${date}`).then(response => {
        this.$store.dispatch("flowerHarvest/getFlowerHarvest", { date });
        this.$router.push(`/farm/harvests?harvest_date=${this.selectedDate}`);
        this.createTableData(response);
      });
    },
    async createTableData(data) {
      this.items = data.result.flower_genus.map(genus => ({ id: genus.id, item: genus.name, flower_count: genus.flower_count, flower_harvest_count: genus.flower_harvest_count }));
      this.flowerGenusCount = data.result.flower_genus_count;
    },
    dateHandler(d) {
      this.edit = false;
      this.selectedDate = d;
      this.loadData(d);
    },
    onClick(e) {
      this.$router.push(`/farm/harvests/${e.id}?harvest_date=${this.selectedDate}`);
    },
    editHandler() {
      if (!this.edit) {
        this.edit = true;
      } else if (Object.keys(this.editContent).length) {
        const payload = [];
        let harvest = {};
        Object.keys(this.editContent).forEach(key => {
          if (key < 0) this.getPostRequestBody(key);
          if (this.exhibitedFlowerHarvest(this.items[key])) {
            harvest = this.getPutRequestBody(key);
          } else {
            harvest = this.getPostRequestBody(key);
          }
          payload.push(harvest);
        });
        this.putFlowerHarvest(payload);
      } else {
        this.edit = false;
        this.dialogOpen = false;
      }
    },
    onEdit(e, id, key) {
      if (key === "status") {
        this.changeFlowerHarvestStatus(e, id);
        return;
      }

      const { value } = e.target;
      if (!this.editContent[id]) this.editContent[id] = {};
      this.editContent[id][key] = value;
      if (id > -1) this.items[id][key] = value;
    },
    putFlowerHarvest(payload) {
      this.$store.dispatch("flowerHarvest/putFlowerHarvest", payload)
        .then(() => {
          this.$store.dispatch("flowerHarvest/getFlowerHarvest", this.selectedDate, this.flowerGenusId)
            .then(() => {
              this.editContent = {};
              this.edit = false;
              this.dialogOpen = false;
              this.$router.push(`/farm/harvests?harvest_date=${this.selectedDate}`);
              this.$store.dispatch("setToast", { type: "success", msg: "更新しました" }, { root: true });
              this.createTableData();
            });
        });
    },
    exhibitedFlowerHarvest(harvest) {
      return harvest ? !!harvest.id : false;
    },
    getPostRequestBody(key) {
      const confirmed_count = (this.editContent[key].set_count || this.items[key].set_count) * (this.editContent[key].min_count || this.items[key].min_count);
      let note = "";
      if (Object.keys(this.editContent[key]).includes("note")) {
        note = this.editContent[key].note;
      } else {
        note = this.items[key].note;
      }

      const harvest_date = {
        start: this.selectedDate,
        end: this.selectedDate,
        weekdays: null
      };

      if (this.editContent[key].harvest_date?.end) {
        harvest_date.end = this.editContent[key].harvest_date?.end;
        harvest_date.weekdays = this.editContent[key].harvest_date.weekdays;
      }

      return {
        id: null,
        confirmed_count: confirmed_count === "" ? 0 : confirmed_count,
        min_count: this.editContent[key].min_count || this.items[key].min_count,
        price: this.editContent[key].price || this.items[key].price,
        flower_id: this.editContent[key].flower_id || this.items[key].flower_id,
        harvest_date,
        stem_size: this.editContent[key].stem_size || this.items[key].stem_size,
        note
      };
    },
    onCopy(index) {
      const harvest = this.$store.state.flowerHarvest.list[index];
      for (let i = 0; i < this.items.length; i++) {
        if (
          (harvest.id && this.items[index].id === harvest.id) || !harvest.id
        ) {
          const keyList = ["min_count", "confirmed_count", "price", "flower_id", "stem_size", "note"];

          keyList.forEach(key => {
            this.items[index][key] = harvest[key];
          });

          this.items[index].set_count = harvest.set_count;
          if (!this.editContent[index]) this.editContent[index] = {};

          keyList.forEach(key => {
            this.editContent[index][key] = harvest[key];
          });
          this.editContent[index].id = harvest.id;
          this.editContent[index].harvest_date = harvest.harvest_date;
          break;
        }
      }
    },
    openHandler() {
      this.edit = true;
      this.dialogOpen = true;
      this.editContent = {};
    },
    closeHandler() {
      this.edit = false;
      this.dialogOpen = false;
      this.editContent = {};
    }
  }
};
</script>

<style scoped>
.harvest-main {
  background: #f5f5f5;
}
</style>
