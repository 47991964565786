<template>
  <ul :class="styledClasses" class="flowerList">
    <li v-for="flower in flowers" :key="flower.id" class="flowerList__item">
      <component
        :is="!noLink ? 'button' : 'div'"
        :type="!noLink ? 'button' : undefined"
        class="flowerList__inner"
        @click="handleClick(flower.id)"
      >
        <span class="flowerList__imageContainer">
          <img
            v-if="flower.images && flower.images.length"
            :src="flower.images[0].image_url"
            class="flowerList__image"
            alt=""
          />
          <div v-else class="flowerList__noImage" />
        </span>
        <span class="flowerList__textContainer">
          <span class="flowerList__farm text-xs color-gray-600">{{ flower.farm.name }}</span>
          <strong class="flowerList__name text-xs color-black-500">{{ flower.flower_specie.name }}</strong>
        </span>
      </component>
    </li>
  </ul>
</template>

<script>
export default {
  name: "FlowerList",
  props: {
    flowers: { type: Array, required: true },
    noLink: { type: Boolean },
  },
  computed: {
    styledClasses() {
      const classes = [];
      if (this.noLink) classes.push("-no-link");
      return classes;
    },
  },
  methods: {
    handleClick(flowerId) {
      if (this.noLink) return;
      this.$emit("click", flowerId);
    },
  },
};
</script>

<style lang="scss" scoped>
.flowerList {
  display: flex;
  flex-wrap: wrap;
  gap: 26px 16px;
  padding: 0;
  list-style-type: none;
}

.flowerList__item {
  width: calc(50% - 8px);
}

.flowerList__inner {
  display: block;
  width: 100%;
  text-align: left;

  .-no-link & {
    cursor: default;
  }
}

.flowerList__imageContainer {
  display: block;
  width: 100%;
  aspect-ratio: 1 / 1.15;
}

.flowerList__image {
  width: 100%;
  height: 100%;
  vertical-align: top;
  object-fit: cover;
}

.flowerList__noImage {
  width: 100%;
  height: 100%;
  background: #ccc;
}

.flowerList__textContainer {
  position: relative;
  display: block;
  margin-top: 8px;

  &::after {
    position: absolute;
    top: 0;
    right: -5px;
    content: "\F0142";
    font-family: "Material Design Icons";
    font-size: 18px;
    line-height: 1;
    color: variables.$color-gray-600;
  }

  .-no-link &::after {
    display: none;
  }
}

.flowerList__farm,
.flowerList__name {
  display: block;
}

.flowerList__farm {
  overflow: hidden;
  padding-right: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.flowerList__name {
  margin-top: 2px;
}
</style>
