<template>
  <FlowerHarvestList />
</template>

<script>
import FlowerHarvestList from "@/components/farm/flowers/flower_harvests/index.vue";

export default {
  components: { FlowerHarvestList },
};
</script>
