<template>
  <div class="full-root d-flex justify-center align-center" @drop.prevent="e=>$emit('drop', e)" @dragover.prevent>
    <slot />
  </div>
</template>

<script>
export default {

};
</script>

<style scoped>
.full-root {
  background: #ffffff;
  width: 100%;
  height: 100%;
}
</style>
