<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" :height="size" viewBox="0 0 24 24" :width="size">
    <path
      :fill="color"
      d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z"
    />
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>

<style>
</style>
