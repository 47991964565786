<template>
  <div class="px-4 d-flex flex-column flex-grow-1">
    <FlowerImageSlider :imageHandler="imageHandler" :images="flowerImages" :flowerId="flowerId" />

    <div class="mt-4">
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("item") }}</p>
        </div>
        <div class="col-8">
          <template>
            <span class="read-label">{{ flowerGenusName }}</span>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("specie") }}</p>
        </div>
        <div class="col-8">
          <template>
            <span class="read-label">{{ flowerSpecieName }}</span>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("stem_size") }}</p>
        </div>
        <div class="col-8">
          <template>
            <span class="read-label">{{ stemSize }}cm</span>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("min_count") }}</p>
        </div>
        <div class="col-8">
          <template>
            <span class="read-label">{{ minCount }}{{ $t("volumn") }}</span>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("set_count") }}</p>
        </div>
        <div class="col-8">
          <template>
            <span class="read-label">{{ setCount }}{{ $t("lot") }}</span>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("shipping_date") }}</p>
        </div>
        <div class="col-8">
          <template>
            <div class="period-wrapper">
              <div class="read-label">{{ formattedDate(startHarvestDate) }}</div>
              <div class="read-label">{{ formattedDate(endHarvestDate) }}</div>
            </div>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("price_unit") }}</p>
        </div>
        <div class="col-8">
          <template>
            <span class="read-label">{{ price }}{{ $t("yen") }}</span>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("product_description") }}</p>
        </div>
        <div class="col-8">
          <template>
            <span class="read-label">{{ note }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import FlowerImageSlider from "./FlowerImageSlider.vue";

export default {
  components: {
    FlowerImageSlider
  },
  props: {
    imageHandler: Function,
    flowerImages: Array,
    flowerId: String,
    flowerGenusName: String,
    flowerSpecieName: String,
    stemSize: String,
    minCount: String,
    setCount: String,
    note: String,
    price: String,
    startHarvestDate: Date,
    endHarvestDate: Date
  },
  methods: {
    formattedDate(value) {
      return dayjs(value).format("YYYY/MM/DD(ddd)");
    },
  }
};
</script>

<style lang="scss" scoped>
.period-wrapper {
  line-height: 28px;
}
</style>
