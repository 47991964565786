import { formatDate } from "@/plugins/i18n";

export function toDateKey(date) {
  return formatDate(date, "yyyy-mm-dd");
}

export function addDate(date, value) {
  date = new Date(date);
  date.setDate(date.getDate() + value);
  return date;
}

export function nextDateKey(date = null, step = 1, allowWdays) {
  date = date ? new Date(date) : new Date();
  if (!allowWdays || !allowWdays.length) return toDateKey(date);
  const wdays = allowWdays && allowWdays.length > 0 ? allowWdays : [0, 1, 2, 3, 4, 5, 6];
  while (!wdays.includes(date.getDay())) {
    date = addDate(date, step);
  }

  return toDateKey(date);
}

export function formattedDate(date) {
  date = date ? new Date(date) : new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function formattedJPDate(date) {
  date = date ? new Date(date) : new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDay = date.toLocaleDateString("ja-JP", { weekday: "short" });
  return `${year}-${month}-${day} (${formattedDay})`;
}
