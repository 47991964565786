<template>
  <!-- eslint-disable -->
  <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M49.4999 72.205L38.5116 61.2167C37.9195 60.6246 37.1164 60.2919 36.2791 60.2919C35.4417 60.2919 34.6387 60.6246 34.0466 61.2167C33.4545 61.8088 33.1218 62.6118 33.1218 63.4492C33.1218 63.8638 33.2035 64.2743 33.3622 64.6574C33.5208 65.0404 33.7534 65.3885 34.0466 65.6817L47.2832 78.9183C48.5182 80.1533 50.5132 80.1533 51.7482 78.9183L85.2516 45.415C85.8437 44.8229 86.1763 44.0199 86.1763 43.1825C86.1763 42.3452 85.8437 41.5421 85.2516 40.95C84.6595 40.3579 83.8564 40.0253 83.0191 40.0253C82.1817 40.0253 81.3787 40.3579 80.7866 40.95L49.4999 72.205Z" fill="#315198"/>
    <rect x="3" y="3" width="112" height="112" rx="56" stroke="#315198" stroke-width="6"/>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>
