<template>
  <div class="collectionSections">
    <div v-for="section in sections" :key="section.id" class="collectionSections__section">
      <img
        v-if="section.image && section.image.image_url"
        :src="section.image.image_url"
        alt=""
        class="collectionSections__image"
      />
      <FlowerList :flowers="section.flowers" class="collectionSections__flowers" @click="handleClick" />
    </div>
  </div>
</template>

<script>
import { FlowerList } from "..";

export default {
  name: "CollectionSections",
  components: { FlowerList },
  props: {
    sections: { type: Array, required: true },
    flowerHarvests: { type: Array },
  },
  methods: {
    handleClick(flowerId) {
      this.$emit("click", flowerId);
    },
  },
};
</script>

<style lang="scss" scoped>
.collectionSections__section {
  &:not(:first-child) {
    margin-top: 42px;
  }
}

.collectionSections__image {
  margin-bottom: 32px;
  width: 100%;
  height: auto;
}
</style>
