<template>
  <div v-if="type" class="alert-main">
    <v-alert border="left" :close-text="$t('close_toast')" :type="type" dark>{{ msg }}</v-alert>
  </div>
</template>
<script>
export default {
  computed: {
    type() {
      return this.$store.state.toast.type;
    },
    msg() {
      return this.$store.state.toast.msg;
    }
  }
};
</script>

<style scoped>
.alert-main {
  position: absolute;
  bottom: 30px;
  width: 100%;
  padding: 4rem;
}
</style>
