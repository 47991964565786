<template>
  <div class="pa-4 bg-back-gray my-2 rounded-8 content-border mb-4 pt-2" style="position: relative;">
    <div class="d-flex justify-space-between align-center mb-1">
      <div class="d-flex">
        <div v-if="warehouse" class="warehouse">{{ warehouse }}</div>
        <div class="color-gray-900">{{ shopName }}</div>
      </div>
      <div class="color-secondary font-weight-bold text-xl">
        <button class="no-picked-up-btn">{{ $t('order_status_' + status) }}</button>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <p class="mb-0 genus-name" style="font-size: 14px; color: #68727B;">{{ genusName }}</p>
    </div>
    <div class="d-flex justify-space-between">
      <p class="font-weight-bold color-black-500 mb-0 text-nowrap" style="font-size: 18px;">{{ speciesName }}</p>
    </div>
    <div class="d-flex justify-space-between mt-1" style="font-size: 12px;">
      <p class="color-white bg-color-menu px-2">{{ stemSize }}</p>
    </div>
    <div class="d-flex justify-space-between note">
      <p class="mb-0" style="font-size: 14px;">{{ note }}</p>
    </div>
    <div class="d-flex justify-end align-end">
      <span class="mr-2" style="font-size: 14px;">
        {{ totalPrice.toLocaleString() }}{{ $t('yen') }}
      </span>
      <span class="font-weight-bold color-secondary text-xl">
        {{ totalCount }}
      </span>
      <span class="font-weight-bold text-xs color-secondary">
        {{ $t('volumn') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    speciesName: String,
    genusName: String,
    stemSize: String,
    totalCount: Number,
    totalPrice: Number,
    price: Number,
    lotCount: Number,
    harvestMinCount: Number,
    shopName: String,
    status: String,
    warehouse: String,
    note: String
  },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs
    };
  }
};
</script>

<style scoped>
.no-picked-up-btn {
  background-color: white;
  color: green;
  border: 1px solid green;
  padding: 6px 16px 6px 16px;
  border-radius: 100px;
  font-size: 14px;
  top: 16px;
  right: 16px;
}

.warehouse {
  background-color: #D4D7D9;
  color: #191D38;
  font-weight: 400;
  font-size: 12px;
  padding: 2px 6px 2px 6px;
  border-radius: 99px;
  margin: auto 4px auto 0;
  white-space: nowrap;
}

.content-border {
  border: 1px solid #BFC3C6;
  background-color: #F5F5F5;
}
</style>
