<template>
  <v-btn
    :loading="loading"
    :color="backgroundColor"
    :outlined="outlined"
    :small="small"
    @click="click"
    depressed
  >
    <span class="font-weight-bold" :style="`color:${textColor}`"><slot name="label"></slot></span>
    <template v-slot:loader>
    <v-progress-linear
      buffer-value="0"
      color="backgroundColor"
      reverse
      stream
      value="0"
    ></v-progress-linear>
      <span class="font-weight-bold" :style="`color:${textColor}`">{{ loadingText }}</span>
    </template>
  </v-btn>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
      require: true
    },
    loadingText: {
      type: String,
      default: "Loading...",
      require: true
    },
    backgroundColor: {
      type: String,
      default: "primary",
      require: false
    },
    textColor: {
      type: String,
      default: "#fff",
      require: false
    },
    outlined: {
      type: Boolean,
      default: false,
      require: false
    },
    small: {
      type: Boolean,
      default: false,
      require: false
    },
    plain: {
      type: Boolean,
      default: false,
      require: false
    },
    click: {
      type: Function,
      require: true
    }
  }
};
</script>

<style scoped>
</style>
