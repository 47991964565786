<template>
  <div ref="container" :class="styledClasses" class="mstTab">
    <ul role="tablist" class="mstTab__list">
      <li v-for="item in items" :key="item.value" role="presentation" class="mstTab__item">
        <button
          :aria-selected="item.value === modelCurrent"
          :tabindex="item.value === modelCurrent ? 0 : -1"
          ref="tab"
          type="button"
          role="tab"
          class="mstTab__tab"
          @click="handleClick(item.value)"
          @keydown="handleKeyDown"
        >
          {{ item.label }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MstTab",
  model: {
    event: "change",
    prop: "current",
  },
  props: {
    items: { type: Array, required: true },
    current: { type: String, required: true },
    fixed: { type: Boolean },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    modelCurrent: {
      get() {
        return this.current;
      },
      set(newValue) {
        this.$emit("change", newValue);
      },
    },
    styledClasses() {
      const classes = [];
      if (this.fixed) classes.push("-fixed");
      return classes;
    },
  },
  watch: {
    modelCurrent(newValue) {
      this.currentIndex = this.getIndex(newValue);
      if (!this.fixed) {
        this.updateScrollPosition();
      }
    },
  },
  created() {
    this.currentIndex = this.getIndex(this.current);
  },
  mounted() {
    if (!this.fixed) {
      this.updateScrollPosition();
    }
  },
  methods: {
    getIndex(value) {
      return this.items.findIndex(item => item.value === value);
    },
    updateScrollPosition() {
      const containerRect = this.$refs.container.getBoundingClientRect();
      const { width, x } = this.$refs.tab[this.currentIndex].getBoundingClientRect();
      const tabPosition = this.$refs.container.scrollLeft + x - containerRect.left;
      this.$refs.container.scrollTo({
        left: tabPosition - containerRect.width / 2 + width / 2,
        behavior: "smooth",
      });
    },
    handleKeyDown(event) {
      if (!event.key) return;
      switch (event.key) {
        case "ArrowLeft":
          if (this.currentIndex === 0) {
            this.currentIndex = this.items.length - 1;
            break;
          }
          this.currentIndex -= 1;
          break;
        case "ArrowRight":
          if (this.currentIndex === this.items.length - 1) {
            this.currentIndex = 0;
            break;
          }
          this.currentIndex += 1;
          break;
        case "Home":
          this.currentIndex = 0;
          break;
        case "End":
          this.currentIndex = this.items.length - 1;
          break;
        default:
          break;
      }

      this.$nextTick(() => {
        this.modelCurrent = this.items[this.currentIndex].value;
        this.$refs.tab[this.currentIndex].focus();
      });
    },
    handleClick(value) {
      this.modelCurrent = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.mstTab {
  display: block;
  border-bottom: 1px solid variables.$color-gray-300;
  overflow-x: auto;
}

.mstTab__list {
  display: flex;
  padding: 0;
  width: 100%;
  list-style-type: none;
}

.mstTab__item {
  .-fixed & {
    flex: 1;
  }
}

.mstTab__tab {
  position: relative;
  padding: 12px 21px;
  width: 100%;
  font-size: 14px;
  line-height: 1.1;
  outline: none;
  white-space: nowrap;
  color: variables.$color-gray-800;
  transition: color 0.3s ease, font-weight 0.3s ease, background 0.3s ease;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 3px;
    background: variables.$color-brand-primary;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &[aria-selected='true'] {
    font-weight: bold;
    color: variables.$color-black-500;

    &::before {
      opacity: 1;
    }
  }

  &:focus-visible {
    background: variables.$color-gray-50;
  }
}
</style>
