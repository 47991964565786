<template>
  <div class="page-container mb-4">
    <div class="non-table-container ma-4">
      <div class="d-flex align-center pointer" @click="onGoBack()">
        <ArrowLeftIcon size="12" color="black" />
        <h4 class="ml-2 font-weight-normal">{{ $t("back_short") }}</h4>
      </div>
      <h1 class="text-center my-6">{{ $t("hidden_list") }}</h1>

      <div class="d-flex justify-end mb-4" v-if="!mobile">
        <MstButton width="140px" class="btn orange" to="/farm/flowers/new" color="balck-500">{{ $t("listRegistredProducts") }}</MstButton>
      </div>

      <div class="mobile-add-button" v-if="mobile">
        <MstFloatingButton :label="$t('listRegistredProducts')" icon="mdi-plus" color="#173169" labelPosition="outside" to="/farm/flowers/new" />
      </div>

      <div class="search-board">
        <div class="d-flex bg-back-gray search-container align-center">
          <SearchIcon color="gray" size="16" />
          <input class="search-button" placeholder="キーワードから探す" @input="onChangeSearch" :value="searchString" />
        </div>
        <div class="search-result" v-if="searchString !== ''">
          <div class="mx-4 pa-2 pointer" @click="onHandleTap(item.id)" v-for="item in searchResult" :key="item.id">
            <div class="my-2 d-flex justify-space-between align-center">
              <div class="d-flex">
                <span class="badge-gray mr-2">{{ item.category }}</span>
                <span>{{ flowerGenus.name }}</span>
              </div>
              <span class="bagde-red w-100px flex-shrink-0 flex-grow-0" v-if="!item.isWorking">{{ $t('hidden') }}</span>
            </div>
            <div class="font-weight-bold text-overflow-dot">{{ item.specie_name }}</div>
          </div>
          <div v-if="searchResult.length === 0">
            <div class="mx-4 pa-2">{{ $t('no_products_matching') }}</div>
          </div>
        </div>
      </div>

      <div class="d-flex my-4 justify-space-between align-center">
        <h2>{{ flowerGenus.name }}</h2>
        <h3 class="pointer color-secondary font-weight-normal underline" @click="setShowSelect()" >{{ showSelect ? $t("cancel") : $t("selection") }}</h3>
      </div>
    </div>

    <div class="my-4 table">
      <div class="d-flex pa-4 align-center">
        <div class="flex-shrink-1 flex-grow-1">{{ $t("specie") }}</div>
        <div class="w-100px">{{ $t("category") }}</div>
        <div class="table-action d-flex">
          <input @click="onSelectedAll()" v-if="showSelect" class="checkbox" type="checkbox" :checked="allSelected" />
        </div>
      </div>

      <div class="pa-4" v-if="loadingStatus">
        {{ $t("loading") }}
      </div>

      <div v-else-if="hiddenItems.length > 0">
        <div v-if="showSelect" >
          <div class="d-flex pa-4 table-row pointer" v-for="flower in hiddenItems" :key="flower.id">
            <div class="flex-shrink-1 flex-grow-1 font-weight-bold text-overflow-dot"> {{ flower.specie_name }} </div>
            <div class="w-100px flex-shrink-0 flex-shrink-0"> {{ flower.category }} </div>
            <div class="table-action d-flex flex-shrink-0 flex-shrink-0">
              <input @click="onSelect(flower.id)" class="checkbox" type="checkbox" :checked="selectedItemIds.includes(flower.id)" />
            </div>
          </div>
        </div>
        <div v-else >
          <div class="d-flex pa-4 table-row pointer"  @click="onHandleTap(flower.id)" v-for="flower in hiddenItems" :key="flower.id">
            <div class="flex-shrink-1 flex-grow-1 font-weight-bold text-overflow-dot"> {{ flower.specie_name }} </div>
            <div class="w-100px flex-shrink-0 flex-shrink-0"> {{ flower.category }} </div>
            <div class="table-action flex-shrink-0 flex-shrink-0"> <ArrowRightIcon size="12" color="black" /> </div>
          </div>
        </div>
      </div>

      <div class="pa-4" v-else>
        {{ $t("no_not_working_flowers") }}
      </div>
    </div>

    <div class="px-4">
      <MstButton
        @click="onDisplaySelectedProduct"
        :class="['w-100', 'btn-display-product', 'd-flex', 'align-center', selectedItemIds.length === 0 ? 'bg-gray' : 'bg-secondary']"
        :disabled="selectedItemIds.length === 0"
      >
        <ArrowBackIcon width="17" color="white" />
        <span class="ml-2">{{ $t("display_selected_product") }}</span>
      </MstButton>
    </div>
  </div>
</template>

<script>
import SearchIcon from "@/components/icons/SearchIcon.vue";
import { MstFloatingButton, MstButton } from "@/components/master";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon.vue";
import ArrowBackIcon from "../../../components/icons/ArrowBackIcon.vue";

export default {
  components: { SearchIcon, MstFloatingButton, ArrowRightIcon, ArrowLeftIcon, ArrowBackIcon, MstButton },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      flowerGenusId: this.$route.params.flower_genera_id,
      flowerGenus: {},
      searchString: "",
      hiddenItems: [],
      loadingStatus: false,
      showSelect: false,
      selectedItemIds: [],
      allSelected: false,
    };
  },
  created() {
    this.loadingStatus = true;
    this.$api.get("/harvests/flower_genus").then(response => {
      this.flowerGenus = response.result.flower_genus.find(genus => genus.id.toString() === this.flowerGenusId);
      this.$store.dispatch("flowers/getFlowers").then(this.createTableData);
    });
  },
  methods: {
    async createTableData() {
      this.hiddenItems = [];
      const { flowers } = this.$store.state;

      flowers.listArr.forEach(id => {
        const f = flowers.list[id];
        if (this.flowerGenusId === f.flowerSpecie.flowerGenus.id.toString() && !f.isWorking) {
          this.hiddenItems.push({
            id: f.id,
            category: f.category.name,
            genus_name: f.flowerSpecie.flowerGenus.name,
            specie_name: f.flowerSpecie.name,
            flower_type: f.flower_type,
            isWorking: f.isWorking
          });
        }
      });
      this.loadingStatus = false;
    },
    onGoBack() {
      this.$router.push("/farm/flower_genera");
    },
    onHandleTap(flower_id) {
      this.$router.push(`/farm/flower_genera/${this.flowerGenusId}/flowers/${flower_id}`);
    },
    onChangeSearch(event) {
      this.searchString = event.target.value;
      this.searchResult = this.hiddenItems.filter(item => item.specie_name.includes(this.searchString) || this.flowerGenus.name.includes(this.searchString));
    },
    setShowSelect() {
      this.showSelect = !this.showSelect;
    },
    onSelect(id) {
      if (!this.selectedItemIds.includes(id)) {
        this.selectedItemIds.push(id);
      } else {
        this.selectedItemIds = this.selectedItemIds.filter(item => item !== id);
      }
      this.allSelected = this.selectedItemIds.length === this.hiddenItems.length;
    },
    onSelectedAll() {
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        this.hiddenItems.forEach(item => {
          if (!this.selectedItemIds.includes(item.id)) {
            this.selectedItemIds.push(item.id);
          }
        });
      } else {
        this.selectedItemIds.splice(0, this.selectedItemIds.length);
      }
    },
    async onDisplaySelectedProduct() {
      await this.$store.dispatch("flowers/putFlowerIsWorking", {
        ids: this.selectedItemIds,
        isWorking: true
      });
      this.loadingStatus = true;
      this.$api.get("/harvests/flower_genus").then(response => {
        this.flowerGenus = response.result.flower_genus.find(genus => genus.id.toString() === this.flowerGenusId);
        this.$store.dispatch("flowers/getFlowers").then(this.createTableData);
        this.selectedItemIds = [];
        this.allSelected = false;
      });
    },
  },
};
</script>

<style lang="scss">
.underline {
  text-decoration: underline;
}

.checkbox {
  width: 18px;
  height: 18px;
}

.btn-display-product {
  color: white !important;
  font-weight: 400 !important;
  height: 48px;
}

.bg-gray {
  background-color: #999999 !important;
}
</style>
