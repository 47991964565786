<template>
  <div class="text-center ma-2">
    <v-snackbar
      v-model="snackbarVisible"
      :timeout="10000"
      :color="color"
      :right="true"
      :absolute="true"
      :top="true"
    >
      {{ msg }}

        <v-btn
          color="#fff"
          text
          @click="disableSnackBar"
        >
          Close
        </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  data: () => ({
    snackbar: true
  }),
  computed: {
    snackbarVisible: {
      get() {
        return this.$store.state.snackbar.isEnable;
      },
      set() {
        return this.$store.dispatch("snackOff");
      }
    },
    msg() {
      return this.$store.state.snackbar.msg;
    },
    color() {
      return this.$store.state.snackbar.color;
    }
  },
  methods: {
    disableSnackBar() {
      this.$store.dispatch("snackOff");
    }
  }
};
</script>

<style scoped>
.v-snack--top{
  position: fixed;
  top: 70px;
}
</style>
