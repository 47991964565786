<template>
  <div :class="`review-item ${is_selected ? 'selected-item' : ''}`">
    <div class="item-row">
      <img class="mr-2" :src="img" width="64px" height="80px" />
      <div class="mr-2 break-all">
        <span class="color-gray-800 text-xs">{{ shop }}</span><br/>
        <span class="badge">{{ flower_genus }}</span><br/>
        <span class="text-sm">{{ flower_specie }}</span>
      </div>
      <div class="d-flex align-center">
        <div class="emoticon-layout mr-2" @click="$emit('onChangeStatus', 2)">
          <SmileIcon size="32" :color="status === 2 ? '#191D38' : '#A9AFB3'"></SmileIcon>
          <div>{{ $t("good") }}</div>
        </div>
        <div class="emoticon-layout" @click="$emit('onChangeStatus', 1)">
          <SadIcon size="32" :color="status === 1 ? '#191D38' : '#A9AFB3'"></SadIcon>
          <div>{{ $t("disappointing") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SadIcon from "@/components/icons/SadIcon.vue";
import SmileIcon from "@/components/icons/SmileIcon.vue";

export default {
  components: {
    SadIcon,
    SmileIcon,
  },
  props: {
    status: {
      type: Number,
      default: 0
    },
    shop: {
      type: String,
    },
    flower_genus: {
      type: String,
    },
    flower_specie: {
      type: String,
    },
    img: {
      type: String,
    },
    is_selected: {
      type: Boolean,
    }
  },
};
</script>

<style lang="scss" scoped>
.review-item {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #D4D7D9;
  padding: 16px 8px 16px 8px;
}
.selected-item {
  background-color: #96C1D833;
}
.badge {
  border: 1px solid #E9EDEE;
  border-radius: 10px;
  padding: 2px 6px 2px 6px;
  font-size: 10px;
}
.emoticon-layout {
  text-align: center;
  width: 50px;
  flex-direction: column;
  white-space: nowrap;
  font-size: 10px;
}
.item-row {
  display: flex;
  justify-content: space-between;
}
.break-all {
  word-break: break-all;
}
</style>
