<template>
  <div>
    <div
      class="d-flex align-center ma-3"
      @click="onClickBackButton()"
    >
      <ArrowLeftIcon size="12" color="black" />
      <h4 class="ml-2 font-weight-normal">{{ $t("back_short") }}</h4>
    </div>
    <div class="ma-3">
      <div class="my-2 d-flex justify-space-between align-center">
        <div>
          <span v-if="!showSelect" class="badge-gray mr-2">{{ flower.categoryName }}</span>
          <span class="genus-name">{{ flower.specieName }}</span>
        </div>
      </div>
      <div class="font-weight-bold text-overflow-dot">{{ flower.genusName }}</div>
    </div>

    <div
      @click="onClickFilterFormVisibilityToggleButton()"
      class="filter-form-toggle-button">
      条件で絞り込み
      <img
      :class="['accordion-icon', isShowFilterForm ? 'open' : '']"
      :src="require('@/assets/accordion-icon.svg')" />
    </div>
    <SearchFilterForm :isShow="isShowFilterForm"/>
    <div class="filter-items-wrapper" v-if="filterOptionItems.length > 0">
      <div class="filter-item" v-for="item in filterOptionItems" :key="item.number">
        {{  `${item.name} ${item.value}`  }}
      </div>
    </div>

    <div class="mt-2 mr-3 text-right">
      <h3 class="pointer color-secondary font-weight-normal underline" @click="setShowSelect()" >{{ showSelect ? $t("cancel") : $t("selection") }}</h3>
    </div>

    <div class="mobile-add-button" v-if="mobile && !showSelect">
      <MstFloatingButton :label="$t('add_harvest')" icon="mdi-plus" color="#173169" labelPosition="outside" to="/farm/flower_harvests/new" />
    </div>
    <div :class="mobile ? 'mobile-select-bar' : 'pc-select-bar'" v-if="showSelect && selectedItemIds.length > 0">
      <button @click="onClickNotOnSaleButton()" class="stop-button">出品停止</button>
      <button @click="onClickEditButton()" class="edit-button">編集</button>
    </div>
    <div class="table">
      <div class="d-flex pa-4 table-header-label">
        <div class="flex-shrink-1 flex-grow-1">
          <span>{{ $t("shipping_date") }}</span>
        </div>
        <div class="w-60px pl-1" @click="handleHeaderClick('stem_size')">
          <span>{{ $t("stem_size") }}</span>
          <img :src="require('@/assets/sort.svg')" class="table-header-sort"  :class="[sortObj['stem_size'].isDesc ? '' : 'flipped-sort']"/>
        </div>
        <div class="w-60px pl-1" @click="handleHeaderClick('price')">
          <span>{{ $t("price") }}</span>
          <img :src="require('@/assets/sort.svg')" class="table-header-sort" :class="[sortObj['price'].isDesc ? '' : 'flipped-sort']"/>
        </div>
        <div class="w-60px pl-1" @click="handleHeaderClick('set_count')">
          <span>{{ $t("set_count") }}</span>
          <img :src="require('@/assets/sort.svg')" class="table-header-sort" :class="[sortObj['set_count'].isDesc ? '' : 'flipped-sort']"/>
        </div>
        <div class="table-action">
          <input @click="onSelectedAll()" v-if="showSelect" class="checkbox" type="checkbox" :checked="allSelected" />
        </div>
      </div>
      <div class="d-flex px-4 py-3 table-row text-center" @click="!showSelect && onClickFlowerHarvest(item.id)" v-for="item in showItems" :key="item.id">
        <div class="flex-shrink-1 flex-grow-1 mb-1 text-overflow-dot harvest-date"> {{ item.formatted_harvest_date }} </div>
        <div class="w-60px flex-shrink-0 flex-shrink-0"> <span class="table-value">{{ item.stemSize }}</span><span class="table-unit">{{ $t("size_label") }}</span> </div>
        <div class="w-60px flex-shrink-0 flex-shrink-0"> <span class="table-value">{{ item.price }}</span><span class="table-unit">{{ $t("yen") }}</span> </div>
        <div class="w-60px flex-shrink-0 flex-shrink-0"> <span class="table-value">{{ item.set_count }}</span><span class="table-unit">{{ $t("lot") }}</span> </div>
        <div class="table-action">
          <div v-if="showSelect" >
            <input @click="onSelect(item.id.toString())" class="checkbox" type="checkbox" :checked="selectedItemIds.includes(item.id.toString())" />
          </div>
          <div v-else >
            <ArrowRightIcon size="12" color="black" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="stopShow" class="modal">
      <div class="modal-content">
        <div class="card dialog-card">
          <img class="card-logo" :src="require('@/assets/harvest_stop.svg')" alt="Harvest Stop">
          <p class="dialog-header">{{ $t("harvest_stop_message_1") }}</p>
          <p class="dialog-text">{{ $t("harvest_stop_message_2") }}</p>
          <div class="flex-items">
            <MstButton :outlined="true" @click="cancelStop()">{{ $t("back_short") }}</MstButton>
            <MstButton @click="onClickNotOnSaleButton()">{{ $t("stop_on_sale") }}</MstButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon.vue";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import { MstButton, MstFloatingButton } from "@/components/master";
import { formatDate } from "@/plugins/i18n";
import dayjs from "dayjs";
import SearchFilterForm from "./SearchFilterForm.vue";

export default {
  components: { ArrowLeftIcon, ArrowRightIcon, MstButton, MstFloatingButton, SearchFilterForm },
  data() {
    return {
      flower: {
        categoryName: "",
        specieName: "",
        genusName: ""
      },
      flowerHarvests: [],
      filterFlowerHarvests: [],
      mobile: this.$vuetify.breakpoint.xs,
      showSelect: false,
      selectedItemIds: [],
      allSelected: false,
      isShowFilterForm: false,
      filterOptionItems: [],
      sortObj: {
        stem_size: {
          selected: false,
          isDesc: true,
        },
        price: {
          selected: false,
          isDesc: true,
        },
        stock_count: {
          selected: false,
          isDesc: true,
        },
        set_count: {
          selected: false,
          isDesc: true,
        },
      },
      stopShow: false,
    };
  },
  async created() {
    const flowerId = this.$route.params.flower_id;
    if (!flowerId) return;

    const [flowerRes, flowerHarvestsRes] = await Promise.all([
      this.$http2("get", `farm/flowers/${flowerId}`),
      this.$http2("get", `farm/harvests?flower_id=${flowerId}`, { params: this.$route.query })
    ]);

    if (flowerRes.status === 200) {
      const flower = flowerRes.result.flowers[0];
      this.flower = {
        categoryName: flower.category.name,
        specieName: flower.flowerSpecie.name,
        genusName: flower.flowerSpecie.flowerGenus.name
      };
    }

    if (flowerHarvestsRes.status === 200) {
      const flowerHarvestsArr = flowerHarvestsRes.result.data.map(filterFlowerHarvest => {
        let set_count = 0;
        if (filterFlowerHarvest.minCount) {
          set_count = filterFlowerHarvest.confirmedCount / filterFlowerHarvest.minCount;
        }
        return {
          ...filterFlowerHarvest,
          set_count
        };
      });
      this.flowerHarvests = flowerHarvestsArr;
      this.filterFlowerHarvests = flowerHarvestsArr;
    }
    this.setFlowerHarvests();
  },
  methods: {
    setFlowerHarvests() {
      const minCount = this.$route.query.minCount;
      const setCount = this.$route.query.setCount;
      const stemSize = this.$route.query.stemSize;
      const startHarvestDate = this.$route.query.startHarvestDate;
      const endHarvestDate = this.$route.query.endHarvestDate;
      const daysOfWeek = this.$route.query.daysOfWeek ? this.$route.query.daysOfWeek.split(",").map(Number) : [];
      const harvestDates = daysOfWeek.length > 0 ? this.generateHarvestDates(startHarvestDate, endHarvestDate, daysOfWeek) : [];

      // クエリパラメーターの値を元に絞り込み
      this.filterFlowerHarvests = this.flowerHarvests.filter(flowerHarvest => {
        const { min_count, set_count, stem_size, harvest_date } = flowerHarvest;
        const harvestDate = dayjs(harvest_date).format("YYYY-MM-DD");

        const isMinCountMatch = !minCount || min_count === Number(minCount);
        const isSetCountMatch = !setCount || set_count === Number(setCount);
        const isStemSizeMatch = !stemSize || stem_size === Number(stemSize);
        const isHarvestDateMatch = !daysOfWeek.length || harvestDates.includes(harvestDate);

        return isMinCountMatch && isSetCountMatch && isStemSizeMatch && isHarvestDateMatch;
      });

      const newFilterItems = [];

      if (minCount) newFilterItems.push({ name: "ロット本数", value: `${minCount}本` });
      if (setCount) newFilterItems.push({ name: "ロ数", value: `${setCount}ロ` });
      if (stemSize) newFilterItems.push({ name: "全長", value: `${stemSize}cm` });
      if (startHarvestDate && endHarvestDate && daysOfWeek.length > 0) {
        newFilterItems.push({
          name: "出荷日",
          value: `${dayjs(startHarvestDate).format("YYYY/MM/DD")} ~ ${dayjs(endHarvestDate).format("YYYY/MM/DD")}, ${daysOfWeek.map(day => this.dayOfWeekJp(day)).join("・")}`
        });
      }
      this.filterOptionItems = newFilterItems;
      this.isShowFilterForm = false;
    },
    generateHarvestDates(startDate, endDate, daysOfWeek) {
      const harvestDates = [];
      const endHarvestDate = dayjs(endDate);
      let currentDate = dayjs(startDate);

      while (currentDate.isBefore(endHarvestDate, "day") || currentDate.isSame(endHarvestDate, "day")) {
        if (daysOfWeek.includes(currentDate.day())) {
          const formattedDate = currentDate.format("YYYY-MM-DD");
          harvestDates.push(formattedDate);
        }
        currentDate = currentDate.add(1, "day");
      }
      return harvestDates;
    },
    onClickBackButton() {
      this.$router.push("/farm/flower_harvests");
    },
    onClickFilterFormVisibilityToggleButton() {
      this.isShowFilterForm = !this.isShowFilterForm;
    },
    onClickEditButton() {
      this.$router.push(`/farm/flower_harvests/${this.selectedItemIds.join(",")}/multi_edit`);
    },
    onClickFlowerHarvest(id) {
      this.$router.push(`/farm/flower_harvests/${id}`);
    },
    handleHeaderClick(item) {
      if (this.sortObj[item].selected) {
        this.sortObj[item].isDesc = !this.sortObj[item].isDesc;
      } else {
        this.initSortObj();
        this.sortObj[item].selected = true;
      }
    },
    initSortObj() {
      const keys = Object.keys(this.sortObj);
      keys.forEach(key => {
        this.sortObj[key].selected = false;
        this.sortObj[key].isDesc = true;
      });
    },
    setShowSelect() {
      this.showSelect = !this.showSelect;
    },
    onSelect(id) {
      if (!this.selectedItemIds.includes(id)) {
        this.selectedItemIds.push(id);
      } else {
        this.selectedItemIds = this.selectedItemIds.filter(item => item !== id);
      }
      this.allSelected = this.selectedItemIds.length === this.flowerHarvests.length;
    },
    onSelectedAll() {
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        const items = [...this.flowerHarvests];
        items.forEach(item => {
          if (!this.selectedItemIds.includes(item.id.toString())) {
            this.selectedItemIds.push(item.id.toString());
          }
        });
      } else {
        this.selectedItemIds.splice(0, this.selectedItemIds.length);
      }
    },
    async onClickNotOnSaleButton() {
      const res = await this.$http2("put", "farm/harvests/update_all_not_on_sale", { ids: this.selectedItemIds });
      if (res.status !== 204) {
        alert("更新に失敗しました");
        return;
      }

      this.filterFlowerHarvests = this.filterFlowerHarvests.filter(flowerHarvest => !this.selectedItemIds.includes(flowerHarvest.id.toString()));
      this.selectedItemIds = [];
      this.showSelect = false;
      this.stopShow = false;
    },
    preStop() {
      this.stopShow = true;
    },
    cancelStop() {
      this.stopShow = false;
    },
    dayOfWeekJp(day) {
      const daysOfWeekJp = {
        0: "日",
        1: "月",
        2: "火",
        3: "水",
        4: "木",
        5: "金",
        6: "土"
      };
      return daysOfWeekJp[day];
    },
  },
  computed: {
    showItems() {
      const items = [...this.filterFlowerHarvests];
      let sortItem = "id";
      let isSortDesc = true;
      Object.keys(this.sortObj).forEach(key => {
        if (this.sortObj[key].selected) {
          sortItem = key;
          isSortDesc = this.sortObj[key].isDesc;
        }
      });
      return items?.map(item => {
        item.formatted_harvest_date = formatDate(item.harvestDate, "mm/dd");
        return item;
      }).sort((a, b) => (isSortDesc ? -1 : 1) * (a[sortItem] > b[sortItem] ? 1 : -1));
    },
  }
};
</script>

<style lang="scss" scoped>
.table {
  font-family: sans-serif;
  font-style: normal;
  text-align: left;
  .table-row:nth-of-type(even) {
    background-color: #f5f5f5;
  }
  .w-60px {
    width: 60px;
  }
  .table-action {
    width: 20px;
    text-align: right;
    margin: auto;
  }
}

.filter-form-toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #173169;
  color: #173169;
  text-align: center;
  height: 40px;
  width: 160px;
  margin-left: 15px;
  cursor: pointer;

  .accordion-icon {
    transition: transform 0.3s;
    margin-left: 8px;
  }

  .open {
    transform: rotate(180deg);
  }
}

.filter-items-wrapper {
  display: flex;
  gap: 8px;
  margin: 8px 15px 0px 15px;
  flex-wrap: wrap;

  .filter-item {
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 100px;
    border: 1px solid #7E868E;
    color: #191D38;
  }
}

.table-header-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #111111;
}
.table-header-sort {
  margin: 2px 0 2px 4px;
}
.table-value {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 19.36px;
  text-align: center;
  color: #173169;
}
.table-unit {
  font-family: sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.52px;
  text-align: center;
  color: #173169;
}
.text-overflow-dot {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mobile-add-button {
  position: fixed;
  bottom: 100px;
  right: 15px;
  z-index: 100;
}
.mobile-select-bar {
  padding-top: 37px;
  padding-bottom: 16px;
  position: fixed;
  bottom: 75px;
  border-top: 1px solid #EAEAEA;
  z-index: 1;
  width: 100vw;
  display: flex;
  gap: 6px;
  justify-content: center;
  background-color: white;

  .stop-button {
    width: 179px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: #173169;
    border: 1px solid #173169;
    border-radius: 4px;
  }

  .edit-button {
    width: 179px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    background-color: #173169;
    border-radius: 4px;
  }
}
.pc-select-bar {
  margin: 40px;
  z-index: 1;
  width: calc(100vw - 175px);
  display: flex;
  gap: 6px;
  justify-content: center;

  .stop-button {
    width: 179px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: #173169;
    border: 1px solid #173169;
    border-radius: 4px;
  }

  .edit-button {
    width: 179px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    background-color: #173169;
    border-radius: 4px;
  }
}
.badge-gray {
  font-weight: 400;
  font-size: 10px;
  height: 16px;
  background-color: #D7E4EB;
  border-radius: 100px;
  padding: 2px 10px 2px 10px;
}
.genus-name {
  font-size: 14px;
}
.flipped-sort {
  transform: rotate(180deg);
}
.modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 0 solid #888;
  border-radius: 8px;
  max-width: 344px;
}
.dialog-card {
  padding: 25px 52px;
  display: flex;
  flex-direction: column;
  height: 264px;
  width: 344px;
  margin: auto;
}
.card-logo {
  width: 42px;
  height: 46.67px;
  margin: auto;
  margin-top: 12px;
  margin-bottom: 12px;
}
.dialog-header {
  margin: auto;
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
}
.dialog-text {
  margin-bottom: 12px;
  font-size: 14px;
}
.flex-items {
  display: flex;
  gap: 10px;
}
.harvest-date {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #191D38;
}
.checkbox {
  width: 18px;
  height: 18px;
}

.text-center {
  text-align: center;
}
</style>
