<template>
  <div class="page-root">
    <div class="header">
      <img class="logo" src="@/assets/CAVIN_Logo_black.png" height="41" />
    </div>

    <div class="content">
      <div class="card">
        <div class="card-title">メールアドレス認証メールを送信しました。</div>
        <div class="message">
          <strong>{{ email }}</strong>へ受信確認用のメールアドレスを送信しました<br>
          <br>
          メールをご確認いただき、メールに記載されたURLをクリックし、CAVINへの登録を完了させてください。
        </div>
      </div>

      <div class="note">
        <div class="note-title">メールが届かない場合</div>
        1. 迷惑メールフォルダに入っている場合<br>
        認証メールはno-reply@cavin.oooのメールアドレスより<br>
       「メールアドレス確認メール」という件名で送信しております。<br>
       迷惑メールフォルダに同じ件名がないかご確認お願い致します。<br>
       <br>
       2. 迷惑メール設定を「PCから送信されるメールを受け取らない」にしている場合<br>
       迷惑メール設定を「PCから送信されるメールを受け取らない」にしている場合は、<br>
       認証メールが届かないことがあります。端末設定より迷惑メールの設定変更後、再送信をお願い致します。<br>
       <br>
       ▼認証メールの再送信を行う場合、以下のボタンより再設定してください。
      </div>

      <router-link class="button" to="/users/reconfirmation">メールアドレスを再設定</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: this.$route.query.email
    };
  },

  methods: {
    send() {
      this.$router.push({
        path: "/users/send_email",
        query: {
          email: this.email
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-root {
  background-color: #eee;
}

.header {
  margin-bottom: 40px;

  .logo {
    display: block;
    margin: 0 auto;
  }
}

.content {
  max-width: 720px;
  margin: 0 auto;
}

.card {
  padding: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  color: #333;
}

.card-title {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

.message {
  margin-top: 30px;
  color: #555;
  font-size: 16px;
}

.button {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  border: #007E57 1px solid;
  background-color: #fff;
  border-radius: 3px;
  color: #007E57;
  font-size: 18px;
  font-weight: bold;
  outline: none;
}

.note {
  margin-top: 30px;
  font-size: 16px;
  color: #555;
}

.note-title {
  color: #333;
  font-size: 20px;
  font-weight: bold;
}
</style>
