<template>
  <div class="page-container container-spacer">
    <div class="non-table-container px-4 pb-4">
      <div class="d-flex justify-end mb-4" v-if="!mobile">
        <MstButton width="140px" class="btn orange" to="/farm/flower_harvests/new" color="balck-500">{{ $t("add_harvest") }}</MstButton>
      </div>

      <div class="search-board">
        <div class="d-flex bg-back-gray search-container align-center">
          <SearchIcon color="gray" size="16" />
          <input class="search-button" placeholder="キーワードから探す" @input="onChangeSearch" :value="searchString" />
        </div>
        <div class="search-result" v-if="searchString !== ''">
          <div class="mx-4 pa-2" @click="onHandleTap(item)" v-for="item in searchResult" :key="item.id">
            <div class="my-2 d-flex justify-space-between align-center">
              <div>
                <span class="badge-gray mr-2">{{ item.category }}</span>
                <span>{{ item.genus_name }}</span>
              </div>
              <span class="badge-red w-100px flex-shrink-0 flex-grow-0" v-if="!item.is_working">{{ $t('hidden') }}</span>
            </div>
            <div class="font-weight-bold text-overflow-dot">{{ item.specie_name }}</div>
          </div>
          <div v-if="searchResult.length === 0">
            <div class="mx-4 pa-3">{{ $t('no_products_matching') }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-add-button" v-if="mobile">
      <MstFloatingButton :label="$t('add_harvest')" icon="mdi-plus" color="#173169" labelPosition="outside" to="/farm/flower_harvests/new" />
    </div>
    <MstTab :current="currentTab" :items="categories" :fixed="true" @change="e => currentTab = e"/>
    <div class="table">
      <div class="d-flex pa-3 table-row" @click="onHandleTap(item)" v-for="item in showItems" :key="item.id">
        <div class="flex-shrink-1 flex-grow-1 mb-1 text-overflow-dot">
          <div class="table-genus-value text-overflow-dot">{{ item.genus_name }}</div>
          <div class="font-weight-bold table-specie-value text-overflow-dot">{{ item.specie_name }}</div>
        </div>
        <div class="flex-shrink-1 flex-grow-1 mb-1">
          <div class="table-action"> <ArrowRightIcon size="12" color="black" /> </div>
          <div class="w-70px flex-shrink-0 flex-shrink-0 table-body-value"> {{ $t("on_sale") }}：{{ item.count }} {{ $t("piece") }} </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchIcon from "@/components/icons/SearchIcon.vue";
import { MstButton, MstFloatingButton, MstTab } from "@/components/master";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";

export default {
  components: { SearchIcon, MstButton, MstFloatingButton, MstTab, ArrowRightIcon },
  data() {
    return {
      searchString: "",
      searchResult: [],
      mobile: this.$vuetify.breakpoint.xs,
      items: [],
      currentTab: "0",
    };
  },
  async created() {
    await this.$store.dispatch("getSearchQuery");
    const harvestCountRes = await this.$http2("get", "farm/harvests/harvest_count");
    if (harvestCountRes.result) {
      this.items = harvestCountRes.result.data;
    }
  },
  computed: {
    categories() {
      const categories = this.$store.state.categories.map(category => {
        category.value = category.id.toString();
        category.label = category.name;
        return category;
      });
      return [{ value: "0", label: this.$t("all_1") }, ...categories];
    },
    showItems() {
      return this.currentTab === "0" ? this.items : this.items.filter(item => item.category_id.toString() === this.currentTab);
    }
  },
  methods: {
    exhibitedFlowerHarvest(harvest) {
      return !!harvest.id;
    },
    getValue(harvest, key) {
      return this.exhibitedFlowerHarvest(harvest) ? harvest[key] : null;
    },
    getSetCount(harvest) {
      if (!harvest.harvest_date || !this.getValue(harvest, "min_count")) return null;

      return this.getValue(harvest, "confirmed_count") / this.getValue(harvest, "min_count");
    },
    onHandleTap(item) {
      this.$router.push(`/farm/flowers/${item.flower_id}/flower_harvests`);
    },
    onChangeSearch(event) {
      this.searchString = event.target.value;
      this.searchResult = this.showItems.filter(item => item.genus_name.includes(this.searchString) || item.specie_name.includes(this.searchString));
    }
  },
};
</script>

<style lang="scss" scoped>
.container-spacer {
  margin-top: -35px;
}
.table {
  text-align: left;
  font-family: sans-serif;
  .table-row:nth-of-type(even) {
    background-color: #f5f5f5;
  }
  .w-100px {
    width: 100px;
  }
  .table-action {
    width: 20px;
    text-align: right;
    margin-left: auto;
  }
}
.table-genus-value {
  color: #434343;
  font-size: 12px;
  margin-bottom: 4px;
}
.table-specie-value {
  color: #111;
  font-size: 16px;
  font-weight: 700;
}
.table-body-value {
  font-size: 16px;
  text-align: right;
}
.text-overflow-dot {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mobile-add-button {
  position: fixed;
  bottom: 100px;
  right: 15px;
  z-index: 100;
}
.search-container {
  padding: 12px;
  border-radius: 4px;
  box-shadow: none;
}
.search-button {
  margin-left: 10px;
  height: 15px;
  font-size: 12px;
  width: 100%;
}
.search-button:focus {
  outline: none;
}
.search-board {
  position: relative;
  box-shadow: none;
}
.search-result {
  width: 100%;
  position: absolute;
  background-color: white;
  overflow: auto !important;
  max-height: 250px;
  z-index: 1;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.10);
}
.badge-gray {
  font-weight: 400;
  font-size: 10px;
  height: 16px;
  background-color: #D7E4EB;
  border-radius: 100px;
  padding: 2px 10px 2px 10px;
}
.badge-red {
  border: 1px solid red;
  border-radius: 100px;
  color: red;
  padding: 2px 10px 2px 10px;
  font-size: 12px;
}
</style>
