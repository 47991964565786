<template>
<div v-if="this.isLoading">
  <FlowerHarvestDetail
    :flowerGenusName="flowerGenusName"
    :flowerSpecieName="flowerSpecieName"
    :stemSize="stemSize"
    :setCount="setCount"
    :minCount="minCount"
    :price="price"
    :startHarvestDate="startHarvestDate"
    :endHarvestDate="endHarvestDate"
    :imageHandler="imageHandler"
    :flowerImages="flowerImages"
    :flowerId="flowerId"
    />
</div>
<div v-else>
  <FlowerImageSlider :imageHandler="imageHandler" :images="flowerImages" :flowerId="flowerId" />

  <!-- フォーム -->
  <div class="px-4 d-flex flex-column flex-grow-1">
    <div class="mt-4">
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("item") }}</p>
        </div>
        <div class="col-8">
          <template>
            <SelectBox v-model="flowerGenusId" :options="flowerGenera" @change="onClickFlowerGenus($event)"/>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("specie") }}</p>
        </div>
        <div class="col-8">
          <template>
            <div class="searchBox__inner">
              <MstTextField @input="handleChangeSearchFlowerSpecie" @focus="setVisible" @blur="loseFocus" :value="searchFlowerSpecieText"/>
              <transition name="container">
                <div class="searchBox__container" ref="search_box" v-if="isVisible">
                  <div class="mx-4 pa-2" @click="onClickFlowerSpecie(flowerSpecie.id)" v-for="flowerSpecie in flowerSpecies" :key="flowerSpecie.id">
                    <div>{{ flowerSpecie.name }}</div>
                  </div>
                </div>
              </transition>
            </div>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("stem_size") }}</p>
        </div>
        <div class="col-8">
          <template>
            <MstTextField v-model="stemSize" type="number" width="100px" :error="error.stemSize" @input="handleChangeStemSize($event)">
              <span class="unit-margin">cm</span>
            </MstTextField>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("min_count") }}</p>
        </div>
        <div class="col-8">
          <template>
            <MstTextField v-model="minCount" type="number" width="100px" :error="error.minCount" @input="handleChangeMinCount($event)">
              <span class="unit-margin">{{ $t("volumn") }}</span>
            </MstTextField>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("set_count") }}</p>
        </div>
        <div class="col-8">
          <template>
            <MstTextField v-model="setCount" type="number" width="100px" :error="error.setCount" @input="handleChangeSetCount($event)" >
              <span class="unit-margin">{{ $t("lot") }}</span>
            </MstTextField>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("shipping_date") }}</p>
        </div>
        <div class="col-8">
          <template>
            <div
              @click="onClickCalendarIcon()"
              :class="['period', startHarvestDate < endHarvestDate ? '' : 'single-period']"
            >
              <div>{{ formattedPeriod }}</div>
              <img
                class="calendar-icon" :src="require('@/assets/calendar-icon-gray.svg')"
              />
            </div>
            <DateRangePicker
              v-if="isCalendarShow"
              :startDate="startHarvestDate"
              :endDate="endHarvestDate"
              @close="closeCalendar"
              @confirm="confirmPeriod"
            />
            <div class="select-day-of-week-wrapper">
              <div v-if="startHarvestDate < endHarvestDate" class="select-day-of-week">
                <div
                  @click="onClickDayOfWeek(day.number)"
                  v-for="day in isHarvestDaysOfWeek" :key="day.i18Label">
                  <div :class="['day-of-week border-right', day.include ? 'active' : '']">
                    {{ $t(day.i18Label) }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="price-label">{{ $t("price_unit") }}</p>
        </div>
        <div class="col-8">
          <template>
            <MstTextField v-model="price" type="number" width="100px" :error="error.price" @input="handleChangePrice($event)" >
              <span class="unit-margin">{{ $t("yen") }}</span>
            </MstTextField>
            <span v-if="flowerGenusRecommendedMinPrice || flowerGenusRecommendedMaxPrice" class="price-recommend">
               {{ $t("recommend_price") }} ¥{{ flowerGenusRecommendedMinPrice }} ~ ¥{{ flowerGenusRecommendedMaxPrice }}
            </span>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("product_description") }}</p>
        </div>
        <div class="col-8">
          <template>
            <MstTextArea v-model="note" @input="handleChangeNote($event)" />
          </template>
        </div>
      </div>
    </div>
  </div>

  <!-- 送信ボタン -->
  <div class="px-8 color-red my-2">
    <span>{{ errorConfirmedCount }}</span>
  </div>
  <div class="mx-7">
    <MstButton :disabled="!isValid()" @click="onClickOpenConfirmModalButton">{{ $t("save_flower_harvest") }}</MstButton>
  </div>

  <div v-if="isShowConfirmModal" class="modal">
    <div class="modal-content">
      <div class="card dialog-card">
        <img class="card-logo" :src="require('@/assets/harvest_confirm.svg')" alt="Harvest Confirm">
        <p class="dialog-header">{{ $t("harvest_confirm_message_1") }}</p>
        <p class="dialog-text">{{ $t("harvest_confirm_message_2") }}</p>
        <div class="flex-items">
          <MstButton :outlined="true" @click="onClickCloseConfirmModalButton()">{{ $t("harvest_edit") }}</MstButton>
          <MstButton @click="submit()">{{ $t("save_flower_harvest") }}</MstButton>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { MstTextArea, MstTextField, MstButton } from "@/components/master";
import dayjs from "dayjs";
import DateRangePicker from "@/components/shared/DateRangePicker.vue";
import FlowerImageSlider from "./FlowerImageSlider.vue";
import FlowerHarvestDetail from "./FlowerHarvestDetail.vue";
import SelectBox from "./SelectBox.vue";

const STEM_SIZE_LIMIT_DIGIT = 7;
const SET_COUNT_LIMIT_DIGIT = 10;
const MIN_COUNT_LIMIT_DIGIT = 10;

export default {
  components: {
    FlowerImageSlider,
    FlowerHarvestDetail,
    SelectBox,
    MstButton,
    MstTextArea,
    MstTextField,
    DateRangePicker
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      isCalendarShow: false,
      isShowConfirmModal: false,
      errorConfirmedCount: "",
      flowers: [],
      flowerGenera: [],
      searchFlowerSpecieText: "",
      isHarvestDaysOfWeek: [],
      flowerGenusId: "",
      flowerSpecieId: "",
      flowerId: "",
      stemSize: "",
      minCount: "",
      setCount: "",
      price: "",
      recommended_min_price: "",
      recommended_max_price: "",
      note: "",
      startHarvestDate: dayjs().toDate(),
      endHarvestDate: dayjs().toDate(),
      error: {
        stemSize: "",
        minCount: "",
        setCount: "",
        price: "",
        harvestDate: "",
      },
    };
  },
  async created() {
    this.isLoading = true;

    const workingWdays = this.$store.getters.workingWdays;
    const daysOfWeekLabels = ["short_sunday", "short_monday", "short_tuesday", "short_wednesday", "short_thursday", "short_friday", "short_saturday"];

    this.isHarvestDaysOfWeek = workingWdays.map(workingWday => ({
      i18Label: daysOfWeekLabels[workingWday],
      number: workingWday,
      include: true,
    }));

    const selectedDate = this.$route.query.selectedHarvestDate;
    if (selectedDate) {
      this.startHarvestDate = dayjs(selectedDate).toDate();
      this.endHarvestDate = dayjs(selectedDate).toDate();
    } else {
      this.startHarvestDate = dayjs(this.startDate).toDate();
      this.endHarvestDate = dayjs(this.startDate).toDate();
    }

    const flowerGenusRes = await this.$http2("get", "farm/harvests/flower_genus");
    if (flowerGenusRes.status === 200) {
      this.flowerGenera = flowerGenusRes.result.flowerGenus.map((obj, index) => {
        const flowerGenus = { ...obj, _id: index };
        flowerGenus.name_with_category = `${flowerGenus.name ?? ""} - ${flowerGenus.categoryName ?? ""}`;
        return flowerGenus;
      });
    }

    const flowersRes = await this.$http2("get", "farm/flowers");
    if (flowersRes.status === 200) {
      this.flowers = flowersRes.result.flowers;
    }

    this.isLoading = false;
  },
  computed: {
    formattedPeriod() {
      if (!this.startHarvestDate && !this.endHarvestDate) return "";

      const startFormatDate = dayjs(this.startHarvestDate).format("YYYY/MM/DD(ddd)");
      const endFormatDate = dayjs(this.endHarvestDate).format("YYYY/MM/DD(ddd)");
      if (startFormatDate < endFormatDate) {
        return `${startFormatDate} ~ ${endFormatDate}`;
      }
      return startFormatDate;
    },
    workingFlowerIds() {
      return this.flowers.filter(flower => flower.isWorking);
    },
    flowerSpecies() {
      const searchResultFlowerSpecies = this.workingFlowerIds.filter(flower => {
        const isMatchFlowerSpecieName = flower.flowerSpecie.name.includes(this.searchFlowerSpecieText);
        const isMatchFlowerGenusId = flower.flowerSpecie.flowerGenus.id === this.flowerGenera[Number(this.flowerGenusId)].id;
        const isMatchCategoryId = flower.category.id === this.flowerGenera[Number(this.flowerGenusId)].categoryId;

        return isMatchFlowerSpecieName && isMatchFlowerGenusId && isMatchCategoryId;
      }).map(flower => flower.flowerSpecie);

      return searchResultFlowerSpecies.reduce((uniqueFlowerSpecies, flowerSpecie) => {
        const exists = uniqueFlowerSpecies.some(uniqueFlowerSpecie => uniqueFlowerSpecie.id === flowerSpecie.id);
        if (!exists) {
          uniqueFlowerSpecies.push(flowerSpecie);
        }
        return uniqueFlowerSpecies;
      }, []);
    },
    foundFlowerGenus() {
      return this.flowerGenera[Number(this.flowerGenusId)];
    },
    flowerGenusName() {
      return `${this.foundFlowerGenus?.name ?? ""} - ${this.foundFlowerGenus?.category_name ?? ""}`;
    },
    flowerGenusRecommendedMinPrice() {
      return this.foundFlowerGenus?.recommended_min_price?.toString() || "";
    },
    flowerGenusRecommendedMaxPrice() {
      return this.foundFlowerGenus?.recommended_max_price?.toString() || "";
    },
    flowerCategoryId() {
      return this.foundFlowerGenus.categoryId;
    },
    flowerSpecieName() {
      const foundFlower = this.flowers.find(flower => flower.id === Number(this.flowerId));

      return foundFlower?.flowerSpecie.name || "";
    },
    flowerImages() {
      const foundFlower = this.flowers.find(flower => flower.id === Number(this.flowerId));

      if (!foundFlower) return [];
      return foundFlower.images;
    },
    startDate() {
      return dayjs(this.$store.getters.getFarmDeadline).format("YYYY-MM-DD");
    },
  },
  watch: {
    async flowerId (newValue) {
      const foundFlower = this.flowers.find(flower => flower.id === Number(newValue));
      if (!newValue || !foundFlower) return;

      this.isLoading = true;
      const res = await this.$http2("get", `farm/harvests/candidate?flower_specie_id=${foundFlower.flowerSpecie.id}`);
      const flowerHarvest = res.result.flower_harvest;
      this.isLoading = false;

      if (!flowerHarvest) return;

      if (flowerHarvest.min_count === 0) this.setCount = 0;
      else this.setCount = Math.floor(flowerHarvest.confirmed_count / flowerHarvest.min_count).toString();
      this.minCount = flowerHarvest.min_count?.toString() || "";
      this.price = flowerHarvest.price?.toString() || "";
      this.stemSize = flowerHarvest.stem_size?.toString() || "";
      this.note = flowerHarvest.note;
    }
  },
  methods: {
    async imageHandler(e) {
      this.isLoading = true;
      const payload = {
        genusId: this.flowerGenera[this.flowerGenusId].id,
        specieId: this.flowerSpecieId,
        imgs: e
      };

      switch (e) {
        case "OVERSIZE":
          this.$store.dispatch("setSnackBar", { msg: this.$t("image_size_over"), color: "red" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
          break;

        case "WRONG_FORMAT":
          this.$store.dispatch("setSnackBar", { msg: this.$t("image_wrong_format"), color: "red" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
          break;

        default:
          await this.$store.dispatch("flowers/putFlower", {
            flower_id: this.flowerId,
            data: payload
          });

          await this.$http2("get", "farm/flowers").then(res => {
            if (res.status !== 200) return;

            this.flowers = res.result.flowers;
          });
          break;
      }
      this.isLoading = false;
    },
    setVisible() {
      this.isVisible = true;
    },
    loseFocus() {
      setTimeout(() => {
        this.isVisible = false;
      }, 300);
    },
    onClickFlowerGenus(flowerGenusId) {
      this.flowerGenusId = flowerGenusId.toString();
      this.flowerId = "";
      this.flowerSpecieId = "";
      this.searchFlowerSpecieText = "";
    },
    onClickCalendarIcon() {
      this.isCalendarShow = true;
      document.body.style.overflow = "hidden";
    },
    closeCalendar() {
      this.isCalendarShow = false;
      document.body.style.overflow = "auto";
    },
    confirmPeriod(start, end) {
      this.startHarvestDate = dayjs(start).toDate();
      this.endHarvestDate = dayjs(end).toDate();
      this.isCalendarShow = false;
      document.body.style.overflow = "auto";
    },
    onClickDayOfWeek(dayOfWeekNumber) {
      const targetDayIndex = this.isHarvestDaysOfWeek.findIndex(day => day.number === dayOfWeekNumber);
      this.isHarvestDaysOfWeek[targetDayIndex].include = !this.isHarvestDaysOfWeek[targetDayIndex].include;
    },
    handleChangeStemSize(value) {
      if (Number(value) < 0) {
        this.error.stemSize = this.$t("harvest_number_error_msg");
        return;
      }

      if (value.length >= STEM_SIZE_LIMIT_DIGIT) {
        this.error.stemSize = this.$t("harvest_over_float_error_msg");
        this.stemSize = 10 ** STEM_SIZE_LIMIT_DIGIT;
        return;
      }

      this.error.stemSize = "";
      this.stemSize = value;
    },
    handleChangeSetCount(value) {
      if (Number(value) < 0) {
        this.error.stemSize = this.$t("harvest_number_error_msg");
        return;
      }

      if (value.length >= SET_COUNT_LIMIT_DIGIT) {
        this.error.stemSize = this.$t("harvest_over_int_error_msg");
        this.stemSize = 10 ** SET_COUNT_LIMIT_DIGIT;
        return;
      }

      this.error.setCount = "";
      this.setCount = value;
    },
    handleChangeMinCount(value) {
      if (Number(value) < 0) {
        this.error.stemSize = this.$t("harvest_number_error_msg");
        return;
      }

      if (value.length >= MIN_COUNT_LIMIT_DIGIT) {
        this.error.stemSize = this.$t("harvest_over_int_error_msg");
        this.minCount = 10 ** MIN_COUNT_LIMIT_DIGIT;
        return;
      }

      this.error.minCount = "";
      this.minCount = value;
    },
    handleChangePrice(value) {
      if (Number(value) < 0) {
        this.error.price = this.$t("harvest_number_error_msg");
        return;
      }

      this.price = value;
    },
    handleChangeNote(value) {
      this.note = value;
    },
    onClickFlowerSpecie(flowerSpecieId) {
      const foundFlower = this.flowers.find(flower => flower.flowerSpecie.id === flowerSpecieId && flower.category.id === this.flowerCategoryId);
      if (!foundFlower) return;

      this.flowerId = foundFlower.id.toString();
      this.flowerSpecieId = foundFlower.flowerSpecie.id.toString();
      this.searchFlowerSpecieText = foundFlower.flowerSpecie.name;
    },
    handleChangeSearchFlowerSpecie(value) {
      this.searchFlowerSpecieText = value;

      const foundFlower = this.flowers.find(flower => flower.flowerSpecie.nameFurigana === value);
      if (foundFlower) {
        this.flowerId = foundFlower.id.toString();
        this.flowerSpecieId = foundFlower.flowerSpecie.id.toString();
      } else {
        this.flowerId = "";
        this.flowerSpecieId = "";
      }
    },
    isValid() {
      const startHarvestDate = dayjs(this.startHarvestDate).format("YYYY-MM-DD");
      const endHarvestDate = dayjs(this.endHarvestDate).format("YYYY-MM-DD");
      const isSelectedDaysOfWeek = this.isHarvestDaysOfWeek.some(daysOfWeek => daysOfWeek.include);

      return this.flowerId
        && Number(this.minCount) > 0
        && Number(this.setCount) >= 0
        && Number(this.price) > 0
        && Number(this.stemSize) > 0
        && this.minCount.length <= MIN_COUNT_LIMIT_DIGIT
        && this.setCount.length <= SET_COUNT_LIMIT_DIGIT
        && this.stemSize.length <= STEM_SIZE_LIMIT_DIGIT
        && startHarvestDate >= this.startDate
        && endHarvestDate >= startHarvestDate
        && isSelectedDaysOfWeek;
    },
    onClickCloseConfirmModalButton() {
      this.isShowConfirmModal = false;
    },
    onClickOpenConfirmModalButton() {
      this.isShowConfirmModal = true;
    },
    generateHarvestDates() {
      const daysOfWeek = this.isHarvestDaysOfWeek.filter(day => day.include).map(day => day.number);
      const harvestDates = [];
      let currentDate = this.startHarvestDate;

      while (currentDate <= this.endHarvestDate) {
        if (daysOfWeek.includes(currentDate.getDay())) {
          harvestDates.push(dayjs(currentDate).format("YYYY-MM-DD"));
        }
        currentDate = dayjs(currentDate).add(1, "day").toDate();
      }

      return harvestDates;
    },
    async submit() {
      this.isShowConfirmModal = false;

      const confirmedCount = this.set_count * this.minCount;
      const confirmedCountLimit = SET_COUNT_LIMIT_DIGIT ** MIN_COUNT_LIMIT_DIGIT;
      if (confirmedCount >= confirmedCountLimit) {
        this.errorConfirmedCount = this.$t("error_confirmed_count");
        return;
      }

      const body = {
        flower_id: this.flowerId,
        confirmed_count: Number(this.setCount) * Number(this.minCount),
        min_count: Number(this.minCount),
        harvest_dates: this.generateHarvestDates(),
        stem_size: Number(this.stemSize),
        price: Number(this.price),
        note: this.note
      };

      this.isLoading = true;
      const res = await this.$http2("post", "farm/harvests/create_many", body);
      this.isLoading = false;

      if (res.status !== 200) {
        alert("作成に失敗しました");
        return;
      }

      // TODO: 作成に失敗した出品のアラート
      // if (res.failure.length > 0) {
      //   const alertMsg = res.failure.map(failure => `${failure.harvest_date}: ${failure.message}`).join("\n");
      //   alert(alertMsg);
      // }

      this.$store.dispatch("flowerHarvest/setIsShowSuccessModal", { isShowSuccessModal: true });
      this.$router.push("/farm/flower_harvests");
    }
  }
};
</script>
<style lang="scss" scoped>
.label-item {
  margin: 4px auto 4px 0;
  color: #727272;
}
.unit-margin {
  margin: 4px 8px;
  width: 15px;
}
.price-label {
  margin-top: 4px;
  color: #727272;
}

.period {
  position: relative;
  height: 56px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #D7D7D7;
  display: flex;
  padding: 2px 8px;
  line-height: 26px;
  .calendar-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
  }
}

.single-period {
  height: 34px;
  align-items: center;
}

.select-day-of-week-wrapper {
  display: inline-block;
  .select-day-of-week {
    margin-top: 8px;
    display: flex;
    height: 48px;
    border-radius: 4px;
    outline: 1px solid #BFC3C6;
    overflow: auto;

    .day-of-week {
      flex: 1;
      width: 48px;
      line-height: 48px;
      text-align: center;
      background: #F4F6F8;
      transition: all 0.4s;
    }

    .active {
      background: #315198;
      color: #ffffff;
      transition: all 0.4s;
    }

    .border-right {
      border-right: 1px solid #BFC3C6;
    }
  }
}
.searchBox__inner {
  position: relative;
  cursor: pointer;
}
.searchBox__container {
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 4px 0;
  max-width: 100%;
  max-height: 202px;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1;
}
.dialog-card {
  padding: 25px 52px;
  display: flex;
  flex-direction: column;
  height: 264px;
  width: 344px;
  margin: auto;
}
.card-logo {
  width: 42px;
  height: 46.67px;
  margin: auto;
  margin-top: 12px;
  margin-bottom: 12px;
}
.dialog-header {
  margin: auto;
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
}
.dialog-text {
  margin-bottom: 12px;
  font-size: 14px;
}
.flex-items {
  display: flex;
  gap: 10px;
}
.modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 0 solid #888;
  border-radius: 8px;
  max-width: 344px;
}
</style>
