<template>
  <!-- eslint-disable -->
  <svg :width="width" :height="height" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.25 9.4375C6.25 9.25516 6.32243 9.08029 6.45136 8.95136C6.5803 8.82243 6.75516 8.75 6.9375 8.75H11.0625C11.2448 8.75 11.4197 8.82243 11.5486 8.95136C11.6776 9.08029 11.75 9.25516 11.75 9.4375C11.75 9.61984 11.6776 9.79471 11.5486 9.92364C11.4197 10.0526 11.2448 10.125 11.0625 10.125H6.9375C6.75516 10.125 6.5803 10.0526 6.45136 9.92364C6.32243 9.79471 6.25 9.61984 6.25 9.4375ZM3.5 5.3125C3.5 5.13016 3.57243 4.9553 3.70136 4.82636C3.8303 4.69743 4.00516 4.625 4.1875 4.625H13.8125C13.9948 4.625 14.1697 4.69743 14.2986 4.82636C14.4276 4.9553 14.5 5.13016 14.5 5.3125C14.5 5.49484 14.4276 5.6697 14.2986 5.79864C14.1697 5.92757 13.9948 6 13.8125 6H4.1875C4.00516 6 3.8303 5.92757 3.70136 5.79864C3.57243 5.6697 3.5 5.49484 3.5 5.3125ZM0.75 1.1875C0.75 1.00516 0.822433 0.830295 0.951364 0.701364C1.0803 0.572433 1.25516 0.5 1.4375 0.5H16.5625C16.7448 0.5 16.9197 0.572433 17.0486 0.701364C17.1776 0.830295 17.25 1.00516 17.25 1.1875C17.25 1.36984 17.1776 1.5447 17.0486 1.67364C16.9197 1.80257 16.7448 1.875 16.5625 1.875H1.4375C1.25516 1.875 1.0803 1.80257 0.951364 1.67364C0.822433 1.5447 0.75 1.36984 0.75 1.1875Z" :fill="color" />
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    width: String,
    height: String
  }
};
</script>
