<template>
  <div>
    <div style="text-align: center" id="top">
      <small>{{ $t("welcome") }}</small>、
      <strong>{{ shop.name }}：{{ shop.representative_name }}</strong>
      <small>{{ $t("sama") }}</small>
    </div>

    <PageContainer>
      <v-divider />
      <div>
        <div class="text-subtitle-2"><span class="text-title-">{{ flower_species.length }}</span>件</div>
      </div>
      <div>
        <ProductsList :list="flower_species" />
      </div>
    </PageContainer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageContainer from "@/components/shop/PageContainer.vue";
import ProductsList from "@/components/shop/products/ProductsList.vue";
import { formatDate } from "@/plugins/i18n";
import sanitizeQueryObject from "@/helpers/sanitizeQueryObject";

const watchQuery = ["category_id", "flower_genus_id", "farm_id", "delivery_date", "page", "stem_size", "available", "order"];

export default {
  components: {
    PageContainer,
    ProductsList,
  },
  watchQuery,
  computed: {
    ...mapGetters(["getUserName"]),
    shop() {
      return this.$store.state.user.shop;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    farms() {
      return this.$store.state.searchQuery.availableFarms;
    },
    categories() {
      return this.$store.state.categories;
    },
  },

  data() {
    return {
      flower_species: [],
      page: null,
      pagination: {},
      available: 0,
    };
  },

  async beforeMount() {
    this.listView = await this.$db("shopProductViewType");
  },

  mounted() {
    this.page = this.$route.query.page ? JSON.parse(decodeURIComponent(this.$route.query.page)) : 1;
    this.available = this.$route.query.available ? JSON.parse(decodeURIComponent(this.$route.query.available)) : 0;
  },
  created() {
    this.fetchHome();
  },
  watch: {
    flower_species: {
      handler() {},
      deep: true
    },
    available() {
      const newQuery = new URLSearchParams({ ...this.$route.query, available: this.available });
      this.$router.push(`?${newQuery}`);
      this.fetchHome();
    },
    order() {
      const newQuery = new URLSearchParams({ ...this.$route.query, order: this.order });
      this.$router.push(`?${newQuery}`);
      this.fetchHome();
    }
  },

  methods: {
    onClickSearchHandler (q) {
      this.page = 1;
      const params = { page: this.page, order: this.order };
      if (q.category) params.category_id = q.category.id;
      if (q.genus) params.flower_genus_id = q.genus.id;
      if (q.farm) params.farm_id = q.farm.id;
      if (q.date) params.delivery_date = q.date;
      if (q.stem_size) params.stem_size = q.stem_size;

      params.available = this.available;

      const query = new URLSearchParams(sanitizeQueryObject(params, watchQuery)).toString();
      this.$router.push(`?${query}`);
      this.fetchHome();
    },
    paginationHandler(number) {
      const query = this.$route.query;
      const newQuery = new URLSearchParams({ ...query, page: number });
      this.$router.push(`?${newQuery}`);
      this.fetchHome(newQuery);
    },
    formatDate(d) {
      return formatDate(d, "yyyy-mm-dd");
    },
    async fetchHome () {
      const { status, result } = await this.$http("get", "/flower_species");

      if (status === 200) {
        this.flower_species = result.flower_species;
      }
    },
    async agreetment() {
      const res = await this.$http("put", "users/agree_terms");
      if (res.status === 200) { this.$store.state.user.agree_terms = true; }
    },
    changeView(type) {
      this.listView = type === "list";
      this.$db("shopProductViewType", this.listView);
    },
    initializeProductFilterMobile() {
      this.$refs.productFilterMobile.initialize();
    },
    async submit() {
      if (this.whenAbsent) {
        const defaultLocation = this.$store.state.user.shop.locations.find(location => location.default === true);
        await this.$store.dispatch("putLocation", {
          id: defaultLocation.id,
          content: { when_absent: this.whenAbsent }
        }).then(() => {
          this.$store.dispatch("setSnackBar", { msg: "不在時納品場所を登録しました", color: "green" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-title {
  margin: 40px 0 0 0;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 15px;
  @media only screen and (max-width: 960px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

.product-total {
  margin: 10px 10px 10px 10px;
}
</style>
