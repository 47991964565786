<template>
  <Signup type="Farm" />
</template>

<script>
import Signup from "@/components/auth/Signup.vue";

export default {
  components: {
    Signup
  }
};
</script>

<style>
</style>
