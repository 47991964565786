<template>
  <div :class="styledClasses" class="mstMessageBalloon">
    <div v-if="image" class="mstMessageBalloon__imageWrapper">
      <button type="button" class="mstMessageBalloon__imageButton" @click="isVisibleViewer = true">
        <img :src="image" alt="" class="mstMessageBalloon__image" />
      </button>
      <div v-if="!body" class="mstMessageBalloon__meta text-xs">
        <i v-if="me && read" class="mstMessageBalloon__read">既読</i>
        <time :datetime="createdAt" class="mstMessageBalloon__createdAt">
          {{ $d(createdAt, "shortDateTime") }}
        </time>
      </div>

      <MstModal v-model="isVisibleViewer" viewer no-teleport>
        <img :src="image" alt="" class="mstMessageBalloon__viewer" />
      </MstModal>
    </div>
    <div v-if="body" class="mstMessageBalloon__inner">
      <div class="mstMessageBalloon__content">
        <p class="mstMessageBalloon__body text-sm">{{ body }}</p>
      </div>
      <div class="mstMessageBalloon__meta text-xs">
        <i v-if="me && read" class="mstMessageBalloon__read">既読</i>
        <time :datetime="createdAt" class="mstMessageBalloon__createdAt">
          {{ $d(createdAt, "shortDateTime") }}
        </time>
      </div>
    </div>
  </div>
</template>

<script>
import { MstModal } from "@/components/master";

export default {
  components: { MstModal },
  props: {
    body: { type: String, required: true },
    createdAt: { type: Date, required: true },
    image: { type: String },
    me: { type: Boolean },
    read: { type: Boolean },
  },
  data() {
    return {
      isVisibleViewer: false,
    };
  },
  computed: {
    styledClasses() {
      const classes = [];
      if (this.me) classes.push("-me");
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
.mstMessageBalloon__inner {
  display: flex;
  align-items: flex-end;
  gap: 0 8px;
}

.mstMessageBalloon__content {
  position: relative;
  border-radius: 8px;
  padding: 10px 12px;
  max-width: 75%;
  background: variables.$color-white;
  color: variables.$color-black-500;

  &::before {
    content: "";
    position: absolute;
    top: 8px;
    right: calc(100% - 3px);
    border-width: 5px 8px;
    border-style: solid;
    border-color: transparent variables.$color-white transparent transparent;
  }
}

.mstMessageBalloon__body {
  margin-bottom: 0;
  white-space: pre-line;
  word-wrap: break-word;
  word-break: break-all;
}

.mstMessageBalloon__meta {
  padding-bottom: 3px;
  color: variables.$color-black-500;
}

.mstMessageBalloon__read {
  display: block;
  font-style: normal;
  text-align: right;
}

.mstMessageBalloon__imageWrapper {
  display: flex;
  align-items: flex-end;
  gap: 0 8px;
  margin-bottom: 6px;
}

.mstMessageBalloon__imageButton {
  display: inline-block;
  max-width: 75%;
}

.mstMessageBalloon__image {
  border-radius: 10px;
  width: 100%;
  vertical-align: top;
}

.mstMessageBalloon__viewer {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.-me {
  .mstMessageBalloon__inner {
    flex-direction: row-reverse;
  }

  .mstMessageBalloon__content {
    background: variables.$color-brand-primary;
    color: variables.$color-white;

    &::before {
      right: auto;
      left: calc(100% - 3px);
      border-color: transparent transparent transparent variables.$color-brand-primary;
    }
  }

  .mstMessageBalloon__imageWrapper {
    flex-direction: row-reverse;
    text-align: right;
  }
}
</style>
