<template>
  <div>
    <v-btn
      v-if="backBtnLabel"
      class="grey"
      :class="{'mobile-btn': mobile}"
      @click="onClick('backward')"
      :disabled="disabled"
    >{{ backBtnLabel }}</v-btn>

    <v-btn
      v-if="nextBtnLabel"
      class="blue ml-2"
      :class="{'mobile-btn': mobile}"
      @click="onClick('forward')"
      :disabled="disabled"
    >{{ nextBtnLabel }}</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    onClick: Function,
    nextBtnLabel: String,
    backBtnLabel: String,
    disabled: Boolean
  },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs
    };
  }
};
</script>

<style scoped>
.mobile-btn {
  width: 125px;
  word-wrap: break-word !important;
}
</style>
