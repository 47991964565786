<template>
  <div class="harvest-main">
    <Datepicker class="mt-3" :selected="selectedDate" :allowWdayList="allowWdayList" @change="dateHandler" />

    <div class="d-flex justify-space-between pr-3 mt-3">
      <div>
        <v-btn class="ml-2" :to="`/farm/harvests?harvest_date=${$route.query.harvest_date}`" block outlined color="#007e57">一覧に戻る</v-btn>
      </div>
      <div>
        <div v-if="edit">
          <v-btn class="ml-2 ga-farm-all_copy" style="color: #00796B" outlined v-if="edit && !copyAllDisabled" @click="copyAll">{{ $t("copy_all") }}</v-btn>
        </div>
        <div class="d-flex">
          <harvestDialog v-if="!edit || dialogOpen"
          :harvestDate="selectedDate" :dialogOpen="dialogOpen" :allowWdayList="allowWdayList"
            @open="openHandler"
            @close="closeHandler"
            @on-edit="onEdit" @edit-handler="editHandler" @copy="onCopy"/>
        </div>
      </div>
    </div>

    <div class="d-flex justify-space-between pr-3">
     <v-breadcrumbs
      :items="breadcrumbs"
      divider=">" />
    </div>

    <div class="ma-3" style="overflow-x: auto; border-bottom: 1px solid #dedede">
      <Table
        :headers="headers"
        :items="items"
        :edit="(edit && !dialogOpen)"
        :on-edit="onEdit"
        :on-sort-by-status="onSortByStatus"
        :hiddenLabel="true"
        :hideDefaultFooter="true"
        :currentDate="selectedDate"
        @copy="onCopy"
        @on-edit="onEdit"
      />
    </div>
    <div class="pl-2 pr-2 fixed">
      <v-btn v-if="edit && !dialogOpen" block class="blue" @click="editHandler">{{ $t("save_flower_harvest") }}</v-btn>
      <v-btn v-else block class="orange" @click="editHandler">{{ $t("edit") }}</v-btn>
    </div>
  </div>
</template>

<script>
import Datepicker from "@/components/shared/Datepicker.vue";
import Table from "@/components/shared/Table.vue";
import harvestDialog from "@/components/farm/harvest/harvestDialog.vue";
import { formatDate } from "@/plugins/i18n";

export default {
  components: { Datepicker, Table, harvestDialog },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      headers: [
        "editable",
        "category",
        "flower_specie",
        "flower_harvest_status",
        "price",
        "min_count",
        "set_count",
        "stem_size",
        "note",
        "sales_rate"
      ].map(x => ({ text: this.$t(x), value: x, width: "120px" })),
      items: [],
      selectedDate: null,
      edit: false,
      dialogOpen: false,
      copyAllDisabled: false,
      editContent: {},
      flowerGenusId: this.$route.params.flowerGenusId,
      weekArray: [
        this.$t("short_sunday"),
        this.$t("short_monday"),
        this.$t("short_tuesday"),
        this.$t("short_wednesday"),
        this.$t("short_thursday"),
        this.$t("short_friday"),
        this.$t("short_saturday")
      ],
      breadcrumbs: [
        {
          text: "品目一覧",
          disabled: false,
          href: `/farm/harvests?harvest_date=${this.$route.query.harvest_date}`
        },
        {
          text: this.genusName,
          disabled: true
        }
      ]
    };
  },
  computed: {
    today() {
      return this.$date.MMDD(new Date());
    },
    tomorrow() {
      const d = new Date().getTime() / 1000 + 84600;
      return this.$date.MMDD(new Date(d));
    },
    allowWdayList() {
      return this.$store.getters.workingWdays;
    }
  },
  watch: {
    selectedDate() {
      return this.selectedDate;
    }
  },
  created() {
    const date = new Date(this.$route.query.harvest_date);
    const wdays = this.allowWdayList && this.allowWdayList.length > 0 ? this.allowWdayList : [0, 1, 2, 3, 4, 5, 6];
    while (!wdays.includes(date.getDay())) {
      date.setDate(date.getDate() + 1);
    }
    this.dateHandler(formatDate(date, "yyyy-mm-dd"));
  },
  methods: {
    loadData(date) {
      this.$store
        .dispatch("flowerHarvest/getFlowerHarvest", { date, flowerGenusId: this.flowerGenusId })
        .then(() => {
          this.editContent = {};
          this.edit = false;
          this.$router.push(`/farm/harvests/${this.flowerGenusId}?harvest_date=${this.selectedDate}`);
          this.createTableData();
        });
    },
    async createTableData() {
      const { flowerHarvest } = this.$store.state;
      this.breadcrumbs[1].text = flowerHarvest.list[0].flower.flower_specie.flower_genus.name;
      this.items = [];
      this.copyAllDisabled = false;
      flowerHarvest.listArr.forEach(id => {
        const harvest = flowerHarvest.list[id];
        this.pushToTableItems(harvest);
        if (!harvest.harvest_date) return;
        if (this.exhibitedFlowerHarvest(harvest)) {
          if (harvest.min_count) this.copyAllDisabled = true;
        }
      });

      const harvestDate = new Date(this.selectedDate);
      harvestDate.setHours(0);
      harvestDate.setMinutes(0);
      harvestDate.setSeconds(0);

      const currentDate = new Date();
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setSeconds(0);

      const isPastHarvest = harvestDate <= currentDate;
      if (isPastHarvest) this.copyAllDisabled = isPastHarvest;
    },
    pushToTableItems(harvest) {
      this.items.push({
        ...harvest,
        flower_specie: harvest.flower.flower_specie.name,
        category: harvest.flower.category.name,
        price: this.getValue(harvest, "price"),
        confirmed_count: this.getValue(harvest, "confirmed_count"),
        min_count: this.getValue(harvest, "min_count"),
        set_count: this.getSetCount(harvest),
        stem_size: this.getValue(harvest, "stem_size"),
        note: this.getValue(harvest, "note"),
        current_date: this.selectedDate,
        sales_rate: this.getSalesRate(harvest)
      });
    },
    dateHandler(d) {
      this.edit = false;
      this.selectedDate = d;
      this.loadData(d);
    },
    putFlowerHarvest(payload) {
      this.$store.dispatch("flowerHarvest/putFlowerHarvest", payload)
        .then(() => {
          this.$store.dispatch("flowerHarvest/getFlowerHarvest", { date: this.selectedDate, flowerGenusId: this.flowerGenusId })
            .then(() => {
              this.editContent = {};
              this.edit = false;
              this.dialogOpen = false;
              this.$router.push(`/farm/harvests/${this.flowerGenusId}?harvest_date=${this.selectedDate}`);
              this.createTableData();
            });
        });
    },
    editHandler() {
      if (!this.edit) {
        this.edit = true;
      } else if (Object.keys(this.editContent).length) {
        const payload = [];
        let harvest = {};
        Object.keys(this.editContent).forEach(key => {
          if (key < 0) this.getPostRequestBody(key);
          if (this.exhibitedFlowerHarvest(this.items[key])) {
            harvest = this.getPutRequestBody(key);
          } else {
            harvest = this.getPostRequestBody(key);
          }
          payload.push(harvest);
        });
        this.putFlowerHarvest(payload);
      } else {
        this.edit = false;
        this.dialogOpen = false;
      }
    },
    onEdit(e, id, key) {
      if (key === "status") {
        this.changeFlowerHarvestStatus(e, id);
        return;
      }

      const { value } = e.target;
      if (!this.editContent[id]) this.editContent[id] = {};
      this.editContent[id][key] = value;
      if (id > -1) this.items[id][key] = value;
    },
    changeFlowerHarvestStatus(status, id) {
      const payload = [];

      payload.push({
        id,
        status: status ? "on_sale" : "not_on_sale"
      });

      this.putFlowerHarvest(payload);
    },
    onCopy(index) {
      const harvest = this.$store.state.flowerHarvest.list[index];
      for (let i = 0; i < this.items.length; i++) {
        if (
          (harvest.id && this.items[index].id === harvest.id) || !harvest.id
        ) {
          const keyList = ["min_count", "confirmed_count", "price", "flower_id", "stem_size", "note"];

          keyList.forEach(key => {
            this.items[index][key] = harvest[key];
          });

          this.items[index].set_count = harvest.set_count;
          if (!this.editContent[index]) this.editContent[index] = {};

          keyList.forEach(key => {
            this.editContent[index][key] = harvest[key];
          });
          this.editContent[index].id = harvest.id;
          this.editContent[index].harvest_date = harvest.harvest_date;
          break;
        }
      }
    },
    onSortByStatus(items, index) {
      if (index !== "flower_harvest_status") { return items; }
      return this.compare(items);
    },
    compare(items) {
      items.sort((a, b) => {
        let a1 = 0;
        let b1 = 0;

        a1 = this.harvestToNumber(a);
        b1 = this.harvestToNumber(b);

        return b1 - a1;
      });
    },
    harvestToNumber(harvest) {
      if (this.exhibitedFlowerHarvest(harvest)) {
        return harvest.status === "on_sale" ? 1 : -1;
      }
      return -2;
    },
    openHandler() {
      this.edit = true;
      this.dialogOpen = true;
      this.editContent = {};
    },
    closeHandler() {
      this.edit = false;
      this.dialogOpen = false;
      this.editContent = {};
    },
    copyAll() {
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if (!item.min_count || !item.confirmed_count || !item.price || !item.stem_size) {
          this.onCopy(this.items.indexOf(item));
        }
      }
    },
    getValue(harvest, key) {
      return this.exhibitedFlowerHarvest(harvest) ? harvest[key] : null;
    },
    getSetCount(harvest) {
      if (!harvest.harvest_date || !this.getValue(harvest, "min_count")) return null;
      return this.getValue(harvest, "confirmed_count") / this.getValue(harvest, "min_count");
    },
    exhibitedFlowerHarvest(harvest) {
      return harvest ? !!harvest.id : false;
    },
    getPutRequestBody(key) {
      const confirmed_count = (this.editContent[key].set_count || this.items[key].set_count) * (this.editContent[key].min_count || this.items[key].min_count);
      return {
        id: this.items[key].id,
        price: this.editContent[key].price,
        confirmed_count: confirmed_count === "" ? 0 : confirmed_count,
        min_count: this.editContent[key].min_count,
        stem_size: this.editContent[key].stem_size,
        note: this.editContent[key].note
      };
    },
    getPostRequestBody(key) {
      const confirmed_count = (this.editContent[key].set_count || this.items[key].set_count) * (this.editContent[key].min_count || this.items[key].min_count);
      let note = "";
      if (Object.keys(this.editContent[key]).includes("note")) {
        note = this.editContent[key].note;
      } else {
        note = this.items[key].note;
      }

      const harvest_date = {
        start: this.selectedDate,
        end: this.selectedDate,
        weekdays: null
      };

      if (this.editContent[key].harvest_date?.end) {
        harvest_date.end = this.editContent[key].harvest_date?.end;
        harvest_date.weekdays = this.editContent[key].harvest_date.weekdays;
      }

      return {
        id: null,
        confirmed_count: confirmed_count === "" ? 0 : confirmed_count,
        min_count: this.editContent[key].min_count || this.items[key].min_count,
        price: this.editContent[key].price || this.items[key].price,
        flower_id: this.editContent[key].flower_id || this.items[key].flower_id,
        harvest_date,
        stem_size: this.editContent[key].stem_size || this.items[key].stem_size,
        note
      };
    },
    getSalesRate(flower_harvest) {
      if (!flower_harvest.confirmed_count || !this.exhibitedFlowerHarvest(flower_harvest)) return 0;
      const sales_rate = ((flower_harvest.confirmed_count - flower_harvest.stock_count) / flower_harvest.confirmed_count) * 100;
      return this.orgRound(sales_rate, 1);
    },
    /**
    * 任意の桁で四捨五入する関数
    * @param {number} value 四捨五入する数値
    * @param {number} base どの桁で四捨五入するか
    * @return {number} 四捨五入した値
    */
    orgRound(value, base) {
      return Math.round(value * base) / base;
    }
  }
};
</script>

<style scoped>
.harvest-main {
  background: #f5f5f5;
}

.fixed {
  width: 100%;
  position: fixed;
  bottom: 100px;
}
</style>
