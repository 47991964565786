<template>
  <router-view />
</template>

<script>
export default {
  watch: {
    $route(to) {
      const loginPath = "/driver/login";
      if (
        !this.$store.getters.isSignedIn
        && to.path !== loginPath
      ) {
        this.$router.push(loginPath);
      }
    }
  },
  created() {
    if (!this.$store.getters.isSignedIn) {
      this.$router.push("/driver/login");
    }
  }
};
</script>

<style scoped>

</style>
