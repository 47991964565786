import FileChecksum from "./FileChecksum";

export async function getPresignedPostObject($http, uniqueId) {
  const result = await $http("get", `presigned-url?uniqueId=${uniqueId}`, undefined, false);
  return result;
}

/**
 * directUpload
 * upload files to S3 via presigned_post_object
 * @param files File[]
 * @returns {Promise<unknown[]>}
 */
export default async function directUpload(files, $http) {
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  return Promise.all(
    files.map(async (file, index) => {
      const uniqueId = new Date().getTime() + index;
      sleep(1000);
      const presignedPost = await getPresignedPostObject($http, uniqueId);
      const form = new FormData();
      Object.keys(presignedPost.fields).forEach(key => form.append(key, presignedPost.fields[key]));
      form.append("file", file);
      const result = await fetch(presignedPost.url, {
        method: "POST",
        body: form
      });
      const xml = await result.text();
      const parser = new DOMParser();
      const dom = parser.parseFromString(xml, "application/xml");

      const fileSize = file.size;
      const attachmentPost = {
        key: dom.getElementsByTagName("Key")[0]?.textContent,
        byte_size: fileSize
      };

      return new Promise((resolve, reject) => {
        FileChecksum.create(file, (error, base64) => {
          if (error) {
            reject();
          }
          attachmentPost.checksum = base64;
          resolve(attachmentPost);
        });
      });
    })
  );
}
