<template>
  <button
    class="button-root align-center justify-center"
    :class="[`color-${color}`, `size-${size}`, { block }]"
    :disabled="disabled || busy"
    @click="click"
  >
    <div>
      <slot />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "base"
    },
    size: {
      type: String,
      default: "normal"
    },
    block: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: null
    }
  },

  data() {
    return {
      busy: false
    };
  },

  methods: {
    async click(e) {
      this.$emit("click", e);
      if (this.onClick) {
        this.busy = true;
        await this.onClick(e);
        this.busy = false;
      }
      if (this.to) {
        this.$router.push(this.to).catch(() => null);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.button-root {
  display: flex;
  position: relative;
  border-radius: 3px;
  border: 1px solid transparent;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  outline: none;

  &.color-base {
    background-color: #007E57;
    &:active {
      background-color: #48AE84;
    }
  }

  &.color-blue {
    background-color: #5FAEDB;
    &:active {
      background-color: #94E0FF;
    }
  }

  &.color-yellow {
    background-color: #E38C00;
    &:active {
      background-color: #F2CB7B;
    }
  }

  &.color-red {
    background-color: #FF8C71;
    &:active {
      background-color: #FFBCA0;
    }
  }

  &.color-white {
    background-color: #FF8C71;
    &:active {
      background-color: #FFBCA0;
    }
  }

  &.color-gray {
    background-color: #ccc;
    &:active {
      background-color: #ddd;
    }
  }

  &.color-white-border-gray {
    background-color: #fff;
    color: #777;
    border-color: #ccc;
    &:active {
      background-color: #EAEAEA;
    }
  }

  &.color-white-borderless {
    background-color: #fff;
    color: #000000;
    &:active {
      background-color: #EAEAEA;
    }
  }

  &.color-inherit-border-green {
    color: #007E57;
    border: 1px solid #007E57;
    background-color: inherit;
    &:active {
      background-color: #EAEAEA;
    }
  }

  &.size-normal {
    min-width: 140px;
    min-height: 40px;
  }

  &.size-big {
    min-width: 240px;
    min-height: 50px;
    font-size: 18px;
  }

  &.size-small {
    min-width: 100px;
    min-height: 34px;
  }

  &.block {
    display: block;
    width: 100%;
    min-width: 0;
  }

  &.checkable {
    cursor: pointer;
  }

  &[disabled] {
    background-color: #eee;
    color: #fff;
    border-color: transparent;
    cursor: initial;

    &:active {
      background-color: #eee;
    }
  }
}
</style>
