<template>
  <ul class="otherCollections">
    <li v-for="collection in collections" :key="collection.id" class="otherCollections__item">
      <router-link :to="`/shop/collections/${collection.id}`" class="otherCollections__inner">
        <img v-if="collection.main_section.image" :src="collection.main_section.image.image_url" alt="" class="otherCollections__image" />
        <div class="otherCollections__textContainer">
          <MstTextTag :text="isStarted(collection) ? '開催中' : 'coming soon'" size="sm" color="gray-50" rounded />
          <strong class="otherCollections__title text-lg font-weight-bold">{{ collection.title }}</strong>
          <p class="otherCollections__note text-xs">{{ collection.description }}</p>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { MstTextTag } from "@/components/master";

export default {
  name: "OtherCollections",
  components: { MstTextTag },
  props: {
    collections: { type: Array, required: true },
  },
  methods: {
    isStarted(collection) {
      const now = new Date();
      return new Date(collection.start_date).getTime() < now.getTime();
    },
  },
};
</script>

<style lang="scss" scoped>
.otherCollections {
  padding-left: 0;
  list-style-type: none;
}

.otherCollections__item {
  &:not(:first-child) {
    margin-top: 16px;
  }
}

.otherCollections__inner {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 0 16px;
  padding-right: 21px;
  text-decoration: none;
  color: variables.$color-gray-900;

  &::after {
    position: absolute;
    top: 0;
    right: -7px;
    content: "\F0142";
    font-family: "Material Design Icons";
    font-size: 24px;
    line-height: 1;
    color: variables.$color-black-900;
  }
}

.otherCollections__image {
  flex-shrink: 0;
  border-radius: 5px;
  width: 84px;
  height: 84px;
  object-fit: cover;
}

.otherCollections__title {
  display: block;
  margin-top: 1px;
}

.otherCollections__note {
  overflow: hidden;
  display: -webkit-box;
  margin-top: 3px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
