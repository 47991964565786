<template>
  <ol class="conversationContent">
    <li v-for="item in content" :key="item.id" :class="getMe(item) ? '-me' : null" class="conversationContent__item">
      <img v-if="toImage && !getMe(item)" :src="toImage" alt="" class="conversationContent__toImage" />
      <MstMessageBalloon
        :body="item.body"
        :image="item.attachment ? item.attachment.payload.image_url : undefined"
        :created-at="new Date(item.created_at)"
        :read="item.status === 'read'"
        :me="getMe(item)"
      />
    </li>
  </ol>
</template>

<script>
import { MstMessageBalloon } from "@/components/master";

export default {
  name: "ConversationContent",
  components: { MstMessageBalloon },
  props: {
    content: { type: Array, required: true },
    currentUserType: { type: String, validates: value => ["Shop", "Farm"].includes(value), required: true },
    toImage: { type: String },
  },
  methods: {
    getMe(item) {
      return item.sender_type === this.currentUserType;
    },
  },
};
</script>

<style lang="scss" scoped>
.conversationContent {
  padding: 0;
  list-style: none;
}

.conversationContent__item {
  display: flex;
  gap: 0 8px;

  &.-me {
    display: list-item;
  }
}

.conversationContent__toImage {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.conversationContent__item {
  &:not(:first-child) {
    margin-top: 21px;
  }
}
</style>
