<template>
  <div>
    <v-chip
      v-if="type === 'required'"
      x-small
      label
      color="red accent-1"
      text-color="white"
      class="pa-1"
    >
      必須
    </v-chip>

    <v-chip
      v-if="type === 'option'"
      x-small
      label
      color="grey lighten-1"
      text-color="white"
      class="pa-1"
    >
      任意
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "required"
    }
  }
};
</script>

<style scoped>
</style>
