<template>
  <div class="pa-4 bg-back-gray my-2 rounded-8 mb-4 content-border pt-2 pb-2" style="margin-bottom: -7px;">
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex">
        <div v-if="warehouse" class="warehouse">{{ warehouse }}</div>
        <div class="color-gray-900">{{ shopName }}</div>
      </div>
      <div class="color-secondary font-weight-bold mb-1 text-xl">
        {{ totalCount }}
        <span class="text-xs">{{ $t('volumn') }}</span>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <p class="mb-0 genus-name" style="font-size: 14px; color: #68727B;">{{ genusName }}</p>
    </div>
    <div class="d-flex justify-space-between">
      <p class="font-weight-bold specie-name color-black-500 mb-0" style="font-size: 18px;">{{ speciesName }}</p>
    </div>
    <div class="d-flex justify-space-between mt-1" style="font-size: 12px;">
      <p class="color-white bg-color-menu px-2">{{ stemSize }}</p>
    </div>
    <div class="d-flex justify-space-between" style="font-size: 14px;">
      <p class="mb-0">
        ＠{{ harvestMinCount }}{{ $t('volumn') }} × {{ lotCount }}{{ $t('lot') }} × {{ price.toLocaleString() }}{{ $t('yen') }}= {{ totalPrice.toLocaleString() }}{{ $t('yen') }}
      </p>
    </div>
    <div class="d-flex justify-space-between note mt-2 ">
      <p class="mb-0" style="font-size: 14px; color: #68727B;">{{ note }}</p>
    </div>
    <div class="d-flex justify-space-between align-center mt-2" style="font-size: 12px;">
      <p>{{ $t('request_answer_deadline') }} : {{ request_answer_deadline }}</p>
    </div>
    <div class="d-flex justify-space-between align-center">
      <div class="color-gray-800 order-id" style="font-size: 12px;">{{ $t('order') }} : {{ id }}</div>
      <div v-if="status === 'unconfirmed'" class="d-flex align-center">
        <button class="turndown-btn rounded-4 mr-2" @click="$emit('openCancelModal')">
          <svg width="12" height="11" viewBox="0 0 12 11" fill="none">
            <path d="M11 10.5L1 0.5M11 0.5L1 10.5" stroke="white" stroke-linecap="round"/>
          </svg>
          {{ $t('reject_order') }}
        </button>
        <button class="approval-btn rounded-4 d-flex align-center" @click="$emit('openConfirmModal')">
          <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
            <path d="M2.33301 7.5L5.83301 11L11.6663 4" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ $t('approval') }}
        </button>
      </div>
      <div v-else-if="status === 'canceled'" class="d-flex align-center rollback">
        {{ $t('canceled') }}
      </div>
      <div v-else-if="status === 'rejected_request'" class="d-flex align-center rollback">
        {{ $t('rejected_request') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    speciesName: String,
    genusName: String,
    stemSize: String,
    totalCount: Number,
    totalPrice: Number,
    price: Number,
    lotCount: Number,
    harvestMinCount: Number,
    shopName: String,
    warehouse: String,
    status: String,
    request_answer_deadline: String,
    note: String
  },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs
    };
  }
};
</script>

<style scoped>
.turndown-btn {
  background-color: #C75757;
  font-size: 14px;
  color: white;
  padding: 6px 12px 6px 12px;
  width: 72px;
  height: 40px;

}
.approval-btn {
  background-color: #67A16D;
  font-size: 14px;
  color: white;
  padding: 6px 12px 6px 12px;
  width: 72px;
  height: 40px;
}
.rounded-4 {
  border-radius: 4px;
}
.rollback {
  font-size: 12px;
  color: #E52235;
}
.warehouse {
  background-color: #D4D7D9;
  color: #191D38;
  font-weight: 400;
  font-size: 12px;
  padding: 2px 6px 2px 6px;
  border-radius: 99px;
  margin: auto 4px auto 0;
}

.order-id {
  margin-top: auto;
}

.content-border {
  border: 1px solid #BFC3C6;
  background-color: #F5F5F5;
}
</style>
