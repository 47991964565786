<template>
  <div class="px-4 d-flex flex-column flex-grow-1">
    <div class="mt-4">
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("item") }}</p>
        </div>
        <div class="col-8">
          <span class="read-label">{{ genusName }}</span>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("specie") }}</p>
        </div>
        <div class="col-8">
          <span class="read-label">{{ specieName }}</span>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("stem_size") }}</p>
        </div>
        <div class="col-8">
          <template v-if="isEdit">
            <MstTextField v-model="harvest.stem_size" type="number" width="100px" :error="errors.stem_size" @input="handleNumberInput($event, 'stem_size')">
              <span class="unit-margin">cm</span>
            </MstTextField>
          </template>
          <template v-else>
            <span class="read-label">{{ harvest.stem_size }}cm</span>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("min_count") }}</p>
        </div>
        <div class="col-8">
          <span class="read-label">{{ harvest.min_count }}{{ $t("volumn") }}</span>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("set_count") }}</p>
        </div>
        <div class="col-8">
          <template v-if="isEdit">
            <MstTextField v-model="harvest.set_count" type="number" width="100px" :error="errors.set_count" @input="handleNumberInput($event, 'set_count')" >
              <span class="unit-margin">{{ $t("lot") }}</span>
            </MstTextField>
          </template>
          <template v-else>
            <span class="read-label">{{ harvest.set_count }}{{ $t("lot") }}</span>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("shipping_date") }}</p>
        </div>
        <div class="col-8">
          <template v-if="isEdit">
            <MstDatePicker v-model="harvest.harvest_date" :error="errors.harvest_date" :min="harvest_min_date" @input="handleHarvestDateInput($event)" />
          </template>
          <template v-else>
            <span class="read-label">{{ formattedDate(harvest.harvest_date) }}</span>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p :class="[isEdit ? 'price-label' : 'label-item']">{{ $t("price_unit") }}</p>
        </div>
        <div class="col-8">
          <template v-if="isEdit">
            <MstTextField v-model="harvest.price" type="number" width="100px" :error="errors.price" @input="handleNumberInput($event, 'price')" >
              <span class="unit-margin">{{ $t("yen") }}</span>
            </MstTextField>
             <span v-if="harvest.recommended_min_price || harvest.recommended_max_price" class="price-recommend">
               {{ $t("recommend_price") }} ¥{{ harvest.recommended_min_price }} ~ ¥{{ harvest.recommended_max_price }}
             </span>
          </template>
          <template v-else>
            <span class="read-label">{{ harvest.price }}{{ $t("yen") }}</span>
          </template>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-4">
          <p class="label-item">{{ $t("product_description") }}</p>
        </div>
        <div class="col-8">
          <template v-if="isEdit">
            <MstTextArea v-model="harvest.note" @input="$emit($event, 'not')" />
          </template>
          <template v-else>
            <span class="read-label">{{ harvest.note }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MstSelectBox, MstTextArea, MstTextField, MstDatePicker } from "@/components/master";
import { formattedJPDate, nextDateKey } from "@/util/date";

export default {
  components: {
    MstDatePicker,
    MstSelectBox,
    MstTextField,
    MstTextArea
  },
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    flowerHarvest: Object,
    genusOptions: Array,
    flowerOptions: Array,
    flowerOptionsHash: Object,
  },
  data() {
    return {
      harvest: this.flowerHarvest,
      errors: {
        stem_size: "",
        min_count: "",
        set_count: "",
        price: "",
        harvest_date: "",
      },
      isVisible: false,
      searchResult: this.flowerOptions
    };
  },
  methods: {
    handleFlowerGenusChange(value) {
      this.searchResult = this.flowerOptionsHash[value];
      this.$emit("on-change", value, "flower_genus_id");
    },
    handleHarvestDateInput(date) {
      this.errors.harvest_date = "";
      if (new Date(date) < new Date(nextDateKey("", 1, this.allowWdayList))) this.errors.harvest_date = this.$t("harvest_date_error_msg");
      this.$emit("on-change", nextDateKey(date, 1, this.allowWdayList), "harvest_date");
    },
    handleNumberInput(value, key) {
      this.errors[key] = "";
      if (Number(value) < 0) {
        this.errors[key] = this.$t("harvest_number_error_msg");
        value = 0;
      } else {
        const max_length = key === "stem_size" ? 7 : 10;
        const max_length_over_error = key === "stem_size" ? "harvest_over_float_error_msg" : "harvest_over_int_error_msg";

        if (Number(value).toString().length >= max_length) {
          this.errors[key] = this.$t(max_length_over_error);
          value = 10 ** max_length - 1;
        }
      }
      if (key === "set_count" && Number(value) === 0 && value) {
        this.$emit("on-change", 0, key);
      } else if (key === "set_count" && Number(value) < Number(this.harvest.bought_count)) {
        this.errors[key] = `売れた口数以上の値を設定して下さい。売れた口数：${this.harvest.bought_count}`;
      } else {
        this.$emit("on-change", Number(value) || "", key);
      }
    },
    handleFlowerSpecieChange(value) {
      const flower = this.flowerOptions[this.flowerOptions.findIndex(item => item.value === value)];
      if (flower) {
        this.$emit("on-change", flower.flower_specie.name_furigana, "flower_specie");
        this.$emit("on-change", flower.flower_specie.id, "specie_id");
        this.$emit("on-change", flower.category.id, "category_id");
        this.$emit("on-change", value, "flower_id");
        this.isVisible = false;
      }
    },
    formattedDate(value) {
      return formattedJPDate(value);
    },
    onChangeSearch(e) {
      this.searchResult = this.flowerOptions.filter(item => item.flower_specie.name_furigana.includes(e));
      const flowerSpecieIdx = this.flowerOptions.findIndex(item => item.flower_specie.name_furigana === e);
      let flowerId = "";
      if (flowerSpecieIdx >= 0) flowerId = this.flowerOptions[flowerSpecieIdx].value;
      this.$emit("on-change", flowerId, "flower_id");
      this.$emit("on-change", e, "flower_specie");
    },
    setVisible() {
      this.isVisible = true;
    },
    loseFocus() {
      setTimeout(() => {
        this.isVisible = false;
      }, 300);
    },
  },
  computed: {
    allowWdayList() {
      return this.$store.getters.workingWdays;
    },
    genusName() {
      let name = "";
      const genusOptionIdx = this.genusOptions.findIndex(item => item.value === this.harvest.flower_genus_id);
      if (genusOptionIdx >= 0) name = this.genusOptions[genusOptionIdx].label;
      return name;
    },
    specieName() {
      let name = "";
      const flowerOptionIdx = this.flowerOptions.findIndex(item => item.value === this.harvest.flower_id);
      if (flowerOptionIdx >= 0) name = this.flowerOptions[flowerOptionIdx].flowerSpecie.name;
      return name;
    },
    harvest_min_date() {
      const dateObj = new Date();
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const day = String(dateObj.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.label-item {
  margin: 4px auto 4px 0;
  color: #727272;
}
.unit-margin {
  margin: 4px 8px;
  width: 15px;
}
.read-label {
  vertical-align: sub;
}
.price-label {
  margin-top: 4px;
  color: #727272;
}
.price-recommend {
  white-space: nowrap;
  font-size: 14px;
}
.searchBox__inner {
  position: relative;
  cursor: pointer;
}
.searchBox__container {
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 4px 0;
  max-width: 100%;
  max-height: 202px;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1;
}
</style>
