<template>
  <component ref="root" :is="tag" class="mstIntersection">
    <slot>
      <div class="mstIntersection__empty" />
    </slot>
  </component>
</template>

<script>
export default {
  name: "MstIntersection",
  props: {
    tag: { type: String, default: "div" },
    rootMargin: { type: String, default: "0px" },
    threshold: { type: Number, default: 0 },
    disabled: { type: Boolean },
  },
  computed: {
    observer() {
      return new IntersectionObserver(this.handleIntersection, {
        rootMargin: this.rootMargin,
        threshold: this.threshold,
      });
    },
  },
  mounted() {
    this.observer.observe(this.$refs.root);
  },
  methods: {
    handleIntersection(entries) {
      if (!entries.length || this.disabled) return;
      this.$emit("intersect", entries[0].isIntersecting);
    },
  },
};
</script>

<style lang="scss" scoped>
.mstIntersection__empty {
  width: 100%;
  height: 40px;
}
</style>
