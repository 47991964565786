<template>
  <!-- eslint-disable -->

  <svg
    class="svg-icon"
    :height="size"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="color"
      d="M511.99079 64.456011c-247.170402 0-447.542966 200.372563-447.542966 447.538873 0 247.180636 200.372563 447.548082 447.542966 447.548082 247.188822 0 447.560362-200.36847 447.560362-447.548082C959.551152 264.827551 759.184729 64.456011 511.99079 64.456011L511.99079 64.456011zM511.99079 925.117922c-228.161467 0-413.118945-184.945199-413.118945-413.124062 0-228.157373 184.956456-413.114852 413.118945-413.114852 228.158397 0 413.132248 184.956456 413.132248 413.114852C925.127132 740.173746 740.154303 925.117922 511.99079 925.117922L511.99079 925.117922zM700.423417 684.868139c0 11.685133-21.190625 21.190625-21.190625 21.190625L340.382341 706.058764c0 0-21.171182-9.455349-21.171182-21.190625 0-87.174432 58.594513-160.544463 138.490168-183.210695-32.084743-18.190291-53.776787-52.592822-53.776787-92.117001 0-58.488089 47.433312-105.905028 105.921401-105.905028 58.487066 0 105.884562 47.415916 105.884562 105.905028 0 39.524179-21.696138 73.92671-53.779857 92.117001C641.845277 524.322653 700.423417 597.693708 700.423417 684.868139L700.423417 684.868139z"
    />
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>

<style>
</style>
