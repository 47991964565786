<template>
  <!-- eslint-disable -->

  <svg xmlns="http://www.w3.org/2000/svg" :width="size" viewBox="0 0 16 16.449">
    <path
      d="M17.519,12.03a5.671,5.671,0,0,0,0-1.612l1.735-1.357a.415.415,0,0,0,.1-.526L17.708,5.689a.413.413,0,0,0-.5-.181l-2.048.822a6.009,6.009,0,0,0-1.39-.806l-.313-2.179a.4.4,0,0,0-.4-.345H9.763a.4.4,0,0,0-.4.345L9.047,5.525a6.319,6.319,0,0,0-1.39.806L5.61,5.508a.4.4,0,0,0-.5.181L3.463,8.535a.405.405,0,0,0,.1.526L5.3,10.418a6.522,6.522,0,0,0-.058.806,6.522,6.522,0,0,0,.058.806L3.562,13.387a.415.415,0,0,0-.1.526l1.645,2.846a.413.413,0,0,0,.5.181l2.048-.822a6.009,6.009,0,0,0,1.39.806L9.36,19.1a.4.4,0,0,0,.4.345h3.29a.4.4,0,0,0,.4-.345l.313-2.179a6.319,6.319,0,0,0,1.39-.806l2.048.822a.4.4,0,0,0,.5-.181l1.645-2.846a.415.415,0,0,0-.1-.526L17.519,12.03ZM11.408,14.1a2.879,2.879,0,1,1,2.879-2.879A2.882,2.882,0,0,1,11.408,14.1Z"
      transform="translate(-3.406 -3)"
      :fill="color"
    />
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: String,
      default: "16"
    }
  }
};
</script>

<style>
</style>
