<template>
  <!-- eslint-disable -->
  <svg :width="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6667 13.1111H11.6811M20.3333 13.1111H20.3478M3 16C3 17.7072 3.33625 19.3977 3.98957 20.9749C4.64288 22.5521 5.60045 23.9852 6.80761 25.1924C8.01477 26.3995 9.44788 27.3571 11.0251 28.0104C12.6023 28.6637 14.2928 29 16 29C17.7072 29 19.3977 28.6637 20.9749 28.0104C22.5521 27.3571 23.9852 26.3995 25.1924 25.1924C26.3995 23.9852 27.3571 22.5521 28.0104 20.9749C28.6637 19.3977 29 17.7072 29 16C29 14.2928 28.6637 12.6023 28.0104 11.0251C27.3571 9.44788 26.3995 8.01477 25.1924 6.80761C23.9852 5.60045 22.5521 4.64288 20.9749 3.98957C19.3977 3.33626 17.7072 3 16 3C14.2928 3 12.6023 3.33626 11.0251 3.98957C9.44788 4.64288 8.01477 5.60045 6.80761 6.80761C5.60045 8.01477 4.64288 9.44788 3.98957 11.0251C3.33625 12.6023 3 14.2928 3 16Z" :stroke="color" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.3889 20.6951C12.8596 20.2147 13.4214 19.8331 14.0415 19.5725C14.6616 19.3119 15.3274 19.1777 16 19.1777C16.6726 19.1777 17.3384 19.3119 17.9584 19.5725C18.5785 19.8331 19.1404 20.2147 19.6111 20.6951" :stroke="color" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>

<style>
</style>
