<template>
  <login type="Driver" />
</template>

<script>
import Login from "@/components/auth/Login.vue";

export default {
  components: {
    Login
  }
};
</script>

<style>
</style>
