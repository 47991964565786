<template>
  <!-- eslint-disable -->
  
  <svg :height="size" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M21.1369 19.5859C20.585 18.2387 19.7842 17.015 18.7789 15.9829C17.7768 14.9479 16.5896 14.1226 15.283 13.5528C15.2712 13.5467 15.2595 13.5437 15.2478 13.5377C17.0704 12.1809 18.2553 9.97085 18.2553 7.47739C18.2553 3.34673 15.008 0 11 0C6.99205 0 3.74473 3.34673 3.74473 7.47739C3.74473 9.97085 4.92956 12.1809 6.75216 13.5407C6.74045 13.5467 6.72875 13.5497 6.71705 13.5558C5.40642 14.1256 4.23036 14.9427 3.22106 15.9859C2.21676 17.0188 1.41603 18.2423 0.863099 19.5889C0.319897 20.9073 0.026935 22.3207 7.31554e-05 23.7528C-0.000707679 23.7849 0.00476896 23.817 0.0161805 23.8469C0.0275921 23.8769 0.0447076 23.9042 0.0665186 23.9272C0.0883295 23.9503 0.114395 23.9686 0.143178 23.9811C0.171961 23.9936 0.202881 24 0.234114 24H1.98942C2.11814 24 2.22054 23.8945 2.22346 23.7648C2.28197 21.4372 3.18888 19.2573 4.79206 17.605C6.45083 15.8955 8.65374 14.9548 11 14.9548C13.3463 14.9548 15.5492 15.8955 17.2079 17.605C18.8111 19.2573 19.718 21.4372 19.7765 23.7648C19.7795 23.8975 19.8819 24 20.0106 24H21.7659C21.7971 24 21.828 23.9936 21.8568 23.9811C21.8856 23.9686 21.9117 23.9503 21.9335 23.9272C21.9553 23.9042 21.9724 23.8769 21.9838 23.8469C21.9952 23.817 22.0007 23.7849 21.9999 23.7528C21.9707 22.3116 21.681 20.9095 21.1369 19.5859ZM11 12.6633C9.65719 12.6633 8.39337 12.1236 7.44258 11.1437C6.49179 10.1638 5.96812 8.86131 5.96812 7.47739C5.96812 6.09347 6.49179 4.79095 7.44258 3.81106C8.39337 2.83116 9.65719 2.29146 11 2.29146C12.3428 2.29146 13.6066 2.83116 14.5574 3.81106C15.5082 4.79095 16.0319 6.09347 16.0319 7.47739C16.0319 8.86131 15.5082 10.1638 14.5574 11.1437C13.6066 12.1236 12.3428 12.6633 11 12.6633Z" 
      :fill="color"/>
  </svg>
  
  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>

<style scoped>

</style>
