<template>
  <div>
    <header class="shop-header d-flex">
      <Drawer class="drawer hidden-md-and-up" />

      <router-link class="align-self-center logo-link" to="/shop/products">
        <img :src="require('@/assets/CAVIN_Logo_black.png')" :style="`width: ${mobile || tablet ? '80px' : '120px'}`" />
      </router-link>

      <div class="hidden-sm-and-down" style="visibility: hidden"><v-icon x-large>mdi-account-circle</v-icon></div>

      <div class="right hidden-sm-and-down">
        <router-link
          class="tab"
          :class="{selected: isProductPage}"
          to="/shop/products"
        >{{ $t("shopProducts") }}</router-link>

        <router-link
          class="tab"
          :class="{selected: isShopInvoicePage}"
          to="/shop/pdf_picker"
        >{{ $t("shop_invoice") }}</router-link>

        <router-link
          class="tab"
          :class="{selected: isOrderPage}"
          to="/shop/orders"
        >{{ $t("shopOrders") }}</router-link>

        <router-link
          class="tab"
          :class="{selected: isCollectionPage}"
          to="/shop/collections"
        >{{ $t("shopCollections") }}</router-link>

         <Button class="message" color="white-borderless" to="/shop/conversations">
          <v-icon class="mr-3">mdi-email</v-icon>
          <span>{{ $t("message") }}</span>
          <div v-if="unreadCount" class="badge">{{ unreadCount }}</div>
        </Button>

        <div class="menu" ref="menu">
          <div
            color="white"
            size="none"
            class="d-flex justify-center align-center menu-button"
            @click="menuOpened = !menuOpened"
          >
            <EllipsisIcon />
          </div>
          <div class="menu-list" v-show="menuOpened" @click="menuOpened = false">
            <a class="menu-item" :href="officialLineUrl" target="_blank">
              <QuestionCircleIcon class="menu-icon" color="currentColor" width="16" height="16" />{{ $t("line_support") }}
            </a>
            <div class="menu-item" @click="logout">
              <LogoutIcon class="menu-icon" />ログアウト
            </div>
          </div>
        </div>

        <Button color="white" size="big" class="cart" to="/shop/cart">カートの中を見る ({{ draftCount }})</Button>
      </div>
    </header>
  </div>
</template>

<script>
import Button from "@/components/shop/Button.vue";
import Drawer from "@/components/shop/Drawer.vue";
import EllipsisIcon from "@/components/icons/EllipsisIcon.vue";
import LogoutIcon from "@/components/icons/LogoutIcon.vue";
import QuestionCircleIcon from "@/components/icons/QuestionCircleIcon.vue";

export default {
  components: {
    Button,
    Drawer,
    EllipsisIcon,
    LogoutIcon,
    QuestionCircleIcon
  },

  data() {
    return {
      menuOpened: false
    };
  },

  computed: {
    isProductPage() {
      return this.$route.path.startsWith("/shop/products");
    },
    isShopInvoicePage() {
      return this.$route.path.startsWith("/shop/pdf_picker");
    },
    isOrderPage() {
      return this.$route.path.startsWith("/shop/orders");
    },
    isCollectionPage() {
      return this.$route.path.startsWith("/shop/collections");
    },
    isConversationPage() {
      return this.$route.path.startsWith("/shop/conversations");
    },
    draftCount() {
      return this.$store.getters["orders/draftCount"];
    },
    unreadCount() {
      return this.$store.getters["conversations/unreadCount"];
    },
    officialLineUrl() {
      return process.env.VUE_APP_X_OFFICIAL_SHOP_LINE_URL;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    tablet() {
      return this.$vuetify.breakpoint.sm;
    }
  },

  created() {
    this.$store.dispatch("orders/getDraftOrder");
  },

  mounted() {
    this.$store.dispatch("conversations/getUnreadMessagesCount");
    window.addEventListener("mousedown", this.onOutsideClick);
  },

  beforeDestroy() {
    window.removeEventListener("mousedown", this.onOutsideClick);
  },

  methods: {
    onOutsideClick(e) {
      if (!this.$refs.menu.contains(e.target)) {
        this.menuOpened = false;
      }
    },

    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
svg {
  display: block;
}

.shop-header {
  height: 50px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #0000001a;
  white-space: nowrap;
  justify-content: space-between;
}

.right {
  display: flex;
}

.tab {
  margin-right: 40px;
  display: flex;
  align-items: center;
  color: #0c1624;
  font-size: 18px;
  border-top: 3px transparent solid;
  border-bottom: 3px transparent solid;
  cursor: pointer;

  &.selected {
    color: #007e57;
    border-bottom-color: #007e57;
  }
}

.badge {
  position: absolute;
  right: -6px;
  top: -6px;
  width: 24px;
  line-height: 24px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  background-color: #e38c00;
  border-radius: 50%;
  box-shadow: 0px 1px 3px #00000029;
}

.message {
  margin-right: 40px;
  align-self: center;
}

.menu {
  margin-right: 20px;
  position: relative;
  align-self: center;

  .menu-button {
    height: 40px;
    width: 40px;
  }

  .menu-list {
    position: absolute;
    bottom: -4px;
    right: -40px;
    transform: translateY(100%);
    padding: 8px 0;
    background-color: #fff;
    box-shadow: 0px 3px 6px #0000001a;
    border: #ccc 1px solid;
    border-radius: 4px;
    z-index: 1;
  }

  .menu-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #555;
    font-size: 16px;
    padding: 8px 35px 8px 16px;
  }

  .menu-icon {
    color: #777;
    margin-right: 10px;
  }
}

.cart {
  border-radius: 0;
  text-align: center;
}

.cart-sp {
  min-width: 70px;
  border-radius: 0;
  font-size: 8px;
}

.drawer {
  position: absolute;
  left: 0;
}

@media only screen and (min-width: 600px) {
  .logo-link {
    margin-left: 8px;
  }
}

@media only screen and (max-width: 600px) {
  .shop-header {
    justify-content: center;
    align-items: center;
  }
}
</style>
