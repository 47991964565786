<template>
  <div :class="`${mobile ? 'page-contents' : 'page-contents-pc'}`">
    <img class="fill" src="@/assets/thanks.png" />
    <div class="link-corp">
      <p class="link">関連リンク</p>
      <a href="https://cavin.ooo/" target="_brank">
        <img class="fill" src="@/assets/ogp_corp.png" />
      </a>
      <div class="mx-4 my-8">
        <MstButton @click="logout" outlined>{{ $t("logout") }}</MstButton>
      </div>
    </div>
    <p class="mark">
      (c) CAVIN Inc. All Rights Reserved.
    </p>
  </div>
</template>

<script>
import { MstButton } from "@/components/master";

export default {
  components: { MstButton },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    }
  },
  methods: {
    logout() {
      this.$router.push("/").then(() => {
        this.$store.dispatch("logout");
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.page-contents {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .fill {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .link {
    color: #585858;
    font-size:14px;
    font-weight: 600;
    margin: 16px;
    padding-bottom: 2px;
    border-bottom: 2px solid #D4D7D9
  }

  .link-corp {
    margin-bottom: 40px;
  }

  .mark {
    position: absolute;
    color: #191D38;
    font-size: 10px;
    padding: 4px 15px;
    bottom: 0;
  }
}
.page-contents-pc {
  margin: auto;
  padding: 0;
  padding-top: 16px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  background-color: white;

  .fill {
    display: block;
    max-width: 600px;
    height: auto;
    object-fit: contain;
  }

  .link {
    color: #585858;
    font-size:20px;
    font-weight: 600;
    margin: 24px;
    text-align: left;
    padding-bottom: 2px;
    border-bottom: 2px solid #D4D7D9
  }

  .link-corp {
    margin-bottom: 40px;
  }

  .mark {
    position: absolute;
    color: #191D38;
    font-size: 14px;
    padding: 4px 15px;
    bottom: 0;
  }
}
</style>
