<template>
  <v-container class="wrapper">
    <div class="title-wrapper">
      <div class="title">売上金額（請求書）</div>
      <div v-if="isSupportedInvoiceSystem" class="description">売上明細書が発行されましたら内容をご確認の上、承認をお願いいたします。</div>
    </div>
    <v-row class="mx-1 mb-4">
      <v-col cols="6">
        <v-select
          class="my-2"
          v-model="targetYear"
          :items="targetYearOptions"
          style="width: 200px"
          solo
          hide-details
        />
      </v-col>
      <v-col cols="6" class="d-flex justify-end align-center">
        <button
          class="download-button"
          v-if="targetYear && invoices.length > 0"
          @click="getSalesInvoicePdfList"
        >
          {{ loading ? "ダウンロード中です" : "まとめてダウンロード" }}
        </button>
      </v-col>
    </v-row>

    <div class="invoice-list">
      <div
        class="invoice-wrapper"
        v-for="(invoice, index) in invoices" :key="index"
        @click="openPdf(invoice.start_date, invoice.end_date)"
        v-bind:disabled="invoice.total_harvest_price_with_tax === 0"
        v-bind:class="{disabled: invoice.total_harvest_price_with_tax === 0}"
      >
        <div>
          <div class="invoice-period-wrapper">
            <div class="invoice-period">{{ format(invoice.start_date) }} ~ {{ format(invoice.end_date) }}</div>
            <div v-if="invoice.total_harvest_price_with_tax === 0" class="no-invoice">
              取引なし
            </div>
          </div>
          <div class="invoice-total-price">¥ {{ invoice.total_harvest_price_with_tax }}</div>
        </div>
        <div v-if="isSupportedInvoiceSystem && !!invoice.id" class="buttons-wrapper">
          <div v-if="invoice.approved" class="approved">承認済み</div>
          <button
            v-else
            class="approval-modal-open-button"
            @click="onClickApprovalModalOpenButton($event, invoice.id)"
          >
            内容を承認
          </button>
        </div>
      </div>
    </div>

    <div v-if="isShowApprovalModal" class="modal">
      <div class="modal-content">
        <div class="dialog-card">
          <img class="card-logo" :src="require('@/assets/harvest_confirm.svg')" alt="Harvest Confirm">
          <p class="dialog-header">請求書の内容を承認しますか？</p>
          <p class="dialog-text">承認すると、適格請求書として発行できます。</p>
          <div class="buttons-wrapper">
            <button @click="onClickCancelButton" class="cancel-button">キャンセル</button>
            <button @click="onClickApproveButton" class="approval-button">承認する</button>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { formatDate } from "@/plugins/i18n";

export default {
  data() {
    return {
      params: "",
      targetYear: 0,
      targetYearOptions: [],
      selectedInvoiceId: "",
      invoices: [],
      loading: false,
      isShowApprovalModal: false,
    };
  },

  watch: {
    targetYear() {
      this.getPdfDataList();
    }
  },

  async created() {
    const d = new Date();
    this.targetYear = d.getFullYear();
    this.getPdfDataList();
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    tablet() {
      return this.$vuetify.breakpoint.sm;
    },
    isSupportedInvoiceSystem() {
      return this.$store.getters.isSupportedInvoiceSystem;
    },
  },

  methods: {
    format(date) {
      const d = new Date(date);
      return formatDate(d, "m月d日");
    },
    async openPdf(start_date, end_date) {
      const res = await this.$http2("get", `farm/invoices/sales_invoice_pdf.pdf?start_date=${start_date}&end_date=${end_date}`, { responseType: "blob" });
      const blob = new Blob([res], { type: "application/pdf" });
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl, "_blank");
    },
    async onClickApproveButton() {
      if (!this.selectedInvoiceId) return;

      const res = await this.$http2("put", `farm/invoices/${this.selectedInvoiceId}/approve`);
      this.isShowApprovalModal = false;
      if (res.status !== 204) {
        alert("承認に失敗しました");
        return;
      }

      this.getPdfDataList();
    },
    async onClickApprovalModalOpenButton(event, id) {
      event.stopPropagation();

      this.selectedInvoiceId = id;
      this.isShowApprovalModal = true;
    },
    async onClickCancelButton() {
      this.selectedInvoiceId = "";
      this.isShowApprovalModal = false;
    },
    async getPdfDataList() {
      const res = await this.$http2("get", `farm/invoices?year=${this.targetYear}`);

      const options = [];
      res.result.year_options.forEach(year => {
        options.push({
          text: `${year}年`,
          value: year
        });
      });

      this.invoices = res.result.invoice_list;
      this.targetYearOptions = options;
    },
    async getSalesInvoicePdfList() {
      this.loading = true;

      const res = await this.$http2("get", `farm/invoices/sales_invoice_pdf_list?year=${this.targetYear}`, { responseType: "blob" });
      const blob = new Blob([res], { type: "application/zip" });
      const objectUrl = URL.createObjectURL(blob);
      this.zipsrc = objectUrl;
      window.location.href = this.zipsrc;

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>

.wrapper {
  position: relative;
  max-width: variables.$container-max-width;

  .title-wrapper {
    margin-top: 25px;
    margin-bottom: 18px;
    .title {
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
    }
    .description {
      margin-top: 16px;
      font-size: 14px;
      font-weight: 400;
      margin: 0 15px;
    }
  }

  .download-button {
    width: 184px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-weight: 600;
    color: #173169;
    border-radius: 4px;
    border: 1px solid #173169;
  }
  .invoice-list {
    margin: 0 16px;
    .disabled {
      pointer-events: none;
    }
    .invoice-wrapper {
      cursor: pointer;
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #D4D7D9;
      .invoice-period-wrapper {
        display: flex;
        line-height: 23px;
        .invoice-period {
          font-size: 14px;
          color: #68727B;
          font-weight: 600;
        }

        .no-invoice {
          margin-left: 8px;
          color: #ffffff;
          width: 56px;
          height: 23px;
          text-align: center;
          border-radius: 4px;
          background: #A9AFB3;
          font-weight: 400;
          font-size: 12px;
        }
      }

      .invoice-total-price {
        font-size: 16px;
        line-height: 19px;
        color: #191D38;
        font-weight: 600;
      }

      .buttons-wrapper {
        display: flex;
        align-items: center;
        .approval-modal-open-button {
          border-radius: 4px;
          padding: 0 8px;
          font-size: 12px;
          height: 31px;
          line-height: 31px;
          color: #FFFFFF;
          background: #173169;
          font-weight: 600;
        }

        .approved {
          border-radius: 4px;
          padding: 0 8px;
          font-size: 12px;
          height: 31px;
          line-height: 31px;
          color: #FFFFFF;
          background: #A9AFB3;
          font-weight: 600;
        }
      }
    }
  }
  .modal {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    .modal-content {
      background-color: #fefefe;
      border: 0 solid #888;
      border-radius: 8px;
      max-width: 344px;
      .dialog-card {
        padding: 20px 51px;
        display: flex;
        flex-direction: column;
        width: 344px;
        margin: auto;
      }
      .card-logo {
        width: 42px;
        height: 46.67px;
        margin: auto;
        margin-top: 12px;
        margin-bottom: 12px;
      }
      .dialog-header {
        margin: auto;
        margin-top: 0;
        margin-bottom: 12px;
        font-weight: 700;
      }
      .dialog-text {
        margin-bottom: 28px;
        font-size: 16px;
      }
      .buttons-wrapper {
        display: flex;
        gap: 8px;
        .cancel-button {
          font-weight: 400;
          width: 120px;
          text-align: center;
          height: 48px;
          line-height: 48px;
          border-radius: 4px;
          background: #FFFFFF;
          border: 1px solid #173169;
        }
        .approval-button {
          font-weight: 400;
          width: 120px;
          text-align: center;
          height: 48px;
          line-height: 48px;
          border-radius: 4px;
          background: #173169;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
