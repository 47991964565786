<template>
  <div>
    <v-container fluid>
<!--      <alert v-if="!agreeTerms">-->
<!--        <template v-slot:contents>-->
<!--          <div class="text-body-1">-->
<!--            2024年1月16日、<u><a class="d-inline-flex align-baseline" :href="`${publicPath}invoice-guide.pdf`" target="_blank">-->
<!--            インボイス制度に伴うガイドライン<v-icon color="#2196f3" small>mdi-open-in-new</v-icon></a></u>を公開しました。-->
<!--          </div>-->
<!--          <div class="text-body-1">内容をご確認いただき同意される場合は、チェックボックスにチェックを入れて「利用する」ボタンを押してください。</div>-->
<!--        </template>-->
<!--        <template v-slot:actions>-->
<!--          <div class="d-flex flex-column">-->
<!--            <v-checkbox label="ガイドラインを確認しました。" v-model="agree" class="ma-0" hide-details />-->
<!--            <v-btn :disabled="!agree" color="info" outlined class="mt-4" @click="agreetment">利用する</v-btn>-->
<!--          </div>-->
<!--        </template>-->
<!--      </alert>-->
      <div class="alerts">
        <div class="my-3 text-xl text-center">
          <p class="mb-0">2024年11月7日に</p>
          <p class="mb-0">以下のURLに移行しました</p>
        </div>

        <div class="my-3 text-xl text-center">
          <p class="mb-0">11月7日以降は新ページから</p>
          <p class="mb-0">商品掲載・出荷をお願いします</p>
        </div>

        <a href="https://cavin.farm" class="my-3 text-xl red--text text-center">
          <p class="mb-0">CAVINの新ページはこちら</p>
          <p class="mb-0">https://cavin.farm</p>
        </a>

        <div class="my-2 text-xs text-left">
          <p class="mb-0">これまでと同じメールアドレス、パスワードで</p>
          <p class="mb-0">ログインが可能です</p>
        </div>

        <div class="my-2 text-xs text-left">
          <p class="mb-0">パスワードがご不明な場合は、上記ページの</p>
          <p class="mb-0">パスワード再設定から再設定をおこなってください</p>
        </div>

        <div class="my-2 mb-5 text-xs text-left">
          <p class="mb-0">過去の出品・出荷は、このページのメニュー</p>
          <p class="mb-0">からご確認ください</p>
        </div>
      </div>
      <div v-if="data.harvestsCount > 0">
        <h2 class="text-center ma-4">{{ $t("farmDashboard") }}</h2>
        <div class="link-group">
          <MstLinkBar
            icon="truck-cargo-container"
            :to="todayOrderItemLink"
            :label="$t('schedule_ship_today')(data.orderItemsLotCountToday)"
          />
          <MstLinkBar
            icon="truck-cargo-container"
            :to="confirmedOrderLink"
            :label="$t('new_orders')(data.confirmedOrderItemsLotCount)"
          />
          <MstLinkBar
            icon="truck-cargo-container"
            :to="requestOrderItemLink"
            :label="$t('new_reservations')(data.requestOrderItemsLotCount)"
          />
        </div>
        <div class="link-group">
          <MstLinkBar icon="text-box" to="/farm/shipping_results" :label="$t('show_shipping_results')" />
          <MstLinkBar icon="chart-line" to="/farm/pdf_picker" :label="$t('download_report')" />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Alert from "@/components/shared/Alert.vue";
import { MstLinkBar } from "@/components/master";
import dayjs from "dayjs";
import "dayjs/locale/ja";

dayjs.locale("ja");

export default {
  components: {
    MstLinkBar,
    Alert
  },
  data() {
    return {
      agree: false,
      publicPath: process.env.BASE_URL,
      todayOrderItemLink: "",
      confirmedOrderLink: "",
      requestOrderItemLink: "",
    };
  },
  computed: {
    data() {
      return this.$store.state.farmDashboard;
    },
    agreeTerms() {
      return this.$store.state.auth.agree_terms;
    },
    todayOrderItemLinkTo() {
      let base = "";
      if (this.data.orderItemsLotCountToday > 0) {
        base = `/farm/order_items?status=unshipped&date=${dayjs().format("YYYY-MM-DD")}`;
      }

      return base;
    },
    confirmedOrderLinkTo() {
      let base = "";
      if (this.data.confirmedOrderItemsLotCount > 0) {
        base = "/farm/order_items?status=unshipped";
        if (this.data.comingShippingDate) {
          base += `&date=${this.data.comingShippingDate}`;
        }
      }

      return base;
    },
    requestOrderItemLinkTo() {
      let base = "";
      if (this.data.requestOrderItemsLotCount > 0) {
        base = "/farm/order_items?status=request";
      }

      return base;
    }
  },

  async created() {
    await this.$store.dispatch("getDashboardData");
    this.todayOrderItemLink = this.todayOrderItemLinkTo;
    this.confirmedOrderLink = this.confirmedOrderLinkTo;
    this.requestOrderItemLink = this.requestOrderItemLinkTo;
  },

  methods: {
    async agreetment() {
      const res = await this.$http("put", "users/agree_terms");
      if (res.status === 200) { this.$store.dispatch("agreement"); }
    }
  }
};
</script>

<style lang="scss" scoped>
.link-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 40px;
}

.container {
  max-width: variables.$container-max-width;
}

.alerts {
  display: grid;
  grid-gap: 12px;
  background-color: #cccccc;
  margin: -12px;
  padding: 12px 50px;
}
</style>
