const getDefaultLocation = locations => {
  if (typeof locations === "undefined") {
    return null;
  }

  for (let i = 0; i < locations.length; i++) {
    const location = locations[i];
    if (location.default) {
      return location;
    }
  }
  return null;
};

export default getDefaultLocation;
