/**
 * @typedef UnuseableAreaUsers
 * @type {object}
 * @property {number} id
 * @property {string} prefecture
 * @property {string} municipality
 * @property {string} address
 * @property {string} building_name
 * @property {string} name
 * @property {string} phone
 * @property {string} email
 * @property {string} postal_code
 * @property {string} representative_name
 * @property {string} user_type
 * @property {string} note
 */

export default {
  namespaced: true,
  state: {
    id: null,
    postal_code: "",
    prefecture_id: null,
    municipality: "",
    address: "",
    building_name: "",
    phone: "",
    email: "",
    name: "",
    representative_name: "",
    note: "",
    user_type: "",
    area_id: null
  },
  mutations: {
    setAddress(state, payload) {
      state.user_type = payload.user_type;
      state.postal_code = payload.postal_code;
      state.prefecture_id = payload.prefecture_id;
      state.municipality = payload.municipality;
      state.address = payload.address;
      state.area_id = payload.area_id;
    }
  },
  actions: {
    getIsAvailableArea({ commit }, { payload }) {
      return new Promise(resolve => {
        this._vm.$api
          .get(`is_available_area?user_type=${payload.user_type}&postal_code=${payload.postal_code}`, {
            headers: {
              X_CLIENT_ID: process.env.VUE_APP_X_CLIENT_ID,
              X_CLIENT_SECRET: process.env.VUE_APP_X_CLIENT_SECRET
            }
          })
          .then(res => {
            payload.area_id = res.result.area_id;
            commit("setAddress", payload);
            resolve(res);
          });
      });
    },
    postUnuseableAreaUser({}, payload) {
      return new Promise(resolve => {
        const data = {
          unuseable_user: {
            postal_code: payload.postal_code,
            prefecture_id: payload.prefecture_id,
            municipality: payload.municipality,
            address: payload.address,
            building_name: payload.building_name,
            phone: payload.phone,
            email: payload.email,
            name: payload.name,
            representative_name: payload.representative_name,
            note: payload.note,
            user_type: payload.user_type
          }
        };

        this._vm.$api
          .post("unuseable_users", data, {
            headers: {
              X_CLIENT_ID: process.env.VUE_APP_X_CLIENT_ID,
              X_CLIENT_SECRET: process.env.VUE_APP_X_CLIENT_SECRET
            }
          })
          .then(res => {
            if (res.status === 200) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      });
    }
  },
  getters: {
    getState(state) {
      return state;
    },
    getPostalCode: state => state.list.postal_code
  }
};
