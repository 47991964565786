<template>
  <div :class="styledClasses" class="mstCounterButton font-weight-bold">
    <button
      :disabled="disabled || (min !== undefined && min >= value)"
      type="button"
      class="mstCounterButton__button -decrease"
      @click="handleChange(-1)"
    >
      -
    </button>
    <span class="mstCounterButton__value">{{ value }}</span>
    <button
      :disabled="disabled || (max !== undefined && max <= value)"
      type="button"
      class="mstCounterButton__button  -increase"
      @click="handleChange(1)"
    >
      +
    </button>
  </div>
</template>

<script>
export default {
  name: "MstCounterButton",
  model: {
    event: "change",
  },
  props: {
    value: { type: Number, required: true },
    min: { type: Number, default: 0 },
    max: { type: Number },
    size: { type: String, validator: value => ["sm", "md"].includes(value), default: "md" },
    disabled: { type: Boolean },
  },
  computed: {
    styledClasses() {
      const classes = [];
      classes.push(`-${this.size}`);
      return classes;
    },
  },
  methods: {
    handleChange(value) {
      this.$emit("change", this.value + value);
    },
  },
};
</script>

<style lang="scss" scoped>
.mstCounterButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 4px;
}

.mstCounterButton__button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid currentColor;
  background: variables.$color-white;
  line-height: 1;
  color: variables.$color-brand-primary;
  transition: color 0.3s ease, background 0.3s ease;

  &.-decrease {
    border-radius: 5px 0 0 5px;
  }

  &.-increase {
    border-radius: 0 5px 5px 0;
  }

  &:hover,
  &:focus {
    background: variables.$color-gray-50;
  }

  &:disabled {
    color: variables.$color-gray-300;
    cursor: not-allowed;

    &:hover,
    &:focus {
      background: variables.$color-white;
    }
  }
}

.mstCounterButton__value {
  color: variables.$color-black-900;
}

.-md {
  width: 114px;

  .mstCounterButton__button {
    width: 36px;
    height: 38px;
    font-size: 13px;
  }

  .mstCounterButton__value {
    font-size: 13px;
    line-height: 1;
  }
}

.-sm {
  width: 90px;

  .mstCounterButton__button {
    width: 28px;
    height: 30px;
    font-size: 12px;
  }

  .mstCounterButton__value {
    font-size: 12px;
    line-height: 1;
  }
}
</style>
