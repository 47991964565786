<template>
  <div>
    <div v-if="showOnly">
      <div class="cart-shipping-card">
        <div class="name">{{ defaultLocation.name }}</div>
        <div class="zip">{{ defaultLocation.postal_code }}</div>
        <div class="address">
          {{ getPrefectureName(defaultLocation) }}
          {{ defaultLocation.municipality }}
          {{ defaultLocation.address }}
          {{ defaultLocation.building_name }}
        </div>
      </div>
    </div>

    <div
      v-else
      v-for="location in locations"
      :key="location.id"
      class="cart-shipping-card d-flex align-center mt-2"
    >
      <div>
        <div class="name">{{ location.name }}</div>
        <div class="zip">{{ location.postal_code }}</div>
        <div class="address">
          {{ getPrefectureName(location) }}
          {{ location.municipality }}
          {{ location.address }}
          {{ location.building_name }}
        </div>
      </div>

      <div
        v-if="defaultLocation && defaultLocation.id === location.id"
        class="d-flex flex-column"
        style="color: #007E57"
      >
        <v-icon color="#007E57" class="ma-2" style="font-size: 40px">mdi-check-circle-outline</v-icon>
        <span
          class="ma-2"
          style="font-size: 14px; font: Bold 14px/29px Helvetica Neue"
        >{{ $t("delivery_this_address") }}</span>
      </div>

      <Button
        v-else
        class="button align-self-end"
        color="white-border-gray"
        @click="e=>selectHandler(location.id)"
      >この住所に届ける</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/shop/Button.vue";

export default {
  model: {
    prop: "selected",
    event: "change"
  },

  components: {
    Button
  },

  props: {
    showOnly: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    selectHandler(location_id) {
      this.$store.dispatch("orders/changeLocation", location_id);
    },
    getPrefectureName(location) {
      return location.prefecture?.attributes?.name;
    }
  },

  computed: {
    locations() {
      return this.$store.state.user.shop.locations;
    },
    defaultLocation() {
      return this.$store.state.orders.draft.location || {};
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-shipping-card {
  justify-content: space-between;
  background-color: #fff;
  border: #ccc 1px solid;
  border-radius: 5px;
  padding: 16px;
  font-size: 14px;
  color: #5B5B5B;
}

.name {
  font-weight: bold;
  margin-bottom: 4px;
}

.selected,
.button {
  grid-row: span 4;
}

.selected {
  width: 140px;
  color: #007e57;
  font-weight: bold;
  text-align: center;

  .icon {
    width: 40px;
    height: 40px;
    border: currentColor 3px solid;
    border-radius: 50%;
    margin-bottom: 16px;

    &::after {
      display: block;
      content: "";
      width: 10px;
      height: 16px;
      border-right: currentColor 3px solid;
      border-bottom: currentColor 3px solid;
      transform: translateY(-2px) rotate(45deg);
    }
  }
}

.button {
  margin-bottom: -8px;
}
</style>
