<template>
  <!-- eslint-disable -->

  <svg width="1em" :width="size" height="13" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false" role="img">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.47 7.97a.75.75 0 0 1 1.06 0l5.5 5.5a.75.75 0 1 1-1.06 1.06L12 9.56l-4.97 4.97a.75.75 0 0 1-1.06-1.06l5.5-5.5Z" fill="currentColor"></path>
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    size: String
  }
};
</script>
