<template>
  <CartLayout
    :order="draftOrder"
    title="注文の確定"
    :next-text="$t('complete_order')"
    @next="purchase"
    prev-text="カートに戻る"
    prev-to="/shop/cart"
  >
    <template v-if="draftOrder">
      <CartSectionTitle>配送情報</CartSectionTitle>
      <CartShippingCard show-only />
      <!-- TODO: NPかけ払の実装のため、一旦コメントアウト -->
      <!-- <CartSectionTitle>お支払い情報</CartSectionTitle> -->
      <!-- <div class="payment-card"> -->
      <!--   <RadioButton checked-value="1" label="銀行振込" selected="1" /> -->
      <!-- </div> -->
    </template>
  </CartLayout>
</template>

<script>
import CartLayout from "@/components/shop/cart/CartLayout.vue";
import CartShippingCard from "@/components/shop/cart/CartShippingCard.vue";
import CartSectionTitle from "@/components/shop/cart/CartSectionTitle.vue";
import RadioButton from "@/components/shop/RadioButton.vue";

export default {
  components: {
    CartLayout,
    CartShippingCard,
    CartSectionTitle,
    RadioButton
  },

  data() {
    return {
      unbuyableFlowerSpecieNameList: []
    };
  },

  computed: {
    draftOrder() {
      return this.$store.state.orders.draft;
    }
  },

  mounted() {
    this.$store.dispatch("orders/getDraftOrder");
  },

  methods: {
    async purchase() {
      class OrderConfirmError extends Error {
        constructor(status, message) {
          super(message);
          this.status = status;
          this.name = "OrderConfirmError";
        }
      }

      try {
        const res = await this.$api.post("/orders/draft/confirm");

        if (res.status === 521) {
          res.result.order_item_id_list.forEach(error_order_item_id => {
            const target_order_item = this.draftOrder.order_items.find(order_item => order_item.id === error_order_item_id);
            this.unbuyableFlowerSpecieNameList.push(target_order_item.flower.flower_specie.name);
          });
          throw new OrderConfirmError(521, "Failed to adding item into cart");
        }

        this.$router.push("/shop/cart/complete");
      } catch (e) {
        if (e.status === 521) {
          this.$store.dispatch("orders/notifyPurchaseUnbuyableAreaError", this.unbuyableFlowerSpecieNameList);
        } else {
          this.$store.dispatch("orders/notifyPurchaseError", e.response.data.errors);
        }

        console.log(e);
      } finally {
        await this.$store.dispatch("orders/getDraftOrder");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.order-table {
  border-radius: 5px;
}

.payment-card {
  display: grid;
  grid-gap: 12px;
  align-content: center;
  background-color: #fff;
  border: #ccc 1px solid;
  border-radius: 5px;
  padding: 16px;
  min-height: 80px;
}
</style>
