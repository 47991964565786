<template>
  <!-- eslint-disable -->

  <svg 
    :width="size || 20"
    :height="size || 20"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.6947 12.8592C12.3391 12.8592 12.8614 12.3369 12.8614 11.6925C12.8614 11.0482 12.3391 10.5259 11.6947 10.5259C11.0504 10.5259 10.5281 11.0482 10.5281 11.6925C10.5281 12.3369 11.0504 12.8592 11.6947 12.8592Z" :fill="color"/>
    <path d="M16.3614 12.8592C17.0057 12.8592 17.528 12.3369 17.528 11.6925C17.528 11.0482 17.0057 10.5259 16.3614 10.5259C15.717 10.5259 15.1947 11.0482 15.1947 11.6925C15.1947 12.3369 15.717 12.8592 16.3614 12.8592Z" :fill="color"/>
    <path d="M7.02799 12.8592C7.67233 12.8592 8.19466 12.3369 8.19466 11.6925C8.19466 11.0482 7.67233 10.5259 7.02799 10.5259C6.38366 10.5259 5.86133 11.0482 5.86133 11.6925C5.86133 12.3369 6.38366 12.8592 7.02799 12.8592Z" :fill="color"/>
    <path d="M19.9431 3.44399C18.0288 1.51724 15.5023 0.318742 12.7991 0.0551095C10.0959 -0.208523 7.38546 0.479235 5.135 1.99981C2.88454 3.52039 1.23513 5.7785 0.47111 8.38484C-0.292907 10.9912 -0.123644 13.7824 0.949718 16.2773C1.06159 16.5092 1.0983 16.7702 1.05472 17.024L0.0280513 21.959C-0.0115032 22.1482 -0.00342608 22.3443 0.0515568 22.5296C0.10654 22.7149 0.206705 22.8836 0.343051 23.0207C0.454823 23.1316 0.587904 23.2188 0.734274 23.2769C0.880644 23.3351 1.03727 23.363 1.19472 23.359H1.42805L6.42138 22.3557C6.67517 22.3251 6.93252 22.3613 7.16805 22.4607C9.66297 23.534 12.4542 23.7033 15.0605 22.9393C17.6669 22.1753 19.925 20.5258 21.4456 18.2754C22.9661 16.0249 23.6539 13.3145 23.3903 10.6113C23.1266 7.90808 21.9281 5.38158 20.0014 3.46733L19.9431 3.44399ZM20.9114 13.1973C20.6833 14.5903 20.1422 15.9134 19.3289 17.067C18.5155 18.2207 17.4511 19.1748 16.2157 19.8577C14.9803 20.5405 13.6062 20.9342 12.1966 21.0092C10.7871 21.0842 9.37892 20.8385 8.07805 20.2907C7.61668 20.0944 7.12107 19.9913 6.61972 19.9873C6.40071 19.9889 6.1822 20.0084 5.96638 20.0457L2.67638 20.7107L3.34138 17.4207C3.47383 16.7081 3.38845 15.9723 3.09638 15.309C2.54851 14.0081 2.30283 12.5999 2.37783 11.1904C2.45283 9.78087 2.84656 8.4067 3.52938 7.17132C4.21221 5.93594 5.16635 4.87153 6.32 4.05819C7.47364 3.24484 8.79674 2.70376 10.1897 2.47566C11.6519 2.2357 13.1501 2.3473 14.5606 2.80123C15.9711 3.25517 17.2532 4.03839 18.3009 5.08612C19.3487 6.13385 20.1319 7.41597 20.5858 8.82644C21.0397 10.2369 21.1513 11.7352 20.9114 13.1973V13.1973Z" :fill="color"/>
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>

<style>
</style>
