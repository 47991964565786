<template>
  <div>
    <div class="title">QRコード読み取り</div>
    <div class="full-screen">
      <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
        <div v-show="showScanConfirmation" class="scan-confirmation">
          <QrCheckmark />
          <h3 style="margin-top: 10px;margin-bottom: 100px;">読み取り成功</h3>
        </div>
      </qrcode-stream>
      <div>
        <router-link to="/driver/information">
          <button v-if="showScanConfirmation" class="scan-finish">読み取り終了</button>
        </router-link>
        <button v-if="showScanConfirmation" class="qrcode-finish" @click="unpause">読み取りを続ける</button>
        <router-link to="/driver/information">
          <button v-if="!showScanConfirmation" class="qrcode-finish">読み取り終了</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import QrCheckmark from "@/components/icons/QrCheckmark.vue";

export default {
  components: { QrCheckmark, QrcodeStream },
  data() {
    return {
      camera: "auto",
      showScanConfirmation: false
    };
  },
  computed: {
    driverType() {
      return this.$store.state.user.driver && this.$store.state.user.driver.driver_type;
    },
    isDeliveryDriver() {
      return this.driverType === "delivery";
    },
    isShippingDriver() {
      return this.driverType === "shipping";
    },
    from() {
      return [...this.$store.state.driverInformation.from];
    },
    to() {
      return [...this.$store.state.driverInformation.to];
    },
    deliveryType() {
      return this.$store.state.driverInformation.deliveryType;
    }
  },
  methods: {
    async onInit(promise) {
      try {
        await promise;
      } catch (e) {
        console.error(e);
      } finally {
        this.showScanConfirmation = this.camera === "off";
      }
    },

    async onDecode(content) {
      let orderItems = [];
      let payload = {};
      if (this.deliveryType === "ship") {
        orderItems = [...this.from];
      } else {
        orderItems = [...this.to];
      }
      const validOrder = orderItems.find(orderItem => orderItem.id.toString() === content.toString());
      if (validOrder) {
        const isShipOrder = (this.isDeliveryDriver && validOrder.status === "unloaded") || (this.isShippingDriver && validOrder.status === "confirmed");
        const isDeliveryOrder = (this.isDeliveryDriver && validOrder.status === "picked") || (this.isShippingDriver && validOrder.status === "shipped");
        const isValidDelivery = (this.deliveryType === "ship" && isShipOrder) || (this.deliveryType === "delivery" && isDeliveryOrder);
        if (isValidDelivery) {
          payload = { orderItem: { [content.toString()]: { checked: true, is_qr: true } } };
        } else {
          this.$store.dispatch("setSnackBar", { msg: "変更できないステータスの注文が含まれています。選択を解除してください。", color: "red" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
        }
      }
      if (payload.orderItem) {
        this.$store.dispatch("driverInformation/setSelectedOrderItem", payload);
        this.pause();
      }
    },

    unpause() {
      this.camera = "auto";
      this.showScanConfirmation = false;
    },

    pause() {
      this.camera = "off";
    }
  }
};
</script>

<style scoped>
.full-screen {
  position: fixed;
  z-index: 1;
  top: 140px;
  bottom: 0;
  right: 0;
  left: 0;
}

.title {
  margin: 20px auto;
  text-align: center;
}

.qrcode-finish {
  position: absolute;
  height: 48px;
  left: 25px;
  right: 25px;
  bottom: 10px;
  color: white;
  background: #315198;
  border-radius: 4px;
}

.scan-finish {
  position: absolute;
  height: 48px;
  left: 25px;
  right: 25px;
  bottom: 70px;
  color: #315198;
  background: white;
  border: 1px solid #315198;
  border-radius: 4px;
}

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgb(255, 255, 255);

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
</style>
