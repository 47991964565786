<template>
  <div class="mstDateFilter">
    <div ref="dates" class="mstDateFilter__dates">
      <div class="mstDateFilter__inner">
        <ul class="mstDateFilter__items">
          <li v-for="date in allowDates" :key="date.getTime()" class="mstDateFilter__item">
            <button
              ref="button"
              :aria-selected="isCurrent(date)"
              :data-date="$date.YYYYMMDD(date)"
              :disabled="!getIsActive(date)"
              type="button"
              class="mstDateFilter__button text-sm"
              @click="$emit('change', date)"
            >
              {{ getDateString(date) }}
            </button>
          </li>
        </ul>
        <MstIntersection v-if="isActiveNext" class="mstDateFilter__intersect" @intersect="handleIntersect" />
      </div>
    </div>
    <div v-if="!hideController" class="mstDateFilter__controller text-sm">
      <button
        :disabled="!isActivePrevious"
        type="button"
        class="mstDateFilter__controllerButton -prev"
        @click="handleMonthController(-1)"
      >
        <MstIcon type="chevron-left" size="18px" />
        前の月
      </button>
      <button
        :disabled="!isActiveNext"
        type="button"
        class="mstDateFilter__controllerButton -next"
        @click="handleMonthController(1)"
      >
        次の月
        <MstIcon type="chevron-right" size="18px" />
      </button>
    </div>
  </div>
</template>

<script>
import {
  isBefore,
  lastDayOfMonth,
  startOfMonth,
  addDays,
  isSameDay,
  differenceInDays,
  addMonths,
  subMonths,
  isSameMonth,
  differenceInMonths,
} from "date-fns";
import { MstIcon, MstIntersection } from "@/components/master";

export default {
  name: "MstDateFilter",
  components: { MstIcon, MstIntersection },
  model: {
    prop: "current",
    event: "change",
  },
  props: {
    current: { type: Date },
    allowDays: { type: Array, default: () => [0, 1, 2, 3, 4, 5, 6] },
    maxDate: { type: Date },
    hideController: { type: Boolean },
  },
  data() {
    return {
      dates: [],
      activeDate: null,
      scrolling: null,
    };
  },
  computed: {
    currentModel: {
      get() {
        return this.current;
      },
      set(newValue) {
        this.$emit("change", newValue);
      }
    },
    isActivePrevious() {
      const yesterday = new Date(this.activeDate.getFullYear(), this.activeDate.getMonth(), this.activeDate.getDate() - 1);
      const minDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate());
      return yesterday.getTime() >= minDate.getTime();
    },
    isActiveNext() {
      if (!this.maxDate) return true;
      const nextDate = new Date(this.activeDate.getFullYear(), this.activeDate.getMonth(), this.activeDate.getDate() + 31);
      const maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
      return nextDate.getTime() <= maxDate.getTime();
    },
    startDate() {
      return this.$store.getters.getFarmDeadline;
    },
    allowDates() {
      return this.dates.filter(date => this.allowDays.includes(date.getDay()));
    },
    buttonObserver() {
      return new IntersectionObserver(this.handleButtonIntersect);
    },
  },
  watch: {
    current() {
      this.updateCenterPosition();
    },
    dates() {
      this.$nextTick(() => {
        if (!this.$refs.button || !this.$refs.button.length) return;
        this.$refs.button.forEach(button => {
          this.buttonObserver.unobserve(button);
          this.buttonObserver.observe(button);
        });
      });
    },
  },
  created() {
    this.dates = this.getNextMonthDates(this.startDate);
    this.activeDate = this.startDate;
  },
  mounted() {
    if (!this.current) {
      this.currentModel = this.getNearbyDate(this.startDate);
    } else {
      this.handleMonthController(differenceInMonths(this.current, this.startDate));
      this.$nextTick(() => {
        this.updateCenterPosition();
      });
    }
  },
  methods: {
    updateCenterPosition() {
      if (!this.$refs.dates || !this.$refs.button) return;
      this.$nextTick(() => {
        const { scrollLeft } = this.$refs.dates;
        const { left: listLeft, width: listWidth } = this.$refs.dates.getBoundingClientRect();
        const selectedButton = this.$refs.button.find(button => button.getAttribute("aria-selected") === "true");
        if (!selectedButton) return;
        const { width, left } = selectedButton.getBoundingClientRect();
        this.$refs.dates.scrollLeft = scrollLeft + left - listLeft + width / 2 - listWidth / 2;
      });
    },
    updateScrollPosition() {
      const refs = this.$refs.button;
      if (!refs || !refs.length) return;
      const target = refs.find(el => isSameDay(new Date(el.getAttribute("data-date")), this.getNearbyDate(this.activeDate)));

      if (isSameMonth(this.activeDate, this.dates[0])) {
        this.$refs.dates.scrollLeft = 0;
        return;
      }

      if (!target || !this.$refs.dates) return;
      const { left } = target.getBoundingClientRect();
      const datesRect = this.$refs.dates.getBoundingClientRect();
      const position = left - datesRect.left + this.$refs.dates.scrollLeft;
      this.$refs.dates.scrollLeft = position;
    },
    getNextMonthDates(baseDate) {
      const nextMonth = lastDayOfMonth(baseDate);
      const days = differenceInDays(nextMonth, baseDate);
      const dates = [];
      for (let i = 0; i <= days; i++) {
        dates.push(addDays(baseDate, i));
      }
      return dates;
    },
    getNearbyDate(date) {
      if (this.allowDays.includes(date.getDay())) return date;
      const dateDay = date.getDay();
      const mostRecentValidDay = this.allowDays.find(allowDay => {
        if (dateDay < 6) return allowDay > dateDay;
        if (allowDay === dateDay) return true;
        return this.allowDays[0];
      });
      let newDate = date;
      let i = 0;

      while (i < 7) {
        i++;
        newDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 1);
        if (newDate.getDay() === mostRecentValidDay) break;
      }

      return newDate;
    },
    isCurrent(date) {
      const current = new Date(this.current.getFullYear(), this.current.getMonth(), this.current.getDate());
      return date.getTime() === current.getTime();
    },
    getIsActive(date) {
      if (!this.maxDate) return true;
      return isBefore(date, addDays(this.maxDate, 1));
    },
    getDateString(date) {
      return `${this.$d(date, "shortDate")}（${this.$d(date, "day")}）`;
    },
    handleMonthController(addMonth) {
      if (addMonth > 0) {
        this.activeDate = startOfMonth(addMonths(this.activeDate, addMonth));

        if (!this.dates.find(date => date === this.activeDate)) {
          new Array(addMonth).fill().forEach(() => {
            const nextMonthDates = this.getNextMonthDates(addDays(this.dates[this.dates.length - 1], 1));
            this.dates = this.dates.concat(nextMonthDates);
          });
        }
      } else {
        this.activeDate = startOfMonth(subMonths(this.activeDate, 1));
      }

      this.$nextTick(() => {
        this.updateScrollPosition();
      });
    },
    handleIntersect(isIntersecting) {
      if (!isIntersecting) return;
      this.dates = this.dates.concat(this.getNextMonthDates(addDays(this.dates[this.dates.length - 1], 1)));
    },
    handleButtonIntersect(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const date = new Date(entry.target.getAttribute("data-date"));
          if (isSameMonth(this.activeDate, date)) return;
          this.activeDate = new Date(date.getFullYear(), date.getMonth(), 1);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mstDateFilter__dates {
  overflow-x: auto;
}

.mstDateFilter__inner {
  display: flex;
}

.mstDateFilter__intersect {
  width: 48px;
  flex-shrink: 0;
}

.mstDateFilter__items {
  display: flex;
  align-items: center;
  padding: 0;
  list-style-type: none;
}

.mstDateFilter__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  border-radius: 2px;
  border: 1px solid variables.$color-gray-300;
  width: 100px;
  height: 30px;
  font-feature-settings: "palt";
  color: variables.$color-gray-600;
  transition: 0.3s ease;

  &[aria-selected="true"] {
    border-color: variables.$color-brand-secondary;
    background: variables.$color-brand-secondary;
    font-weight: bold;
    color: variables.$color-white;
  }

  &:disabled {
    border-color: variables.$color-gray-100;
    color: variables.$color-gray-300;
    cursor: not-allowed;
  }
}

.mstDateFilter__controller {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding: 0 8px;
}

.mstDateFilter__controllerButton {
  display: flex;
  align-items: center;
  line-height: 1;
  color: variables.$color-black-500;
  transition: color 0.3s ease;

  &:disabled {
    color: variables.$color-gray-400;
    cursor: not-allowed;
  }
}
</style>
