<template>
  <!-- eslint-disable -->

  <svg class="pointer" :height="size" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.28305 0.500941L0.510046 8.47494C0.373525 8.615 0.297119 8.80285 0.297119 8.99844C0.297119 9.19403 0.373525 9.38188 0.510046 9.52194L8.28305 17.4989C8.34664 17.5643 8.42268 17.6162 8.50666 17.6516C8.59065 17.6871 8.68089 17.7053 8.77205 17.7053C8.86321 17.7053 8.95345 17.6871 9.03743 17.6516C9.12142 17.6162 9.19745 17.5643 9.26105 17.4989C9.39188 17.365 9.46513 17.1852 9.46513 16.9979C9.46513 16.8107 9.39188 16.6309 9.26105 16.4969L1.95155 8.99844L9.26105 1.50144C9.39143 1.36756 9.46439 1.18807 9.46439 1.00119C9.46439 0.814313 9.39143 0.634819 9.26105 0.500941C9.19745 0.435626 9.12142 0.383712 9.03743 0.348265C8.95345 0.312818 8.86321 0.294556 8.77205 0.294556C8.68089 0.294556 8.59065 0.312818 8.50666 0.348265C8.42268 0.383712 8.34664 0.435626 8.28305 0.500941Z" :fill="color"/>
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>
