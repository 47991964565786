<template>
  <div class="overflow: auto">
    <div class="d-flex justify-space align-center">
      <v-btn @click="$emit('back')" class="ma-2" icon large>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <h3 class="mt-5 mx-5 mb-1">{{ $d(date, 'normalDate') }}
      <span class="ml-2 mr-2">({{ $d(date, 'day') }})</span>
      {{ $t("delivery") }} - {{ category == null ? $t("all_1") : category.name }}
    </h3>
    <span class="ml-5 category">{{ $t("search") }} - {{ $t("choose_farm") }}</span>
    <div style="overflow: auto; margin-top: 15px; height: 73vh;">
      <v-list class="pt-0">
        <div class="mx-5 mb-6 search-board">
          <div class="d-flex align-center search-container">
            <input class="search-button" placeholder="テキストで検索して絞り込み" @input="onChangeSearch" :value="searchString"/>
          </div>
        </div>
        <div v-for="item in filteredAvailableFarms" :key="item.id">
          <v-list-item @click="$emit('click', {key: 'farmIds', value: item.id})" class="pt-2 pb-2 pl-5 pr-5">
            <v-list-item-content :class="{'filter-mob-font': farmIdsArr.includes(item.id)}" class="pl-3">
              <div>
                <span class="mr-2">{{ item.name }}</span>
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon v-if="farmIdsArr.includes(item.id)" class="selected-item-icon">mdi-check</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </div>
      </v-list>
    </div>
    <div class="d-flex justify-space-around mb-4">
      <v-btn color="#03A9F4" @click="$emit('clearFilterBy', {key: 'farmIds'})" outlined>{{ $t("clear") }}</v-btn>
      <v-btn class="blue" @click="$emit('back')" style="width: 50%">{{ $t("confirm") }}</v-btn>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    farmIds: {
      type: String,
      default: null
    },
    date: {
      type: String,
      default: null
    },
    category: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      searchString: "",
    };
  },

  computed: {
    farmIdsArr() {
      if (this.farmIds) {
        return this.farmIds.toString().split(",").map(_id => Number(_id));
      }
      return [];
    },
    filteredAvailableFarms() {
      const availableFarms = this.$store.state.searchQuery.availableFarms;
      return availableFarms.filter(farm => farm.name.toLowerCase().includes(this.searchString.toLowerCase()));
    }
  },

  methods: {
    onChangeSearch(e) {
      this.searchString = e.target.value;
    }
  }
};
</script>

<style scoped>
.category {
  font-size: 14px;
}
.search-board {
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: none;
}
.search-container {
  padding: 12px;
  border-radius: 4px;
  box-shadow: none;
 }
</style>
