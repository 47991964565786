<template>
  <div class="main-content mt-4">
    <div class="pa-3 my-2 mb-0 pt-1">
      <span class="font-weight-bold color-black-500" style="font-size: 16px;">{{ $t("farmCartons") }}</span>
    </div>
    <div class="kyusyu-area" v-if="inputCartons">
      <div class="d-flex justify-space-between">
        <div class="mt-2 ml-2">
          <span class="font-weight-bold color-black-500" style="font-size: 14px;">{{ $t("kyusyu") }}</span>
        </div>
        <div class="mt-3" style="margin-right: 60px; font-size: 14px;">
          <span>{{ $t("packing") }}</span>
        </div>
        <div class="mt-3" style="margin-right: 60px; font-size: 14px;">
          <span>{{ $t("quantity") }}</span>
        </div>
      </div>
      <div v-for="(packing, index) in kyusyuPackings" :key="index" :class="{ 'mt-9': index > 0 }" class="mt-1 d-flex justify-space-between">
        <div style="margin-left: 100px">
          <v-select
            :items="packingOptions"
            item-text="text"
            item-value="value"
            solo
            hide-details
            style="width: 150px; height: 40px;"
            v-model="packing.selectedPacking"
            class="mt-2"
          ></v-select>
        </div>
        <div class="mr-3">
          <v-select
            :items="quantityOptions"
            solo
            hide-details
            style="width: 80px; height: 40px;"
            v-model="packing.selectedQuantity"
            class="mt-2"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="kansai-area" v-if="inputCartons">
      <div class="d-flex justify-space-between">
        <div class="mt-2 ml-2">
          <span class="font-weight-bold color-black-500" style="font-size: 14px;">{{ $t("kansai") }}</span>
        </div>
        <div class="mt-3" style="margin-right: 60px; font-size: 14px;">
          <span>{{ $t("packing") }}</span>
        </div>
        <div class="mt-3" style="margin-right: 60px; font-size: 14px;">
          <span>{{ $t("quantity") }}</span>
        </div>
      </div>
      <div v-for="(packing, index) in kansaiPackings" :key="index" :class="{ 'mt-9': index > 0 }" class="mt-1 d-flex justify-space-between">
        <div style="margin-left: 100px">
          <v-select
            :items="packingOptions"
            item-text="text"
            item-value="value"
            solo
            hide-details
            style="width: 150px; height: 40px;"
            v-model="packing.selectedPacking"
            class="mt-2"
          ></v-select>
        </div>
        <div class="mr-3">
          <v-select
            :items="quantityOptions"
            solo
            hide-details
            style="width: 80px; height: 40px;"
            v-model="packing.selectedQuantity"
            class="mt-2"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="btn-area text-center pt-5 pb-2" v-if="inputCartons">
      <button @click="submit" class="save-button">{{ $t("save") }}</button>
    </div>
    <div class="pb-2 toggle-area">
      <span
        class="pt-2 pointer d-flex justify-end align-center"
        style="font-size: 12px; color: #68727B"
        @click="inputCartons = !inputCartons"
      >
        {{ inputCartons ? $t('close') : $t('input_cartons') }}
        <ArrowUpIcon class="ml-2 mr-2" size="12" color="#68727B" />
      </span>
    </div>
  </div>
</template>

<script>
import ArrowUpIcon from "@/components/icons/ArrowUpIcon.vue";
import { formatDate } from "@/plugins/i18n";

export default {
  components: { ArrowUpIcon },
  props: {
    harvestDate: {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      inputCartons: false,
      packingOptions: [
        { text: "バケット", value: "bucket" },
        { text: "縦箱", value: "vertical_box" },
        { text: "横箱", value: "horizontal_box" }
      ],
      quantityOptions: Array.from({ length: 51 }, (_, i) => i),
      kyusyuPackings: [],
      kansaiPackings: []
    };
  },
  watch: {
    harvestDate: {
      handler: "fetchCartons",
      immediate: true,
    }
  },
  methods: {
    async submit() {
      const kyusyuData = this.kyusyuPackings.map(packing => ({
        quantity: packing.selectedQuantity,
        packing: packing.selectedPacking,
      }));
      const kansaiData = this.kansaiPackings.map(packing => ({
        quantity: packing.selectedQuantity,
        packing: packing.selectedPacking,
      }));

      const body = {
        cartons: {
          kyusyu: kyusyuData,
          kansai: kansaiData,
          harvest_date: this.harvestDate,
        },
      };

      const res = await this.$http2("post", "farm/cartons/build_create_or_update", body);
      if (res.status === 200) {
        this.$store.dispatch("setMstSnackBar", { msg: "変更内容を保存しました。", color: "#21920F" }, { root: true });
        this.$store.dispatch("mstSnackOn", {}, { root: true });
      } else {
        this.$store.dispatch("setMstSnackBar", { msg: "エラーが発生しました。", color: "red" }, { root: true });
        this.$store.dispatch("mstSnackOn", {}, { root: true });
      }
    },
    async fetchCartons() {
      const date = formatDate(this.harvestDate, "yyyy-mm-dd");
      try {
        const response = await this.$http2("get", `farm/cartons?harvest_date=${date}`);
        const cartons = response.result.cartons;
        if (cartons.length === 0) {
          const defaultPackingData = [
            { selectedPacking: "bucket", selectedQuantity: 0 },
            { selectedPacking: "vertical_box", selectedQuantity: 0 },
            { selectedPacking: "horizontal_box", selectedQuantity: 0 }
          ];
          this.kyusyuPackings = JSON.parse(JSON.stringify(defaultPackingData));
          this.kansaiPackings = JSON.parse(JSON.stringify(defaultPackingData));
        } else {
          this.kyusyuPackings = cartons.filter(item => item.area === "kyusyu").map(item => ({
            selectedPacking: item.packing,
            selectedQuantity: item.quantity
          }));

          this.kansaiPackings = cartons.filter(item => item.area === "kansai").map(item => ({
            selectedPacking: item.packing,
            selectedQuantity: item.quantity
          }));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  },
  created () {
    this.fetchCartons();
  }
};
</script>

<style scoped>
.main-content {
  border-radius: 8px;
  border: 1px solid #BFC3C6;
  background-color: #F5F5F5;
}

p {
  margin-bottom: 0px;
}

.bg-lightgray {
  background-color: #D4D7D9;
}

.warehouse {
  color: #191D38;
  font-weight: 400;
  font-size: 12px;
  padding: 2px 6px 2px 6px;
  border-radius: 99px;
  margin: auto 4px auto 0;
}

.confirmed-btn {
  background-color: white;
  color: #315198;
  border: 1px solid #315198;
  padding: 6px 16px 6px 16px;
  border-radius: 100px;
  font-size: 14px;
  white-space: nowrap;
  position: absolute;
  top: 0;
  right: 0;
}

.transaction-border {
  border-left: 3px solid #A9AFB3;
  padding-left: 10px;
  margin-left: 10px;
}

.kyusyu-area {
  border-top: 1px solid #D7D7D7;
  background-color: #E9EDEE;
  height: 210px;
}

.kansai-area {
  background-color: #E9EDEE;
  height: 200px;
}

.btn-area {
  border-bottom: 1px solid #D7D7D7;
  background-color: #E9EDEE;
}

.save-button {
  width: 336px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  background-color: #173169;
  border-radius: 4px;
}

.image-plus-icon {
  width: 20px;
}

.toggle-area {
  border-top: 1px solid #D7D7D7;
}
</style>
