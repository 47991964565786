<template>
  <div>
    <v-row class="my-1">
      <!-- <v-col sm="6" cols="12" class="pb-0 d-flex justify-center">
        <v-btn color="#4A8FCF" to="" style ="color: #FFF; width: 95%" class="d-flex justify-space-between" >
          <span class="invoice-period">{{ monthlyInvoices && format(monthlyInvoices[0].startDate) }} ~ {{ monthlyInvoices && format(monthlyInvoices[0].endDate) }}購入分（確定）</span>
          <span class="invoice-amount">{{ monthlyInvoices && monthlyInvoices[0].totalSalesPriceWithTax }}円</span>
        </v-btn>
      </v-col> -->
      <v-col sm="6" cols="12" class="pb-0 d-flex justify-center">
        <v-btn color="#4A8FCF" outlined to="" style ="width: 95%" class="d-flex justify-space-between">
          <span class="invoice-period">{{ format(monthlyInvoices[1].startDate) }} ~ {{ format(monthlyInvoices[1].endDate) }}購入分（未確定）</span>
          <span class="invoice-amount">{{ monthlyInvoices[1].totalSalesPriceWithTax }}円</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatDate } from "@/plugins/i18n";

export default {
  data() {
    return {
      monthlyInvoices: [{}, {}],
    };
  },
  async created() {
    const res = await this.$api2.get("shop/invoices/current_shop_invoices", this.axiosOptions);
    this.monthlyInvoices = res.result;
  },
  methods: {
    format(date) {
      const d = new Date(date);
      return formatDate(d, "m/d");
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  }
};
</script>

<style lang="scss" scoped>
.invoice-period {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
}
.invoice-amount {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
}
.product-header {
  &-payment-information {
    width: 100%;
    text-align: left;
    font-weight: bold;
  }

  &-left {
    text-align: center;

    &-top {
      font-weight: bold;
      font-size: 16px;
      color: #212121;
    }

    &-bottom {
      font-weight: 500;
      font-size: 15px;
      line-height: 12px;
      color: #212121;
      @media (max-width: 480px){
      font-size: 3.2vw;
      }
    }
  }

  &-right {
    text-align: center;
    &-top {
      font-weight: 500;
      font-size: 10px;
      color: #212121;
    }

    &-bottom {
      font-weight: bold;
      font-size: 16px;
      color: #212121;
      @media (max-width: 480px){
        font-size: 3.5vw;
      }
    }
  }

  &-chips {
    color: white;
    font-weight: 500;
    @media (max-width: 480px){
      font-size: 3vw;
    }
  }

  &-amount {
    color: #212121;
    font-weight: bold;
    font-size: 24px;
    @media (max-width: 480px){
        font-size: 5.5vw;
        font-weight: 900;
    }
    @media (max-width: 320px){
        font-size: 4.3vw;
    }
  }

  &-divider {
    border: 1px solid #00796b;
    transform: rotate(-63.43deg);
    width: 35px;
    height: 0;
    margin-top: 10px;
  }
}
</style>
