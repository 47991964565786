<template>
  <div class="body">
    <div class="content d-flex flex-column align-center">
      <div class="logo pb-2">
        <img :src="require('@/assets/CAVIN_Logo_black.png')" :style="mobile ? 'width: 160px' : 'width: 300px'" />
      </div>

      <span class="page-title">{{ $t("admin_login") }}</span>

      <div v-if="!hasUsers" class="card justify-center align-center">
        <p v-if="invalid" style="color: red;">{{ $t("login_error") }}</p>
        <div style="width: 100%;">
          <div style="margin-bottom: -10px;"><span class="label">{{ $t("user_type") }}</span></div>
          <v-radio-group
            v-model="content.user_type"
            row
          >
            <v-radio
              v-for="userType in userTypes"
              :key="userType.value"
              :label="userType.text"
              :value="userType.value"
            ></v-radio>
          </v-radio-group>
        </div>
        <div class="mt-2" style="width: 100%;">
          <span class="label">{{ $t("email") }}</span>
          <v-text-field ref="email" type="text" v-model="content.email" @keyup="keyupHandler" class="mt-2" :error="invalid" autofocus outlined />
        </div>

        <div class="mt-2" style="width: 100%;">
          <span class="label">{{ $t("password") }}</span>
          <v-text-field
            ref="password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ?'mdi-eye':'mdi-eye-off'"
            class="mt-2"
            v-model="content.password"
            @keyup="keyupHandler"
            @click:append="togglePasswordVisibility"
            :error="invalid"
            outlined
            />
        </div>

        <v-btn class="btn bg-secondary" @click="getUsers" style="width: 100%;">
          <span style="color: white; font-weight: bold;">{{ $t("login") }}</span>
        </v-btn>

        <v-btn to="/" class="mt-4" style="width: 100%;" outlined>
          <span style="color: #173169; font-weight: bold;">{{ $t("user_login") }}はこちら</span>
        </v-btn>
      </div>

      <v-card v-else class="card d-flex flex-column justify-center align-center">
        <div class="mt-10 mb-10" style="width: 100%;">
          <v-autocomplete
            ref="user"
            v-model="content.user"
            :items="users"
            item-value="id"
            item-text="name"
            label="ログインするアカウントを選択する"
          />
        </div>

        <v-btn :class="`btn bg-secondary`" @click="login">
          <span style="color: white; font-weight: bold;">{{ $t("login") }}</span>
        </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      invalid: false,
      content: {},
      userTypes: [
        {
          value: "Shop",
          text: "花屋"
        },
        {
          value: "Farm",
          text: "生産者"
        }
      ],
      users: []
    };
  },
  computed: {
    hasUsers() {
      return this.users.length > 0;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  watch: {
    content: {
      handler(e) {
        if (e.user_type) {
          this.$router.push(`/admin/login?user_type=${e.user_type}`);
        }
      },
      deep: true
    }
  },
  methods: {
    keyupHandler(e) {
      if (e.key === "Enter") this.login();
    },
    getUsers() {
      const { email, password } = this.content;
      const user_type = this.$route.query.user_type;

      if (!user_type) {
        this.$refs.user_type.focus();
        return;
      }

      if (!email) {
        this.$refs.email.focus();
        return;
      }

      if (!password) {
        this.$refs.password.focus();
        return;
      }

      this.$store.dispatch("admin/getUsers", { login: { email, password }, userType: user_type }).then(res => {
        if (res) {
          this.invalid = false;
          this.users = res;
        } else {
          this.invalid = true;
        }
      });
    },
    login() {
      const { user } = this.content;

      if (!user) {
        this.$refs.user.focus();
        return;
      }

      this.$store.dispatch("admin/loginUserById", { id: user }).then(res => {
        if (res) {
          this.invalid = false;
          this.$router.push(`/${res.toLowerCase()}`);
        } else {
          this.invalid = true;
        }
      });
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  }
};
</script>

<style lang="scss" scoped>
.body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 120vh;
}

.content {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}

.logo {
  margin-top: 65px;
}

@media only screen and (max-width: 600px) {
  $width: 90%;
  .card {
    padding: 1rem;
    width: $width;
  }
}

@media only screen and (min-width: 600px) {
  $width: 40rem;
  .card {
    padding: 2rem 4rem 2rem 4rem;
    width: $width;
  }
}

.card {
  margin-top: 50px;
  height: 100%;
}

.label {
  font: Medium 1rem Noto Sans CJK JP;
  color: #111111;
}

.page-title {
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}
</style>
