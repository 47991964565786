<template>
  <h2 class="cart-section-title">
    <slot />
  </h2>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cart-section-title {
  color: #191D38;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin: 32px 12px 12px;
}
</style>
