<template>
  <div class="page-root">
    <Header>
      <template v-slot:caption> 現在、あなたのエリアは対応していません。</template>
      <template v-slot:subCaption> 申し訳ありません。<br />エリア拡大後に優先的にご連絡するために、連絡先を教えてください。</template>
    </Header>
    <div class="page-contents">
      <v-row>
        <v-col>
          <v-subheader>郵便番号<RequiredOrOptionChip type="required"/></v-subheader>
          <v-text-field label="郵便番号" v-model="unuseable_area_user.postal_code" outlined disabled/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader>都道府県名<RequiredOrOptionChip type="required"/></v-subheader>
          <v-select
            v-if="prefectures"
            :items="prefectures"
            item-value="id"
            item-text="name"
            v-model="unuseable_area_user.prefecture_id"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader>市町村名<RequiredOrOptionChip type="option"/></v-subheader>
          <v-text-field label="市町村名" v-model="unuseable_area_user.municipality" outlined/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader>住所<RequiredOrOptionChip type="option"/></v-subheader>
          <v-text-field label="住所" v-model="unuseable_area_user.address" outlined />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader>建物名<RequiredOrOptionChip type="option"/></v-subheader>
          <v-text-field label="建物名" v-model="unuseable_area_user.building_name" outlined />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader>電話番号<RequiredOrOptionChip type="required"/></v-subheader>
          <the-mask class="input" v-model="unuseable_area_user.phone" :mask="['(###)-####-####']" placeholder="090-1111-1111" type="tel" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader>メールアドレス<RequiredOrOptionChip type="required"/></v-subheader>
          <v-text-field label="メールアドレス" v-model="unuseable_area_user.email" outlined />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader>事業者名<RequiredOrOptionChip type="required"/></v-subheader>
          <v-text-field label="事業者名" v-model="unuseable_area_user.name" outlined />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader>代表者名<RequiredOrOptionChip type="required"/></v-subheader>
          <v-text-field label="代表者名" v-model="unuseable_area_user.representative_name" outlined />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader>自由記入欄<RequiredOrOptionChip type="option"/></v-subheader>
          <v-textarea label="自由記入欄" v-model="unuseable_area_user.note" outlined />
        </v-col>
      </v-row>
      <v-row>
        <v-btn color="#0E4DA4" block :disabled="disabled" @click="postUnuseableAreaUser">
          <span style="color: #fff">登録する</span>
          <v-icon v-if="!disabled" color="#fff">mdi-arrow-right</v-icon>
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import Header from "@/components/users/Header.vue";
import RequiredOrOptionChip from "@/components/shared/form/RequiredOrOptionChip.vue";

export default {
  components: {
    Header,
    TheMask,
    RequiredOrOptionChip
  },
  props: {
    access_token: String
  },
  data() {
    return {
      unuseable_area_user: {
        postal_code: "",
        prefecture_id: null,
        municipality: "",
        address: "",
        building_name: "",
        phone: "",
        email: "",
        name: "",
        representative_name: "",
        note: "",
        user_type: ""
      },
      axiosOptions: null,
      prefectures: [],
    };
  },
  computed: {
    disabled() {
      if (!this.unuseable_area_user.postal_code
        || !this.unuseable_area_user.prefecture_id
        || !this.unuseable_area_user.phone
        || !this.unuseable_area_user.email
        || !this.unuseable_area_user.name
        || !this.unuseable_area_user.representative_name) return true;

      return false;
    }
  },
  async created() {
    const base_address = this.$store.state.unuseableAreaUsers;
    this.unuseable_area_user.user_type = base_address.user_type;
    this.unuseable_area_user.postal_code = base_address.postal_code;
    this.unuseable_area_user.municipality = base_address.municipality;
    this.unuseable_area_user.address = base_address.address;
    const result = await this.$http2("get", "prefectures", null, false);
    this.prefectures = result.result.prefectures;
  },
  methods: {
    postUnuseableAreaUser() {
      this.$store.dispatch("unuseableAreaUsers/postUnuseableAreaUser", this.unuseable_area_user).then(res => {
        if (res) {
          this.$router.push("not_available_area/complete");
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$primary-color: #0e4da4;

.page-contents {
  margin: 34px auto 0;
  display: grid;
  grid-template-columns: 400px;
  justify-content: center;

  input[type="tel"]:focus {
    border: 2px solid $primary-color;
    outline: 0;
  }

  .v-input--radio-group {
    width: 100%;
  }

  @media screen and (max-width: 1000px) {
    max-width: 600px;
    grid-template-columns: 1fr;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .v-subheader {
    font-size: 1rem;
    font-weight: bold;
  }

  .radio-tile-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .input-container {
      position: relative;
      height: 5rem;
      width: 7rem;
      margin: 1rem;

      .radio-button {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        margin: 0;
        cursor: pointer;
      }

      .radio-tile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border: 2px solid $primary-color;
        border-radius: 5px;
        padding: 1rem;
        transition: transform 300ms ease;
      }
      .icon svg {
        fill: $primary-color;
        width: 3rem;
        height: 3rem;
      }
      .radio-tile-label {
        text-align: center;
        font-size: 0.75rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $primary-color;
      }

      .radio-button:checked + .radio-tile {
        background-color: $primary-color;
        border: 2px solid $primary-color;
        color: white;
        transform: scale(1.1, 1.1);

        .icon svg {
          fill: white;
          background-color: $primary-color;
        }
        .radio-tile-label {
          color: white;
          background-color: $primary-color;
        }
      }
    }
  }
}
</style>
