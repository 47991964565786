<template>
  <div v-if="reviewItems.length > 0" class="modal">
    <div class="modal-content">
      <div class="pa-4">
        <span class="close" @click="closeReviewModal()">
          <CloseIcon size="24" color="black" />
        </span>
        <div class="text-center">
          <p class="font-weight-bold mb-3">{{ $t("product_review") }}</p>
          <span class="text-sm">お届けした商品はいかがでしたか？</span>
        </div>
      </div>
      <ReviewItem v-for="(item, index) in reviewItems" :key="index" :status="item.status" @onChangeStatus="(status) => onChangeStatus(status, item.id)"
        :shop="item.shop" :flower_genus="item.flowerGenus" :flower_specie="item.flowerSpecie" :img="item.image" :is_selected="item.id === selectedReviewItemId" />
      <div class="pa-2">
        <MstButton :disabled="submitStatus" class="btn" :class="{'bg-disabled': submitStatus, 'bg-secondary': !submitStatus}" color="#173169" @click="submitReview()">
          {{ $t("submit_review") }}
        </MstButton>
      </div>
    </div>
  </div>
</template>

<script>

import CloseIcon from "@/components/icons/CloseIcon.vue";
import ReviewItem from "@/components/shop/ReviewItem.vue";
import { MstButton } from "@/components/master";

export default {
  components: {
    CloseIcon,
    MstButton,
    ReviewItem
  },
  data() {
    return {
      submitStatus: true,
    };
  },
  watch: {
    reviewItems: {
      handler(newVal) {
        this.submitStatus = !newVal.some(item => item.status !== 0);
      },
      deep: true,
    },
  },
  computed: {
    reviewItems() {
      return this.$store.state.reviews.reviewItems;
    },
    selectedReviewItemId() {
      return this.$store.state.reviews.selectedReviewItemId;
    }
  },
  methods: {
    onChangeStatus(status, item_id) {
      this.$store.dispatch("reviews/updateReviewItemStatus", { status, item_id });
    },
    closeReviewModal() {
      this.$store.dispatch("commitModalShow", false);
    },
    async submitReview() {
      const reviews = this.reviewItems
        .filter(reviewItem => reviewItem.status !== 0)
        .map(reviewItem => (
          {
            review_id: reviewItem.reviewId || 0,
            flower_id: reviewItem.flowerId,
            shop_id: reviewItem.shopId,
            is_positive: reviewItem.status === 2,
            order_item_id: reviewItem.id
          }
        ));
      await this.$http2(
        "post",
        "reviews",
        { reviews }
      );
      this.$emit("openConfirmModal");
      this.$store.dispatch("commitModalShow", false);
      this.$store.dispatch("reviews/setIsSubmitReview", true);
    },
  }
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  gap: 12px;
  border: 1px solid #888;
  width: 347px;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  max-height: 600px;
  overflow-y: auto;
}

.close {
  color: #aaa;
  position: absolute;
  right: 10px;
  font-size: 14px;
  font-weight: bold;
}

.review-item {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #E9EDEE;
  padding: 16px 8px 16px 8px;
}
.badge {
  border: 1px solid #E9EDEE;
  border-radius: 10px;
  padding: 2px 6px 2px 6px;
  font-size: 10px;
}
.emoticon-layout {
  text-align: center;
  width: 50px;
  flex-direction: column;
  white-space: nowrap;
  font-size: 10px;
}

.bg-disabled {
  background-color: #68727B;
}
</style>
