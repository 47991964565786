<template>
  <component
    :is="tag"
    :to="to"
    :class="styledClasses"
    class="mstHeaderButton"
    @click="$emit('click')"
  >
    <span v-if="$slots.leading" class="mstHeaderButton__leading">
      <slot name="leading" />
    </span>
    <slot name="default" />
  </component>
</template>

<script>
export default {
  name: "MstHeaderButton",
  props: {
    to: { type: String },
    blank: { type: Boolean },
    outlined: { type: Boolean },
  },
  computed: {
    tag() {
      if (this.to) {
        if (this.blank) return "a";
        return "router-link";
      }
      return "button";
    },
    styledClasses() {
      const classes = [];
      if (this.outlined) classes.push("-outlined");
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
.mstHeaderButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  background: variables.$color-white;
  font-size: variables.$font-size-md;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  color: variables.$color-blue-900;

  &.-outlined {
    border: 1px solid variables.$color-white;
    background: transparent;
    color: variables.$color-white;
  }
}

.mstHeaderButton__leading {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
</style>
