<template>
  <div class="cart-product-card">
    <div class="cart-product-card__main">
      <div class="left-column">
        <div class="left-column-upper">
          <ProductImage class="left-column-upper__product-image" border :src="image" />
        </div>
        <div class="left-column-lower">
          <Counter :min="1" :max="max" v-model="valueModel" />
          <div class="left-column-lower__harvest-min-count">口数<span style="display: inline-block;">（1口={{
              orderItem.harvest_min_count
          }}本）</span></div>
        </div>
      </div>
      <div class="right-column">
        <router-link :to="`products/${orderItem.flower_harvest.id}`">
          <div class="right-column-upper">
            <div class="right-column-upper__detail-link">
              <div>
                <span class="right-column-upper__farm-name">{{ orderItem.farm.name }}</span>
                <span class="right-column-upper__genus-name">{{ orderItem.flower.flower_genus
                }}</span>
              </div>
              <img class="right-column-upper__detail-link--toggle" :src="require('@/assets/toggle.svg')" />
            </div>
            <h4 class="right-column-upper__specie-name">{{ orderItem.flower.flower_specie }}</h4>
            <div class="right-column-upper__purchase-deadline">購入期限：<span class="right-column-upper__date">{{
                purchaseDeadline
            }}</span></div>
            <div class="right-column-upper__shipping-date">発送日：<span class="right-column-upper__date">{{ harvestDate
            }}</span></div>
            <div class="right-column-upper__flower-harvest-note">備考： {{ orderItem.flower_harvest.note ? orderItem.flower_harvest.note : 'なし' }}</div>
            <div v-if="orderItem.order_item_type === 'request'" class="right-column-upper__request-answer-deadline">
              リクエスト回答期限：<span class="right-column-upper__date">{{ requestAnswerDeadline }}</span>
            </div>
          </div>
        </router-link>
        <div class="right-column-lower">
          <div>1本：<span class="right-column-lower__sales-price--number">{{ $n(orderItem.sales_price_without_delivery_fee) }}円</span></div>
          <div class="right-column-lower__subtotal">
            {{ totalCount }}本（{{ orderItem.lot_count }}口）：
            <span class="right-column-lower__subtotal--number">
              {{ $n(orderItem.subtotal_sales_price_without_tax_and_delivery_fee) }}円
            </span>
          </div>
          <button class="right-column-lower__cart-delete-link" @click="$emit('delete')">削除する</button>
        </div>
      </div>
    </div>
    <div v-if="isExpired" class="cart-product-card__footer">
      <p class="footer__expired-message">※購入期限を過ぎています。カートから削除してください。</p>
    </div>
    <div v-else-if="outOfStockMessage" class="cart-product-card__footer">
      <p class="footer__expired-message">{{ outOfStockMessage }}</p>
    </div>
  </div>
</template>

<script>
import ProductImage from "@/components/shop/products/ProductImage.vue";
import Counter from "@/components/shared/v2/Counter.vue";

export default {
  components: {
    ProductImage,
    Counter
  },

  props: {
    orderItem: {
      type: Object,
      required: true
    },
    max: {
      type: Number,
      default: undefined
    }
  },

  data() {
    return {
      flowerHarvestId: this.orderItem.flower_harvest.id,
      harvestDate: this.$d(this.orderItem.flower_harvest.harvest_date, "date"),
      purchaseDeadline: this.$d(this.orderItem.flower_harvest.purchase_deadline, "datetime"),
      minLot: this.orderItem.harvest_min_count,
      isExpired: this.orderItem.flower_harvest.is_expired,
      requestAnswerDeadline: this.orderItem.request_answer_deadline ? this.$d(this.orderItem.request_answer_deadline, "datetime") : 0,
      timeoutId: -1
    };
  },

  methods: {
    async editCountHandler(newLotCount) {
      class OrderItemPutError extends Error {
        constructor(status, message) {
          super(message);
          this.status = status;
          this.name = "OrderItemPutError";
        }
      }

      const data = {
        id: this.orderItem.id,
        lotCount: newLotCount,
        subtotalCount: newLotCount * this.minLot,
        flowerHarvestId: this.orderItem.flower_harvest.id,
      };
      try {
        const res_status = await this.$store.dispatch("orders/changeCount", data);
        if (res_status === 521) throw new OrderItemPutError(521, "Failed to change count");
        if (res_status !== 200) throw new Error("Failed to change count");
        this.$store.dispatch("orders/notifyChangeCount");
      } catch (e) {
        if (e.status === 521) {
          this.$store.dispatch("orders/notifyUnbuyableAreaError");
        } else {
          this.$store.dispatch("orders/notifyChangeCountError");
        }
        console.log(e);
      }
    },
  },

  computed: {
    totalCount() {
      return this.orderItem.harvest_min_count * this.orderItem.lot_count;
    },

    image() {
      if (this.orderItem.flower) {
        return this.orderItem.flower.images[0]?.image || "@/assets/preview.jpg";
      }

      return null;
    },

    valueModel: {
      get() {
        return this.orderItem.lot_count;
      },
      set(value) {
        this.$emit("change", { id: this.orderItem.id, lot_count: value });
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(async () => {
          await this.editCountHandler(value);
          this.$emit("complete");
        }, 800);
      }
    },

    outOfStockMessage() {
      if (this.orderItem.order_item_type === "request") return "";
      if (!this.orderItem.flower_harvest.stock_count) return "※商品が売り切れました。カートから削除してください。";
      if (this.orderItem.flower_harvest.stock_count < this.valueModel * this.orderItem.harvest_min_count) return "※商品の在庫が変更されました。数量を変更してください。";
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-product-card {
  background-color: #F1F1F1;
  padding: 10px 16px 10px 12px;
  border-radius: 4px;

  .cart-product-card__main {
    display: flex;

    .left-column {
      width: 85px;
    }

    .right-column {
      flex: auto;
    }
  }
}

.left-column-upper {
  .left-column-upper__product-image {
    width: 85px;
    height: 85px;
    border-radius: 0px;
    object-fit: cover;
    border: none;
  }
}

.right-column-upper {
  margin-left: 12px;
  color: #5E5E5E;
  font-size: 10px;
  line-height: 12px;

  .right-column-upper__detail-link {
    color: #5E5E5E;
    display: flex;
    justify-content: space-between
  }

  .right-column-upper__detail-link--toggle {
    width: 5px;
    height: 10px;
  }

  .right-column-upper__genus-name {
    margin-left: 5px;
    padding: 2px 8px;
    font-size: 10px;
    line-height: 12px;
    color: #191D38;
    border-radius: 9px;
    background-color: #fff;
  }

  .right-column-upper__specie-name {
    margin: 4px 0 0;
    color: #191D38;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
  }

  .right-column-upper__date {
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
  }

  .right-column-upper__purchase-deadline {
    margin: 9px 0 0;
  }

  .right-column-upper__shipping-date,
  .right-column-upper__request-answer-deadline {
    margin: 2px 0 0;
  }

  .right-column-upper__flower-harvest-note {
    margin-top: 6px;
    color: #3E4A55;
  }
}

.left-column-lower {
  .counter-container {
    margin: 8px 0 0;
  }

  .counter-container::v-deep .step {
    width: 23px;
    height: 23px;
    background-color: white;
  }

  .counter-container::v-deep .number {
    width: 39px;
    height: 23px;
    font-size: 16px;
  }

  .left-column-lower__harvest-min-count {
    margin: 3px 0 0;
    color: #5E5E5E;
    font-size: 9px;
    line-height: 11px;
  }
}

.right-column-lower {
  color: #DD5055;
  font-size: 10px;
  line-height: 12px;
  font-weight: bold;
  text-align: right;
  margin-top: 6px;

  .right-column-lower__sales-price--number {
    font-size: 12px;
    line-height: 14px;
  }

  .right-column-lower__subtotal {
    margin: 6px 0 0;
  }

  .right-column-lower__subtotal--number {
    font-size: 16px;
    line-height: 19px;
  }

  .right-column-lower__cart-delete-link {
    display: inline-block;
    margin: 6px 0 0;
    font-size: 12px;
    font-weight: normal;
    line-height: 15px;
    color: #DD5055;
    border-bottom: 1px solid #DD5055;
  }
}

.cart-product-card__footer {
  text-align: right;

  .footer__expired-message {
    margin: 10px 0 0;
    font-size: 12px;
    color: #d6225a;
    font-weight: bold;
  }
}
</style>
