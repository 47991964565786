<template>
  <portal to="portal">
    <transition name="drawer">
      <div v-show="isVisibleModel" v-bind="$attrs" :id="componentId" class="mstDrawer">
        <button
          ref="close"
          type="button"
          aria-label="閉じる"
          class="mstDrawer__close"
          @click="isVisibleModel = false"
        >
          <MstIcon type="close" size="30px" class="mstDrawer__closeIcon" />
        </button>
        <div class="mstDrawer__inner">
          <slot />
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import { nanoid } from "nanoid";
import { MstIcon } from "@/components/master";

export default {
  name: "MstDrawer",
  model: {
    prop: "isVisible",
    event: "change",
  },
  components: { MstIcon },
  props: {
    isVisible: { type: Boolean, required: true },
    id: { type: String },
  },
  data() {
    return {
      focusOrigin: null,
    };
  },
  computed: {
    componentId() {
      return this.id || `drawer-${nanoid()}`;
    },
    isVisibleModel: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.$emit("change", value);
      }
    },
  },
  watch: {
    isVisibleModel(newValue) {
      if (newValue) {
        this.focusOrigin = document.activeElement;
      }

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.updateFocus(newValue);
          this.updateFocusables(newValue);
        });
      });
    },
  },
  methods: {
    updateFocusables(isVisible) {
      const focusables = document.querySelectorAll("a, button, input, textarea, select, [tabindex]");

      focusables.forEach(focusable => {
        if (focusable.closest(`#${this.componentId}`)) return;
        if (isVisible) {
          const defaultTabindex = focusable.getAttribute("tabindex");
          if (defaultTabindex) focusable.setAttribute("data-tabindex", defaultTabindex);
          focusable.setAttribute("tabindex", "-1");
        } else {
          focusable.removeAttribute("tabindex");
          const defaultTabindex = focusable.getAttribute("data-tabindex");
          if (defaultTabindex) {
            focusable.setAttribute("tabindex", defaultTabindex);
            focusable.removeAttribute("data-tabindex");
          }
        }
      });
    },
    updateFocus(isVisible) {
      if (isVisible) {
        this.$refs.close.focus();
      } else {
        this.focusOrigin.focus();
        this.focusOrigin = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mstDrawer {
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: variables.$color-brand-secondary;
}

.mstDrawer__close {
  position: absolute;
  top: 10px;
  right: 10px;
  outline: none;
  border-radius: 50%;
  padding: 5px;
  line-height: 1;
  vertical-align: top;
  color: variables.$color-white;
  transition: background 0.3s ease;

  &:hover,
  &:focus-visible {
    background: variables.$color-brand-primary;
  }
}

.mstDrawer__inner {
  padding: 52px 0 28px;
}

.drawer-enter-active,
.drawer-leave-active {
  transition: transform 0.3s ease;
}

.drawer-enter,
.drawer-leave-to {
  transform: translateX(100%);
}
</style>
