<template>
  <portal to="portal">
    <transition name="content" @after-enter="handleVisibleEnd" @before-leave="handleHideStart">
      <div
        v-if="isVisible"
        ref="content"
        :id="componentId"
        :style="contentStyled"
        tabindex="-1"
        class="mstBottomSheetContent"
      >
        <button
          type="button"
          aria-label="閉じる"
          class="mstBottomSheetContent__close"
          @click="handleClose"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
        />
        <div class="mstBottomSheetContent__inner">
          <slot />
        </div>
      </div>
    </transition>

    <div v-if="isBgVisible" :style="bgStyled" class="mstBottomSheetBg" />

    <div v-if="isVisible" :id="componentId" class="mstBottomSheetOverlay" @click="handleClose" />
  </portal>
</template>

<script>
import { nanoid } from "nanoid";

export default {
  name: "MstBottomSheet",
  model: {
    prop: "isVisible",
    event: "change:isVisible",
  },
  props: {
    isVisible: { type: Boolean, required: true },
    id: { type: String },
  },
  data() {
    return {
      focusOrigin: null,
      swipe: {
        start: 0,
        move: 0,
        diff: 0,
      },
      isBgVisible: false,
      isSwipe: false,
      isSwipeClosing: false,
    };
  },
  computed: {
    componentId() {
      return this.id || `bottom-sheet-${nanoid()}`;
    },
    contentStyled() {
      const styles = {};
      if (!this.isVisible) return styles;

      if (this.isSwipeClosing) {
        styles.transition = "transform 0.3s ease";
        styles.transform = "translateY(100%)";
        return styles;
      }

      if (!this.swipe.diff) return styles;

      if (this.isSwipe) {
        styles.transition = "none";
      }

      styles.transform = `translateY(${this.swipe.diff}px)`;

      return styles;
    },
    bgStyled() {
      const styles = {};
      if (!this.isBgVisible || !this.swipe.diff) return styles;

      if (this.isSwipe) {
        styles.transition = "none";
      }

      styles.height = `${-this.swipe.diff + 10}px`;
      return styles;
    },
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        this.focusOrigin = document.activeElement;
        window.addEventListener("keydown", this.handleEscKey);
      } else {
        window.removeEventListener("keydown", this.handleEscKey);
      }

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.updateFocusableOutside();
          this.updateFocusPosition();
        });
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEscKey);
  },
  methods: {
    updateFocusableOutside() {
      const elements = document.querySelectorAll("a, button, input, textarea, select, [tabindex]");
      elements.forEach(element => {
        if (element.closest(`#${this.componentId}`)) return;
        if (this.isVisible) {
          const defaultTabindex = element.getAttribute("tabindex");
          if (defaultTabindex) element.setAttribute("data-tabindex", defaultTabindex);
          element.setAttribute("tabindex", "-1");
        } else {
          const defaultTabindex = element.getAttribute("data-tabindex");
          if (defaultTabindex) {
            element.setAttribute("tabindex", defaultTabindex);
            element.removeAttribute("data-tabindex");
          } else {
            element.removeAttribute("tabindex");
          }
        }
      });
    },
    updateFocusPosition() {
      if (this.isVisible) {
        this.$refs.content.focus();
      } else {
        if (!this.focusOrigin) return;
        this.focusOrigin.focus();
        this.focusOrigin = null;
      }
    },
    handleEscKey(event) {
      if (event.key !== "Escape") return;
      this.$emit("change:isVisible", false);
    },
    handleClose() {
      this.$emit("change:isVisible", false);
    },
    handleTouchStart(event) {
      event.preventDefault();
      this.swipe.start = event.touches[0].pageY;
      this.isSwipe = true;
    },
    handleTouchMove(event) {
      if (event.touches.length > 1 || (event.scale && event.scale !== 1)) return;
      event.preventDefault();
      this.swipe.move = event.touches[0].pageY;

      const diff = this.swipe.move - this.swipe.start;
      this.swipe.diff = diff < 0 ? diff / 6 : diff;
    },
    handleTouchEnd() {
      this.isSwipe = false;

      if (this.swipe.diff > 50) {
        this.isSwipeClosing = true;
        window.setTimeout(() => {
          this.$emit("change:isVisible", false);
          this.isSwipeClosing = false;
        }, 150);
      }

      this.swipe.start = 0;
      this.swipe.move = 0;
      this.swipe.diff = 0;
    },
    handleVisibleEnd() {
      this.isBgVisible = true;
    },
    handleHideStart() {
      this.isBgVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mstBottomSheetContent {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1202;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1);
  width: 100vw;
  background: variables.$color-white;
  transition: transform 0.3s ease;
}

.mstBottomSheetContent__close {
  position: absolute;
  top: 8px;
  left: 50%;
  margin-left: -34px;
  width: 68px;
  height: 24px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    border-radius: 2px;
    width: 100%;
    height: 4px;
    background: variables.$color-gray-500;
  }
}

.mstBottomSheetContent__inner {
  overflow-y: auto;
  padding: 40px 15px 32px;
  max-height: 90vh;
}

.mstBottomSheetOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  width: 100vw;
  height: 100vh;
}

.mstBottomSheetBg {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1201;
  width: 100%;
  height: 10px;
  background: variables.$color-white;
  transition: height 0.3s ease;
}

.content-enter-active,
.content-leave-active {
  transition: transform 0.3s ease;
}

.content-enter,
.content-leave-to {
  transform: translateY(100%);
}
</style>
