<template>
  <v-btn class="button" :class="styledClasses" :style="styles" :disabled="(disabled || busy)" :href="to" @click="click">
    <slot />
  </v-btn>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: ""
    },
    primary: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    heavy: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
      busy: false
    };
  },
  computed: {
    styledClasses() {
      const styledClasses = [];
      if (this.primary) styledClasses.push("-primary");
      if (this.outlined) styledClasses.push("-outlined");
      if (this.heavy) styledClasses.push("-heavy");
      return styledClasses;
    },
    styles() {
      return {
        "--button-width": this.width
      };
    }
  },
  methods: {
    async click(e) {
      this.$emit("click", e);
      if (this.onClick) {
        this.busy = true;
        await this.onClick(e);
        this.busy = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: var(--button-width);
  display: inline-flex;
  box-shadow: none;
  padding: 12px 12px !important;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;

  &.-primary {
    background-color: #4A8FCF !important;
    color: #fff;
  }

  &.-outlined {
    background-color: #fff !important;
    color: #4A8FCF;
    border: #4A8FCF solid 1px;
  }

  &.-heavy {
    font-weight: 600;
    font-size: 16px;
  }

  ::v-deep .v-btn__content {
    width: 100%
  }
}
</style>
