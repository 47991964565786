import { render, staticRenderFns } from "./dateBaseList.vue?vue&type=template&id=ca2688c0&scoped=true&"
import script from "./dateBaseList.vue?vue&type=script&lang=js&"
export * from "./dateBaseList.vue?vue&type=script&lang=js&"
import style0 from "./dateBaseList.vue?vue&type=style&index=0&id=ca2688c0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca2688c0",
  null
  
)

export default component.exports