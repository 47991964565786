<template>
  <a :href="officialLineUrl" target="_blank" class="d-flex align-center justify-center icon-wrapper">
    <div class="icon">?</div>
  </a>
</template>

<script>
export default {
  computed: {
    officialLineUrl() {
      return process.env.VUE_APP_X_OFFICIAL_LINE_URL;
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #3794e3;
  z-index: 1;
}
.icon {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
}
</style>
