<template>
  <div class="setting-text-input">
    <div class="label" :class="{ required }">{{ label }}</div>
    <div v-if="id==='prefecture'">
      <v-select
        :items="prefectures"
        v-model="prefecture"
        @change="e=>$emit('input', e)"
        :error="error"
        outlined
      />
    </div>

    <input
      v-else
      class="input"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      :required="required || semiRequired"
      @blur="validate"
      @input="$emit('input', $event.target.value)"
      ref="input"
    />
  </div>
</template>

<script>
import { PREFECTURES } from "@/consts";

export default {
  props: {
    id: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    semiRequired: {
      type: Boolean,
      default: false
    },
    match: {
      type: String,
      default: ""
    },
    mismatchMessage: {
      type: String,
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      prefecture: this.value,
      prefectures: PREFECTURES
    };
  },

  computed: {
    invalidMessage() {
      return this.value && this.match && this.match !== this.value
        ? this.mismatchMessage
        : "";
    }
  },

  methods: {
    validate() {
      const value = this.$refs.input.value;

      switch (this.id) {
        case "phone": {
          const v = value.replace(/\s/g, "").length;
          if (v < 9 || v > 11) {
            this.$refs.input.setCustomValidity(this.$t("invalid_phone"));
          } else {
            this.$refs.input.setCustomValidity("");
          }
          break;
        }

        case "zip":
          if (value.match(/\d/g).join("").length !== 7) {
            this.$refs.input.setCustomValidity(this.$t("invalid_zip"));
          } else {
            this.$refs.input.setCustomValidity("");
          }
          break;

        case "municipality":
          if (!value.replace(/\s/g, "").length) {
            this.$refs.input.setCustomValidity(this.$t("invalid_municipality"));
          } else {
            this.$refs.input.setCustomValidity("");
          }
          break;

        case "address":
          if (!value.replace(/\s/g, "").length) {
            this.$refs.input.setCustomValidity(this.$t("invalid_address"));
          } else {
            this.$refs.input.setCustomValidity("");
          }
          break;

        default:
          break;
      }
    }
  },

  watch: {
    invalidMessage(message) {
      this.$refs.input.setCustomValidity(message);
    }
  }
};
</script>

<style lang="scss" scoped>
.setting-text-input {
  & + & {
    margin-top: 30px;
  }
}

.label {
  color: #777;
  font-size: 16px;

  &.required::after {
    margin-left: 4px;
    content: "*";
    font-size: 16px;
    font-weight: bold;
    color: #c85c45;
  }
}
</style>
