<template>
  <div class="date-picker d-flex align-center">
    <div class="move prev d-flex align-center justify-center" @click="move(-1)">
      <ChevronIcon class="arrow" size="24" />
    </div>

    <div v-resize="onResize" class="date-list flex-grow-1" ref="dateList">
      <div
        v-for="date in dateList()"
        :key="date"
        class="card"
        :class="{ selected: date === selected }"
        @click="$emit('change', toDateKey(date))"
      >
        <div class="day">{{ $d(date, 'day') }}</div>
        <div class="date">{{ $d(date, 'shortDate') }}</div>
      </div>
      <div v-if="noData" class="d-flex justify-center">{{ $t("no_data") }}</div>
    </div>
    <div class="move next d-flex align-center justify-center" @click="move(1)">
      <ChevronIcon size="24" class="arrow" />
    </div>
  </div>
</template>

<script>
import ChevronIcon from "@/components/icons/ChevronIcon.vue";
import { formatDate } from "@/plugins/i18n";

export function toDateKey(date) {
  return formatDate(date, "yyyy-mm-dd");
}

export function addDate(date, value) {
  date = new Date(date);
  date.setDate(date.getDate() + value);
  return date;
}

export function nextDateKey(date = null, step = 1, allowWdays) {
  date = date ? new Date(date) : new Date();
  if (!allowWdays || !allowWdays.length) return toDateKey(date);
  const wdays = allowWdays && allowWdays.length > 0 ? allowWdays : [0, 1, 2, 3, 4, 5, 6];
  while (!wdays.includes(date.getDay())) {
    date = addDate(date, step);
  }

  return toDateKey(date);
}

export default {
  model: {
    prop: "selected",
    event: "change"
  },

  components: {
    ChevronIcon
  },

  props: {
    selected: {
      type: String,
      default: ""
    },
    availableDates: {
      type: Array,
      default: null
    },
    allowWdayList: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      centerDate: nextDateKey(this.selected, 1, this.allowWdayList),
      length: 1,
      noData: true
    };
  },
  watch: {
    selected(x) {
      this.centerDate = x;
    }
  },

  computed: {},

  methods: {
    onResize() {
      const width = this.$refs.dateList.offsetWidth;
      let i = 1;
      while (width > (i * 2 + 1) * 150) i++;
      this.length = i;
    },

    toDateKey,

    nextDateKey,

    move(step) {
      if (this.availableDates && this.availableDates.length) {
        const last = this.availableDates[this.availableDates.length - 1];
        let date = nextDateKey(addDate(this.centerDate, step), step, this.allowWdayList);

        while (!this.availableDates.includes(date)) {
          if (step === 1) {
            if (new Date(date) >= new Date(last)) {
              this.centerDate = last;
              return;
            }
          } else if (new Date(date) <= new Date(this.availableDates[0])) {
            this.centerDate = this.availableDates[0];
            return;
          }
          date = nextDateKey(addDate(date, step), step, this.allowWdayList);
        }
        this.centerDate = date;
      } else {
        this.centerDate = nextDateKey(addDate(this.centerDate, step), step, this.allowWdayList);
      }
    },

    dateList() {
      const list = [];
      let idx;
      let date1 = this.centerDate;
      let date2 = this.centerDate;

      if (this.availableDates && this.availableDates.indexOf(this.centerDate) > -1) {
        list.push(this.centerDate);
      } else if (!this.availableDates) {
        list.push(this.centerDate);
      }

      for (let i = 0; i < this.length; i++) {
        if (this.availableDates && this.availableDates.length) {
          idx = this.availableDates.indexOf(date1);
          if (idx > -1) {
            date1 = this.availableDates[idx + 1];
            if (date1) list.push(date1);
          }

          idx = this.availableDates.indexOf(date2);
          if (idx > -1) {
            date2 = this.availableDates[idx - 1];
            if (date2) list.unshift(date2);
          }
        } else {
          list.push((date1 = nextDateKey(addDate(date1, 1), 1, this.allowWdayList)));
          list.unshift((date2 = nextDateKey(addDate(date2, -1), -1, this.allowWdayList)));
        }
      }

      this.noData = !list.length;

      return list;
    }
  }
};
</script>

<style lang="scss" scoped>
.date-picker {
  padding: 0 16px;
  user-select: none;

  @media screen and (max-width: 960px) {
    padding: 0;
  }
}

.date-list {
  margin: 0 8px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 5px;
}

.card {
  background-color: #fff;
  border-radius: 3px;
  padding: 6px;
  text-align: center;
  color: #555;
  cursor: pointer;

  &.selected {
    color: #fff;
    font-weight: bold;
    background-color: #007e57;
  }
}

.day {
  background-color: #f5f5f5;
  border-radius: 3px;
  font-size: 12px;
  line-height: 18px;
  color: #777;
  margin-bottom: 4px;

  @media screen and (max-width: 960px) {
    font-size: 14px;
    line-height: 24px;
  }

  .selected & {
    background-color: #339879;
    color: #fff;
  }
}

.date {
  font-size: 18px;
  line-height: 28px;

  @media screen and (max-width: 960px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.move {
  width: 36px;
  height: 50px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  fill: #333;
  cursor: pointer;

  &.prev .arrow {
    transform: scaleX(-1);
  }

  @media screen and (max-width: 960px) {
    width: 26px;
    height: 56px;
  }
}
</style>
