<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24">
    <path :fill="color" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>

<style>
</style>
