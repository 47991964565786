<template>
  <div>
    <v-card v-if="mobile" class="d-flex justify-space-between">
      <div class="d-flex align-center justify-center align-self-stretch">
        <div
          class="mobile-menu align-self-stretch d-flex align-center flex-column justify-center ga-farm-hamburger"
          @click="onMobileMenu"
        >
          <MenuIcon color="black" size="36" />
        </div>
      </div>

      <router-link to="/farm/dashboard">
        <img :src="require('@/assets/CAVIN_Logo_black.png')" style="width: 110px" class="ma-4" />
      </router-link>

      <div class="d-flex align-center justify-center user-icon-wrap">
        <router-link to="/farm/setting/account">
          <UserIcon color="black" size="24" />
        </router-link>
      </div>
    </v-card>

    <v-card v-else>
      <v-row>
        <v-col cols="3">
          <router-link to="/farm/dashboard">
          <img :src="require('@/assets/CAVIN_Logo_black.png')" style="width: 120px" class="ma-4" />
          </router-link>
        </v-col>

        <v-col cols="3">
          <div>
            <small>{{ $t("welcome") }}</small>
          </div>
          <span class="user-name">{{ getUserName }}</span>
          <small>{{ $t("sama") }}</small>
        </v-col>

        <v-col cols="6" class="d-flex align-center justify-end">
          <div
            class="d-flex align-center mr-10"
            style="cursor:pointer"
            @click="logout"
          >{{ $t("logout") }}</div>

        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuIcon from "@/components/icons/MenuIcon.vue";
import UserIcon from "@/components/icons/UserIcon.vue";

export default {
  components: { MenuIcon, UserIcon },

  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs
    };
  },
  computed: {
    ...mapGetters(["getUserName"])
  },
  mounted() {
    this.$store.dispatch("conversations/getUnreadMessagesCount");
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    onMobileMenu() {
      this.$store.dispatch("setMobileMenuOpen", true);
    }
  }
};
</script>

<style scoped>
.user-name {
  font: Bold 1rem Helvetica;
  color: #0c1624;
}

.pointer {
  cursor: pointer;
}

.mobile-user {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 20px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
}

.mobile-menu {
  border-radius: 0;
  width: 4rem;
}

.user-icon-wrap {
  width: 4rem;
}

@media only screen and (max-width: 600px) {
    .user-name {
    font-size: .8rem;
  }
}
.menu_label {
  font-size: 10px;
}
</style>
