<template>
  <div class="wrapper">
    <div class="calendar-overlay" ></div>
    <div class="calendar-wrapper">
      <div class="calendar-title">出荷日を選択</div>
      <div class="current-month-wrapper">
        <div class="current-month">{{ formattedCurrentMonth }}</div>
        <div class="arrows-wrapper">
          <img
            @click="onClickLeftArrowIcon()"
            class="arrow-icon" :src="require('@/assets/left-arrow-icon.svg')"
          />
          <img
            @click="onClickRightArrowIcon()"
            class="arrow-icon" :src="require('@/assets/right-arrow-icon.svg')"
          />
        </div>
      </div>
      <div class="days-wrapper">
        <div class="day">日</div>
        <div class="day">月</div>
        <div class="day">火</div>
        <div class="day">水</div>
        <div class="day">木</div>
        <div class="day">金</div>
        <div class="day">土</div>
      </div>
      <div class="dates-wrapper">
        <div
        :class="[
        'date',
        isDisableDate(date) ? 'disable' : '',
        isBetweenStartDateAndEndDate(date) ? 'is-between' : '',
        isSelectDate(date) ? 'active' : ''
        ]"
        v-for="date in dateRange" :key="date.toString()"
        @click="onCilckDate(date)"
        >
          <div v-if="isSelectDate(date)" class="selected-background"></div>
          {{ formatD(date) }}
        </div>
      </div>
      <div class="buttons-wrapper">
        <button @click="onClickCancelButton()" class="cancel-button">{{  $t('cancel') }}</button>
        <button @click="onClickConfirmButton()" class="select-button">{{ $t('selection') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import "dayjs/locale/ja";

dayjs.locale("ja");

export default {
  props: {
    startDate: Date,
    endDate: Date
  },
  data() {
    return {
      currentMonth: dayjs().startOf("month").toDate(),
      selectedDateRange: {
        start: dayjs(this.startDate).toDate(),
        end: null,
      }
    };
  },
  computed: {
    dateRange() {
      const startDate = dayjs(this.currentMonth).startOf("month").startOf("week");
      const endDate = dayjs(this.currentMonth).endOf("month").endOf("week");

      const dates = [];
      for (let date = startDate; date <= endDate; date = date.add(1, "day")) {
        dates.push(date.toDate());
      }
      return dates;
    },
    formattedCurrentMonth() {
      return dayjs(this.currentMonth).format("YYYY年MM月");
    },
  },
  methods: {
    formatD(d) {
      return dayjs(d).format("D");
    },
    onCilckDate(date) {
      if (!this.selectedDateRange.start || (this.selectedDateRange.start && this.selectedDateRange.end)) {
        // 開始日がNULL、もしくは開始日・終了日が設定されている場合は開始日をセット
        this.selectedDateRange.start = date;
        this.selectedDateRange.end = null;
      } else if (date < this.selectedDateRange.start) {
        // 選択された日付が開始日より前の場合は、開始日をサイドセット
        this.selectedDateRange.start = date;
      } else {
        // それ以外は終了日をセット
        this.selectedDateRange.end = date;
      }
    },
    isBetweenStartDateAndEndDate(value) {
      const date = dayjs(value);
      if (!this.selectedDateRange.start || !this.selectedDateRange.end) return false;

      return date > dayjs(this.selectedDateRange.start) && date < dayjs(this.selectedDateRange.end);
    },
    isDisableDate(value) {
      const date = dayjs(value);
      if (!this.selectedDateRange.start) return false;

      return date < dayjs(this.selectedDateRange.start);
    },
    isSelectDate(value) {
      const date = dayjs(value).format("YYYY-MM-DD");
      const startDate = dayjs(this.selectedDateRange.start);
      const endDate = dayjs(this.selectedDateRange.end);

      return startDate.isSame(date, "day") || endDate.isSame(date, "day");
    },
    onClickRightArrowIcon() {
      this.currentMonth = dayjs(this.currentMonth).add(1, "month");
    },
    onClickLeftArrowIcon() {
      this.currentMonth = dayjs(this.currentMonth).subtract(1, "month");
    },
    onClickCancelButton() {
      this.$emit("close");
    },
    onClickConfirmButton() {
      const start = this.selectedDateRange.start;
      const end = this.selectedDateRange.end || start;

      this.$emit("confirm", start, end);
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}

.calendar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.calendar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 360px;
  transform: translate(-50%, -50%);
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #EAEAEA;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.10);

  .calendar-title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 14px;
  }

  .current-month-wrapper {
    display: flex;
    padding: 11px 0;
    .current-month {
      font-size: 17px;
      font-weight: 590;
      letter-spacing: -0.5px;
      line-height: 24px;
    }

    .arrows-wrapper {
      display: flex;
      margin-left: auto;
      gap: 22px;

      .arrow-icon {
        width: 15px;
        height: 22px;
      }
    }
  }

  .days-wrapper {
    display: flex;
    justify-content: center;
    color: rgba(60, 60, 67, 0.30);
    gap: 7px;
    .day {
      cursor: pointer;
      width: 40px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      font-weight: 590px;
    }
  }

  .dates-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 4px;
    .date {
      position: relative;
      width: 46.5px;
      height: 35px;
      margin: 5px 0;
      line-height: 35px;
      text-align: center;
      font-weight: 590px;
      .selected-background {
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: rgba(49, 81, 152, 1);
        border-radius: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        z-index: -1;
      }
    }

    .disable {
      color: gray;
    }

    .active {
      color: white;
    }

    .is-between {
      background-color: #E6EAF3;
    }
  }

  .buttons-wrapper {
    margin-top: 26px;
    display: flex;
    gap: 8px;
    justify-content: center;

    .cancel-button {
      width: 160px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      font-size: 16px;
      color: #173169;
      border: 1px solid #173169;
      border-radius: 4px;
    }

    .select-button {
      width: 160px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      font-size: 16px;
      color: #ffffff;
      background-color: #173169;
      border-radius: 4px;
    }
    .disable {
      background: #68727B;
    }
  }
}
</style>
