export default {
  namespaced: true,
  state: {
    selectedReviewItemId: null,
    isSubmitReview: false,
    reviewItems: [],
  },
  mutations: {
    setSelectedReviewItemId(state, payload) {
      state.selectedReviewItemId = payload;
    },
    setReviewItems(state, payload) {
      state.reviewItems = payload;
    },
    setIsSubmitReview(state, payload) {
      state.isSubmitReview = payload;
    }
  },
  actions: {
    setSelectedReviewItemId({ commit }, payload) {
      commit("setSelectedReviewItemId", payload);
    },
    setReviewItems({ commit }, payload) {
      commit("setReviewItems", payload);
    },
    updateReviewItemStatus({ state, commit }, payload) {
      const reviewItems = state.reviewItems;
      reviewItems.map(reviewItem => {
        if (reviewItem.id === payload.item_id) reviewItem.status = payload.status;
        return reviewItem;
      });
      commit("setReviewItems", reviewItems);
    },
    setIsSubmitReview({ commit }, payload) {
      commit("setIsSubmitReview", payload);
    }
  }
};
