export default function(location) {
  if (typeof location === "undefined") {
    return null;
  }

  if (!location.area) {
    return "※エリアが未設定です。CAVINの管理者に連絡をしてください。";
  }

  return `${location.area?.warehouse.name} - ${location.area?.drivers[0]?.name}`;
}
