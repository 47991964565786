<template>
  <div class="conversation">
    <ConversationHeader :to-name="to" class="conversation__header" />
    <div ref="content" class="conversation__content">
      <MstIntersection v-if="hasUnloadMessages" root-margin="100px" @intersect="getUnloadMessages" />
      <div class="conversation__messages">
        <ConversationContent :current-user-type="currentUserType" :content="messages" />
      </div>
    </div>
    <InputForm
      v-model="newMessage"
      class="conversation__form"
      @submit:message="postMessage"
      @submit:image="postMessage"
    />
  </div>
</template>

<script>
import { MstIntersection } from "@/components/master";
import { ConversationHeader, ConversationContent, InputForm } from "@/components/domains/conversations";

export default {
  components: { MstIntersection, ConversationHeader, ConversationContent, InputForm },
  data() {
    return {
      newMessage: "",
      messages: [],
      initialized: false,
      hasUnloadMessages: true,
    };
  },
  computed: {
    conversationId() {
      return Number(this.$route.params.conversationId);
    },
    currentUserType() {
      return this.$route.matched[1].name;
    },
    conversation() {
      return this.$store.getters["conversations/getConversationById"](Number(this.conversationId));
    },
    to() {
      if (!this.conversation) return "";
      if (this.currentUserType === "Shop") {
        return this.conversation.farm.name;
      }
      return this.conversation.shop.name;
    },
  },
  watch: {
    initialized() {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
  },
  async created() {
    if (!this.conversation) {
      await this.$store.dispatch("conversations/getConversations");
    }
    this.messages = await this.getMessages();
    await this.onloadImages();
    this.initialized = true;
    if (this.messages.length < 20) {
      this.hasUnloadMessages = false;
    }
    this.$store.dispatch("conversations/getUnreadMessagesCount");
  },
  methods: {
    onloadImages() {
      const onloadImage = src => new Promise((resolve, reject) => {
        const image = new Image();
        image.src = src;
        image.onload = () => resolve();
        image.onerror = error => reject(error);
      });
      const images = this.messages
        .filter(message => !!message.attachment)
        .map(message => message.attachment.payload.image_url);

      const loading = images.map(src => onloadImage(src));

      return Promise.all(loading);
    },
    async getMessages(beforeId, count = 20) {
      let url = `conversations/${this.conversationId}/messages?count=${count}`;
      if (beforeId) url += `&before_id=${beforeId}`;

      const response = await this.$http("get", url);
      return response.result.messages.map(message => ({
        ...message,
        body: message.body || "",
      }));
    },
    async postMessage(image = "") {
      try {
        const requestBody = {
          body: "",
        };

        if (image) {
          requestBody.image = {
            data: image,
          };
        } else {
          requestBody.message = {
            body: this.newMessage.trim(),
          };
        }

        const { result } = await this.$http("post", `conversations/${this.conversationId}/messages`, requestBody);
        this.messages = this.messages.concat([result.message]);
        this.newMessage = "";
        this.scrollToBottom();
      } catch (error) {
        console.error(error);
      }
    },
    async getUnloadMessages(isIntersecting) {
      if (!isIntersecting || !this.initialized) return;
      const responses = await this.getMessages(this.messages[0].id);
      this.messages = responses.concat(this.messages);
      this.updateScrollPosition();

      if (!responses.length) {
        this.hasUnloadMessages = false;
      }
    },
    updateScrollPosition() {
      const beforeHeight = this.$refs.content.scrollHeight;
      const scrollTop = this.$refs.content.scrollTop;

      this.$nextTick(() => {
        const afterHeight = this.$refs.content.scrollHeight;
        this.$refs.content.scrollTo(0, scrollTop + afterHeight - beforeHeight);
      });
    },
    scrollToBottom() {
      if (!this.$refs.content) return;
      this.$nextTick(() => {
        this.$refs.content.scrollTo(0, this.$refs.content.scrollHeight);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation {
  position: relative;
  display: grid;
  grid-template-rows: auto max-content;
  padding: 0;
  height: calc(100vh - 106px);
  min-height: 0;
}

.conversation__content {
  overflow-y: auto;
  padding: 50px 0 32px;
  background: #9ec0d5;
}

.conversation__header {
  position: absolute;
  top: 0;
  z-index: 3;
  width: 100%;
}

.conversation__messages {
  padding: 0 15px;
}

.conversation__form {
  position: relative;
  z-index: 2;
  width: 100%;
}
</style>

<style lang="scss">
.v-application--wrap {
  &:has(.conversation) {
    overflow: hidden;
    height: 100vh;
  }
}
</style>
