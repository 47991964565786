<template>
  <portal to="portal" :disabled="noTeleport">
    <transition name="content">
      <div v-if="isVisibleModel" :id="componentId" :class="styledClasses" class="mstModal">
        <button v-if="!noClosable" type="button" aria-label="閉じる" class="mstModal__close" @click="handleClickClose">
          <MstIcon type="close" size="20px" class="mstModal__closeIcon" />
        </button>
        <div ref="content" tabindex="-1" class="mstModal__content">
          <slot />
        </div>
      </div>
    </transition>

    <transition name="overlay">
      <div v-if="isVisibleModel" class="mstModalOverlay" @click="handleClickOverlay" />
    </transition>
  </portal>
</template>

<script>
import { nanoid } from "nanoid";
import { MstIcon } from "@/components/master";

export default {
  name: "MstModal",
  model: {
    prop: "isVisible",
    event: "change:isVisible",
  },
  components: { MstIcon },
  props: {
    isVisible: { type: Boolean, required: true },
    id: { type: String },
    persistent: { type: Boolean },
    viewer: { type: Boolean },
    noClosable: { type: Boolean },
    noTeleport: { type: Boolean },
  },
  data() {
    return {
      focusOrigin: null,
    };
  },
  computed: {
    componentId() {
      return this.id || `modal-${nanoid()}`;
    },
    isVisibleModel: {
      get() {
        return this.isVisible;
      },
      set(newValue) {
        this.$emit("change:isVisible", newValue);
      },
    },
    styledClasses() {
      const classes = [];
      if (this.viewer) classes.push("-viewer");
      return classes;
    },
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        window.addEventListener("keydown", this.handleKeydownEsc);
        this.focusOrigin = document.activeElement;
      } else {
        window.removeEventListener("keydown", this.handleKeydownEsc);
      }

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.updateFocusPosition();
          this.updateFocusableOutside();
          if (!newValue) this.focusOrigin = null;
        });
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydownEsc);
  },
  methods: {
    updateFocusPosition() {
      if (this.isVisible) {
        if (!this.$refs.content) return;
        this.$refs.content.focus();
      } else {
        this.focusOrigin.focus();
      }
    },
    updateFocusableOutside() {
      const elements = document.querySelectorAll("a, button, input, textarea, select, [tabindex]");
      elements.forEach(element => {
        if (element.closest(`#${this.componentId}`)) return;
        if (this.isVisible) {
          const defaultTabindex = element.getAttribute("tabindex");
          if (defaultTabindex) element.setAttribute("data-tabindex", defaultTabindex);
          element.setAttribute("tabindex", "-1");
        } else {
          const defaultTabindex = element.getAttribute("data-tabindex");
          if (defaultTabindex) {
            element.setAttribute("tabindex", defaultTabindex);
            element.removeAttribute("data-tabindex");
          } else {
            element.removeAttribute("tabindex");
          }
        }
      });
    },
    handleKeydownEsc(event) {
      if (event.key !== "Escape" || this.persistent) return;
      this.isVisibleModel = false;
    },
    handleClickClose() {
      this.isVisibleModel = false;
    },
    handleClickOverlay() {
      if (this.persistent) return;
      this.isVisibleModel = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mstModal {
  position: fixed;
  z-index: 1501;
  top: 50%;
  left: 50%;
  padding: 32px 0 0;
  width: calc(100vw - 30px);
  max-width: 650px;
  transform: translate(-50%, -50%);

  &.-viewer {
    width: 100%;
  }
}

.mstModal__close {
  position: absolute;
  top: 0;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  outline: none;
  color: variables.$color-white;

  &:focus-visible {
    background: rgba(0, 0, 0, 0.2);
  }

  .-viewer & {
    right: 8px;
  }
}

.mstModal__content {
  overflow-y: auto;
  border-radius: 6px;
  padding: 16px 15px;
  max-height: calc(84vh - 32px);
  background: variables.$color-white;

  .-viewer & {
    border-radius: 0;
    padding: 0;
    background: transparent;
    text-align: center;
  }
}

.mstModalOverlay {
  position: fixed;
  z-index: 1500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.72);
}

.content-enter-active,
.content-leave-active,
.overlay-enter-active,
.overlay-leave-active {
  transition: opacity 0.3s ease;
}

.content-enter-to,
.overlay-leave-to {
  transition-delay: 0.1s;
}

.content-enter,
.content-leave-to,
.overlay-enter,
.overlay-leave-to {
  opacity: 0;
}
</style>
