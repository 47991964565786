<template>
  <div>
    <PageContainer class="cart-layout">
      <h1 class="cart-title">{{ title }}</h1>
      <div class="content mb-8">
        <slot />
      </div>
      <div class="price">
        <CartPriceCard :order="order" :button-text="nextText" :button-to="nextTo" @button-click="$emit('next')" />
        <div class="prev">
          <Button outlined heavy class="button-prev" :to="prevTo">{{ prevText }}</Button>
        </div>
      </div>
    </PageContainer>
  </div>
</template>

<script>
import PageContainer from "@/components/shop/PageContainer.vue";
import CartPriceCard from "@/components/shop/cart/CartPriceCard.vue";
import Button from "@/components/shared/v2/Button.vue";

export default {
  components: {
    PageContainer,
    CartPriceCard,
    Button
  },

  props: {
    title: {
      type: String,
      default: ""
    },
    nextText: {
      type: String,
      default: ""
    },
    nextTo: {
      type: String,
      default: ""
    },
    prevText: {
      type: String,
      default: ""
    },
    prevTo: {
      type: String,
      default: ""
    },
    order: {
      type: Object,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-layout {
  display: grid;
  grid-template-columns: 1fr 268px;
  grid-gap: 0 8px;

  @media screen and (max-width: 960px) {
    display: block;
  }
}

.cart-title {
  grid-column: span 2;
  margin: 12px 0 18px;
  color: #191D38;
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  text-align: center;
}

.prev {
  padding: 16px;

  .button-prev{
    width: 100%;
  }
}
</style>
