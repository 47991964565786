<template>
  <div @drop.prevent="e=>$emit('drop', e)" @dragover.prevent>
    <slot />
  </div>
</template>

<script>
export default {

};
</script>

<style>

</style>
