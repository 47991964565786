<template>
    <div class="request-order-container">
        <h2>リクエスト</h2>
        <div class="current-count-container">現在カート内口数：<span class="current-count">{{ currentCartCount }}</span>口</div>
        <div class="counter-container">
            リクエスト口数：
            <Counter v-model="valueModel" :disabled="isPurchaseDeadlineExpired" :min="0" />
        </div>
        <p>※{{ $date.dateJp(new Date(requestAnswerDeadline)) }}までに生産者さんが承認した場合のみ、「注文確定」となります。ご了承ください。</p>
        <p>より早く確定させたい場合：上部のメッセージボタンより生産者と直接やりとりをしてみましょう。</p>
        <Button v-if="isPurchaseDeadlineExpired" :disabled="true" heavy width="100%" @click="handleClickRequestOrder">購入期限を過ぎています</Button>
        <Button v-else :disabled="isDisabled" heavy width="100%" @click="handleClickRequestOrder">リクエストする</Button>
    </div>
</template>

<script>
import Button from "@/components/shared/v2/Button.vue";
import Counter from "@/components/shared/v2/Counter.vue";

export default {
  components: {
    Button,
    Counter
  },
  props: {
    currentCartCount: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    requestAnswerDeadline: {
      type: String,
      required: true
    },
    isPurchaseDeadlineExpired: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    isDisabled() {
      return this.valueModel < this.min || this.valueModel === 0;
    }
  },
  methods: {
    handleClickRequestOrder() {
      this.$emit("add");
    }
  }
};
</script>
<style lang="scss" scoped>
.request-order-container {
    width: 100%;
    background: #F5F5F5;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 18px;

    &>h2 {
        font-weight: 700;
        font-size: 18px;
    }
}

.current-count-container {
    margin: 10px 0 5px;

    &>.current-count {
        font-weight: 500;
        font-size: 20px;
    }
}

.counter-container {
    display: flex;
    align-items: center;
    margin: 5px 0 10px;
}
</style>
