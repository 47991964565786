<template>
  <div>
    <div class="ma-3 d-flex justify-space-between">
      <v-btn class="btn-white" style="width: 85px" @click="backHandler">{{ $t("back") }}</v-btn>

      <BackForwardBtn
        :back-btn-label="backBtnLabel"
        :next-btn-label="nextBtnLabel"
        :on-click="backForwardHandler"
      />
    </div>

    <v-card class="ma-3 pa-2" v-if="item">
      <div v-if="!mobile" class="d-flex align-center">
        <div class="status-btn" elevation="0">{{ $t(item.status) }}</div>

        <div class="ml-4">
          {{ $t("customer") }}:
          <strong>{{ item.order.shop.name }}</strong>
        </div>

        <div class="order-id pa-1" elevation="0">{{ $t("order") }}: {{ item.id }}</div>
      </div>

      <div v-else>
        <div class="d-flex align-center">
          <div class="status-btn" elevation="0">{{ getStatus(item.status) }}</div>
          <div class="order-id-mobile pa-1" elevation="0">{{ $t("order") }}: {{ item.id }}</div>
        </div>

        <div class="ml-1 mt-3">
          {{ $t("customer") }}:
          <strong>{{ item.order.shop.name }}</strong>
        </div>
      </div>

      <hr class="my-5" />

      <table :class="mobile ? 'table-mobile' : 'table'">
        <tbody>
          <tr>
            <td>{{ $t("order_datetime") }}</td>
            <td>{{ $date.dateJp(item.order.order_date, true) }}</td>
          </tr>
            <tr>
              <td>{{ $t("shipping_date") }}</td>
              <td>{{ shippingDate }}</td>
            </tr>
          <tr>
            <td>{{ $t("delivery_date") }}</td>
            <td>{{ $date.dateJp(item.delivery_date) }}</td>
          </tr>
          <tr>
            <td>{{ $t("item") }}</td>
            <td>{{ item.flower.flower_specie.flower_genus.name }}</td>
          </tr>
          <tr>
            <td>{{ $t("flower_specie") }}</td>
            <td>{{ item.flower.flower_specie.name }}</td>
          </tr>
          <tr>
            <td>{{ $t("stem_size") }}</td>
            <td>{{ item.flower_harvest.stem_size }}{{ $t("size_label") }}</td>
          </tr>
          <tr>
            <td>{{ $t("unit_price") }}</td>
            <td>{{ item.harvest_price }} 円</td>
          </tr>
          <tr>
            <td>{{ $t("min_count") }}</td>
            <td>{{ item.harvest_min_count }} 本</td>
          </tr>
          <tr>
            <td>{{ $t("set_count") }}</td>
            <td>{{ item.lot_count }} 口</td>
          </tr>
          <tr>
            <td>{{ $t("subtotal_count") }}</td>
            <td>{{ item.subtotal_count }} 本</td>
          </tr>
          <tr>
            <td>{{ $t("total_price") }}</td>
            <td>{{ item.subtotal_harvest_price_without_tax || 0 }} 円</td>
          </tr>
          <tr>
            <td>{{ $t("remarks") }}</td>
            <td>{{ item.flower_harvest.note }}</td>
          </tr>
        </tbody>
      </table>
    </v-card>
  </div>
</template>

<script>
import statusBtnLabel from "@/helpers/statusBtnLabel";
import BackForwardBtn from "@/components/farm/orderItems/BackForwardBtn.vue";
import { formatDate } from "@/plugins/i18n";

export default {
  components: { BackForwardBtn },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      id: null,
      nextBtnLabel: "",
      backBtnLabel: "",
      item: null
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getItem();

    if (!this.item) {
      this.$store
        .dispatch("orderItems/getOrderItems", { id: this.id })
        .then(res => {
          if (res) {
            this.getItem();
            this.createBtnLabels();
          }
        });
    } else {
      this.createBtnLabels();
    }
  },
  computed: {
    shop() {
      if (this.item.id) {
        return this.$store.getters["shops/getShop"](this.item.shop_id);
      }
      return {};
    },
    flower() {
      if (this.item.id) {
        return this.$store.getters["flowers/getFlower"](this.item.flower_id);
      }
      return {
        flower_specie: { flower_genus: {} },
      };
    },
    shippingDate() {
      return formatDate(this.item.flower_harvest.harvest_date, "yyyy年m月d日");
    }
  },
  methods: {
    backHandler() {
      this.$router.push({
        path: "/farm/order_items",
        query: { ...this.$route.query }
      });
    },
    async backForwardHandler(side) {
      if (this.item.status === "unconfirmed" && side === "backward") {
        this.reject();
      } else {
        this.accept(side);
      }
    },
    reject() {
      const payload = {
        order_item_ids: [this.id]
      };
      this.$store.dispatch("orderItems/rejectRequest", payload).then(res => {
        if (res) {
          this.$router.push("/farm/order_items");
        }
      });
    },
    accept(side) {
      const payload = {
        side,
        selectedItems: [this.id],
        status: this.item.status
      };

      this.$store.dispatch("orderItems/postOrderItems", payload).then(res => {
        if (res) {
          this.getItem();
          this.createBtnLabels();
        }
      });
    },
    createBtnLabels() {
      [this.backBtnLabel, this.nextBtnLabel] = statusBtnLabel(
        this,
        this.item.status
      );
    },
    getItem() {
      this.item = this.$store.getters["orderItems/getItem"](this.id);
    },
    getStatus(status) {
      if (["unconfirmed"].includes(status)) return this.$t("unconfirmed");
      if (["confirmed"].includes(status)) return this.$t("confirmed");
      if (["shipped", "unloaded", "picked", "delivered"].includes(status)) return this.$t("shipped");
      return "";
    }
  }
};
</script>

<style scoped>
.status-btn {
  padding: 0.5rem;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #007e57;
  color: #007e57;
}
.order-id {
  border-radius: 20px;
  background-color: #eeeeee;
  color: #777777;
  margin-left: auto;
}
.order-id-mobile {
  background-color: #eeeeee;
  color: #777777;
  margin-left: auto;
}

.table,
.table-mobile {
  width: 100%;
}

.table td {
  padding: 1rem;
  border: 0.1rem solid white;
}

.table-mobile td {
  padding: 0.2rem;
}

.table td:nth-child(1) {
  width: 10rem;
  background: #eeeeee;
}

.table-mobile td:nth-child(1) {
  width: 8rem;
  background: #eeeeee;
}

.table,
.table-mobile td:nth-child(2) {
  background: #f5f5f5;
}
</style>
