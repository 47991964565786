<template>
  <div class="collectionList">
    <section v-for="collection in collections" :key="collection.id" class="collectionList__section">
      <h2 class="collectionList__title font-weight-bold color-black-500">{{ collection.title }}</h2>
      <img v-if="collection.image" :src="collection.image.image_url" alt="" class="collectionList__image" />
      <FlowerList :flowers="summaryFlowers(collection.main_section.collection_section_flowers)" class="collectionList__flowers" @click="$emit('click', $event)" />
      <MstButton :to="`/shop/collections/${collection.id}`" color="black-500" class="collectionList__button">
        コレクションを見る
      </MstButton>
    </section>
  </div>
</template>

<script>
import { MstButton } from "@/components/master";
import { FlowerList } from "..";

export default {
  name: "CollectionList",
  components: { MstButton, FlowerList },
  props: {
    collections: { type: Array, required: true },
  },
  methods: {
    summaryFlowers(collectionFlowers) {
      return collectionFlowers.map(collectionFlower => collectionFlower.flower).slice(0, 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.collectionList__section {
  &:not(:first-child) {
    margin-top: 60px;
  }
}

.collectionList__title {
  font-size: 24px;
}

.collectionList__image {
  margin-top: 15px;
  width: 100%;
  height: auto;
  vertical-align: top;
}

.collectionList__flowers {
  margin-top: 20px;
}

.collectionList__button {
  margin-top: 26px;
}
</style>
