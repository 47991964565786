<template>
  <div>
    <!-- TODO: NP掛け払い実装後に復活 -->
    <!-- <v-alert
      v-model="paymentAlert"
      :close-text="$t('close')"
      text
      type="success"
      outlined
      dismissible
      class="ma-4"
    >
      <div class="text-subtitle-2">振り込み人名義の頭に<span class="font-weight-bold" style="color: #FF8C71">
        {{ userId }}</span>を追記してください。
      </div>
      {{ billingPeriodDate }}の¥{{ billingTotal }}の支払いが確定しました。支払い期限は{{ paymentDueDate }}迄です。
    </v-alert>
    <v-alert v-if="isWhenAbsent" color="#2A3B4D" dark icon="mdi-firework" dense>
      不在時の納品場所を追加してください。
      <v-text-field class="mt-2" v-model="whenAbsent" solo label="例) 店舗前においてください。" outlined dense></v-text-field>
      <div class="d-flex justify-end">
        <v-btn color="primary" @click="submit">
          登録する
        </v-btn>
      </div>
    </v-alert> -->

    <!--
      <alert v-if="!agreeTerms">
        <template v-slot:contents>
          <div class="text-body-1">
            2022年4月15日、<u><a class="d-inline-flex align-baseline" href="https://cavin.ooo/terms-1" target="_blank">
                利用規約<v-icon color="#2196f3" small>mdi-open-in-new</v-icon></a></u>を改定いたしました。
          </div>
          <div class="text-body-1">
            変更後の内容をご確認いただき、同意される場合は、チェックボックスにチェックを入れて「利用する」ボタンを押してください。
          </div>
        </template>
        <template v-slot:actions>
          <div class="d-flex flex-column">
            <v-checkbox label="利用規約の内容を確認しました。" v-model="agree" class="ma-0" hide-details />
            <v-btn :disabled="!agree" color="info" outlined class="mt-4" @click="agreetment">利用する</v-btn>
          </div>
        </template>
      </alert>
    -->
    <div class="alerts">
      <div class="my-3 text-xl text-center">
        <p class="mb-0">2024年11月7日に</p>
        <p class="mb-0">以下のURLに移行しました</p>
      </div>

      <div class="my-3 text-xl text-center">
        <p class="mb-0">11月7日以降は新ページから</p>
        <p class="mb-0">注文をお願いします</p>
      </div>

      <a href="https://cavin.farm" class="my-3 text-xl red--text text-center">
        <p class="mb-0">CAVINの新ページはこちら</p>
        <p class="mb-0">https://cavin.farm</p>
      </a>

      <div class="my-2 text-xs text-left">
        <p class="mb-0">これまでと同じメールアドレス、パスワードで</p>
        <p class="mb-0">ログインが可能です</p>
      </div>

      <div class="my-2 text-xs text-left">
        <p class="mb-0">パスワードがご不明な場合は、上記ページの</p>
        <p class="mb-0">パスワード再設定から再設定をおこなってください</p>
      </div>

      <div class="my-2 mb-5 text-xs text-left">
        <p class="mb-0">過去の注文履歴は、このページのメニューから</p>
        <p class="mb-0">ご確認ください</p>
      </div>
    </div>

    <DatePickerV2 class="mt-5" :selected="selectedDate" :allowWdayList="allowWdayList" @change="dateHandler" />

    <product-filter-mobile @click="onClickSearchHandler" ref="productFilterMobile" :farms="farms"
      :categories="categories" :list="flowers" :selectedDate="selectedDate" @criteria-change="handleCriteriaChange" :showFilter="showFilter" @handleShowFilter="handleShowFilter" />

    <ProductContainer id="top">
      <div class="page-title d-flex justify-space-between align-center">
        <div class="filter-button pointer"  @click="handleShowFilter">
          <FilterIcon height="11" width="18" color="#173169" />
          {{ $t('search') }}
        </div>
        <div class="d-flex">
          <v-btn :color="listView ? '#111111' : '#CCCCCC'" @click="() => changeView('grid')" small fab outlined
            class="ga-shop-products-switch-grid-view list-view">
            <v-icon>mdi-view-grid-outline</v-icon>
          </v-btn>
          <v-btn class="ml-2 ga-shop-products-switch-list-view card-view" :color="listView ? '#CCCCCC' : '#111111'"
            @click="() => changeView('list')" small fab outlined>
            <v-icon>mdi-format-list-bulleted-square</v-icon>
          </v-btn>
          <div class="ml-2">
            <v-menu transition="slide-y-transition" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small color="white">
                  {{ sort_name }}<v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group v-model="sort">
                  <v-list-item value="outside_region" @click="setSortName(0)">
                    <v-list-item-title class="ga-shop-products-order-by-low-price">
                      <v-icon v-if="(sort_selection === 0)" class="check-icon">mdi-check mdi-18px</v-icon>
                      <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                      {{ $t('recommended_order') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item value="latest" @click="setSortName(1)">
                    <v-list-item-title class="ga-shop-products-order-by-latest">
                      <v-icon v-if="(sort_selection === 1)" class="check-icon">mdi-check mdi-18px</v-icon>
                      <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                      {{ $t('latest_order') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item value="sales_price_asc" @click="setSortName(2)">
                    <v-list-item-title class="ga-shop-products-order-by-low-price">
                      <v-icon v-if="(sort_selection === 2)" class="check-icon">mdi-check mdi-18px</v-icon>
                      <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                      {{ $t('low_price_order') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item value="sales_price_desc" @click="setSortName(3)">
                    <v-list-item-title class="ga-shop-products-order-by-high-price">
                      <v-icon v-if="(sort_selection === 3)" class="check-icon">mdi-check mdi-18px</v-icon>
                      <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                      {{ $t('high_price_order') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item value="review_point_desc" @click="setSortName(4)">
                    <v-list-item-title class="ga-shop-products-order-by-review-point">
                      <v-icon v-if="(sort_selection === 4)" class="check-icon">mdi-check mdi-18px</v-icon>
                      <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                      {{ $t('review_point_order') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item value="review_count_desc" @click="setSortName(5)">
                    <v-list-item-title class="ga-shop-products-order-by-review-count">
                      <v-icon v-if="(sort_selection === 5)" class="check-icon">mdi-check mdi-18px</v-icon>
                      <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                      {{ $t('review_count_order') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item value="stock_count" @click="setSortName(6)">
                    <v-list-item-title class="ga-shop-products-order-by-stock">
                      <v-icon v-if="(sort_selection === 6)" class="check-icon">mdi-check mdi-18px</v-icon>
                      <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                      {{ $t('stock_order') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="search-criteria" v-if="!!searchCriteria">
        <div class="font-weight-bold mb-1">{{ $t('filter_criteria') }}</div>
        <div>{{ searchCriteria }}</div>
      </div>
      <!-- <v-divider />
      <div>
        <div class="text-subtitle-2"><span class="text-title-">{{ pagination.total }}</span>件</div>
      </div> -->
      <div v-if="this.isLoading">
        <v-overlay :opacity="0.1" z-index="1500">
          <v-progress-circular
            indeterminate
            size="64"
            color="#375293"
          ></v-progress-circular>
        </v-overlay>
      </div>
      <div v-else>
        <div v-if="listView" class="product-list">
          <ProductListItem v-for="flowerHarvest in flowers" :key="flowerHarvest.id"
          :flowerHarvest="flowerHarvest"/>
        </div>
        <div v-else class="card-product">
          <ProductCard v-for="flowerHarvest in flowers" :key="flowerHarvest.id"
          :flowerHarvest="flowerHarvest" />
        </div>
        <div class="no-product" v-if="!flowers.length">{{ $t("no_product_found") }}</div>
        <div class="items-count">{{ this.passedCount }}/{{ this.totalPagesCount }}件</div>
        <div class="pb-10 mt-2 d-flex justify-center">
          <Paginate
            ref="pagination"
            @hook:mounted="setPage"
            :pageCount="totalPagesLength"
            :pageRange="5"
            :containerClass="'pagination'"
            :prevText="prevArrow"
            :nextText="nextArrow"
            :clickHandler="paginationHandler"
          >
          </Paginate>
        </div>
      </div>
    </ProductContainer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import ProductContainer from "@/components/shop/products/ProductContainer.vue";
import ProductCard from "@/components/shop/products/ProductCard.vue";
import ProductListItem from "@/components/shop/products/ProductListItem.vue";
import DatePickerV2 from "@/components/shop/DatePickerV2.vue";
import ProductFilterMobile from "@/components/shop/products/FilterMobile/index.vue";
import FilterIcon from "@/components/icons/FilterIcon.vue";
import { formatDate, calcFortnight } from "@/plugins/i18n";
import sanitizeQueryObject from "@/helpers/sanitizeQueryObject";
import dayjs from "dayjs";

const watchQuery = ["category_id", "flower_genus_id", "flower_specie_id", "farm_id", "delivery_date", "page", "stem_size", "available", "sort"];

export function getConfirmedStartDate() {
  const today = new Date();
  if (today.getDate() < 16) {
    today.setMonth(today.getMonth() - 1);
    today.setDate(16);
  } else {
    today.setDate(1);
  }
  return formatDate(today, "m/d");
}

export function getConfirmedEndDate() {
  const today = new Date();
  if (today.getDate() < 16) {
    today.setDate(0);
  } else {
    today.setDate(15);
  }
  return formatDate(today, "m/d");
}

export function getCurrentStartDate() {
  const today = new Date();
  if (today.getDate() < 16) {
    today.setDate(1);
  } else {
    today.setDate(16);
  }
  return formatDate(today, "m/d");
}

export function getCurrentEndDate() {
  const today = new Date();
  if (today.getDate() < 16) {
    today.setDate(15);
  } else {
    today.setMonth(today.getMonth() + 1);
    today.setDate(0);
  }
  return formatDate(today, "m/d");
}

export default {
  components: {
    ProductContainer,
    ProductCard,
    ProductListItem,
    ProductFilterMobile,
    DatePickerV2,
    FilterIcon,
    Paginate
  },
  watchQuery,
  computed: {
    ...mapGetters(["getUserName"]),
    shop() {
      return this.$store.state.user.shop;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    farms() {
      return this.$store.state.searchQuery.availableFarms;
    },
    categories() {
      return this.$store.state.categories;
    },
    isWhenAbsent() {
      const whenAbsent = this.$store.state.user.shop.locations?.filter(location => location.default === true)[0].when_absent;
      return !whenAbsent || whenAbsent === "";
    },
    agreeTerms() {
      return this.$store.state.user.agree_terms;
    },
    allowWdayList() {
      return this.$store.getters.workingWdays;
    },
    userId() {
      return this.$store.state.user.id;
    },
    billingPeriodDate() {
      return calcFortnight(true, false);
    },
    billingTotal() {
      return this.previousFortnight || 0;
    },
    paymentDueDate() {
      const today = new Date();
      const isFirstHalfPeriod = today.getDate() < 16;
      const paymentDueDate = isFirstHalfPeriod
        ? new Date(today.getFullYear(), today.getMonth(), 15)
        : new Date(today.getFullYear(), today.getMonth() + 1, 0);
      return formatDate(paymentDueDate, "m/d");
    },
    passedCount() {
      return 30 * (this.page - 1) + this.currentPageCount;
    }
  },
  data() {
    return {
      alert: true,
      paymentAlert: false,
      flowers: [],
      currentFortnight: 0,
      previousFortnight: 0,
      listView: false,
      page: 1,
      pagination: {},
      totalPagesLength: 0,
      totalPagesCount: "-",
      currentPageCount: 0,
      whenAbsent: "",
      available: 0,
      agree: false,
      sort: null,
      confirmedStartDate: getConfirmedStartDate(),
      confirmedEndDate: getConfirmedEndDate(),
      unconfirmedStartDate: getCurrentStartDate(),
      unconfirmedEndDate: getCurrentEndDate(),
      confirmedInvoiceCount: 345736,
      unconfirmedInvoiceCount: 154321,
      productCount: 236,
      selectedDate: null,
      sort_selection: -1,
      sort_name: this.$t("order_title"),
      sortItems: [
        "outside_region",
        "latest",
        "sales_price_asc",
        "sales_price_desc",
        "review_point_desc",
        "review_count_desc",
        "stock_count"
      ],
      searchCriteria: "",
      isLoading: false,
      showFilter: false,
      prevArrow: "<i class='v-icon notranslate mdi mdi-chevron-left' />",
      nextArrow: "<i class='v-icon notranslate mdi mdi-chevron-right' />"
    };
  },
  async beforeMount() {
    this.listView = await this.$db("shopProductViewType");
  },
  mounted () {
    if ((this.$route.query.farm_id || this.$route.query.flower_genus_id) && this.$store.state?.searchQuery?.availableFlowerGenus.length === 0) {
      this.$store.dispatch("getSearchQuery").then(() => this.initializeProductFilterMobile());
    } else {
      this.initializeProductFilterMobile();
    }
    if (this.$route.query.category_id) {
      const category = this.categories.find(c => c.id.toString() === this.$route.query.category_id);
      this.$refs.productFilterMobile.$refs.categoryTabs.changeTab(category);
    }
  },

  created() {
    const today = new Date();
    const todayDate = today.getDate();
    const endOfMonth = dayjs().endOf("month").$D;
    const isDisplayBilling = [1, 16, endOfMonth].includes(todayDate);
    if (isDisplayBilling) this.paymentAlert = true;

    let date = today;

    if (this.$route.query.delivery_date) { date = new Date(this.$route.query.delivery_date); }
    const wdays = this.allowWdayList && this.allowWdayList.length > 0 ? this.allowWdayList : [0, 1, 2, 3, 4, 5, 6];
    const formattedDate = formatDate(date, "yyyy-mm-dd");
    while (!wdays.includes(date.getDay()) && !this.$store.getters.getSpecialDeliveryDays.includes(formattedDate)) {
      date.setDate(date.getDate() + 1);
    }
    this.selectedDate = formatDate(date, "yyyy-mm-dd");

    if (this.$route.query.page) this.page = Number(this.$route.query.page);
    if (this.$route.query.available) this.available = Number(this.$route.query.available);
    if (this.categories.length === 0) { this.$store.dispatch("getHeaderCategory"); }

    const index = this.sortItems.indexOf(this.$route.query.sort);
    if (this.$route.query.sort && index >= 0) {
      this.setSortName(index);
      this.sort = this.$route.query.sort;
    } else {
      this.sort = "outside_region";
      this.sort_selection = 0;
      this.sort_name = this.$t("recommended_order");
    }
    this.fetchTotalPages();
    this.fetchHome();
  },
  watch: {
    flowers: {
      handler() { },
      deep: true
    },
    available() {
      const newQuery = { ...this.$route.query, available: this.available };
      this.$router.push({ query: newQuery });
    },
    sort() {
      const isSignedInRecently = sessionStorage.getItem("isSignedInRecently");
      if (isSignedInRecently === "true") return;
      const newQuery = { ...this.$route.query, sort: this.sort };
      this.$router.push({ query: newQuery });
    },
  },
  methods: {
    calcFortnight,
    setSortName(value) {
      this.sort_selection = value;
      if (value === 0) {
        this.sort_name = this.$t("recommended_order");
      } else if (value === 1) {
        this.sort_name = this.$t("latest_order");
      } else if (value === 2) {
        this.sort_name = this.$t("low_price_order");
      } else if (value === 3) {
        this.sort_name = this.$t("high_price_order");
      } else if (value === 4) {
        this.sort_name = this.$t("review_point_order");
      } else if (value === 5) {
        this.sort_name = this.$t("review_count_order");
      } else if (value === 6) {
        this.sort_name = this.$t("stock_order");
      }
    },
    setPage() {
      this.$refs.pagination.handlePageSelected(this.page);
    },
    onClickSearchHandler(q) {
      this.page = 1;
      const params = { page: this.page, sort: this.sort };
      if (q.category) params.category_id = q.category.id;
      if (q.genusIds.length > 0) params.flower_genus_id = q.genusIds;
      if (q.farmIds.length > 0) params.farm_id = q.farmIds;
      if (q.date) params.delivery_date = q.date;
      if (q.specie) params.flower_specie_id = q.specie.id;
      if (q.stem_size) params.stem_size = q.stem_size;

      params.available = this.available;

      const query = sanitizeQueryObject(params, watchQuery);
      this.$router.push({ query });
    },
    paginationHandler(number) {
      const newQuery = { ...this.$route.query, page: number };
      this.$router.push({ query: newQuery });
    },
    async fetchTotalPages() {
      try {
        const response = await this.$http2("get", "shop/flower_harvests?fetchPagination=true", { params: this.$route.query });
        this.totalPagesLength = response.result.pagination.length;
        this.totalPagesCount = response.result.pagination.total;
      } catch (error) {
        console.error("Failed to fetch total pages:", error);
      }
    },
    dateHandler(delivery_date) {
      const query = { ...this.$route.query, delivery_date, page: 1 };
      this.$router.push({ query });
    },
    formatDate(d) {
      return formatDate(d, "yyyy-mm-dd");
    },
    formatDateMD(md) {
      return formatDate(md, "m/d");
    },
    getNextAllowedWday(date, allowWdayList) {
      const d = new Date(date);
      d.setDate(d.getDate() + 1);
      while (!allowWdayList.includes(d.getDay())) {
        d.setDate(d.getDate() + 1);
      }
      return formatDate(d, "yyyy-mm-dd");
    },
    updateUrlQuery(query) {
      // router.pushで不要な再レンダリングとAPI叩くのを防止する為urlのパラメータを更新するだけ
      const currentUrl = new URL(window.location.href);
      const newUrl = new URL(currentUrl);
      newUrl.search = new URLSearchParams(query).toString();
      window.history.replaceState(null, null, newUrl.toString());
    },
    async fetchHome() {
      this.isLoading = true;
      const isSignedInRecently = sessionStorage.getItem("isSignedInRecently");
      if (isSignedInRecently === "true") { this.$route.query.sort = "outside_region"; }
      const res = await this.$http2("get", "shop/flower_harvests", { params: this.$route.query });
      await this.handleFetchHomeResponse(res);
      this.isLoading = false;
      const query = { ...this.$route.query, delivery_date: this.selectedDate, page: this.page };
      this.updateUrlQuery(query);
      sessionStorage.setItem("isSignedInRecently", false);
    },
    async handleFetchHomeResponse(res) {
      if (res.status === 200) {
        const isSignedInRecently = sessionStorage.getItem("isSignedInRecently");
        this.flowers = res.result.flowers;
        this.currentPageCount = this.flowers.length;
        this.previousFortnight = res.result.previousSortnightTotalSalesPriceWithTax;
        if (!this.currentPageCount && isSignedInRecently === "true") {
          await this.retryWithNextAllowedWday(res.result.deliveryDate);
        } else {
          this.selectedDate = res.result.deliveryDate;
        }
      } else if (res.status === 400) {
        this.$store.dispatch("setSnackBar", { msg: res.error, color: "red" }, { root: true });
        this.$store.dispatch("snackOn", {}, { root: true });
      }
    },
    async retryWithNextAllowedWday(deliveryDate, retryCount = 0) {
      if (retryCount >= 2) return;

      const date = this.getNextAllowedWday(deliveryDate, this.allowWdayList);
      this.$route.query.delivery_date = date;

      const res = await this.$http2("get", "shop/flower_harvests", { params: this.$route.query });
      this.selectedDate = res.result.deliveryDate;
      this.flowers = res.result.flowers;
      this.previousFortnight = res.result.previousSortnightTotalSalesPriceWithTax;
      if (this.flowers.length > 0) {
        this.fetchTotalPages();
        return;
      }
      await this.retryWithNextAllowedWday(res.result.deliveryDate, retryCount + 1);
    },
    async agreetment() {
      const res = await this.$http("put", "users/agree_terms");
      if (res.status === 200) { this.$store.state.user.agree_terms = true; }
    },
    changeView(type) {
      this.listView = type === "list";
      this.$db("shopProductViewType", this.listView);
    },
    initializeProductFilterMobile () {
      // eslint-disable-next-line no-unused-expressions
      if (this.$refs?.productFilterMobile) this.$refs.productFilterMobile.initialize();
    },
    async submit() {
      if (this.whenAbsent) {
        const defaultLocation = this.$store.state.user.shop.locations.find(location => location.default === true);
        await this.$store.dispatch("putLocation", {
          id: defaultLocation.id,
          content: { when_absent: this.whenAbsent }
        }).then(() => {
          this.$store.dispatch("setSnackBar", { msg: "不在時の配送場所を登録しました", color: "green" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
        });
      }
    },
    updateHarvestCountValue(flowerHarvest, value) {
      this.$store.getters["orders/normalOrderItem"](flowerHarvest.id).lot_count = value;
    },
    handleCriteriaChange(value) {
      this.searchCriteria = value;
    },
    handleShowFilter() {
      this.showFilter = !this.showFilter;
      if (this.showFilter && this.$store.state?.searchQuery?.availableFlowerGenus.length === 0) this.$store.dispatch("getSearchQuery");
    }
  }
};
</script>

<style lang="scss" scoped>
.page-title {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
  padding: 0px 15px;
}

.product-count {
  font-size: 18px;
  font-weight: 700;
}

.card-product {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-bottom: 50px;
  row-gap: 40px;

  @media only screen and (max-width: 960px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

.product-total {
  margin: 10px 10px 10px 10px;
}

.container {
  padding: 0px 15px;

  .row {
    margin: 0;
  }

  .col-12 {
    padding: 15px 5px;

    @media only screen and (max-width: 960px) {
      padding: 5px 0 0 0;
    }
  }
}

.check-icon {
  width: 18px;
  height: 18px;
}

.items-count {
  padding-left: 20px;
  color: black;
  font-weight: 500;
}

.no-product {
  text-align: center;
  margin: 24px;
}

.search-criteria {
  font-size: 12px;
  left: 0;
  background: white;
  z-index: 2;
  width: 100vw;
  bottom: 56px;
  font-family: sans-serif;
  padding: 8px 15px 16px 15px;
}

.filter-button {
  color: #173169;
  font-size: 14px;
  padding: 7px 14px;
  border: 1px solid #173169;
  border-radius: 4px;
  margin: 6px;
}

.pagination::v-deep {
  display: flex;
  align-items: center;
  list-style: none;
  padding-right: 24px;

  li {
    width: 2rem;
    height: 2rem;
    text-align: center;
    margin-left: 0.5vw;

    a {
      color: #191D38;
      line-height: 2rem;
    }
  }

  li.active {
    background-color: #E9EDEE;
    border-radius: 50%;

    a {
      font-weight: 700;
    }
  }

  li.disabled > a {
    color: gray;
    cursor: not-allowed;
  }
}
.alerts {
  display: grid;
  grid-gap: 12px;
  background-color: #cccccc;
  margin: -12px;
  padding: 12px 50px;
}
</style>
