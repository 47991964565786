<template>
  <header role="banner" class="mstTheHeader">
    <div class="mstTheHeader__inner">
      <h1 class="mstTheHeader__brand">
        <router-link to="/" class="mstTheHeader__brandLink">
          <img
            :src="require('@/assets/CAVIN_Logo_black.png')"
            width="80"
            alt="CAVIN"
            class="mstTheHeader__logo"
          />
        </router-link>
      </h1>

      <button
        type="button"
        aria-label="メニュー"
        class="mstTheHeader__menuButton"
        @click="isVisible = true"
      >
        <MstIcon type="menu" size="24px" class="mstTheHeader__menuIcon" />
      </button>

      <MstDrawer v-model="isVisible">
        <nav role="navigation" class="mstTheHeader__navigation">
          <ul class="mstTheHeader__navs">
            <li v-for="item in items" :key="item.to" class="mstTheHeader__nav">
              <router-link :to="item.to" class="mstTheHeader__navLink">
                {{ item.label }}
                <MstTextTag
                  v-if="item.badge"
                  :text="String(item.badge)"
                  color="red-500"
                  circle
                  class="mstTheHeader__badge"
                />
              </router-link>
            </li>
          </ul>
        </nav>

        <div class="mstTheHeader__buttons">
          <MstHeaderButton :to="myPageUrl" outlined>マイページ</MstHeaderButton>
          <MstHeaderButton outlined @click="$emit('logout')">ログアウト</MstHeaderButton>
        </div>

        <div v-if="$slots.default" class="mstTheHeader__utility">
          <slot />
        </div>

        <ul class="mstTheHeader__links">
          <li v-for="link in links" :key="link.to" class="mstTheHeader__linkItem">
            <a :href="link.to" target="_blank" rel="noopener" class="mstTheHeader__link">
              {{ link.label }}
              <MstIcon type="open-in-new" size="14px" />
            </a>
          </li>
        </ul>
      </MstDrawer>
    </div>
  </header>
</template>

<script>
import { MstIcon, MstDrawer, MstTextTag } from "@/components/master";
import MstHeaderButton from "./MstHeaderButton.vue";

export default {
  name: "MstTheHeader",
  components: { MstIcon, MstDrawer, MstTextTag, MstHeaderButton },
  props: {
    type: { type: String, required: true, validator: value => ["Shop", "Farm"].includes(value) },
    items: { type: Array, required: true },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    myPageUrl() {
      if (this.type === "Shop") return "/shop/setting";
      return "/farm/setting/account";
    },
    links() {
      return [
        { label: "利用規約", to: "https://cavin.ooo/terms-1" },
        { label: "プライバシーポリシー", to: "https://cavin.ooo/privacypolicy" },
        { label: "特定商取引法に基づく表記", to: "https://cavin.ooo/commerce" },
        { label: "運営元", to: "https://cavin.ooo/about" },
        { label: "採用情報", to: "https://www.wantedly.com/companies/company_6193267" },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.mstTheHeader {
  background: variables.$color-white;
}

.mstTheHeader__inner {
  position: relative;
  padding: 10px 46px 4px;
  text-align: center;
}

.mstTheHeader__brand {
  display: inline-block;
  line-height: 1;
}

.mstTheHeader__brandLink {
  display: inline-block;
  line-height: 1;
}

.mstTheHeader__logo {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.mstTheHeader__menuButton {
  position: absolute;
  top: 50%;
  left: 0;
  padding: 12px;
  line-height: 1;
  transform: translateY(-50%);
}

.mstTheHeader__navs {
  border-bottom: 1px solid #576fa9;
  padding: 0;
  list-style-type: none;
}

.mstTheHeader__nav {
  &:not(:first-child) {
    border-top: 1px solid #576fa9;
  }
}

.mstTheHeader__navLink {
  display: block;
  padding: 15px 33px 16px;
  font-size: variables.$font-size-md;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  color: variables.$color-white;
}

.mstTheHeader__buttons {
  display: flex;
  margin-top: 22px;
  padding: 0 15px;
  gap: 0 10px;
}

.mstTheHeader__utility {
  padding: 10px 15px 0;
}

.mstTheHeader__links {
  margin-top: 32px;
  padding: 0 0 0 25px;
  list-style-type: none;
}

.mstTheHeader__linkItem {
  line-height: 1;

  &:not(:first-child) {
    margin-top: 13px;
  }
}

.mstTheHeader__link {
  font-size: variables.$font-size-sm;
  line-height: 1;
  text-decoration: none;
  color: variables.$color-white;
}
</style>
