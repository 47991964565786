<template>
  <div v-if="!busy">
    <div class="d-flex align-center ma-4 pointer" @click="onGoBack()">
      <ArrowLeftIcon size="12" color="black" />
      <h4 class="ml-2 font-weight-normal">{{ $t("back_short") }}</h4>
    </div>

    <div>
      <img-handler direct :default-value="content.imgs" @finish="imageHandler" multi-preview :is-edit="!!flower_id" />
      <div class="select-board">
        <div class="d-flex align-center ma-4">
          <div class="label">{{ $t("category") }}</div>
          <div class="w-100">
            <category :category-id="content.categoryId" @select="categoryHandler" :read-only="!!flower_id"></category>
          </div>
        </div>
        <div class="d-flex align-center ma-4">
          <div class="label">{{ $t("item") }}</div>
          <div class="w-100">
            <genus :genus-id="content.genusId" :category-id="content.categoryId" @select="genusHandler"
              :read-only="!!flower_id"></genus>
          </div>
        </div>
        <div class="d-flex align-center ma-4">
          <div class="label">{{ $t("specie") }}</div>
          <div class="w-100">
            <specie :genus-id="content.genusId" :specie-id="content.specieId" :read-only="!!flower_id"
              @select="id => content.specieId = id"></specie>
          </div>
        </div>
        <div class="d-flex align-center ma-4" v-if="!!flower_id">
          <div class="label">{{ $t("review") }}</div>
          <div class="w-100">
            <star-rating :rating="content.reviewMark" :increment="0.01" :star-size="12" :padding="2" active-color="#DFCE0F"
              inactive-color="#D9D9D9" :show-rating="false"></star-rating>
            <span class="text-xl font-weight-bold">{{ content.reviewMark }}</span>
            <span class="text-sm font-weight-bold">/5.0</span>
            <span class="text-sm">({{ content.totalReviewCount }}{{ $t("piece") }})</span>
          </div>
        </div>
      </div>

      <div class="ma-4 d-flex py-4">
        <MstButton v-if="flower_id && edit" class="btn-show w-100" @click="save">{{ $t('save_changes') }}</MstButton>
        <MstButton v-else-if="flower_id && isWorking" class="btn-hide w-100" @click="isWorkingHandler(false)">{{
          $t('hide_product') }}</MstButton>
        <MstButton v-else-if="flower_id && !isWorking" class="btn-show w-100" @click="isWorkingHandler(true)">{{
          $t('display_product') }}</MstButton>
        <MstButton v-else
          class="w-100 {!(content.categoryId && content.genusId && content.specieId)? : 'inactive-btn-color' : 'btn-add-color'}"
          :disabled="!(content.categoryId && content.genusId && content.specieId) || isSaving" @click="save">
          {{ $t("add_product") }}
        </MstButton>
      </div>
    </div>

    <div class="modal-mask" v-if="isSaving">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="text-center my-4">
            <span class="font-weight-bold">読み込み中</span>
          </div>
          <div class="text-center my-4">
            <div>商品を登録しています。</div>
            <div>しばらくお待ちください。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Category from "@/components/farm/flowers/category/index.vue";
import Genus from "@/components/farm/flowers/genus/index.vue";
import Specie from "@/components/farm/flowers/specie/index.vue";
import ImgHandler from "@/components/shared/ImgHandler/index.vue";
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon.vue";
import { MstButton } from "@/components/master";
import StarRating from "vue-star-rating";

export default {
  components: {
    Category,
    Genus,
    Specie,
    ImgHandler,
    ArrowLeftIcon,
    MstButton,
    StarRating
  },
  data() {
    return {
      flower_id: null,
      isWorking: false,
      content: {
        categoryId: null,
        genusId: null,
        specieId: null,
        imgs: []
      },
      genusDialog: false,
      specieDialog: false,
      invalid: null,
      error: {},
      busy: false,
      isSaving: false,
      edit: false,
      editContent: {},
    };
  },
  async created() {
    this.flower_id = this.$route.params.flower_id;
    this.busy = true;

    if (this.flower_id) {
      await this.$store.dispatch("flowers/getFlowers", [this.flower_id]);
      const flower = this.$store.state.flowers.list[this.flower_id];
      this.content.specieId = flower.flowerSpecie.id;
      this.content.genusId = flower.flowerSpecie.flowerGenus.id;
      this.content.categoryId = flower.category.id;
      this.content.totalReviewCount = flower.totalReviewCount;
      this.content.reviewMark = flower.reviewMark;
      this.content.imgs = flower.images;
      this.isWorking = flower.isWorking;
    } else {
      const responses = [];
      responses.push(this.$store.dispatch("Category/getCategories"));
      responses.push(this.$store.dispatch("flowerGenus/getFlowerGenus"));
      await Promise.all(responses);
    }
    this.busy = false;
  },
  methods: {
    categoryHandler(categoryId, keepSelectedGenus) {
      this.content.categoryId = parseInt(categoryId, 0);

      if (!keepSelectedGenus) {
        this.content.genusId = null;
      }
    },
    async isWorkingHandler(isWorking) {
      const id = [this.flower_id];
      await this.$store.dispatch("flowers/putFlowerIsWorking", {
        ids: id,
        isWorking
      });
      this.isWorking = isWorking;
    },
    genusHandler(genusId, keepSelectedSpecie) {
      this.content.genusId = parseInt(genusId, 0);

      if (!keepSelectedSpecie) {
        this.content.specieId = null;
      }

      if (genusId) {
        this.$store.dispatch("flowerSpecie/getFlowerSpecie", genusId);
      }
    },
    onGoBack() {
      this.$router.go(-1);
    },
    imageHandler(e) {
      this.edit = true;
      switch (e) {
        case "OVERSIZE":
          this.$store.dispatch("setSnackBar", { msg: this.$t("image_size_over"), color: "red" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
          break;

        case "WRONG_FORMAT":
          this.$store.dispatch("setSnackBar", { msg: this.$t("image_wrong_format"), color: "red" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
          break;

        default:
          this.content.imgs = e;
          this.$store.dispatch("flowerSpecie/setNewImage", e[0]);
          break;
      }
    },
    async save() {
      this.isSaving = true;

      try {
        if (this.hasError(true)) throw new Error("hasError");
        if (this.flower_id) {
          if (Object.keys(this.content).length) {
            await this.$store.dispatch("flowers/putFlower", {
              flower_id: this.flower_id,
              data: this.content
            });
            this.$store.dispatch("flowerSpecie/setNewImage", null);
            this.edit = false;
          }
        } else {
          if (this.$store.getters["flowerSpecie/isCreatedWithoutImage"]) {
            this.$store.dispatch("flowerSpecie/putFlowerSpecie", {
              specieId: this.content.specieId,
              data: {
                data: (this.content.imgs ?? [])[0]?.image_url
              }
            });
            this.$store.dispatch("flowerSpecie/setIsCreatedWithoutImage", false);
          }
          const id = await this.$store.dispatch(
            "flowers/postFlower",
            this.content
          );

          if (id) {
            setTimeout(() => {
              this.$store.dispatch("flowerSpecie/setNewImage", null);
              this.$router.push("/farm/flower_genera").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("setMstSnackBar", { msg: "商品の登録が完了しました。", color: "#21920F" }, { root: true });
                  this.$store.dispatch("mstSnackOn", {}, { root: true });
                }, 2500);
              });
            }, 1600);
          } else {
            this.isSaving = false;
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(() => {
          this.isSaving = false;
        }, 1600);
      }
    },
    editHandler() {
      if (!this.edit) {
        this.edit = true;
      } else if (Object.keys(this.editContent).length) {
        this.$store
          .dispatch("flowers/putFlowers", this.editContent)
          .then(() => {
            this.editContent = {};
            this.edit = false;
            this.createTableData();
          });
      } else {
        this.edit = false;
      }
    },
    hasError(ignoreImg) {
      this.invalid = false;
      this.error = {};

      const arr = [
        "imgs",
        "specieId",
      ];

      for (let i = 0; i < arr.length; i++) {
        const x = arr[i];
        if (ignoreImg && x === "imgs") {
          continue;
        }

        if (!this.content[x]) {
          this.invalid = x;
          this.error[x] = true;
          return true;
        }
      }

      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 600px) {
  .detail-table {
    width: 100%;

    & td:nth-child(1) {
      width: 100px;
    }
  }
}

.flower-invalid {
  color: red;
}

.t1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail-select {
  height: 60px;
}

.w-100 {
  width: 100% !important;
}

.btn-add {
  border: 1px solid #173169 !important;
  padding: 0 16px;
  font-weight: 400 !important;
}

.btn-add-color {
  background-color: white !important;
  color: #173169 !important;
}

.inactive-btn-color {
  background-color: #ccc;
  color: white;
}

.btn-hide {
  background-color: white !important;
  color: #191D38 !important;
  border: 1px solid #191D38 !important;
  padding: 0 16px;
  font-weight: 400 !important;
}

.btn-show {
  color: white !important;
  background-color: #173169 !important;
  font-weight: 400 !important;
}

.label {
  width: 150px;
  min-width: 150px;
  font-size: 18px;
  color: #727272;
}

.select-board {
  border-top: 1px solid #D7D7D7;
  margin-right: -16px;
  margin-left: -16px;
  padding: 16px;
}

.modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 0 solid #888;
  border-radius: 8px;
  max-width: 344px;
}

.dialog-card {
  padding: 25px 52px;
  display: flex;
  flex-direction: column;
  height: 230px;
  width: 344px;
  margin: auto;
}

.register-success {
  width: 63.3px;
  height: 63.3px;
  margin: auto;
  margin-top: 28px;
  margin-bottom: 26px;
}

.register-success-txt {
  font-size: 18px;
  font-weight: bold;
  margin: auto;
  margin-top: 0;
  text-align: center;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 344px;
  margin: 0px auto;
  padding: 20px 45px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
</style>
