<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      :fill="color"
      d="M288 48H136c-22.092 0-40 17.908-40 40v336c0 22.092 17.908 40 40 40h240c22.092 0 40-17.908 40-40V176L288 48zm-16 144V80l112 112H272z"
    />
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String
  }
};
</script>

<style>
</style>
