export default {
  namespaced: true,
  state: {
    /** @type {FlowerSpecie} */
    list: {},
    listArr: [],
    /** @type {Option} */
    options: {},
    newImage: null,
    isCreatedWithoutImage: false
  },
  mutations: {
    setData(state, payload) {
      state.list = payload.list;
      state.options = payload.options;
      state.listArr = Object.keys(state.list)
        .map(Number)
        .sort((a, b) => a - b);
    },
    setNewImage(state, payload) {
      state.newImage = payload;
    },
    setIsCreatedWithoutImage(state, payload) {
      state.isCreatedWithoutImage = payload;
    }
  },
  actions: {
    async getFlowerSpecie({ commit, state }, genusId) {
      const res = await this._vm.$http("get", `flower_genus/${genusId}/flower_species`);

      const newData = { ...state };
      if (res.status === 200) {
        res.result.flower_species.forEach(item => {
          newData.list[item.id] = item;

          if (!newData.options[genusId]) newData.options[genusId] = [];
          if (!newData.options[genusId].find(x => x.id === item.id)) {
            newData.options[genusId].push(item);
          }
        });
        commit("setData", newData);
      }

      return newData.options[genusId];
    },
    async postFlowerSpecie({ commit, state }, { genusId, data }) {
      data = {
        flower_specie: { name: data.name, name_furigana: data.name_furigana },
        image: { data: data.data }
      };
      console.log("[Body]...", data);
      const res = await this._vm.$http("post", `flower_genus/${genusId}/flower_species`, data);

      if (res.status === 201) {
        const { id } = res.result.flower_specie;
        const newData = { ...state };

        newData.list[id] = {
          id,
          name: data.flower_specie.name,
          name_furigana: data.flower_specie.name_furigana,
          image_url: data.image.data
        };

        if (!newData.options[genusId]) newData.options[genusId] = [];
        newData.options[genusId].push(newData.list[id]);

        commit("setData", newData);
        return id;
      }

      return null;
    },
    async putFlowerSpecie({ commit, dispatch, state }, { specieId, data }) {
      const finalData = {};

      if (data.name) finalData.flower_specie = { name: data.name };
      if (data.data) finalData.image = { data: data.data };

      const res = await this._vm.$http("put", `flower_species/${specieId}`, finalData);

      if (res.status === 201) {
        const newData = { ...state };

        if (data.name) {
          newData.list[specieId].name = data.name;
        }

        if (data.data) {
          newData.list[specieId].image_url = data.data;
        }

        commit("setData", newData);
        dispatch("setToast", { type: "success", msg: "updated" }, { root: true });
        return specieId;
      }

      return null;
    },
    async delFlowerSpecie({ commit, dispatch, state }, { genusId, specieId }) {
      const res = await this._vm.$http("delete", `flower_species/${specieId}`);

      if (res.status === 200) {
        const newData = { ...state };

        delete newData.list[specieId];
        newData.listArr = newData.listArr.filter(x => x !== specieId);
        newData.options[genusId] = newData.options[genusId].filter(x => x.id !== specieId);

        commit("setData", newData);
        dispatch("setToast", { type: "success", msg: "deleted" }, { root: true });
        return specieId;
      }

      return null;
    },
    setNewImage({ commit }, newImage) {
      commit("setNewImage", newImage);
    },
    setIsCreatedWithoutImage({ commit }, isCreatedWithoutImage) {
      commit("setIsCreatedWithoutImage", isCreatedWithoutImage);
    }
  },
  getters: {
    options(state) {
      return genusId => state.options[genusId];
    },
    name: state => specieId => state.list[specieId] && state.list[specieId].name,
    newImage: state => state.newImage,
    isCreatedWithoutImage: state => state.isCreatedWithoutImage
  }
};
