<template>
  <div>
    <MstSelectBox :options="categoryOptions" v-model="categoryValue" @change="selectHandler" :disabled="readOnly" />
  </div>
</template>

<script>
import CustomDialog from "@/components/shared/CustomDialog.vue";
import { MstSelectBox } from "@/components/master";

export default {
  components: { CustomDialog, MstSelectBox },
  props: {
    categoryId: {
      type: Number,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      categoryValue: this.categoryId ? this.categoryId.toString() : "",
    };
  },
  methods: {
    selectHandler(id) {
      this.$emit("select", id);
    }
  },
  computed: {
    categoryOptions() {
      const options = this.$store.getters["Category/options"];
      const categoryArray = options.map(obj => ({
        value: obj.value.toString(),
        label: obj.text
      }));
      return categoryArray;
    },
    name() {
      const category = this.$store.state.Category.list[this.categoryId];

      return category && category.name;
    }
  }
};
</script>

<style scoped>
</style>
