import axios from "axios";

export default {
  namespaced: true,
  state: {
    access_token: null,
  },
  mutations: {
    setAccessToken(state, payload) {
      state.access_token = payload;
    }
  },
  actions: {
    getUsers({ commit }, data) {
      return new Promise(resolve => {
        axios.defaults.headers.common.X_CLIENT_ID = process.env.VUE_APP_X_CLIENT_ID;
        axios.defaults.headers.common.X_CLIENT_SECRET = process.env.VUE_APP_X_CLIENT_SECRET;

        this._vm.$http("post", "admin/login", { login: data.login }).then(res => {
          if (res && res.status !== 204) {
            const access_token = `${res.auth.token_type} ${res.auth.access_token}`;

            commit("setAccessToken", access_token);
            axios.defaults.headers.common.Authorization = access_token;

            this._vm.$http("get", `admin/users?user_type=${data.userType}`).then(res2 => {
              if (res2 && res2.status === 200) {
                resolve(res2.result.users);
              } else if (res.status !== 200) {
                resolve(false);
              }
            });
          } else if (res.status === 204) {
            resolve(false);
          }
        });
      });
    },
    loginUserById({ commit }, data) {
      return new Promise(resolve => {
        axios.defaults.headers.common.X_CLIENT_ID = process.env.VUE_APP_X_CLIENT_ID;
        axios.defaults.headers.common.X_CLIENT_SECRET = process.env.VUE_APP_X_CLIENT_SECRET;

        this._vm.$http("post", "admin/users/login", { login: data }).then(res => {
          if (res && res.status !== 204) {
            const access_token = `${res.auth.token_type} ${res.auth.access_token}`;

            const newData = {
              id: res.user.id,
              name: res.user.name,
              user_type: res.user.user_type,
              access_token,
              is_confirmed: res.user.is_confirmed,
            };

            commit("setData", newData, { root: true });

            delete axios.defaults.headers.common.X_CLIENT_ID;
            delete axios.defaults.headers.common.X_CLIENT_SECRET;

            resolve(res.user.user_type);
          } else if (res.status === 204) {
            resolve(false);
          }
        });
      });
    }
  },
  getters: {
    isSignedIn(state) {
      return !!state.access_token;
    }
  }
};
