/* eslint-disable no-console */
<template>
  <div v-if="!busy">
    <div class="d-flex align-center ma-3" @click="onGoBack()">
      <LeftArrowIcon size="12" color="black" />
      <h4 class="ml-2 font-weight-normal">{{ $t("back") }}</h4>
    </div>
    <div v-if="flowerHarvest.id">
      <img-handler direct :default-value="images" @finish="imageHandler" multi-preview :is-edit="!!flowerHarvest.flower_id"/>

      <div class="status-title mx-7">
        <LeftArrowIcon size="24" color="black" />
        <span v-if="flowerHarvest.status === 'not_on_sale'" class="status-label">{{ $t('stop_sale') }}</span>
        <span v-else class="status-label">{{ $t(flowerHarvest.status) }}</span>
        <RightArrowIcon size="24" color="black" />
      </div>

      <HarvestForm
        :flower-harvest="flowerHarvest"
        :genus-options="genusOptions"
        :flower-options="flowerOptions"
        :flower-options-hash="flowerOptionsHash"
        @on-change="handleChange"
      />
      <div v-if="isOnSale" class="mx-7 d-flex">
        <MstButton class="mr-1" :disabled="!flowerHarvest.is_editable" @click="preStop" outlined>{{ $t("stop_sale") }}</MstButton>
        <MstButton class="ml-1" :disabled="!flowerHarvest.is_editable" @click="goToEditPage">{{ $t("harvest_edit") }}</MstButton>
      </div>
      <div v-else class="mx-7">
        <MstButton :disabled="!flowerHarvest.is_editable" @click="preReopen()">{{ $t("re_save_flower_harvest") }}</MstButton>
      </div>

      <div v-if="isShowStopModal" class="modal">
        <div class="modal-content">
          <div class="card dialog-card">
            <img class="card-logo" :src="require('@/assets/harvest_stop.svg')" alt="Harvest Stop">
            <p class="dialog-header">{{ $t("harvest_stop_message_1") }}</p>
            <p class="dialog-text">{{ $t("harvest_stop_message_2") }}</p>
            <div class="flex-items">
              <MstButton :outlined="true" @click="cancelStop()">{{ $t("back_short") }}</MstButton>
              <MstButton @click="changeStatus()">{{ $t("stop_sale") }}</MstButton>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isShowReopenModal" class="modal">
        <div class="modal-content">
          <div class="card dialog-card">
            <img class="card-logo" :src="require('@/assets/harvest_stop.svg')" alt="Harvest Stop">
            <p class="dialog-header">{{ $t("harvest_reopen_message_1") }}</p>
            <p class="dialog-text">{{ $t("harvest_reopen_message_2") }}</p>
            <div class="flex-items">
              <MstButton :outlined="true" @click="cancelReopen()">{{ $t("back_short") }}</MstButton>
              <MstButton @click="changeStatus()">{{ $t("reopen_on_stop") }}</MstButton>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isShowSuccessModal" class="modal">
        <div class="modal-content">
          <div class="card dialog-card">
            <img class="harvest-success" :src="require('@/assets/harvest_success.svg')" alt="Harvest Success">
            <p class="card-text harvest-success-txt">{{ $t("harvest_success_message_1") }}<br>{{ $t("harvest_success_message_2") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftArrowIcon from "@/components/icons/LeftArrowIcon.vue";
import RightArrowIcon from "@/components/icons/RightArrowIcon.vue";
import ImgHandler from "@/components/shared/ImgHandler/index.vue";
import HarvestForm from "@/components/farm/flower_harvests/common/HarvestForm.vue";
import { MstButton } from "@/components/master";

export default {
  components: {
    LeftArrowIcon,
    RightArrowIcon,
    ImgHandler,
    HarvestForm,
    MstButton
  },
  data() {
    return {
      busy: false,
      genusOptions: [],
      flowerOptions: [],
      flowerOptionsHash: {},
      flowerHarvest: {
        id: null,
        flower_genus_id: "",
        recommended_min_price: "",
        recommended_max_price: "",
        flower_id: "",
        flower_specie: "",
        min_count: "",
        set_count: "",
        price: "",
        harvest_date: "",
        stem_size: "",
        note: "",
        status: "not_on_sale",
        is_editable: false,
      },
      flower_harvest_id: "",
      isShowStopModal: false,
      isShowReopenModal: false,
      isShowSuccessModal: this.$store.state.flowerHarvest.isShowSuccessModal,
    };
  },
  async created() {
    this.busy = true;
    this.flower_harvest_id = this.$route.params.flower_harvest_id;
    const [flowersRes, flowerGenusRes] = await Promise.all([
      this.$http2("get", "farm/flowers"),
      this.$http2("get", "farm/harvests/flower_genus"),
    ]);

    const flowerGenusIds = {};
    const flowerGenusArr = [];
    flowerGenusRes.result.flowerGenus.forEach(flowerGenus => {
      if (!flowerGenusIds[flowerGenus.id]) {
        flowerGenusIds[flowerGenus.id] = true;
        flowerGenusArr.push({
          label: flowerGenus.name,
          value: flowerGenus.id.toString(),
          recommended_max_price: flowerGenus.recommendedMaxPrice?.toString() || "",
          recommended_min_price: flowerGenus.recommendedMinPrice?.toString() || ""
        });
      }
    });
    this.genusOptions = flowerGenusArr;

    const flowerHash = {};
    const flowers = flowersRes.result.flowers;
    flowers.forEach(flower => {
      const flowerOption = {
        label: flower.flowerSpecie.name,
        value: flower.id.toString(),
        ...flower
      };

      const genusId = flower.flowerSpecie.flowerGenus.id.toString();
      if (flowerHash[genusId]) {
        flowerHash[genusId].push(flowerOption);
      } else {
        flowerHash[genusId] = [flowerOption];
      }
      this.flowerOptionsHash = flowerHash;
    });

    await this.setFlowerHarvest();
    setTimeout(() => {
      this.isShowSuccessModal = false;
      this.$store.dispatch("flowerHarvest/setIsShowSuccessModal", { isShowSuccessModal: false });
    }, 1600);
  },
  methods: {
    async imageHandler(e) {
      this.busy = true;
      const payload = {
        genusId: this.flowerHarvest.flower_genus_id,
        specieId: this.flowerHarvest.flower_specie_id,
        imgs: e
      };

      switch (e) {
        case "OVERSIZE":
          this.$store.dispatch("setSnackBar", { msg: this.$t("image_size_over"), color: "red" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
          break;

        case "WRONG_FORMAT":
          this.$store.dispatch("setSnackBar", { msg: this.$t("image_wrong_format"), color: "red" }, { root: true });
          this.$store.dispatch("snackOn", {}, { root: true });
          break;

        default:
          await this.$store.dispatch("flowers/putFlower", {
            flower_id: this.flowerHarvest.flower_id,
            data: payload
          });
          break;
      }
      this.busy = false;
    },
    onGoBack() {
      this.$router.go(-1);
    },
    goToEditPage() {
      this.$router.push(`/farm/flower_harvests/${this.flower_harvest_id}/edit`);
    },
    handleChange(e, key) {
      if (key === "flower_genus_id") {
        this.flowerOptions = this.flowerOptionsHash[e];
        this.flowerHarvest.flower_id = "";
        this.flowerHarvest.flower_specie = "";
        this.flowerHarvest.recommended_min_price = this.genusOptions.find(item => item.value === e)?.recommended_min_price || "";
        this.flowerHarvest.recommended_max_price = this.genusOptions.find(item => item.value === e)?.recommended_max_price || "";
      }
      this.flowerHarvest[key] = e.toString();
    },
    preStop() {
      this.isShowStopModal = true;
    },
    cancelStop() {
      this.isShowStopModal = false;
    },
    preReopen() {
      this.isShowReopenModal = true;
    },
    cancelReopen() {
      this.isShowReopenModal = false;
    },
    async changeStatus() {
      this.isShowStopModal = false;
      this.isShowReopenModal = false;

      const body = {
        id: this.flowerHarvest.id,
        status: this.flowerHarvest.status === "on_sale" ? "not_on_sale" : "on_sale"
      };
      const res = await this.$http2("put", "farm/harvests/change_status", body);
      if (res.status !== 204) {
        alert("更新に失敗しました");
        return;
      }

      await this.setFlowerHarvest();
    },
    async setFlowerHarvest() {
      this.busy = true;
      const flowerHarvestRes = await this.$http2("get", `farm/harvests/${this.flower_harvest_id}`);

      const flowerHarvest = flowerHarvestRes.result;
      const minCount = flowerHarvest.min_count > 0 ? flowerHarvest.min_count : 1;
      this.flowerHarvest = {
        id: flowerHarvest.id,
        flower_genus_id: flowerHarvest.flower.flower_specie.flower_genus.id.toString(),
        flower_specie: flowerHarvest.flower.flower_specie.name_furigana,
        flower_specie_id: flowerHarvest.flower.flower_specie.id.toString(),
        flower_id: flowerHarvest.flower_id.toString(),
        min_count: flowerHarvest.min_count.toString(),
        set_count: (flowerHarvest.confirmed_count / minCount).toString(),
        stem_size: flowerHarvest.stem_size.toString(),
        note: flowerHarvest.note,
        price: flowerHarvest.price.toString(),
        harvest_date: flowerHarvest.harvest_date.toString(),
        status: flowerHarvest.status,
        is_editable: flowerHarvest.is_editable
      };
      this.flowerOptions = this.flowerOptionsHash[this.flowerHarvest.flower_genus_id];

      this.busy = false;
    },
    async getFlowers() {
      const flowersRes = await this.$http2("get", `farm/flowers/${this.workingFlowerIds.join(",")}`);

      const flowerHash = {};
      flowersRes.result.flowers.forEach(flower => {
        const flowerOption = {
          label: flower.flowerSpecie.name,
          value: flower.id.toString(),
          ...flower
        };

        const genusId = flower.flowerSpecie.flowerGenus.id.toString();
        if (flowerHash[genusId]) {
          flowerHash[genusId].push(flowerOption);
        } else {
          flowerHash[genusId] = [flowerOption];
        }
      });

      this.flowerOptionsHash = flowerHash;
      if (this.flowerHarvest.flower_genus_id) {
        this.flowerOptions = this.flowerOptionsHash[this.flowerHarvest.flower_genus_id];
      }
    },
  },
  computed: {
    isOnSale() {
      return this.flowerHarvest.status === "on_sale";
    },
    workingFlowerIds() {
      return this.$store.state.flowers.listArr.filter(arr => this.$store.state.flowers.list[arr].is_working === true);
    },
    genusName() {
      return this.genusOptions[this.genusOptions.findIndex(item => item.value === this.flowerHarvest.flower_genus_id)].label;
    },
    images() {
      let imgs = [];
      if (this.flowerOptions.length > 0 && this.flowerHarvest.flower_id) {
        imgs = this.flowerOptions.find(option => option.value === this.flowerHarvest.flower_id).images;
      }
      return imgs;
    },
  }
};
</script>
<style lang="scss" scoped>
.status-title {
  display: flex;
  justify-content: space-between;
  margin: 0 12px 12px 12px;
}
.status-label {
  color: #818691;
  font-weight: bold;
}
.dialog-card {
  padding: 25px 52px;
  display: flex;
  flex-direction: column;
  height: 264px;
  width: 344px;
  margin: auto;
}
.card-logo {
  width: 42px;
  height: 46.67px;
  margin: auto;
  margin-top: 12px;
  margin-bottom: 12px;
}
.dialog-header {
  margin: auto;
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
}
.dialog-text {
  margin-bottom: 12px;
  font-size: 14px;
}
.flex-items {
  display: flex;
  gap: 10px;
}
.harvest-success {
  width: 63.3px;
  height: 63.3px;
  margin: auto;
  margin-top: 28px;
  margin-bottom: 26px;
}
.harvest-success-txt {
  font-weight: bold;
  margin: auto;
  margin-top: 0;
  text-align: center;
}
.modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 0 solid #888;
  border-radius: 8px;
  max-width: 344px;
}
</style>
