<template>
  <!-- eslint-disable -->

  <svg class="pointer" :height="size" width="12" height="13" viewBox="0 0 12 13" fill="none">
    <path d="M3.5752 11.05C3.4502 10.925 3.3877 10.777 3.3877 10.606C3.3877 10.4353 3.4502 10.2875 3.5752 10.1625L7.2377 6.5L3.5627 2.825C3.44603 2.70833 3.3877 2.5625 3.3877 2.3875C3.3877 2.2125 3.4502 2.0625 3.5752 1.9375C3.7002 1.8125 3.8482 1.75 4.0192 1.75C4.18986 1.75 4.3377 1.8125 4.4627 1.9375L8.6627 6.15C8.7127 6.2 8.7482 6.25417 8.7692 6.3125C8.78986 6.37083 8.8002 6.43333 8.8002 6.5C8.8002 6.56667 8.78986 6.62917 8.7692 6.6875C8.7482 6.74583 8.7127 6.8 8.6627 6.85L4.4502 11.0625C4.33353 11.1792 4.18986 11.2375 4.0192 11.2375C3.8482 11.2375 3.7002 11.175 3.5752 11.05Z" :fill="color"/>
  </svg>


  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>
