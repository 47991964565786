<template>
  <!-- eslint-disable -->

  <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="color"
      d="M5.53823 9.82407L1.71573 6.00157L0.414062 7.29407L5.53823 12.4182L16.5382 1.41823L15.2457 0.125732L5.53823 9.82407Z"
    />
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String
  }
};
</script>

<style scoped>

</style>
