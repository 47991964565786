<template>
  <div
    class="mstSnackBar"
    :style="styled"
  >
    <span>{{ msg }}</span>
  </div>
</template>

<script>
export default {
  name: "MstSnackBar",
  computed: {
    styled() {
      const color = this.$store.state.mstSnackBar.color;
      const styled = {};
      styled.color = color;
      styled.border = `2px solid ${color}`;
      return styled;
    },
    isVisible() {
      return this.$store.state.mstSnackBar.isEnable;
    },
    msg() {
      return this.$store.state.mstSnackBar.msg;
    },
  },
  watch: {
    isVisible(flag) {
      if (flag) {
        this.$el.classList.remove("hide");
        this.$el.classList.add("active");
        setTimeout(() => {
          this.$store.dispatch("mstSnackOff");
        }, 5000);
      } else {
        this.$el.classList.remove("active");
        this.$el.classList.add("hide");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mstSnackBar {
  position: absolute;
  top: 135px;
  right: 0px;
  max-width: calc( 100vw - 40px );
  word-break: break-all;
  font-size: 16px;
  border-radius: 4px;
  background-color: white;
  padding: 16px;
  z-index: 1;
  transform: translateX(100%);
  opacity: 1;
  transition: transform 0.5s, opacity 0.5s;
}
.mstSnackBar.active {
  right: 20px;
  transform: translateX(0);
  opacity: 1;
}
.mstSnackBar.hide {
  transform: translateX(100%);
  opacity: 0;
}
</style>
