<template>
  <div class="search-div">
    <input class="search-input" :disabled="!categoryId || readOnly" @input="onChangeSearch" :value="searchString" />
    <div class="search-result" v-if="searchString !== '' && onSearch" v-on:click.self="hideSearch" ref="searchRef">
      <div class="mx-4 pa-2" @click="onGenusItem(item.label, item.value)" v-for="item in searchResult" :key="item.id">
        <div class="my-1 d-flex justify-space-between align-center">
          <span>{{ item.label }}</span>
        </div>
      </div>
      <div v-if="searchResult.length === 0">
        <div class="mx-4 pa-2">{{ $t('not_found_genus') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomDialog from "@/components/shared/CustomDialog.vue";
import { MstSelectBox } from "@/components/master";

export default {
  components: { CustomDialog, MstSelectBox },
  props: {
    genusId: {
      type: Number,
      default: null
    },
    categoryId: {
      type: Number,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      searchString: "",
      searchResult: [],
      onSearch: false
    };
  },
  created() {
    if (this.genusId) {
      this.searchString = this.genusName();
    }
  },
  methods: {
    selectHandler(id) {
      this.$emit("select", id);
    },
    hideSearch() {
      this.onSearch = false;
    },
    onChangeSearch(event) {
      this.onSearch = true;
      this.searchString = event.target.value;
      const genusOptions = this.$store.getters["flowerGenus/options"];
      const genusArray = genusOptions.map(obj => ({
        value: obj.value.toString(),
        label: obj.text
      }));
      this.searchResult = genusArray.filter(genus => genus.label.includes(this.searchString));
      this.selectHandler(null);
    },
    onGenusItem(name, id) {
      this.searchString = name;
      this.searchResult = [];
      this.onSearch = false;
      this.$emit("select", id);
    },
    genusName() {
      return this.$store.state.flowers.list[this.$route.params.flower_id]?.flowerSpecie.flowerGenus.name;
    },
  },
  mounted() {
    document.addEventListener("click", e => {
      if (this.$refs.searchRef && !this.$refs.searchRef.contains(e.target)) {
        this.searchString = "";
        this.hideSearch();
      }
    });
  }
};
</script>

<style scoped>
.search-input {
  border:  1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  height: 33px;
  padding: 4px 8px 4px 8px;
  width: 100%;
}

.search-button:focus {
  outline: none;
}
.search-result {
  width: 100%;
  position: absolute;
  background-color: white;
  overflow: auto !important;
  max-height: 250px;
  z-index: 99;
}
.search-div {
  position: relative;
}
input:disabled {
   background: #f1f1f1;
   cursor: not-allowed !important;
   color: #999;
 }
</style>
