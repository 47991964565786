<template>
  <div v-if="datePickerDates.length > 0" class="mstDateFilter mt-3">
    <div ref="dates" class="mstDateFilter__dates">
      <div class="mstDateFilter__inner">
        <ul class="mstDateFilter__items">
          <li v-for="date in parsedDates" :key="date.getTime()" class="mstDateFilter__item">
            <button
              ref="button"
              :aria-selected="isCurrent(date)"
              :data-date="$date.YYYYMMDD(date)"
              type="button"
              class="mstDateFilter__button text-sm"
              @click="$emit('change', date)"
            >
              {{ getDateString(date) }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="mstDateFilter__controller text-sm">
      <button
        type="button"
        class="mstDateFilter__controllerButton -prev"
        @click="getPrevMonth"
      >
        <MstIcon type="chevron-left" size="18px" />
        前の月
      </button>
      <button
        type="button"
        class="mstDateFilter__controllerButton -next"
        @click="getNextMonth"
      >
        次の月
        <MstIcon type="chevron-right" size="18px" />
      </button>
    </div>
  </div>
  <div v-else>
    <div class="mstDateFilter__controller text-sm">
      <button
        type="button"
        class="mstDateFilter__controllerButton -prev"
        @click="getPrevMonth"
      >
        <MstIcon type="chevron-left" size="18px" />
        前の月
      </button>
      <button
        type="button"
        class="mstDateFilter__controllerButton -next"
        @click="getNextMonth"
      >
        次の月
        <MstIcon type="chevron-right" size="18px" />
      </button>
    </div>
    <div class="mt-4">
      <div v-if="status === 'unconfirmed'">
        この月のリクエストはありません
      </div>
      <div v-if="status === 'confirmed'">
        この月の未出荷の商品はありません
      </div>
      <div v-if="status === 'shipped'">
        この月の出荷済の商品はありません
      </div>
    </div>
  </div>

</template>

<script>
import dayjs from "dayjs";
import {
  startOfMonth,
  isSameMonth,
} from "date-fns";
import { MstIcon, MstIntersection } from "@/components/master";

export default {
  name: "MstDateFilter",
  components: { MstIcon, MstIntersection },
  model: {
    prop: "current",
    event: "change",
  },
  props: {
    current: { type: Date },
    datePickerDates: { type: Array, default: () => [] },
    maxDate: { type: Date },
    status: { type: String }
  },
  data() {
    return {
      dates: [],
      activeDate: null,
      scrolling: null,
    };
  },
  computed: {
    parsedDates() {
      return this.datePickerDates.map(dateStr => new Date(dateStr));
    },
    currentModel: {
      get() {
        return this.current;
      },
      set(newValue) {
        this.$emit("change", newValue);
      }
    },
    startDate() {
      return startOfMonth(dayjs().startOf("day").toDate());
    },
    buttonObserver() {
      return new IntersectionObserver(this.handleButtonIntersect);
    },
  },
  watch: {
    dates() {
      this.$nextTick(() => {
        if (!this.$refs.button || !this.$refs.button.length) return;
        this.$refs.button.forEach(button => {
          this.buttonObserver.unobserve(button);
          this.buttonObserver.observe(button);
        });
      });
    },
  },
  methods: {
    isCurrent(date) {
      return dayjs(date).isSame(this.current, "date");
    },
    getDateString(date) {
      return `${this.$d(date, "shortDate")}（${this.$d(date, "day")}）`;
    },
    getPrevMonth() {
      this.$emit("handle-prev-month");
    },
    getNextMonth() {
      this.$emit("handle-next-month");
    },
    handleButtonIntersect(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const date = new Date(entry.target.getAttribute("data-date"));
          if (isSameMonth(this.activeDate, date)) return;
          this.activeDate = new Date(date.getFullYear(), date.getMonth(), 1);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mstDateFilter__dates {
  overflow-x: auto;
}

.mstDateFilter__inner {
  display: flex;
}

.mstDateFilter__intersect {
  width: 48px;
  flex-shrink: 0;
}

.mstDateFilter__items {
  display: flex;
  align-items: center;
  padding: 0;
  list-style-type: none;
}

.mstDateFilter__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  border-radius: 2px;
  border: 1px solid variables.$color-gray-300;
  width: 100px;
  height: 30px;
  font-feature-settings: "palt";
  color: variables.$color-gray-600;
  transition: 0.3s ease;

  &[aria-selected="true"] {
    border-color: variables.$color-brand-secondary;
    background: variables.$color-brand-secondary;
    font-weight: bold;
    color: variables.$color-white;
  }

  &:disabled {
    border-color: variables.$color-gray-100;
    color: variables.$color-gray-300;
    cursor: not-allowed;
  }
}

.mstDateFilter__controller {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding: 0 8px;
}

.mstDateFilter__controllerButton {
  display: flex;
  align-items: center;
  line-height: 1;
  color: variables.$color-black-500;
  transition: color 0.3s ease;

  &:disabled {
    color: variables.$color-gray-400;
    cursor: not-allowed;
  }
}
</style>
