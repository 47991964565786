/**
 * @typedef Farm
 * @type {object}
 * @property {number} id
 * @property {string} address
 * @property {string} building_name
 * @property {string} municipality
 * @property {string} name
 * @property {string} phone
 * @property {string} postal_code
 * @property {string} prefecture
 * @property {string} quit_reason
 * @property {string} representative_name
 * @property {number} user_id
 */

export default {
  namespaced: true,
  state: {
    list: {},
    listArr: []
  },
  mutations: {
    setData(state, payload) {
      state.list = payload;
      state.listArr = Object.keys(state.list)
        .map(Number)
        .sort((a, b) => a - b);
    }
  },
  actions: {
    getFarms({ commit, state }) {
      return new Promise(resolve => {
        this._vm.$http("get", "farms").then(res => {
          if (res.status === 200) {
            const newData = { ...state.list };

            res.result.farms.forEach(item => {
              newData[item.id] = item;
            });

            commit("setData", newData);
          }

          resolve(true);
        });
      });
    }
  },
  getters: {
    getState(state) {
      return state;
    },
    getFarm: state => id => state.list[id]
  }
};
