<template>
  <!-- eslint-disable -->

  <svg :height="size" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.8809 11.8086L13.618 0.553902C13.5369 0.472648 13.4406 0.408184 13.3345 0.3642C13.2285 0.320217 13.1148 0.297577 13 0.297577C12.8852 0.297577 12.7716 0.320217 12.6655 0.3642C12.5595 0.408184 12.4632 0.472648 12.3821 0.553902L1.11917 11.8086C0.79104 12.1367 0.605103 12.5824 0.605103 13.0473C0.605103 14.0125 1.38987 14.7973 2.3551 14.7973H3.54182V22.8281C3.54182 23.3121 3.93284 23.7031 4.41682 23.7031H11.25V17.5781H14.3125V23.7031H21.5832C22.0672 23.7031 22.4582 23.3121 22.4582 22.8281V14.7973H23.6449C24.1098 14.7973 24.5555 14.6141 24.8836 14.2832C25.5645 13.5996 25.5645 12.4922 24.8809 11.8086V11.8086Z" :fill="color"/>
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>

<style>
</style>
