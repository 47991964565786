<template>
  <CartLayout v-if="draftOrder" :order="draftOrder" title="カート内" next-text="内容を確認する" next-to="/shop/cart/confirmation"
    prev-text="買い物を続ける" prev-to="/shop/products">
    <Pulldown v-for="deliveryDateGroup in draftOrder.order_items_group_by_delivery_date" :key="deliveryDateGroup.id" is-default-open>
      <template #title>
        <div class="order-header">
          <span class="delivery-date">{{ getFormattedDate(deliveryDateGroup.delivery_date) }}</span>
          <span>
            <span class="order-total-price">{{ `${$n(getOrderPrice(deliveryDateGroup))}` }}</span>
            <span class="unit">円</span>
          </span>
        </div>
      </template>
      <template #content>
        <CartSectionTitle>購入</CartSectionTitle>
        <div class="product-list" v-if="(normalOrder(deliveryDateGroup) && normalOrder(deliveryDateGroup).length)">
          <CartProductCard v-for="orderItem in normalOrder(deliveryDateGroup)" :key="orderItem.id"
            :order-item="orderItem" :max="getPurchasableMaxLotCount(orderItem)"
            @change="changeCount($event, normalOrder(deliveryDateGroup))" @complete="changeCountCompleted"
            @delete="deleteOrderItem(orderItem)" />
        </div>
        <div class="empty" v-else>通常の購入はありません。</div>

        <CartSectionTitle>リクエスト</CartSectionTitle>
        <div class="product-list" v-if="(requestOrder(deliveryDateGroup) && requestOrder(deliveryDateGroup).length)">
          <CartProductCard v-for="orderItem in requestOrder(deliveryDateGroup)" :key="orderItem.id"
            :order-item="orderItem" @change="changeCount($event, requestOrder(deliveryDateGroup))"
            @complete="changeCountCompleted" @delete="deleteOrderItem(orderItem)" />
        </div>
        <div class="empty" v-else>リクエストはありません。</div>
      </template>
    </Pulldown>
  </CartLayout>
  <CartLayout v-else :order="draftOrder" title="カート内" next-text="内容を確認する" next-to="/shop/cart/confirmation"
    prev-text="買い物を続ける" prev-to="/shop/products">
    カートに商品が入っていません
  </CartLayout>
</template>

<script>
import CartLayout from "@/components/shop/cart/CartLayout.vue";
import CartProductCard from "@/components/shop/cart/CartProductCard.vue";
import CartSectionTitle from "@/components/shop/cart/CartSectionTitle.vue";
import Pulldown from "@/components/shop/Pulldown.vue";

export default {
  components: {
    CartLayout,
    CartProductCard,
    CartSectionTitle,
    Pulldown
  },

  computed: {
    draftOrder() {
      return this.$store.getters["orders/groupedDraftOrder"];
    },
  },

  mounted() {
    this.$store.dispatch("orders/getDraftOrdersGroupByDeliveryDate");
    this.$store.dispatch("getProfile", "shop");
  },

  methods: {
    getPurchasableMaxLotCount(orderItem) {
      return orderItem.flower_harvest.stock_count / orderItem.harvest_min_count;
    },
    async deleteOrderItem(orderItem) {
      await this.$api.delete(`order_items/${orderItem.id}`);
      this.$store.dispatch("orders/getDraftOrdersGroupByDeliveryDate");
      this.$store.dispatch("orders/getDraftOrder");
    },
    changeCount(data, orderItems) {
      const targetOrderItem = orderItems.find(orderItem => orderItem.id === data.id);
      targetOrderItem.lot_count = data.lot_count;
    },
    changeCountCompleted() {
      this.$store.dispatch("orders/getDraftOrdersGroupByDeliveryDate");
    },
    getFormattedDate(date) {
      const dateObj = new Date(date);
      return `${this.$date.MMDD(dateObj, "/")}（${this.$date.getDayOfWeek(dateObj)}）`;
    },
    getOrderPrice(order) {
      return order.subtotal_sales_price_without_tax_and_delivery_fee;
    },
    normalOrder(order) {
      if (!order || !order.order_items || !order.order_items.length) return [];
      return order.order_items.filter(o => o.order_item_type !== "request");
    },
    requestOrder(order) {
      if (!order || !order.order_items || !order.order_items.length) return [];
      return order.order_items.filter(o => o.order_item_type === "request");
    }
  }
};
</script>

<style lang="scss" scoped>
.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  &>.delivery-date {
    background: #375293;
    border-radius: 2px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 14px;
    text-align: center;
    min-width: 120px;
  }

  & .order-total-price {
    font-weight: 700;
    font-size: 20px;
    color: #DD5055;
  }

  & .unit {
    font-weight: 400;
    font-size: 12px;
    color: #DD5055;
  }
}

.product-list {
  display: grid;
  grid-gap: 8px;
}

.empty {
  margin: 16px 24px;
  color: #777;
  font-size: 14px;
  font-weight: bold;
}
</style>
