<template>
  <div>

    <div v-if="isEditIcon">
      <button
        class="button"
        @click="$emit('click')"
      >
        <v-icon large color="grey darken-3">mdi-square-edit-outline</v-icon>
        <div class="button-label">編集する</div>
      </button>
    </div>

    <div v-if="isReturnIcon">
      <button
        class="button"
        @click="$emit('click')"
      >
        <v-icon large color="grey darken-3">mdi-keyboard-return</v-icon>
        <div class="button-label">戻る</div>
      </button>
    </div>

    <div v-if="isUpdateNotOnSaleIcon">
      <button
        class="button"
        @click="$emit('click')"
      >
        <v-icon large color="grey darken-3">mdi-stop-circle</v-icon>
        <div class="button-label">出品をやめる</div>
      </button>
    </div>

    <div v-if="isUpdateOnSaleIcon">
      <button
        class="button"
        @click="$emit('click')"
      >
        <v-icon large color="green darken-2">mdi-check-circle</v-icon>
        <div class="button-label">再出品をする</div>
      </button>
    </div>

    <div v-if="isSaveIcon">
      <button
        class="button"
        @click="$emit('click')"
        :disabled="disabled"
      >
        <v-icon v-if="!disabled" large color="green darken-2">mdi-check-circle</v-icon>
        <v-icon v-else large color="grey lighten-2">mdi-check-circle</v-icon>
        <div class="button-label">保存する</div>
      </button>
    </div>

    <div v-if="isCopyIcon">
      <button
        class="button"
        @click="$emit('click')"
        :disabled="disabled"
      >
        <v-icon v-if="!disabled" large color="grey darken-3">mdi-content-copy</v-icon>
        <v-icon v-else large color="grey lighten-2">mdi-content-copy</v-icon>
        <div class="button-label">前回をコピー</div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isEditIcon() {
      return this.type === "edit";
    },
    isReturnIcon() {
      return this.type === "return";
    },
    isUpdateNotOnSaleIcon() {
      return this.type === "update_not_on_sale";
    },
    isUpdateOnSaleIcon() {
      return this.type === "update_on_sale";
    },
    isCopyIcon() {
      return this.type === "copy";
    },
    isSaveIcon() {
      return this.type === "save";
    }
  }
};
</script>

<style scoped>
.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem;
  white-space: nowrap;
  color: #424242;
  min-width: 80px;
}

.button-label {
  font-size: 0.8rem;
}
</style>
