<template>
  <span :class="`mdi-${type}`" :style="styled" class="mstIcon" />
</template>

<script>
export default {
  name: "MstIcon",
  props: {
    type: { type: String, required: true },
    size: { type: String, default: "24px" },
  },
  computed: {
    styled() {
      return {
        fontSize: this.size,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.mstIcon {
  display: inline-block;
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
}
</style>
