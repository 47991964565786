import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"width":"500","scrollable":""},on:{"click:outside":function($event){return _vm.$emit('close')}},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[(_vm.title)?_c(VCardTitle,{staticClass:"headline lighten-2",attrs:{"primary-title":""}},[_c('div',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"ml-auto"},[_c(VIcon,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-close")])],1)]):_vm._e(),_c(VDivider),_c(VCardText,{staticClass:"pa-2"},[_vm._t("text")],2),_c(VDivider),(!_vm.hideActions)?_c(VCardActions,{staticClass:"card-actions"},[_vm._t("actions")],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }