<template>
  <div>
    <v-card v-if="mobile" class="d-flex align-center">
      <img :src="require('@/assets/CAVIN_Logo_black.png')" style="width: 110px" class="ma-4" />

      <div class="ml-auto d-flex align-center justify-center align-self-stretch">
        <div class="mr-2">
          <span class="user-name">{{ getUserName }}</span>
          <small>{{ $t("sama") }}</small>
        </div>
        <div
          class="mobile-menu align-self-stretch d-flex align-center justify-center"
          @click="onMobileMenu"
        >
          <MenuIcon color="white" size="48" />
        </div>
      </div>
    </v-card>

    <v-card v-else>
      <v-row>
        <v-col cols="2">
          <img :src="require('@/assets/CAVIN_Logo_black.png')" style="width: 120px" class="ma-4" />
        </v-col>

        <v-col cols="1">
          <div>
            <small>{{ $t("welcome") }}</small>
          </div>
          <span class="user-name">{{ getUserName }}</span>
          <small>{{ $t("sama") }}</small>
        </v-col>

        <v-col cols="3" class="d-flex align-center">
          <div v-if="driver && !is_admin" class="area mr-8">
            エリア：{{ driver.areas[0].name }}
          </div>
          <v-btn
            small
            outlined
            @click="logout"
          >{{ $t("logout") }}</v-btn>
        </v-col>

        <v-col cols="6" class="d-flex align-center justify-end">
          <router-link to="/driver/settings" class="d-flex black--text">
            <SettingsIcon color="black"></SettingsIcon>
            <span class="mr-7 ml-2">{{ $t("settings") }}</span>
          </router-link>
          <a :href="officialLineUrl" class="d-flex black--text">
            <img :src="require('@/assets/question-circle-solid.svg')" style="width: 18px" />
            <span class="mr-5 ml-2">{{ $t("line_support") }}</span>
          </a>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuIcon from "@/components/icons/MenuIcon.vue";
import SettingsIcon from "@/components/icons/SettingsIcon.vue";

export default {
  components: { MenuIcon, SettingsIcon },

  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    };
  },
  computed: {
    ...mapGetters(["getUserName"]),
    driver() {
      return this.$store.state.user.driver;
    },
    is_admin() {
      return this.$store.state.user.driver.is_admin;
    },
    officialLineUrl() {
      return process.env.VUE_APP_X_OFFICIAL_LINE_URL;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    onMobileMenu() {
      this.$store.dispatch("setMobileMenuOpen", true);
    }
  }
};
</script>

<style scoped>
.user-name {
  font: Bold 1rem Helvetica;
  color: #0c1624;
}

.pointer {
  cursor: pointer;
}

.mobile-user {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 20px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
}

.mobile-menu {
  background: #007e57;
  border-radius: 0;
  width: 4rem;
}

.area {
  background: #E0F2F1;
  border-radius: 6px;
  padding: 0.8rem;
  font-size: 0.6rem;
}
.mobile-area {
  background: #E0F2F1;
  border-radius: 6px;
  padding: 0.5rem;
  font-size: 0.6rem;
}

@media only screen and (max-width: 600px) {
  .user-name {
    font-size: .8rem;
  }
}
</style>
