<template>
  <!-- eslint-disable -->

  <svg xmlns="http://www.w3.org/2000/svg" :width="size" viewBox="0 0 16 14">
    <path
      d="M6,0V2h8V12H6v2H16V0ZM4,4,0,7l4,3V8h8V6H4Z"
      :fill="color"
    />
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: String,
      default: "16"
    }
  }
};
</script>

<style>
</style>
