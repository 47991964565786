<template>
  <v-container class="result-list">
    <p class="title my-2 ml-4">出荷実績</p>

    <v-select
      class="mb-2 ml-4"
      v-model="targetMonth"
      :items="targetMonthOptions"
      style="width: 200px"
      solo
      hide-details
    />

    <v-list
      three-line
      class="pa-0"
    >
      <template
        v-for="(result, index) in results"
      >
        <v-list-item
          :key="index"
          class="result-list-item"
          @click="clickHandler(result.id)"
        >
          <v-list-item-content class="mx-4">
            <div class="item-content d-flex justify-space-between">
              <div class="left">
                <v-list-item-title class="mb-2">
                  {{ deliveryDateFormat(result.harvest_date) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span class="mr-10">{{ currency(result.total_harvest_price_without_tax) }}</span>
                  <span>{{ result.item_count }}個の商品</span>
                </v-list-item-subtitle>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-container>
</template>

<script>
import { formatDate, currency } from "@/plugins/i18n";

export default {
  components: {
  },
  data() {
    return {
      params: "",
      targetMonth: "",
      targetMonthOptions: [],
      results: [],
      weekArray: [
        this.$t("short_sunday"),
        this.$t("short_monday"),
        this.$t("short_tuesday"),
        this.$t("short_wednesday"),
        this.$t("short_thursday"),
        this.$t("short_friday"),
        this.$t("short_saturday")
      ]
    };
  },

  watch: {
    targetMonth() {
      this.getShippingResultList();
    }
  },

  async created() {
    const d = new Date();
    const month = `${d.getFullYear()}-${d.getMonth() + 1}`;

    this.targetMonth = month;
  },

  methods: {
    format(date) {
      const d = new Date(date);
      return formatDate(d, "m月d日");
    },
    deliveryDateFormat(delivery_date) {
      const deliveryDate = new Date(delivery_date);
      return `${this.format(delivery_date)}(${this.weekArray[deliveryDate.getDay()]})`;
    },
    clickHandler(receipt_id) {
      this.$router.push({ path: `shipping_result?receipt_id=${receipt_id}` });
    },
    async getShippingResultList() {
      const res = await this.$http2("get", `farm/farm_receipts?month=${this.targetMonth}`);

      const options = [];
      res.result.month_options.forEach(month => {
        options.push({
          text: `${formatDate(month, "yyyy年m月")}`,
          value: `${formatDate(month, "yyyy-m")}`
        });
      });

      this.results = res.result.receipts;
      this.targetMonthOptions = options;
    },
    currency
  }
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 16px !important;
}

.result-list {
  max-width: variables.$container-max-width;
  &-item {
    height: 4rem;
    font: Bold 1rem Helvetica Neue;
    letter-spacing: 0.38px;
    color: #333333;
    margin: 0 auto;
    border-bottom: 1px solid #bbb;

    &:last-child {
      border-bottom: none;
    }
  }

  .item-content {
    width: 100%;

    .left {
      width: 80%;
    }

    .right {
      width: 20%;
      text-align: center;
      margin: auto;
    }
  }
}
</style>
