if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(registration => {
      registration.unregister();
    });
  });

  caches.keys().then(keys => {
    const promises = [];
    keys.forEach(cacheName => {
      if (!cacheName) return;
      promises.push(caches.delete(cacheName));
    });
  });
}
