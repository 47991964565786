<template>
  <div style="height: 87vh; overflow:auto">
    <div>
      <div>
        <div class="d-flex list-header">
          <div class="d-flex flex-column" style="width: 100%">
            <v-select v-model="$store.state.driverInformation.selectedDate" :items="deliveryDates" label="集荷日" @change="getOrderItems" />
            <v-select
              v-if="isAdmin"
              v-model="selectedShippingArea"
              :items="shippingDrivers"
              class="mt-4"
              item-text="name"
              item-value="id"
              label="集荷エリア"
              @change="getOrderItems"
            />
            <v-select
              v-if="isAdmin"
              v-model="selectedDeliveryArea"
              :items="deliveryDrivers"
              class="mt-4"
              item-text="name"
              item-value="id"
              label="配送エリア"
              @change="getOrderItems"
            />
          </div>
        </div>

        <v-alert v-model="alert" color="blue" text dismissible class="ma-3">ステータスの変更が完了しました。</v-alert>
      </div>

      <v-container class="driver">
        <div>
          <div class="d-flex align-center justify-end">
            <span @click="changeView('list')">
              <list-icon style="width: 44px" :color="deliveryView ? '#111' : '#BDBDBD'" />
            </span>
            <span @click="changeView('grid')">
              <table-view :size="'44px'" :color="deliveryView ? '#BDBDBD' : '#111'" />
            </span>
          </div>
          <list-by-place :from="from" :to="to" :isAdmin="isAdmin" :viewType="deliveryView ? 'list' : 'card'"/>
        </div>
      </v-container>
    </div>

    <div v-if="selectedOrderListCnt > 0">
      <button v-if="deliveryType === 'ship'" class="qrcode-read-confirm" @click="editStatus">集荷済に変更（{{ selectedOrderListCnt }}件）</button>
      <button v-else class="qrcode-read-confirm" @click="editStatus">配送済に変更（{{ selectedOrderListCnt }}件）</button>
    </div>
    <div v-if="isDeliveryDriver || isShippingDriver" @click="deliveryTypeDialog = true">
      <qr-code-reader-icon class="qrcode-reader"></qr-code-reader-icon>
    </div>

    <v-dialog v-if="isDeliveryDriver || isShippingDriver" v-model="deliveryTypeDialog" max-width="600px" persistent>
      <v-card class="pb-3">
        <div style="height: 40px">
          <v-btn class="delivery-type-confirm-title" text @click="deliveryTypeDialog = false">×</v-btn>
        </div>
        <div class="delivery-type-confirm-area">
          <router-link to="/driver/qr_code_reader">
            <button class="ship-order-btn" @click="setDeliveryType('ship')">集荷済にする</button>
          </router-link>
          <router-link to="/driver/qr_code_reader">
            <button class="delivery-order-btn" @click="setDeliveryType('delivery')">配送済にする</button>
          </router-link>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ListIcon from "@/components/icons/ListIcon.vue";
import TableView from "@/components/icons/TableView.vue";
import QrCodeReaderIcon from "@/components/icons/QrCodeReaderIcon.vue";
import ListByPlace from "@/components/driver/information/ListByPlace.vue";
import { formatDate } from "@/plugins/i18n";

export default {
  components: {
    ListIcon,
    TableView,
    QrCodeReaderIcon,
    ListByPlace,
  },
  data() {
    return {
      selectedShippingArea: 0,
      selectedDeliveryArea: 0,
      deliveryDates: [],
      deliveryView: true,
      dialog: false,
      deliveryTypeDialog: false,
      listType: "",
      alert: false,
      allowUnconfirmedOrderToShipped: false,
      canEditStatus: false
    };
  },
  async created() {
    await this.$store.dispatch("drivers/getAreas");
    await this.$store.dispatch("drivers/getDrivers");
    await this.getOrderItems();
  },
  computed: {
    areas() {
      const areas = [...this.$store.state.drivers.areas];
      areas.unshift({ id: 0, name: "全エリア", note: "" });
      return areas;
    },
    shippingDrivers() {
      let drivers = [...this.$store.state.drivers.drivers];
      drivers = drivers.filter(d => d.driver_type === "shipping");
      drivers.unshift({ id: 0, name: "全エリア", note: "" });
      return drivers;
    },
    deliveryDrivers() {
      let drivers = [...this.$store.state.drivers.drivers];
      drivers = drivers.filter(d => d.driver_type === "delivery");
      drivers.unshift({ id: 0, name: "全エリア", note: "" });
      return drivers;
    },
    from() {
      const fromOrderItems = [...this.$store.state.driverInformation.from];
      if (this.selectedOrderListCnt > 0 && this.deliveryType === "ship") {
        const selectedOrderList = { ...this.selectedOrderList };
        fromOrderItems.map(orderItem => {
          if (selectedOrderList[orderItem.id.toString()]) {
            orderItem.is_checked = selectedOrderList[orderItem.id.toString()].checked;
            orderItem.is_qr = selectedOrderList[orderItem.id.toString()].is_qr;
          } else {
            orderItem.is_checked = false;
          }
          return orderItem;
        });
      } else {
        fromOrderItems.map(orderItem => {
          orderItem.is_checked = false;
          return orderItem;
        });
      }
      const groupByName = {};
      // eslint-disable-next-line array-callback-return
      fromOrderItems.map(item => {
        if (groupByName[item.name]) {
          groupByName[item.name].push(item);
        } else {
          groupByName[item.name] = [item];
        }
      });
      return { ...groupByName };
    },
    to() {
      const toOrderItems = [...this.$store.state.driverInformation.to];
      if (this.selectedOrderListCnt > 0 && this.deliveryType === "delivery") {
        const selectedOrderList = { ...this.selectedOrderList };
        toOrderItems.map(orderItem => {
          if (selectedOrderList[orderItem.id.toString()]) {
            orderItem.is_checked = selectedOrderList[orderItem.id.toString()].checked;
            orderItem.is_qr = selectedOrderList[orderItem.id.toString()].is_qr;
          } else {
            orderItem.is_checked = false;
          }
          return orderItem;
        });
      } else {
        toOrderItems.map(orderItem => {
          orderItem.is_checked = false;
          return orderItem;
        });
      }
      const groupByName = {};
      // eslint-disable-next-line array-callback-return
      toOrderItems.map(item => {
        if (groupByName[item.name]) {
          groupByName[item.name].push(item);
        } else {
          groupByName[item.name] = [item];
        }
      });
      return { ...groupByName };
    },
    orders() {
      return this.$store.state.driverInformation.orders;
    },
    ordersByFarm() {
      return this.$store.state.driverInformation.ordersByFarm;
    },
    ordersByShop() {
      return this.$store.state.driverInformation.ordersByShop;
    },
    selectedList() {
      return this.$store.state.driverInformation.selectedList;
    },
    isConfirm() {
      return (
        this.selectedList.delivery.from.length > 0
        || this.selectedList.delivery.to.length > 0
      );
    },
    driverType() {
      return this.$store.state.user.driver && this.$store.state.user.driver.driver_type;
    },
    isDeliveryDriver() {
      return this.driverType === "delivery";
    },
    isShippingDriver() {
      return this.driverType === "shipping";
    },
    isAdmin() {
      return (
        this.$store.state.user.driver && this.$store.state.user.driver.is_admin
      );
    },
    availableDateList() {
      return this.$store.state.driverInformation.availableDateList.map(date => this.formatDate(date));
    },
    selectedOrderList() {
      return this.$store.state.driverInformation.selectedOrderList;
    },
    selectedOrderListCnt() {
      return Object.values(this.$store.state.driverInformation.selectedOrderList).filter(item => item.checked).length;
    },
    detailItems() {
      const selectedOrderList = Object.keys(this.selectedOrderList);
      let orderItems = [];
      const selectedItems = [];
      if (this.deliveryType === "ship") {
        orderItems = [...this.$store.state.driverInformation.from];
      } else {
        orderItems = [...this.$store.state.driverInformation.to];
      }
      let filterFlg = false;
      if (selectedOrderList?.length > 0) {
        orderItems.map(orderItem => {
          const isShipOrders = (orderItem.status === "confirmed" && !this.isDeliveryDriver) || (orderItem.status === "unloaded" && !this.isShippingDriver);
          const isDeliveryOrders = (orderItem.status === "shipped" && !this.isDeliveryDriver) || (orderItem.status === "picked" && !this.isShippingDriver);
          const isSelected = selectedOrderList.includes(orderItem.id.toString()) && this.selectedOrderList[orderItem.id.toString()].checked;
          if (isSelected) {
            if ((this.deliveryType === "delivery" && isDeliveryOrders) || (this.deliveryType === "ship" && isShipOrders)) {
              selectedItems.push(orderItem);
            } else {
              filterFlg = true;
            }
          }
          return orderItem;
        });
      }
      if (filterFlg) return [];
      return selectedItems;
    },
    deliveryType() {
      return this.$store.state.driverInformation.deliveryType;
    }
  },
  methods: {
    changeView(type) {
      this.deliveryView = type === "list";
      this.clearSelected();
    },
    getAreaIds(driver) {
      if (driver.id !== 0) {
        return driver.areas.map(d => d.id);
      }
      return null;
    },
    getOrderItems() {
      const shippingDriver = this.shippingDrivers.find(driver => driver.id === this.selectedShippingArea);
      const deliveryDriver = this.deliveryDrivers.find(driver => driver.id === this.selectedDeliveryArea);
      const shippingAreaIds = this.getAreaIds(shippingDriver);
      const deliveryAreaIds = this.getAreaIds(deliveryDriver);
      this.$store.dispatch("driverInformation/getOrderItems", {
        shippingAreaIds,
        deliveryAreaIds
      }).then(() => {
        this.deliveryDates = this.availableDateList;
      });
    },
    formatDate(d) {
      return formatDate(d, "yyyy-mm-dd");
    },
    clearSelected() {
      this.$store.dispatch("driverInformation/clearSelected");
    },
    validItems(items) {
      let arrowStatus = [];
      if (this.isShippingDriver) {
        if (this.listType === "from") {
          arrowStatus = ["unconfirmed", "confirmed", "packed"];
        } else {
          arrowStatus = ["shipped"];
        }
      } else if (this.isDeliveryDriver) {
        if (this.listType === "from") {
          arrowStatus = ["unloaded", "unconfirmed", "confirmed", "packed"];
        } else {
          arrowStatus = ["picked", "shipped"];
        }
      } else {
        return true;
      }

      const invalidItems = items.filter(item => !arrowStatus.includes(item.status));

      return invalidItems.length === 0;
    },
    async editStatus() {
      let itemType = "from";
      const items = this.detailItems.reduce((ids, item) => {
        if (item.type === "to") itemType = "to";
        if (!(item.type === "from" && item.status === "shipped")) {
          ids.push(item.id);
        }
        return ids;
      }, []);
      if (items.length > 0) {
        await this.$store.dispatch("driverInformation/editStatus", {
          transitionType: !this.isDeliveryDriver && itemType === "from" ? "shipped" : "forward",
          items,
        });

        this.getOrderItems();
        this.alert = true;
        this.$store.dispatch("driverInformation/clearSelectedOrderList");
      } else {
        this.$store.dispatch("setSnackBar", { msg: "変更できないステータスの注文が含まれています。選択を解除してください。", color: "red" }, { root: true });
        this.$store.dispatch("snackOn", {}, { root: true });
      }
    },
    toggleFlag() {
      this.allowUnconfirmedOrderToShipped = !this.allowUnconfirmedOrderToShipped;
      this.canEditStatus = this.allowUnconfirmedOrderToShipped;
    },
    setDeliveryType(delivery_type) {
      this.$store.dispatch("driverInformation/updateDeliveryType", { deliveryType: delivery_type });
    },
  },
};
</script>

<style scoped>
.list-header {
  padding: 12px;
  background-color: #ffffff;
}
.confirm-area {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  margin: 24px 0;
}
.confirm-card {
  padding: 10px;
  border-style: solid;
  border-radius: 5px;
  border-width: 2px;
}
.border-green {
  border-color: #00796b;
}
.border-orange {
  border-color: #ff6f00;
}
.confirm-card .order-item {
  font-size: 20px;
  font-weight: bold;
}
.small-gray {
  font-size: 12px;
  color: #bdbdbd;
}
.flower_image {
  object-fit: cover;
}

/*QrCode Reader Button*/
.qrcode-reader {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  display: flex;
  z-index: 1;
}

.qrcode-read-confirm {
  position: absolute;
  width: 261px;
  height: 71px;
  left: 10px;
  bottom: 15px;
  color: white;
  background: #315198;
  border-radius: 50px;
  z-index: 1;
}

/*Delivery Type Modal*/
.delivery-type-confirm-title {
  position: absolute;
  right: 0;
  top: 10px;
  font-size: x-large;
}

.delivery-type-confirm-area {
  margin: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 150px;
}

.ship-order-btn {
  position: absolute;
  height: 48px;
  left: 25px;
  right: 25px;
  top: 70px;
  color: white;
  background: #315198;
  border-radius: 4px;
}

.delivery-order-btn {
  position: absolute;
  height: 48px;
  left: 25px;
  right: 25px;
  top: 140px;
  color: white;
  background: #315198;
  border-radius: 4px;
}
</style>
