<template>
  <v-container class="page-container">
    <div class="non-table-container pa-4">
      <div class="d-flex align-center pointer" @click="onGoBack()">
        <ArrowLeftIcon size="12" color="black" />
        <h4 class="ml-2 font-weight-normal">{{ $t("back_short") }}</h4>
      </div>
      <h1 class="text-center my-6">{{ $t("farmFlowersGenera") }}</h1>

      <div class="d-flex justify-end mb-4" v-if="!mobile">
        <MstButton width="140px" class="btn bg-secondary" to="/farm/flowers/new" color="balck-500">{{ $t("listRegistredProducts") }}</MstButton>
      </div>

      <div class="mobile-add-button" v-if="mobile">
        <MstFloatingButton :label="$t('listRegistredProducts')" icon="mdi-plus" color="#173169" labelPosition="outside" to="/farm/flowers/new" />
      </div>

      <div class="search-board">
        <div class="d-flex bg-back-gray search-container align-center">
          <SearchIcon color="gray" size="16" />
          <input class="search-button" placeholder="キーワードから探す" @input="onChangeSearch" :value="searchString" />
        </div>
        <div class="search-result" v-if="searchString !== ''">
          <div class="mx-4 pa-2 pointer" @click="onHandleTap(item.id)" v-for="item in searchResult" :key="item.id">
            <div class="my-2 d-flex justify-space-between align-center">
              <div class="d-flex">
                <span class="badge-gray mr-2">{{ item.category }}</span>
                <span>{{ flowerGenus.name }}</span>
              </div>
              <span class="bagde-red w-100px flex-shrink-0 flex-grow-0" v-if="!item.isWorking">{{ $t('hidden') }}</span>
            </div>
            <div class="font-weight-bold text-overflow-dot">{{ item.specie_name }}</div>
          </div>
          <div v-if="searchResult.length === 0">
            <div class="mx-4 pa-2">{{ $t('no_products_matching') }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-4 table">
      <div class="d-flex pb-4">
        <h3 class="flex-shrink-1 flex-grow-1 pl-4 pt-2">{{ flowerGenus.name }}</h3>
        <div class="sort-button">
          <v-menu transition="slide-y-transition" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small color="white" style="box-shadow: none;">
                {{ sortName }}<v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group v-model="sort">
                <v-list-item value="review_rates_order" @click="setSortName(0)">
                  <v-list-item-title>
                    <v-icon v-if="(sortSelection === 0)" class="check-icon">mdi-check mdi-18px</v-icon>
                    <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                    {{ $t('review_rates_order') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item value="review_counts_order" @click="setSortName(1)">
                  <v-list-item-title>
                    <v-icon v-if="(sortSelection === 1)" class="check-icon">mdi-check mdi-18px</v-icon>
                    <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                    {{ $t('review_counts_order') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item value="japanese_syllabary_order" @click="setSortName(2)">
                  <v-list-item-title>
                    <v-icon v-if="(sortSelection === 2)" class="check-icon">mdi-check mdi-18px</v-icon>
                    <v-icon v-else class="check-icon">mdi-blank mdi-18px</v-icon>
                    {{ $t('japanese_syllabary_order') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
        <div class="table-action"> </div>
      </div>

      <div class="pa-4" v-if="loadingStatus">
        {{ $t("loading") }}
      </div>

      <template v-else-if="workingFlowers.length">
        <div class="d-flex pa-4 table-row pointer item-row" @click="onHandleTap(item.id)" v-for="item in workingFlowers" :key="item.id">
          <div class="flex-shrink-1 flex-grow-1 font-weight-bold text-overflow-dot item-row">
            <img class="mr-2" :src="item.img !== '' ? item.img : require('@/assets/preview.jpg')" width="63px" height="73px" />
            <span class="specie-name">{{ item.specie_name }}</span>
          </div>
          <div>
            <star-rating
              :rating="item.reviewMark"
              :increment="0.01"
              :star-size="12"
              :padding="2"
              active-color="#DFCE0F"
              inactive-color="#D9D9D9"
              :show-rating="false"
            ></star-rating>
            <div class="reviews-container">
              <span class="review-marks">{{ item.reviewMark.toFixed(1) }}</span>
              <span class="total-review-count">({{ item.totalReviewCount }}件)</span>
            </div>
          </div>
          <div class="table-action flex-shrink-0 flex-shrink-0"> <ArrowRightIcon size="12" color="black" /> </div>
        </div>
      </template>

      <div class="pa-4" v-else>
        {{ $t("no_working") }}
      </div>
    </div>

    <div class="d-flex color-secondary ma-4 align-center" @click="goToHiddenProduct()">
      <h3 class="font-weight-normal mr-3 pointer">{{ $t("not_working") }}</h3>
      <ArrowRightIcon size="16" color="#173169"/>
    </div>
  </v-container>
</template>

<script>
import StarRating from "vue-star-rating";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import { MstFloatingButton, MstButton } from "@/components/master";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon.vue";

export default {
  components: { SearchIcon, MstFloatingButton, ArrowRightIcon, ArrowLeftIcon, MstButton, StarRating },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      searchString: "",
      searchResult: [],
      flowerGenusId: this.$route.params.flower_genera_id,
      flowerGenus: {},
      items: [],
      loadingStatus: false,
      sort: this.$route.query.sort || "japanese_syllabary_order",
      sortName: this.$route.query.sort ? this.$t(`${this.$route.query.sort}`) : this.$t("japanese_syllabary_order"),
      sortSelection: this.getSortSelection(this.$route.query.sort),
    };
  },
  created() {
    this.loadingStatus = true;
    this.$api.get("/harvests/flower_genus").then(response => {
      this.flowerGenus = response.result.flower_genus.find(genus => genus.id.toString() === this.flowerGenusId);
      this.fetchFlowers().then(this.createTableData);
    });
  },
  watch: {
    sortSelection: {
      handler() {
        this.updateQueryParams();
        this.fetchFlowers().then(this.createTableData);
      },
      deep: true
    }
  },
  methods: {
    async createTableData() {
      this.items = [];
      const { flowers } = this.$store.state;

      flowers.listArr.forEach(id => {
        const flower = flowers.list[id];
        if (this.flowerGenusId === flower.flowerSpecie.flowerGenus.id.toString()) {
          const img = flower.images[0] ? flower.images[0].imageUrl : "";
          this.items.push({
            id: flower.id,
            category: flower.category.name,
            genus_name: flower.flowerSpecie.flowerGenus.name,
            specie_name: flower.flowerSpecie.name,
            flower_type: flower.flower_type,
            isWorking: flower.isWorking,
            reviewMark: flower.reviewMark,
            totalReviewCount: flower.totalReviewCount,
            img
          });
        }
      });
      this.loadingStatus = false;
    },
    onGoBack() {
      this.$router.go(-1);
    },
    onHandleTap(flower_id) {
      this.$router.push(`/farm/flower_genera/${this.flowerGenusId}/flowers/${flower_id}`);
    },
    goToHiddenProduct() {
      this.$router.push(`/farm/flower_genera/${this.flowerGenusId}/hidden_flowers`);
    },
    onChangeSearch(event) {
      this.searchString = event.target.value;
      this.searchResult = this.items.filter(item => item.specie_name.includes(this.searchString) || this.flowerGenus.name.includes(this.searchString));
    },
    setSortName(value) {
      this.sortSelection = value;
      if (value === 0) {
        this.sort = "review_rates_order";
        this.sortName = this.$t("review_rates_order");
      } else if (value === 1) {
        this.sort = "review_counts_order";
        this.sortName = this.$t("review_counts_order");
      } else if (value === 2) {
        this.sort = "japanese_syllabary_order";
        this.sortName = this.$t("japanese_syllabary_order");
      }
    },
    async fetchFlowers() {
      const { flowers } = this.$store.state;
      flowers.listArr = [];
      const res = await this.$http2("get", `farm/flowers?sort=${this.sort}`);
      res.result.flowers.forEach(item => {
        flowers.list[item.id] = item;
        flowers.listArr.push(item.id);
      });
    },
    updateQueryParams() {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("sort", this.sort);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.replaceState(null, "", newUrl);
    },
    getSortSelection(sort) {
      switch (sort) {
        case "review_rates_order":
          return 0;
        case "review_counts_order":
          return 1;
        default:
          return 2;
      }
    }
  },

  computed: {
    workingFlowers() {
      return this.items.filter(item => item.isWorking);
    },
    notWorkingFlowers() {
      return this.items.filter(item => !item.isWorking);
    }
  }
};
</script>

<style lang="scss">

.page-container {
  max-width: variables.$container-max-width;
}

.item-row {
  display: flex;
  gap: 5px;
  align-items: center;
}
.specie-name {
  width: 140px;
  white-space: break-spaces;
}

.sort-button {
  margin-right: -6px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px; /* ボーダーの角を丸める */
}
</style>
