<template>
  <header class="conversationHeader">
    <h1 class="conversationHeader__title text-md color-black-500">{{ toName }}</h1>
    <button type="button" aria-label="一覧へ戻る" class="conversationHeader__button" @click="handleClickBack">
      <MstIcon type="chevron-left" size="24px" />
    </button>
  </header>
</template>

<script>
import { MstIcon } from "@/components/master";

export default {
  name: "ConversationHeader",
  components: { MstIcon },
  props: {
    toName: { type: String, required: true },
  },
  methods: {
    handleClickBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.conversationHeader {
  position: relative;
  padding: 15px 12px 15px 43px;
}

.conversationHeader__title {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.conversationHeader__button {
  position: absolute;
  top: 50%;
  left: 9px;
  margin-top: -12px;
}
</style>
