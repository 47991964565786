<template>
  <New/>
</template>

<script>
import New from "@/components/farm/flower_harvests/new/index.vue";

export default {
  components: {
    New
  }
};
</script>
