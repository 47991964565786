<template>
  <div v-if="readonly" class="setting-text-input">
    {{ value }}
  </div>
  <div v-else class="setting-text-input">
     <input
      class="input"
      type="number"
      pattern="\d*"
      :value="value"
      :placeholder="placeholder"
      min="0"
      @keypress="validate"
      @input="$emit('edit', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: ""
    },
    value: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    semiRequired: {
      type: Boolean,
      default: false
    },
    match: {
      type: String,
      default: ""
    },
    mismatchMessage: {
      type: String,
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    readonly() {
      return this.readonly;
    }
  },
  methods: {
    validate(e) {
      const charCode = (e.which) ? e.which : e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  color: #777;
  font-size: 16px;

  &.required::after {
    margin-left: 4px;
    content: "*";
    font-size: 16px;
    font-weight: bold;
    color: #c85c45;
  }
}

.input {
  width: 70px;
}
</style>
