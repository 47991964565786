<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  watch: {
    $route(to) {
      const loginPath = "/farm/login";
      if (
        !this.$store.getters.isSignedIn
        && to.path !== loginPath
        && to.path !== "/farm/signup"
      ) {
        this.$router.push(loginPath);
      }
    }
  },
  created() {
    if (!this.$store.getters.isSignedIn
      && !this.$route.path.startsWith("/farm/login")) {
      this.$router.push("/farm/login");
    }
  }
};
</script>

<style>
</style>
