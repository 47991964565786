<template>
  <div class="order-wrapper">
    <div class="order-header">
      <span class="delivery-date">{{ getFormattedDate(ordersList.delivery_date) }}</span>
      <span>
        <span class="order-total-price">{{ `${$n(getOrderPrice(ordersList))}` }}</span>
        <span class="unit">円</span>
        <span class="order-delivery-fee">（送料{{ `${$n(getTotalDeliveryFee(ordersList))}` }}円込）</span>
      </span>
    </div>
    <div v-for="order in ordersList.orders" :key="order.id">
      <div class="order-items-header">
        <span><span class="item-title">注文日時：</span><span class="item-value">{{ $date.YYYYMD(new
            Date(order.order_date),
            ".")
        }}</span></span>
        <span><span class="item-title">注文番号：</span><span class="item-value">{{ order.id }}</span></span>
        <!-- <span><span class="item-title">支払い方法：</span><span class="item-value">銀行振込</span></span> -->
      </div>
      <template v-if="(order.order_items && order.order_items.length > 0)">
        <div v-for="orderItem in order.order_items" :key="orderItem.id" class="order-item-wrapper">
          <img class="flower-image" :src="getFlowerImage(orderItem)" />
          <div class="info-wrapper">
            <div v-if="orderItem.status === 'canceled'" class="status canceled-status">
              {{ $t('shop_order_canceled') }}
            </div>
            <div v-else-if="orderItem.status === 'rejected_request'" class="status canceled-status">
              {{ $t('shop_order_rejected') }}
            </div>
            <div v-else class="status">
              {{ orderItem.status_label }}
            </div>
            <div class="farm-name">{{ orderItem.farm.name }}</div>
            <div class="flower-wrapper">
              <div class="flower-genus">{{ orderItem.flower.flower_genus }}</div>
              <div class="flower-specie">{{ orderItem.flower.flower_specie }}</div>
            </div>
            <div class="flower-harvest-note">備考： {{ orderItem.flower_harvest.note ? orderItem.flower_harvest.note : 'なし' }}</div>
            <div class="order-item-id">取引番号： {{ orderItem.id }}</div>
            <div class="order-item-price">
              {{ `${orderItem.harvest_min_count}本 × ${orderItem.lot_count}口 × ${orderItem.sales_price_without_delivery_fee}円 = ` }}
              <span class="total-price">{{ $n(orderItem.subtotal_sales_price_without_tax_and_delivery_fee) }}</span>円
            </div>
            <div v-if="orderItem.status === 'delivered'" class="order-item-review">
              <div class="order-item-review-complete" v-if="orderItem.reviewed">レビュー済み</div>
              <button class="order-item-review-btn" v-else @click="openReviewModal(orderItem.id, order.id)">この商品をレビューする</button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="order-item-wrapper">
          <p class="no-order-items">条件に一致する注文はありません。</p>
        </div>
      </template>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    ordersList: {
      type: Object,
      required: true
    }
  },
  methods: {
    getFormattedDate(date) {
      const dateObj = new Date(date);
      return `${this.$date.MMDD(dateObj, "/")}（${this.$date.getDayOfWeek(dateObj)}）`;
    },
    getTotalDeliveryFee(groupedOrderList) {
      return groupedOrderList.orders.reduce((prevDeliveryFee, currentOrder) => prevDeliveryFee + currentOrder.subtotal_delivery_fee, 0);
    },
    getOrderPrice(groupedOrderList) {
      return groupedOrderList.orders.reduce((prevOrderResult, currentOrder) => prevOrderResult + currentOrder.subtotal_sales_price_without_tax, 0);
    },
    getOrderDeliveryFee(groupedOrderList) {
      return groupedOrderList.orders.reduce((prevOrderResult, currentOrder) => {
        if (!currentOrder.order_items) return prevOrderResult;
        return prevOrderResult + currentOrder.order_items.reduce((prevOrderItemResult, currentOrderItem) => prevOrderItemResult + currentOrderItem.subtotal_delivery_fee,
          0);
      }, 0);
    },
    getFlowerImage(orderItem) {
      if (!orderItem.flower.images || !orderItem.flower.images.length) return "";
      return orderItem.flower.images[0].image;
    },
    async openReviewModal(order_item_id, order_id) {
      const response = await this.$http2(
        "get",
        `reviews/order_items_by_order?order_id=${order_id}`
      );
      const reviewItems = response.result.orderItems.map(order_item => ({ ...order_item, status: this.getReviewStatus(order_item) }));
      this.$store.dispatch("reviews/setReviewItems", reviewItems);
      this.$store.dispatch("reviews/setSelectedReviewItemId", order_item_id);
      this.$store.dispatch("commitModalShow", true);
    },
    getReviewStatus(order_item) {
      let status = 0;
      if (order_item.reviewId && Object.prototype.hasOwnProperty.call(order_item, "isPositive")) status = order_item.isPositive ? 2 : 1;
      return status;
    }
  }
};
</script>

<style lang="scss" scoped>
.order-wrapper {
  margin-bottom: 20px;

  &>.order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    &>.delivery-date {
      background: #375293;
      border-radius: 2px;
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      padding: 4px 14px;
      text-align: center;
    }

    & .order-total-price {
      font-weight: 700;
      font-size: 16px;
      color: #DD5055;
    }

    & .order-delivery-fee {
      margin-left: 5px;
      font-size: 12px;
    }

    & .unit {
      font-weight: 400;
      font-size: 11px;
      color: #DD5055;
    }
  }

  & .order-items-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    & .item-title {
      font-weight: 400;
      font-size: 10px;
    }

    & .item-value {
      font-weight: 600;
      font-size: 10px;
    }
  }

  & .order-item-wrapper {
    display: flex;
    background: #EEEEEE;
    border: 1px solid #CFCFCF;
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 8px;

    & .flower-image {
      object-fit: cover;
      width: 56px;
      height: 72px;
      flex-shrink: 0;
    }

    &>.no-order-items {
      margin: 0 auto;
    }

    &>.info-wrapper {
      margin-left: 8px;
      position: relative;
      width: 100%;

      & .status {
        position: absolute;
        top: 0;
        right: 0;
        color: #4A8FCF;
        border: 1px solid #4A8FCF;
        text-align: center;
        border-radius: 40px;
        padding: 4px 12px;
        font-size: 10px;
      }

      & .canceled-status {
        color: #DD5055;
        border: 1px solid #DD5055;
      }

      & .farm-name {
        font-size: 12px;
        color: #999999;
      }
      & .flower-wrapper {
        margin-top: 6px;
        font-size: 0px;

        & .flower-genus {
          display: inline-block;
          font-size: 10px;
          width: auto;
          background-color: #ffffff;
          border-radius: 10px;
          padding: 2.4px 8.4px;
        }
        & .flower-specie {
          margin-top: 6px;
          font-weight: 700;
          font-size: 14px;
        }
      }

      & .flower-harvest-note {
        margin-top: 6px;
        font-size: 12px;
        color: #3E4A55;
      }

      & .order-item-id {
        margin-top: 6px;
        font-size: 12px;
        color: #999999;
      }

      & .order-item-price {
        text-align: right;
        font-size: 12px;
        line-height: 15px;
        margin-top: 2px;
        color: #191D38;

        & .total-price {
          font-size: 16px;
          font-weight: 700;
        }
      }

      & .order-item-review {
        text-align: right;
      }

      & .order-item-review-btn {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: #191D38;
        text-decoration: underline;
        margin-top: 4px;
      }

      & .order-item-review-complete {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: #7E868E;
        margin-top: 4px;
      }
    }
  }
}
</style>
