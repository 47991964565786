<!-- TODO: 各コンポーネントを別ページに分割する -->
<!-- Flower一覧ページ・FlowerHarvest一覧ページ・日付毎のFlowerHarvest一覧ページが1つのページになっている -->
<template>
  <v-container class="page-container">
    <h2 class="text-center ma-4" :class="mobile ? '' : 'harvest-title'">{{ $t("farmHarvest") }}</h2>
    <div :class="mobile ? 'view-type-btn-group' : 'view-type-btn-group-pc'">
      <button @click="isDateView = true">
        <img v-if="isDateView" :src="require('@/assets/calendar-icon.svg')"/>
        <img v-else :src="require('@/assets/disabled-calendar-icon.svg')"/>
      </button>
      <button @click="isDateView = false">
        <img v-if="!isDateView" :src="require('@/assets/product-icon.svg')"/>
        <img v-else :src="require('@/assets/disabled-product-icon.svg')"/>
      </button>
    </div>

    <DateBaseList v-if="isDateView" />
    <FlowerBaseList v-else />
  </v-container>
</template>

<script>
import DateBaseList from "@/components/farm/flower_harvests/dateBaseList.vue";
import FlowerBaseList from "@/components/farm/flower_harvests/flowerBaseList.vue";

export default {
  components: { DateBaseList, FlowerBaseList },
  data() {
    return {
      isDateView: true,
      flowerHarvests: [],
      mobile: this.$vuetify.breakpoint.xs
    };
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  max-width: variables.$container-max-width;
}
.harvest-title {
  height: 70px;
  padding-top: 5px;
}
.view-type-btn-group {
  position: relative;
  left: calc( 100vw - 80px );
  top: -46px;
  display: flex;
  gap: 12px;
}
.view-type-btn-group-pc {
  position: absolute;
  left: calc( 100vw - 140px );
  top: 110px;
  display: flex;
  gap: 12px;
}

</style>
