export default {
  namespaced: true,
  state: {
    collections: [],
  },
  mutations: {
    setCollections(state, payload) {
      state.collections = payload;
    },
  },
  actions: {
    async fetchCollections({ commit }, params = { comingSoon: true }) {
      const res = await this._vm.$http2("get", `shop/collections?include_comming_soon=${params.comingSoon}`);
      commit("setCollections", res.result.collections.sort((before, next) => (before.priority > next.priority ? 1 : -1)));
    },
  },
  getters: {
    collections: state => state.collections,
  },
};
