<template>
  <MultiEdit />
</template>

<script>
import MultiEdit from "@/components/farm/flower_harvests/multiEdit.vue";

export default {
  components: {
    MultiEdit
  }
};
</script>
