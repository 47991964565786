<template>
  <!-- eslint-disable -->

  <svg :class="className" :width="size" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.45 4.075C1.575 3.95 1.723 3.8875 1.894 3.8875C2.06467 3.8875 2.2125 3.95 2.3375 4.075L6 7.7375L9.675 4.0625C9.79167 3.94583 9.9375 3.8875 10.1125 3.8875C10.2875 3.8875 10.4375 3.95 10.5625 4.075C10.6875 4.2 10.75 4.348 10.75 4.519C10.75 4.68966 10.6875 4.8375 10.5625 4.9625L6.35 9.1625C6.3 9.2125 6.24583 9.248 6.1875 9.269C6.12917 9.28966 6.06667 9.3 6 9.3C5.93333 9.3 5.87083 9.28966 5.8125 9.269C5.75417 9.248 5.7 9.2125 5.65 9.1625L1.4375 4.95C1.32083 4.83333 1.2625 4.68966 1.2625 4.519C1.2625 4.348 1.325 4.2 1.45 4.075Z" :fill="color"/>
  </svg>


  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String,
    size: String,
    className: String,
  }
};
</script>
