<template>
  <div class="main">

    <div v-if="mobile">
      <div v-if="isMenuOpen" class="mobile-menu">
        <Menu />
      </div>

      <div v-else class="d-flex flex-column">
        <div ref="header">
          <Header />
        </div>

        <div class="mobile-body">
          <router-view />
        </div>
      </div>
    </div>

    <div v-else class style="width: 100%; height: 100%; border: 1px solid green">
      <div class="header">
        <Header />
      </div>

      <div class="body d-flex">
        <div>
          <Menu />
        </div>
        <div class="body-content d-flex flex-column">
          <router-view class="flex-grow-1" />
          <Footer origin="driver" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/driver/Header.vue";
import Footer from "@/components/shared/Footer.vue";
import Menu from "@/components/driver/Menu.vue";

export default {
  components: { Header, Menu, Footer },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    };
  },
  async created() {
    await this.$store.dispatch("getProfile", "driver");
  },
  async mounted() {
    const res = await this.$api2.get("users/profile", this.axiosOptions);
    // loginユーザーのIDをGAに送信
    this.$gtag.config({
      user_id: res.result.user.uuid
    });
  },
  computed: {
    isMenuOpen() {
      return this.$store.state.isMobileMenuOpen;
    },
    route() {
      return this.$route.name;
    }
  }
};
</script>

<style scoped>
.main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin-bottom: -17px;
}

.body {
  background-color: #f5f5f5;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.mobile-body {
  background-color: #f5f5f5;
  overflow-y: auto;
  overflow-x: hidden;
}

.mobile-menu {
  overflow: auto;
  width: 100%;
  height: 100%;

}

.body-content {
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100vw - 230px);
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
}
</style>
