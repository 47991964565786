<template>
  <v-data-table
    v-model="selected"
    class="elevation-0"
    :headers="headers"
    :items="items"
    :group-by="groupBy"
    :mobile-breakpoint="0"
    :show-select="showCheckBox"
    :items-per-page="1000000"
    :hide-default-header="hideDefaultHeader"
    :hide-default-footer="hideDefaultFooter"
    @item-selected="selectedHandler"
    @toggle-select-all="selectedHandler"
    @click:row="clickHandler"
    :disable-sort="disableSort"
  >
    <template v-slot:[`header.heart`]="{}">&nbsp;</template>

    <template v-slot:[`item.editable`]="{ item }">
      <span>{{ item.is_editable || item.is_status_editable ? $t("editable_flower_harvest") : $t("not_editable_flower_harvest") }}</span>
    </template>

    <template v-slot:[`header.flower_harvest_status`]="{}">
      <span @click="onSortByStatus(items, 'flower_harvest_status')">
        {{ $t("flower_harvest_status") }}
      </span>
    </template>

    <template v-slot:[`item.chevron_right`]="{}">
      <v-icon>mdi-chevron-right</v-icon>
    </template>

      <template v-slot:[`item.heart`]="{}">
        <v-icon>mdi-heart-outline</v-icon>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <div v-if="edit" class="item-wrapper">
          <span>
            <numericInput
              id="price"
              v-model.number="item.price"
              placeholder="5"
              :readonly="false"
              required
              @edit="onNumberChange($event, items.indexOf(item), 'price')"
            />
          </span>
          <span class="unit">円</span>
        </div>
        <template v-else>
          <span>{{ item.price }}</span>

          <span class="unit">円</span>
        </template>
      </template>

      <template v-slot:[`item.base_price`]="{ item }">
        <span>{{ item.base_price }}</span>
        <span class="unit">円</span>
      </template>

      <template v-slot:[`item.confirmed_count`]="{ item }">
        <div v-if="edit" class="item-wrapper">
          <span>
            <numericInput
              id="confirmed_count"
              v-model.number="item.confirmed_count"
              placeholder="5"
              :readonly="false"
              required
              @edit="onNumberChange($event, items.indexOf(item), 'confirmed_count')"
            />
          </span>
          <span class="unit">本</span>
        </div>
        <template  v-else>
          <span>{{ item.confirmed_count }}</span>

          <span class="unit">本</span>
        </template>
      </template>

      <template v-slot:[`item.min_count`]="{ item }">
        <div v-if="edit" class="item-wrapper">
          <span>
            <numericInput
              id="min_count"
              v-model.number="item.min_count"
              placeholder="5"
              :readonly="!item.is_editable"
              required
              @edit="onNumberChange($event, items.indexOf(item), 'min_count')"
            />
          </span>
          <span class="unit">本</span>
        </div>
        <template  v-else>
          <span>{{ item.min_count }}</span>

          <span class="unit">本</span>
        </template>
      </template>

      <template v-slot:[`item.sales_rate`]="{ item }">
        <span>{{ item.sales_rate }}</span>

        <span class="unit">%</span>
      </template>

      <template v-slot:[`group.header`]="{ items, toggle, isOpen }">
        <td colspan="8" class="text-start">
          <button type="button" @click="toggle"
          class="ma-0 v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small">
          <span class="v-btn__content"><i aria-hidden="true"
          :class="`${isOpen ? 'mdi-minus ga-farm-order_items-close' : 'mdi-plus-thick ga-farm-order_items-open'}`"
          class="v-icon notranslate mdi theme--light"></i></span></button>
          <b v-if="isGroupByShop">{{ items[0].shop_name }}</b>
          <b v-else>{{ items[0].flower_specie }}({{ items[0].stem_size }})</b>
        </td>
      </template>

      <template v-slot:[`group.summary`]="{ items }">
          <td colspan="8" class="text-start">
            <b>口数: {{ getGroupSummerySetCountSum(items) }}口,
            合計: {{ getGroupSummerySum(items) }}本</b>
          </td>
      </template>

      <template v-slot:[`item.flower_harvest_status`]="{ item }">
        <div v-if="item" class="d-flex align-center justify-space-between">
          <Status :flowerHarvest="item" @change="onEdit($event, item.id, 'status')" />
          <div v-if="edit && canCopy(item)">
            <v-btn
              style="color: #00796B"
              outlined
              class="ml-5 ga-farm-previous_copy"
              @click="$emit('copy', items.indexOf(item))"
              small
            >{{ $t("copy") }}</v-btn>
          </div>
        </div>
      </template>

      <template v-slot:[`item.set_count`]="{ item }">
        <div v-if="edit" class="item-wrapper">
          <span>
            <numericInput
              id="set_count"
              v-model.number="item.set_count"
              placeholder="5"
              :readonly="false"
              required
              @edit="onNumberChange($event, items.indexOf(item), 'set_count')"
            />
          </span>
          <span class="unit">口</span>
        </div>
        <template v-else>
          <span>{{ item.set_count }}</span>

          <span class="unit">口</span>
        </template>
      </template>

      <template v-slot:[`item.is_working`]="{ item }">
        <span v-if="item.is_working" class="d-flex align-center">
          <v-icon color="#007e57">mdi-earth</v-icon>表示中
        </span>
        <span v-else>
          <v-icon>mdi-lock</v-icon>非表示
        </span>
      </template>

      <template v-slot:[`item.stem_size`]="{ item }">
        <div v-if="edit" class="item-wrapper">
          <span>
            <numericInput
              id="stem_size"
              v-model.number="item.stem_size"
              placeholder="5"
              :readonly="!item.is_editable"
              required
              @edit="onNumberChange($event, items.indexOf(item), 'stem_size')"
            />
          </span>
          <span class="unit">cm</span>
        </div>
        <template v-else>
          <span>{{ item.stem_size }}</span>

          <span class="unit">cm</span>
        </template>
      </template>

      <template v-slot:[`item.copy`]="{item}">
        <span v-if="edit" >
          <v-btn
            style="color: #00796B"
            outlined
            @click="$emit('copy', items.indexOf(item))"
            small
          >{{ $t("copy") }}</v-btn>
        </span>
      </template>

      <template v-slot:[`item.flower_specie`]="{ item }">
        <div v-if="item.name" class="d-flex align-center">
          <img :src="item.image_url" class="ma-1" style="max-width:50px" />
          <div class="ml-2">
            <div style="font-size: 12px;">{{ item.flower_genus.name }}</div>
            <strong style="font-size: 16px; color: #212121">{{ item.name }}</strong>
          </div>
        </div>
        <div v-else>
          <span v-if="isGroupByShop">{{ item.flower_specie }}({{ item.stem_size }})</span>
          <span v-else>{{ item.flower_specie }}</span>
        </div>
      </template>

      <template v-slot:[`item.note`]="{ item }">
        <div v-if="edit" class="d-flex align-center mt-4" style="width: 400px">
          <v-textarea
            :value="item.note"
            outlined
            rows="1"
            row-height="15"
            :readonly="!item.is_editable"
            prepend-icon="mdi-comment"
            @keyup="onEdit($event, items.indexOf(item), 'note')"
            @blur="onEdit($event, items.indexOf(item), 'note')"
          ></v-textarea>
        </div>
        <div v-else class="d-flex align-center">
          <div v-if="item.note">
            <span class="text-caption">入力あり</span><v-icon size="x-large">mdi-comment</v-icon>
          </div>
        </div>
      </template>

  </v-data-table>
</template>

<script>
import Status from "@/components/farm/harvest/Status.vue";
import numericInput from "@/components/shared/numericInput.vue";

export default {
  components: { Status, numericInput },
  props: {
    headers: Array,
    items: Array,
    edit: Boolean,
    onEdit: Function,
    onSortByStatus: Function,
    onChecked: Function,
    onClick: Function,
    showCheckBox: Boolean,
    hiddenLabel: Boolean,
    currentDate: String,
    groupBy: String,
    clearSelected: Boolean,
    border: {
      type: Boolean,
      default: true
    },
    disableSort: Boolean,
    isGroupByShop: {
      type: Boolean,
      default: false
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: [],
      rowsPerPageItems: [10, 20, 30, 40],
      pagination: {
        rowsPerPage: 20
      }
    };
  },
  methods: {
    getGroupSummerySum(items) {
      return items.reduce((prev, current) => prev + current.subtotal_count, 0);
    },
    getGroupSummerySetCountSum(items) {
      return items.reduce((prev, current) => prev + current.set_count, 0);
    },
    selectedHandler(e) {
      if (this.onChecked) {
        this.onChecked(e);
      }
    },
    clickHandler(row) {
      if (this.onClick) this.onClick(row);
    },
    canCopy(item) {
      if (item.id) return false;
      const harvestDate = new Date(item.harvest_date);
      harvestDate.setHours(0);
      harvestDate.setMinutes(0);
      harvestDate.setSeconds(0);

      const currentDate = new Date();
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setSeconds(0);
      if (harvestDate <= currentDate) return false;
      return true;
    },
    onNumberChange(e, index, key) {
      const event = {
        target: {
          value: key === "note" ? e : Number(e)
        }
      };
      this.$emit("on-edit", event, index, key);
    }
  },
  watch: {
    clearSelected() {
      this.selected = [];
    }
  }
};
</script>

<style scoped>
.item-wrapper {
  display: flex;
}

.item-wrapper > .unit {
  align-self: end;
}
.unit {
  font: 0.7rem Noto Sans CJK JP;
  color: #777777;
}
.input-size {
  width: 60px;
}
</style>
