<template>
  <div class="main-content mt-4" :key="itemKey">
    <div class="pa-3 my-2 mb-0 pt-1">
      <div class="bg-back-gray " style="position: relative;">
        <div class="d-flex justify-space-between align-center">
          <div class="mb-1" style="flex-grow: 1; margin-right: 140px;">
            <div>
              <span style="font-size: 14px; color: #68727B;">
                {{ genusName }}
              </span>
            </div>
            <div>
              <span class="font-weight-bold color-black-500" style="font-size: 18px;">{{ speciesName }}</span>
            </div>
          </div>
          <button class="confirmed-btn">未発送</button>
        </div>
        <span class="color-white bg-color-menu px-2 mb-0">{{ stemSize }}</span>
        <div class="mt-3">
          <p style="font-size: 14px;">
            {{ truncatedNote }}
            <span v-if="isNoteTruncated && !showFullNote">...</span>
          </p>
          <span v-if="isNoteTruncated" class="pointer d-flex align-center" style="font-size: 12px; color: #68727B" @click="toggleFullNote">
            {{ showFullNote ? $t('close') : '全文を読む' }}
            <ArrowDownIcon v-if="!showFullNote" class="ml-2" size="12" color="#68727B" />
            <ArrowUpIcon v-if="showFullNote" class="ml-2" size="12" color="#68727B" />
          </span>
        </div>
        <div class="d-flex justify-end align-end">
          <span class="mr-2" style="font-size: 14px;">
            {{ totalPrice.toLocaleString() }}{{ $t('yen') }}
          </span>
          <span class="font-weight-bold color-secondary text-xl" style="font-size: 24px;">
            {{ totalCount }}
          </span>
          <span class="font-weight-bold text-xs color-secondary" style="font-size: 14px;">
            {{ $t('volumn') }}
          </span>
        </div>
      </div>
    </div>
    <div class="transaction-content" v-if="showTransactions">
      <div :key="key" v-for="(transaction, key) in transactions">
        <div class="d-flex justify-space-between pa-2 my-2">
          <span class="font-weight-bold text-md color-black-500">{{ key }}</span>
          <span class="color-secondary font-weight-bold text-xl">{{ transaction.total_count }}<span class="text-sm">{{
            $t('volumn') }}</span></span>
        </div>
        <span v-if="!isOpen(key)" class="pointer d-flex justify-end align-center mr-2 mb-2"
          @click="toggleTransaction(key)" style="font-size: 12px; color: #68727B">
          {{ $t('view_by_transaction') }}
          <ArrowDownIcon class="ml-2" size="12" color="#68727B" />
        </span>
        <v-divider v-if="isOpen(key)" class="border-opacity-50 mr-2 ml-2" color="#BFC3C6"></v-divider>

        <div v-if="isOpen(key)">
          <div class="mb-4 mt-2 transaction-border" :key="itemIndex"
            v-for="(order_item, itemIndex) in transaction.order_items">
            <div class="d-flex justify-space-between mr-2">
              <div class="d-flex">
                <span class="font-weight-bold text-lg color-black-500" style="font-size: 16px;">{{ order_item.shop_name
                }}</span>
              </div>
              <span class="color-secondary font-weight-bold" style="font-size: 16px;">{{ order_item.subtotal_count }}
                <span style="font-size: 12px;">{{ $t('volumn') }}</span>
              </span>
            </div>
            <div class="d-flex justify-space-between align-center" style="font-size: 14px;">
              <div>＠{{ order_item.min_count }}{{ $t('volumn') }} × {{ order_item.lot_count }}{{ $t('lot') }} × {{
                order_item.unit_price.toLocaleString() }}{{ $t('yen') }}
                = {{ order_item.subtotal_harvest_price_without_tax.toLocaleString() }}{{ $t('yen') }}</div>
            </div>
            <div class="d-flex justify-space-between align-center" style="font-size: 12px;">
              <div class="color-gray-800">{{ $t('order') }} : {{ order_item.id }}</div>
            </div>
            <div class="d-flex justify-space-between align-center" style="font-size: 12px;">
              <div class="color-gray-800">{{ $t('order_date') }} : {{ order_item.order_date }}</div>
            </div>
          </div>
        </div>
        <span v-if="isOpen(key)" class="pointer d-flex justify-end align-center mb-2" @click="toggleTransaction(key)"
          style="font-size: 12px; color: #68727B;">
          {{ $t('close') }}
          <ArrowUpIcon v-if="isOpen(key)" class="ml-2 mr-2" size="16" color="#191D38" />
        </span>
        <v-divider color="#BFC3C6"></v-divider>
      </div>
    </div>
    <v-divider v-if="!showTransactions" color="#BFC3C6"></v-divider>
    <div class="pb-2">
      <span class="pt-2 pointer d-flex justify-end align-center" style="font-size: 12px; color: #68727B"
        @click="showTransactions = !showTransactions">
        {{ showTransactions ? $t('close') : $t('view_by_transaction_breakdown') }}
        <ArrowUpIcon class="ml-2 mr-2" size="12" color="#68727B" />
      </span>
    </div>
  </div>
</template>

<script>
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import ArrowDownIcon from "@/components/icons/ArrowDownIcon.vue";
import ArrowUpIcon from "@/components/icons/ArrowUpIcon.vue";

export default {
  components: { ArrowRightIcon, ArrowDownIcon, ArrowUpIcon },
  props: {
    speciesName: String,
    genusName: String,
    stemSize: String,
    totalCount: Number,
    totalPrice: Number,
    note: String,
    itemKey: String,
    transactions: Object
  },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      showTransactions: false,
      showFullNote: false,
      openedTransactions: {}
    };
  },
  computed: {
    isNoteTruncated() {
      const note = this.note !== null ? this.note : "";
      return note.length > 45;
    },
    truncatedNote() {
      const note = this.note !== null ? this.note : "";
      return this.showFullNote ? note : note.substring(0, 45);
    }
  },
  created() {
    this.openedTransactions = Object.keys(this.transactions).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
  },
  methods: {
    toggleFullNote() {
      this.showFullNote = !this.showFullNote;
    },
    toggleTransaction(key) {
      this.$set(this.openedTransactions, key, !this.openedTransactions[key]);
    },
    isOpen(key) {
      return !!this.openedTransactions[key];
    }
  },
};
</script>

<style scoped>
.main-content {
  border-radius: 8px;
  border: 1px solid #BFC3C6;
  background-color: #F5F5F5;
}

.transaction-content {
  border-top: 1px solid #D7D7D7;
  background-color: #E9EDEE;
}

p {
  margin-bottom: 0px;
}

.bg-lightgray {
  background-color: #D4D7D9;
}

.warehouse {
  color: #191D38;
  font-weight: 400;
  font-size: 12px;
  padding: 2px 6px 2px 6px;
  border-radius: 99px;
  margin: auto 4px auto 0;
}

.confirmed-btn {
  background-color: white;
  color: #315198;
  border: 1px solid #315198;
  padding: 6px 16px 6px 16px;
  border-radius: 100px;
  font-size: 14px;
  white-space: nowrap;
  position: absolute;
  top: 0;
  right: 0;
}

.transaction-border {
  border-left: 3px solid #A9AFB3;
  padding-left: 10px;
  margin-left: 10px;
}
</style>
