function getTransactionProducts(order) {
  const transactionProducts = order.order_items.map(order_item => ({
    name: order_item.flower.flower_specie.name,
    sku: `生産者${order_item.farm.id}の${order_item.flower.flower_specie.name}`,
    price: order_item.sales_price,
    quantity: order_item.subtotal_count,
    category: order_item.flower.category?.name,
    order_item_type: order_item.order_item_type,
    delivery_date: order_item.delivery_date
  }));

  return transactionProducts;
}

export default {
  install(Vue) {
    Vue.prototype.$datalayer = {
      sendData(order) {
        const dataLayer = window.dataLayer || [];

        dataLayer.push({
          event: "purchase",
          transactionId: order.id,
          transactionTotal: order.subtotal_sales_price_without_tax,
          transactionAffiliation: order.shop.id,
          shopArea: order.location.area?.name,
          transactionProducts: getTransactionProducts(order)
        });
      }
    };
  }
};
