<template>
  <div>
    <div class="d-flex align-center ma-3" @click="onGoBack()">
      <LeftArrowIcon size="12" color="black" />
      <h4 class="ml-2 font-weight-normal">{{ $t("back") }}</h4>
    </div>

    <img-handler direct :default-value="images" @finish="imageHandler" multi-preview :is-edit="!!flowerId" :is-disable="!flowerId"/>

    <div class="status-title mx-7">
      <LeftArrowIcon size="24" color="black" />
      <span class="status-label">{{ $t("not_on_sale") }}</span>
      <RightArrowIcon size="24" color="black" />
    </div>
  </div>
</template>

<script>
import LeftArrowIcon from "@/components/icons/LeftArrowIcon.vue";
import RightArrowIcon from "@/components/icons/RightArrowIcon.vue";
import ImgHandler from "@/components/shared/ImgHandler/index.vue";

export default {
  components: {
    LeftArrowIcon,
    RightArrowIcon,
    ImgHandler
  },
  props: {
    images: Array,
    flowerId: String,
    imageHandler: Function
  },
  methods: {
    onGoBack() {
      this.$router.push("/farm/flower_harvests");
    },
  }
};
</script>

<style lang="scss" scoped>
.status-title {
  display: flex;
  justify-content: space-between;
  margin: 0 12px 12px 12px;
}
.status-label {
  color: #818691;
  font-weight: bold;
}

</style>
