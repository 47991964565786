<template>
  <div class="section-title">{{ text }}</div>
</template>

<script>
export default {
  props: {
    text: String,
  }
};
</script>

<style scoped>
.section-title {
  margin: 12px 0;
  color: #333;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 0;
}
</style>
