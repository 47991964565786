<template>
  <div class="mstTextArea">
    <div class="mstTextArea__field">
      <textarea
        v-model="modelValue"
        v-bind="$attrs"
        :id="componentId"
        :aria-invalid="!!error"
        :aria-describedby="describedby"
        class="mstTextArea__input"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
        @change="$emit('change', $event)"
      />
    </div>
    <p v-if="hint" :id="`${componentId}-hint`" class="mstTextArea__hint">
      {{ hint }}
    </p>
    <p v-if="error" :id="`${componentId}-error`" role="alert" aria-live="assertive" class="mstTextArea__error">
      {{ error }}
    </p>
  </div>
</template>

<script>
import { nanoid } from "nanoid";

export default {
  name: "MstTextArea",
  inheritAttrs: false,
  props: {
    value: { type: String, required: true },
    id: { type: String },
    hint: { type: String },
    error: { type: String },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    componentId() {
      return this.id || `field-${nanoid()}`;
    },
    describedby() {
      const ids = [];
      if (this.hint) ids.push(`${this.componentId}-hint`);
      if (this.error) ids.push(`${this.componentId}-error`);
      return ids.join(" ");
    },
  }
};
</script>

<style lang="scss" scoped>
.mstTextArea__input {
  border-radius: 4px;
  border: 1px solid variables.$color-gray-200;
  padding: 6px 8px;
  width: 100%;
  height: 100px;
  font-size: 16px;
  line-height: 1.4;
  outline: none;
  transition: border 0.3s ease;

  &::placeholder {
    color: variables.$color-gray-600;
    padding: 0;
  }

  &:focus {
    border-color: variables.$color-blue-900;
  }
}

.mstTextArea__hint {
  margin: 8px 0 0;
  font-size: variables.$font-size-xs;
  line-height: 1.4;
  color: variables.$color-gray-600;
}

.mstTextArea__error {
  margin: 8px 0 0;
  font-size: variables.$font-size-xs;
  line-height: 1.4;
  color: variables.$color-red-500;
}
</style>
