/* eslint-disable camelcase */
import axios from "axios";

export default {
  state: {
    id: null,
    uuid: null,
    name: null,
    email: null,
    access_token: null,
    user_type: null,
    agree_terms: null,
    is_confirmed: null,
    is_show_review_modal: false,
  },

  mutations: {
    setData(state, payload) {
      Object.keys(payload).forEach(x => {
        state[x] = payload[x];
      });
      localStorage.setItem(this.state.localStorageKeyAuth, JSON.stringify(state));
      axios.defaults.headers.common.Authorization = state.access_token;
    }
  },
  actions: {
    init({ commit }) {
      const key = this.state.localStorageKeyAuth;
      let data = window.localStorage.getItem(key);
      if (data) {
        data = JSON.parse(data);

        // Set Axios headers
        if (data.access_token) {
          axios.defaults.headers.common.Authorization = data.access_token;
        }

        commit("setData", data);
      }
    },
    sign_up({ commit }, data) {
      return new Promise(resolve => {
        axios.defaults.headers.common.X_CLIENT_ID = process.env.VUE_APP_X_CLIENT_ID;
        axios.defaults.headers.common.X_CLIENT_SECRET = process.env.VUE_APP_X_CLIENT_SECRET;

        this._vm.$http("post", "users", data).then(res => {
          if (res && res.status !== 204 && !res.result.error) {
            const access_token = `${res.result.auth.token_type} ${res.result.auth.access_token}`;
            const newData = {
              id: res.result.user.id,
              name: res.result.user.name,
              user_type: res.result.user.user_type,
              agree_terms: res.result.user.agree_terms,
              access_token
            };

            commit("setData", newData);

            localStorage.setItem(this.state.localStorageKeyAuth, JSON.stringify(newData));

            axios.defaults.headers.common.Authorization = access_token;
            delete axios.defaults.headers.common.X_CLIENT_ID;
            delete axios.defaults.headers.common.X_CLIENT_SECRET;

            resolve(access_token);
          } else {
            resolve(false);
          }
        });
      });
    },
    login({ commit }, data) {
      return new Promise(resolve => {
        axios.defaults.headers.common.X_CLIENT_ID = process.env.VUE_APP_X_CLIENT_ID;
        axios.defaults.headers.common.X_CLIENT_SECRET = process.env.VUE_APP_X_CLIENT_SECRET;

        this._vm.$http("post", "login", { login: data }).then(res => {
          if (res && res.unpaid) {
            resolve(res);
          } else if (res && res.status !== 204) {
            const access_token = `${res.auth.token_type} ${res.auth.access_token}`;

            const newData = {
              id: res.user.id,
              uuid: res.user.uuid,
              name: res.user.name,
              user_type: res.user.user_type,
              agree_terms: res.user.agree_terms,
              access_token,
              is_confirmed: res.user.is_confirmed,
              is_show_review_modal: false,
            };

            commit("setData", newData);

            localStorage.setItem(this.state.localStorageKeyAuth, JSON.stringify(newData));

            // Add access token to header and remove client secret and id
            axios.defaults.headers.common.Authorization = access_token;
            delete axios.defaults.headers.common.X_CLIENT_ID;
            delete axios.defaults.headers.common.X_CLIENT_SECRET;

            resolve(res.user.user_type);
          } else if (res.status === 204) {
            resolve(false);
          }
        });
      });
    },
    logout({ commit }) {
      const newData = { name: null, email: null, access_token: null, user_type: null };

      commit("setData", newData);

      localStorage.setItem(`${this.state.localStorageKey}Auth`, JSON.stringify(newData));

      window.location.reload();
    },
    agreement({ commit }) {
      commit("setData", {
        agree_terms: true
      });
    },
    commitModalShow({ commit }, value) {
      commit("setData", {
        is_show_review_modal: value
      });
    }
  },

  getters: {
    isSignedIn(state) {
      return !!state.access_token;
    },
    getUserType(state) {
      return state.user_type.toLowerCase();
    },
    getUserName(state) {
      return state.name;
    }
  }
};
