<template>
  <!-- eslint-disable -->

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <circle cx="92" cy="256" r="28" :fill="color" />
    <circle cx="92" cy="132" r="28" :fill="color" />
    <circle cx="92" cy="380" r="28" :fill="color" />
    <path
      :fill="color"
      d="M432 240H191.5c-8.8 0-16 7.2-16 16s7.2 16 16 16H432c8.8 0 16-7.2 16-16s-7.2-16-16-16zM432 364H191.5c-8.8 0-16 7.2-16 16s7.2 16 16 16H432c8.8 0 16-7.2 16-16s-7.2-16-16-16zM191.5 148H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H191.5c-8.8 0-16 7.2-16 16s7.2 16 16 16z"
    />
  </svg>

  <!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    color: String
  }
};
</script>

<style>
</style>
