<template>
  <PageContainer pc-only class="page-container mt-4">
    <div class="tabs d-flex">
      <router-link
        class="tab"
        :class="{ active: tab === 'shop' }"
        to="/shop/settings/shop"
      >{{ $t("florist_info") }}</router-link>
      <router-link
        class="tab"
        :class="{ active: tab === 'account' }"
        to="/shop/settings/account"
      >{{ $t("account_info") }}</router-link>
      <router-link
        class="tab"
        :class="{ active: tab === 'shipping_address' }"
        to="/shop/settings/shipping_address"
      >{{ $t("shipping_address") }}</router-link>
    </div>

    <form class="content" @submit.prevent ref="form">
      <div class="conent-inner">
        <slot />
        <input type="submit" ref="submit" hidden />
      </div>
    </form>

    <div class="buttons d-flex justify-center flex-wrap flex-row-reverse" v-if="!readonly">
      <template v-if="isEdit">
        <Button class="button" color="blue" :on-click="submit">{{ $t("save_changes") }}</Button>
        <Button
          class="button"
          color="gray"
          @click="$emit('update:isEdit', false)"
        >{{ $t("cancel") }}</Button>
      </template>
      <template v-else>
        <Button class="button" color="blue" @click="edit" :disabled="disabled">{{ $t("edit") }}</Button>
      </template>
    </div>
  </PageContainer>
</template>

<script>
import PageContainer from "@/components/shop/PageContainer.vue";
import Button from "@/components/shop/Button.vue";

export default {
  components: {
    PageContainer,
    Button
  },

  props: {
    tab: {
      type: String,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      readySubmit: () => null
    };
  },

  methods: {
    edit() {
      this.$emit("edit");
      this.$emit("update:isEdit", true);
    },

    async submit() {
      this.$refs.submit.click();
      if (this.$refs.form.checkValidity()) {
        await this.onSubmit();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  max-width: 780px;

  @media screen and (max-width: 960px) {
    max-width: none;
  }
}

.tabs {
  margin-bottom: -1px;
}

.tab {
  position: relative;
  display: block;
  width: 180px;
  padding: 0 8px;
  line-height: 50px;
  text-align: center;
  background-color: #eee;
  border: transparent 1px solid;
  border-radius: 5px 5px 0 0;
  color: #777;
  font-size: 14px;

  & + & {
    margin-left: 8px;
  }

  &.active {
    background-color: #fff;
    border-color: #0000001f #0000001f transparent #0000001f;
    color: #111;
    font-size: 14px;
    font-weight: bold;
    z-index: 1;

    @media screen and (max-width: 960px) {
      font-size: calc(12px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
    }
  }

  @media screen and (max-width: 960px) {
    margin-left: 2px;
    min-width: 100px;
    width: auto;
    border-radius: 0;
    font-size: calc(10px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));

    & + & {
      margin-left: 4px;
    }
  }
}

.content {
  background-color: #fff;
  border: 1px solid #0000001f;
  border-radius: 0 5px 5px 5px;
  padding: 50px 0;

  @media screen and (max-width: 960px) {
    border-radius: 0;
    border-right: none;
    border-left: none;
  }
}

.conent-inner {
  max-width: 490px;
  margin: 0 auto;
  padding: 0 20px;
}

.buttons {
  margin-top: 36px;
  padding: 0 10px;

  @media screen and (max-width: 960px) {
    margin-top: 16px;
  }
}

.button {
  margin: 4px 10px;
}
</style>
