/* eslint-disable prefer-rest-params */
/* eslint-disable no-console */
function logger(tag) {
  if (process.env.VUE_APP_ENV !== "production") {
    const css = "background: rgba(0,0,255,0.3); color:white; padding: 2px";
    console.log("%c%s", css, tag, "\n", ...Array.prototype.slice.call(arguments, 1));
  }
}

export default logger;
