<template>
  <div class="page-container container-spacer">
    <MstDateFilter
      v-model="selectedDate"
      :allow-days="allowWdayList"
      :max-date="maxDate"
      :hide-controller="true"
      @change="dateHandler"
    />

    <div class="non-table-container pa-4">
      <div class="d-flex justify-end mb-4" v-if="!mobile">
        <MstButton
          width="140px"
          class="btn bg-secondary"
          :to="{ path: '/farm/flower_harvests/new',
          query: { selectedHarvestDate: selectedDate.toISOString() } }"
          color="balck-500"
        >
          {{ $t("add_harvest") }}
        </MstButton>
      </div>

      <div class="search-board">
        <div class="d-flex bg-back-gray search-container align-center">
          <SearchIcon color="gray" size="16" />
          <input class="search-button" placeholder="品種・品目から探す" @input="onChangeSearch" :value="searchString" />
        </div>
        <div class="search-result" v-if="searchString !== ''">
          <div class="mx-4 pa-2" @click="onHandleTap(item.id)" v-for="item in searchResult" :key="item.id">
            <div class="my-2 d-flex justify-space-between align-center">
              <div>
                <span class="badge-gray mr-2">{{ item.category }}</span>
                <span>{{ item.genus_name }}</span>
              </div>
              <span class="badge-red w-100px flex-shrink-0 flex-grow-0" v-if="!isWorking(item.status)">{{ $t('hidden') }}</span>
            </div>
            <div class="font-weight-bold text-overflow-dot">{{ item.specie_name }}</div>
          </div>
          <div v-if="searchResult.length === 0">
            <div class="mx-4 pa-3">{{ $t('no_products_matching') }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-add-button" v-if="mobile">
      <MstFloatingButton
        :label="$t('add_harvest')"
        icon="mdi-plus"
        color="#173169"
        labelPosition="outside"
        :to="{ path: '/farm/flower_harvests/new', query: { selectedHarvestDate: selectedDate.toISOString() } }"
      />
    </div>
    <MstTab :current="currentTab" :items="statuses" :fixed="true" @change="e => currentTab = e"/>
    <div class="table">
      <div class="d-flex pa-4 table-header-label">
        <div class="flex-shrink-1 flex-grow-1 text-left">
          <span>{{ $t("genus_specie") }}</span>
        </div>
        <div class="w-60px pl-1" @click="handleHeaderClick('stem_size')">
          <span>{{ $t("stem_size") }}</span>
          <img :src="require('@/assets/sort.svg')" class="table-header-sort"  :class="[sortObj['stem_size'].isDesc ? '' : 'flipped-sort']"/>
        </div>
        <div class="w-60px pl-1" @click="handleHeaderClick('price')">
          <span>{{ $t("price") }}</span>
          <img :src="require('@/assets/sort.svg')" class="table-header-sort" :class="[sortObj['price'].isDesc ? '' : 'flipped-sort']"/>
        </div>
        <div class="w-60px pl-1" @click="handleHeaderClick('set_count')">
          <span>{{ $t("set_count") }}</span>
          <img :src="require('@/assets/sort.svg')" class="table-header-sort" :class="[sortObj['set_count'].isDesc ? '' : 'flipped-sort']"/>
        </div>
        <div class="table-action"> </div>
      </div>
      <div class="d-flex pa-3 table-row" @click="onHandleTap(item.id)" v-for="item in showItems" :key="item.id">
        <div class="flex-shrink-1 flex-grow-1 mb-1 text-overflow-dot">
          <div class="table-genus-value text-overflow-dot">{{ item.genus_name }}</div>
          <div class="font-weight-bold text-overflow-dot table-specie-value">{{ item.specie_name }}</div>
        </div>
        <div class="w-60px flex-shrink-0 flex-shrink-0 table-body-value"> {{ item.stem_size }}{{ $t("size_label") }} </div>
        <div class="w-60px flex-shrink-0 flex-shrink-0 table-body-value"> {{ item.price }}{{ $t("yen") }} </div>
        <div class="w-60px flex-shrink-0 flex-shrink-0 table-body-value"> {{ item.set_count }}{{ $t("lot") }} </div>
        <div class="table-action"> <ArrowRightIcon size="12" color="black" /> </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchIcon from "@/components/icons/SearchIcon.vue";
import { MstButton, MstDateFilter, MstFloatingButton, MstTab } from "@/components/master";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import { formatDate } from "@/plugins/i18n";

const today = new Date();

export default {
  components: { SearchIcon, MstButton, MstDateFilter, MstFloatingButton, MstTab, ArrowRightIcon },
  data() {
    return {
      searchString: "",
      searchResult: [],
      mobile: this.$vuetify.breakpoint.xs,
      items: [],
      currentTab: "on_sale",
      selectedDate: this.startDate,
      maxDate: new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()),
      statuses: [
        {
          label: this.$t("on_sale"),
          value: "on_sale"
        },
        {
          label: this.$t("stop_on_sale"),
          value: "not_on_sale"
        },
      ],
      sortObj: {
        stem_size: {
          selected: false,
          isDesc: true,
        },
        price: {
          selected: false,
          isDesc: true,
        },
        stock_count: {
          selected: false,
          isDesc: true,
        },
        set_count: {
          selected: false,
          isDesc: true,
        },
      },
    };
  },
  computed: {
    startDate() {
      return this.$store.getters.getFarmDeadline;
    },
    allowWdayList() {
      return this.$store.getters.workingWdays;
    },
    showItems() {
      let sortItem = "id";
      let isSortDesc = true;
      Object.keys(this.sortObj).forEach(key => {
        if (this.sortObj[key].selected) {
          sortItem = key;
          isSortDesc = this.sortObj[key].isDesc;
        }
      });
      return this.items.filter(item => item.status === this.currentTab)
        .sort((a, b) => (isSortDesc ? -1 : 1) * (a[sortItem] > b[sortItem] ? 1 : -1));
    }
  },
  watch: {
    selectedDate() {
      return this.selectedDate;
    }
  },
  created() {
    let date = this.startDate;
    if (this.$route.query.harvest_date) { date = new Date(this.$route.query.harvest_date); }
    const wdays = this.allowWdayList && this.allowWdayList.length > 0 ? this.allowWdayList : [0, 1, 2, 3, 4, 5, 6];
    while (!wdays.includes(date.getDay())) {
      date.setDate(date.getDate() + 1);
    }
    this.dateHandler(date);
  },
  methods: {
    isWorking(status) {
      return status === "on_sale";
    },
    loadData(date) {
      this.$store
        .dispatch("flowerHarvest/getFlowerHarvest", { date })
        .then(() => {
          this.editContent = {};
          this.edit = false;
          this.createTableData();
        });
    },
    async createTableData() {
      this.items = [];
      const { flowerHarvest } = this.$store.state;
      flowerHarvest.listArr.forEach(id => {
        if (flowerHarvest.list[id].id) {
          const harvest = flowerHarvest.list[id];
          this.items.push({
            ...harvest,
            specie_name: harvest.flower.flowerSpecie.name,
            genus_name: harvest.flower.flowerSpecie.flowerGenus.nameFurigana,
            price: this.getValue(harvest, "price"),
            confirmed_count: this.getValue(harvest, "confirmedCount"),
            min_count: this.getValue(harvest, "minCount"),
            set_count: this.getSetCount(harvest),
            stem_size: this.getValue(harvest, "stemSize"),
            note: this.getValue(harvest, "note"),
            current_date: this.selectedDate,
          });
        }
      });
    },
    exhibitedFlowerHarvest(harvest) {
      return !!harvest.id;
    },
    getValue(harvest, key) {
      return this.exhibitedFlowerHarvest(harvest) ? harvest[key] : null;
    },
    getSetCount(harvest) {
      if (this.getValue(harvest, "minCount") === 0) return 0;

      if (!harvest.harvestDate || !this.getValue(harvest, "minCount")) return null;

      return this.getValue(harvest, "confirmedCount") / this.getValue(harvest, "minCount");
    },
    dateHandler(d) {
      this.edit = false;
      this.selectedDate = d;
      this.currentTab = "on_sale";
      this.initSortObj();
      this.loadData(formatDate(d, "yyyy-mm-dd"));
    },
    onHandleTap(id) {
      this.$router.push(`/farm/flower_harvests/${id}`);
    },
    onChangeSearch(event) {
      this.searchString = event.target.value;
      this.searchResult = this.showItems.filter(item => item.genus_name.includes(this.searchString) || item.specie_name.includes(this.searchString));
    },
    handleHeaderClick(item) {
      if (this.sortObj[item].selected) {
        this.sortObj[item].isDesc = !this.sortObj[item].isDesc;
      } else {
        this.initSortObj();
        this.sortObj[item].selected = true;
      }
    },
    initSortObj() {
      const keys = Object.keys(this.sortObj);
      keys.forEach(key => {
        this.sortObj[key].selected = false;
        this.sortObj[key].isDesc = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container-spacer {
  margin-top: -35px;
}
.table {
  font-family: sans-serif;
  font-style: normal;
  text-align: left;
  .table-row:nth-of-type(even) {
    background-color: #f5f5f5;
  }
  .w-60px {
    width: 60px;
  }
  .table-action {
    width: 20px;
    text-align: right;
    margin: auto;
  }
}
.table-header-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #111111;
}
.table-header-sort {
  margin: 2px 0 2px 4px;
}
.table-body-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #424242;
  margin: auto;
}
.table-genus-value {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #434343;
  margin-top: 4px;
  margin-bottom: 4px;
}
.table-specie-value {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #111111;
}
.text-overflow-dot {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mobile-add-button {
  position: fixed;
  bottom: 100px;
  right: 15px;
  z-index: 100;
}
.search-container {
  padding: 12px;
  border-radius: 4px;
  box-shadow: none;
}
.search-button {
  margin-left: 10px;
  height: 15px;
  font-size: 12px;
  width: 100%;
}
.search-button:focus {
  outline: none;
}
.search-board {
  position: relative;
  box-shadow: none;
}
.search-result {
  width: 100%;
  position: absolute;
  background-color: white;
  overflow: auto !important;
  max-height: 250px;
  z-index: 1;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.10);
}
.badge-group {
  display: flex;
  align-items: center;
  text-align: left;
}
.badge-gray {
  font-weight: 400;
  font-size: 10px;
  height: 16px;
  background-color: #D7E4EB;
  border-radius: 100px;
  padding: 2px 10px 2px 10px;
}
.badge-red {
  border: 1px solid red;
  border-radius: 100px;
  color: red;
  padding: 2px 10px 2px 10px;
  font-size: 12px;
}
.flipped-sort {
  transform: rotate(180deg);
}
.text-left {
  text-align: left;
}
</style>
