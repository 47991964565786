<template>
  <div>
    <hr class="top_line" />
    <div class="footer d-flex align-center justify-space-around py-2">
      <div v-for="item in menus" :key="item.name">
        <router-link :to="item.path"
          :class="`d-flex flex-column align-center justify-center ${item.class} ${selectedMenu === item.name ? 'selected' : ''}`">
          <HomeIcon v-if="item.name === 'shopProducts'" size="25"
            :color="selectedMenu === item.name ? primaryColor : '#ccc'" />
          <CartIcon v-if="item.name === 'shopCart'" size="25"
            :color="selectedMenu === item.name ? primaryColor : '#ccc'" />
          <v-badge v-if="item.name === 'shopCart' && draftCount > 0" class="ml-2 badge" color="red"
            :content="`${draftCount}`" />
          <HistoryIcon v-if="item.name === 'shopOrders'" size="25"
            :color="selectedMenu === item.name ? primaryColor : '#ccc'" />
          <v-icon v-if="item.name === 'shopCollections'" size="25"
            :color="selectedMenu === item.name ? primaryColor : '#ccc'">mdi-flower</v-icon>
          <MessageIcon v-if="item.name === 'shopConversations'" size="25"
            :color="selectedMenu === item.name ? primaryColor : '#ccc'" />

          <span :style="{ color: selectedMenu === item.name ? primaryColor : '#ccc' }">{{ $t(item.name) }}</span>

          <v-badge v-if="item.name === 'shopConversations' && unreadCount" :content="unreadCount" color="red" class="ml-2 badge" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HomeIcon from "@/components/icons/HomeIcon.vue";
import CartIcon from "@/components/icons/CartIcon.vue";
import HistoryIcon from "@/components/icons/HistoryIcon.vue";
import MessageIcon from "@/components/icons/MessageIcon.vue";

export default {
  components: { HomeIcon, CartIcon, HistoryIcon, MessageIcon },
  data() {
    return {
      menus: [
        { path: "/shop/products", name: "shopProducts", class: "ga-shop-footer-products" },
        { path: "/shop/cart", name: "shopCart", class: "ga-shop-footer-cart" },
        { path: "/shop/orders", name: "shopOrders", class: "ga-shop-footer-orders" },
        { path: "/shop/collections", name: "shopCollections", class: "ga-shop-footer-collections" },
        { path: "/shop/conversations", name: "shopConversations", class: "ga-shop-footer-conversations" }
      ],
      selectedMenu: "shopProducts",
      primaryColor: "#375293"
    };
  },
  watch: {
    $route(to) {
      this.selectedMenu = to.name;
    }
  },
  mounted() {
    this.selectedMenu = this.$route.name;
  },
  computed: {
    draftCount() {
      return this.$store.getters["orders/draftCount"];
    },
    unreadCount() {
      return this.$store.getters["conversations/unreadCount"];
    },
  },
  methods: {
    isActive(menu) {
      return this.selectedMenu === menu ? "#375293" : "#cccccc";
    }
  }
};
</script>

<style scoped>
.footer {
  font-size: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
}

.selected {
  color: #375293;
}

.badge {
  position: absolute;
  top: 25%;
}

.top_line {
  position: absolute;
  bottom: 53px;
  height: 12px;
  width: 100%;
  border: 0;
  margin: 0;
  background: transparent;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(grey), color-stop(20%, transparent));
}
</style>
