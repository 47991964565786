<template>
  <SettingLayout
    tab="driver"
    :hiddenEdit="true"
  >
    <template>
      <SettingTextView label="ドライバー氏名">{{ driver.name }}</SettingTextView>
      <SettingTextView label="電話番号">{{ driver.phone }}</SettingTextView>
      <SettingTextView label="アカウントタイプ">{{ getDriverType(driver.driver_type) }}</SettingTextView>
      <SettingTextView label="担当エリア">{{ driver.areas[0].name }}</SettingTextView>
    </template>
  </SettingLayout>
</template>

<script>
import SettingLayout from "@/components/driver/settings/SettingLayout.vue";
import SettingTextView from "@/components/driver/settings/SettingTextView.vue";

export default {
  components: {
    SettingLayout,
    SettingTextView
  },
  computed: {
    driver() {
      return this.$store.state.user.driver;
    }
  },
  methods: {
    getDriverType(type) {
      if (type === "default") return "集荷・配送";
      if (type === "shipping") return "集荷";
      if (type === "delivery") return "配送";
      return "集荷・配送";
    }
  },
  async created() {
    await this.$store.dispatch("getProfile", "driver");
  }
};
</script>

<style lang="scss" scoped>
</style>
