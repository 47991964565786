<template>
  <div class="body" :style="{backgroundImage: `url(${require('@/assets/flower-background.png')})`}">
    <div class="backdrop" />
    <div class="content d-flex flex-column justify-center align-center">
      <HelpIcon />
      <img :src="require('@/assets/CAVIN_Logo_white.png')" class ="mt-3" :style="mobile ? 'width: 150px' : 'width: 300px'" />

      <span class="mt-3 welcome">{{ $t("welcome") }}</span>

      <v-card class="card d-flex flex-column justify-center align-center">
        <span class="card-title">{{ $t("new_member_registration") }}</span>

        <div style="width: 100%;">
          <span class="label">{{ $t("company_business_name") }}</span>
          <input
            type="text"
            ref="name"
            :class="isInvalid('name')"
            v-model="content.name"
            @keyup="keyupHandler"
          />
        </div>

        <div class="mt-4" style="width: 100%;">
          <span class="label">{{ $t("email") }}</span>
          <input
            type="email"
            ref="email"
            :class="isInvalid('email')"
            v-model="content.email"
            @keyup="keyupHandler"
          />
        </div>

        <div class="mt-4" style="width: 100%;">
          <span class="label">{{ $t("password") }}</span>
          <input
            :type="showPassword ? 'text' : 'password'"
            ref="pwd"
            :class="isInvalid('pwd')"
            v-model="content.pwd"
            @keyup="keyupHandler"
          />
        </div>

        <div class="checkbox">
          <v-checkbox
            :label="$t('show_pwd')"
            v-model="showPassword"
            class="ma-0"
            color="success"
            hide-details
          />
        </div>

        <div class="forgot_pwd">
          <span>{{ $t("forgot_pwd") }}</span>
          <router-link to="/forgot_pwd" class="link">{{ $t("here") }}</router-link>
        </div>

        <v-btn :class="`btn ${userType}`" @click="signup">{{ $t("login_btn") }}</v-btn>
      </v-card>

      <div class="signup">
        {{ $t("login_is") }}
        <router-link
          :to="`/${type.toLowerCase()}`"
          class="link"
          style="color: white"
        >{{ $t("here") }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HelpIcon from "@/components/HelpIcon.vue";

export default {
  components: { HelpIcon },
  props: {
    type: String
  },

  data() {
    return {
      showPassword: false,
      invalid: null,
      content: {}
    };
  },
  mounted() {
    this.$refs.name.focus();
  },
  computed: {
    userType() {
      return this.type === "Farm" ? "orange" : "green";
    }
  },
  methods: {
    keyupHandler(e) {
      if (e.key === "Enter") this.signup();
    },
    signup() {
      const { name, email, pwd } = this.content;
      this.invalid = null;

      if (!name) {
        this.invalid = "name";
        this.$refs.name.focus();
        return;
      }

      if (!email || !this.$refs.email.checkValidity()) {
        this.invalid = "email";
        this.$refs.email.focus();
        return;
      }

      if (!pwd || pwd.length < 6) {
        this.invalid = "pwd";
        this.$refs.pwd.focus();
      }
    },
    isInvalid(field) {
      return this.invalid === field ? "invalid" : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.backdrop {
  background-color: #333333;
  opacity: 0.8;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.content {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}

.welcome {
  font: Bold 1rem Helvetica;
  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  $width: 90%;
  .card {
    padding: 2rem;
    width: $width;
  }
  .signup {
    width: $width;
  }

  .forgot_pwd {
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (min-width: 600px) {
  $width: 40rem;
  .card {
    padding: 2rem 4rem 2rem 4rem;
    width: $width;
  }

  .signup {
    width: $width;
  }

  .forgot_pwd {
    text-align: left;
    margin-top: -1.5rem;
    margin-bottom: 2rem;
  }
}

.card {
  margin-top: 2.5rem;
}

.signup {
  font: Bold 1rem Helvetica;
  text-align: left;
  color: #ffffff;
}

.forgot_pwd {
  width: 100%;
  font: Regular 0.8rem Helvetica;
  color: #777777;
  display: none;
}

.card-title {
  font: Bold 1.5rem Helvetica;
  color: #333333;
}

.label {
  font: Medium 1rem Noto Sans CJK JP;
  color: #111111;
}

.link {
  cursor: pointer;
  color: #3794e3;
  text-decoration: underline;
}

.checkbox {
  margin-top: -5px;
  margin-left: auto;
  color: #979797;
  font: SemiBold 14px/18px Muli;
}

.btn {
  width: 200px;
}
</style>
