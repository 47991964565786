<template>
  <v-tabs class="tabs" fixed-tabs>
    <v-tab v-for="tab in tabs" :key="tab.name" :href="tab.to" :link="!!tab.to" @click="handleClick(tab)">{{ tab.name
    }}</v-tab>
  </v-tabs>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true
    },
  },
  methods: {
    handleClick(tab) {
      this.$emit("click", tab);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-tab {
  color: #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  &--active {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
}

::v-deep .v-tabs-slider-wrapper {
  height: 2px;
}
</style>
