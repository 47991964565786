<template>
  <div class="shop-layout">
    <Header class="header" />
    <div class="main">
      <router-view class="page mb-10" />
    </div>
    <MobileFooter v-if="mobile || tablet" class="footer" />
    <Footer v-else class="footer" />
    <ReviewModal v-show="showReviewModal" @openConfirmModal="openConfirmModal" />
    <div class="modal" v-show="showConfirmModal">
      <div class="confirm-modal-content pa-4">
          <span class="close" @click="closeConfirmModal()">
            <CloseIcon size="24" color="black" />
          </span>
          <div class="text-center mt-6">
            <p class="font-weight-bold">{{ $t("product_review") }}</p>
            <span class="text-sm">{{ $t("review_submitted") }}</span><br/>
            <span class="text-sm">{{  $t("thanks_for_cooperation") }}</span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/shop/Header.vue";
import Footer from "@/components/shared/Footer.vue";
import MobileFooter from "@/components/shop/MobileFooter.vue";
import ReviewModal from "@/components/shop/ReviewModal.vue";
import getDefaultLocation from "@/util/getDefaultLocation";
import CloseIcon from "@/components/icons/CloseIcon.vue";

export default {
  components: {
    Header,
    Footer,
    MobileFooter,
    ReviewModal,
    CloseIcon
  },
  data() {
    return {
      confirmationToken: "",
      axiosOptions: {},
      user: {},
      showConfirmModal: false,
    };
  },
  async mounted() {
    // const response = await this.$http2(
    //   "get",
    //   "reviews/order_items"
    // );
    //
    // if (this.$store.state.auth.is_show_review_modal && response.result.review_order_items.length > 0 && new Date().getHours() > 18) {
    //   const reviewItems = response.result.review_order_items.map(review_order_item => ({ ...review_order_item, status: 0 }));
    //   this.$store.dispatch("reviews/setReviewItems", reviewItems);
    // }
    const res = await this.$api2.get("users/profile", this.axiosOptions);

    this.$store.commit("setData", {
      is_confirmed: res.result.user.is_confirmed,
    });
    const defaultLocation = getDefaultLocation(res.result.user.shop.locations);
    const obj = {
      user: res.result.user,
      defaultLocation
    };

    this.$store.commit("setProfile", obj);

    if (!res.result.user.is_confirmed) {
      this.$router.push({
        path: "/users/wait_confirmed",
        query: {
          type: res.result.user.user_type,
          email: res.result.user.email
        }
      });
    }

    // if (!res.result.user.is_confirmed || defaultLocation?.prefecture?.attributes?.name === "大阪府") {
    //   this.$router.push({
    //     path: "/users/wait_confirmed",
    //     query: {
    //       type: res.result.user.user_type,
    //       email: res.result.user.email
    //     }
    //   });
    // }

    // loginユーザーのIDをGAに送信
    this.$gtag.config({
      user_id: res.result.user.uuid
    });
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    tablet() {
      return this.$vuetify.breakpoint.sm;
    },
    showReviewModal() {
      return this.$store.state.auth.is_show_review_modal;
    }
  },
  methods: {
    openConfirmModal() {
      this.showConfirmModal = true;
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.shop-layout {
  display: grid;
  grid-template-rows: auto 1fr auto;
  color: #0c1624;
  min-height: 100vh;
  background-color: #fff;
}

.header {
  position: sticky;
  top: 0;
  z-index: 101;
}

.main {
  position: relative;
  min-width: 0;
  // z-index: 0;
}

.footer {
  z-index: 3;
}
.page {
  padding-bottom: 60px;
  min-height: 100%;

  @media screen and (max-width: 960px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.confirm-modal-content {
  background-color: #fefefe;
  gap: 12px;
  border: 1px solid #888;
  width: 347px;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  position: absolute;
  transform: translate(-50%, -50%);
}

.close {
  color: #aaa;
  float: right;
  font-size: 14px;
  font-weight: bold;
}
</style>
