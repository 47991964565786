<template>
  <div class="wrapper">
    <div v-if="isLoading">
      <div class="px-4 required-form-wrapper flex-column flex-grow-1">
        <div class="mt-4">
          <div class="required-form-wrapper">
            <div class="label-wrapper">
              <p class="label">{{ $t("stem_size") }}</p>
            </div>
            <div>
              <template>
                <span class="read-label">{{ stemSize }}cm</span>
              </template>
            </div>
          </div>
          <div class="required-form-wrapper">
            <div class="label-wrapper">
              <p class="label">{{ $t("min_count") }}</p>
            </div>
            <div>
              <template>
                <span class="read-label">{{ minCount }}{{ $t("volumn") }}</span>
              </template>
            </div>
          </div>
          <div class="required-form-wrapper">
            <div class="label-wrapper">
              <p class="label">{{ $t("set_count") }}</p>
            </div>
            <div>
              <template>
                <span class="read-label">{{ setCount }}{{ $t("lot") }}</span>
              </template>
            </div>
          </div>
          <div class="required-form-wrapper">
            <div class="label-wrapper">
              <p class="label">{{ $t("price_unit") }}</p>
            </div>
            <div>
              <template>
                <span class="read-label">{{ price }}{{ $t("yen") }}</span>
              </template>
            </div>
          </div>
          <div class="description-form-wrapper">
            <div class="label-wrapper">
              <p class="label">{{ $t("product_description") }}</p>
            </div>
            <div class="description-wrapper">
              <template>
                <span class="read-label">{{ note }}</span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="title">{{ flowerHarvestIds.length }}件の出品を編集する</div>
      <div class="required-form-wrapper">
        <div class="label-wrapper">
          <p class="form-label">{{ $t("stem_size") }}</p>
          <p class="required-label">{{ $t('required_field') }}</p>
        </div>
        <div>
          <template>
            <MstTextField v-model="stemSize" type="number" width="100px" :error="error.stemSize" @input="handleChangeStemSize($event)">
              <span class="unit-margin">cm</span>
            </MstTextField>
          </template>
        </div>
      </div>
      <div class="required-form-wrapper">
        <div class="label-wrapper">
          <p class="form-label">{{ $t("min_count") }}</p>
          <p class="required-label">{{ $t('required_field') }}</p>
        </div>
        <div>
          <template>
            <MstTextField v-model="minCount" type="number" width="100px" :error="error.minCount" @input="handleChangeMinCount($event)">
              <span class="unit-margin">{{ $t("volumn") }}</span>
            </MstTextField>
          </template>
        </div>
      </div>
      <div class="required-form-wrapper">
        <div class="label-wrapper">
          <p class="form-label">{{ $t("set_count") }}</p>
          <p class="required-label">{{ $t('required_field') }}</p>
        </div>
        <div>
          <template>
            <MstTextField v-model="setCount" type="number" width="100px" :error="error.setCount" @input="handleChangeSetCount($event)" >
              <span class="unit-margin">{{ $t("lot") }}</span>
            </MstTextField>
          </template>
        </div>
      </div>
      <div class="required-form-wrapper">
        <div class="label-wrapper">
          <p class="form-label">{{ $t("price_unit") }}</p>
          <p class="required-label">{{ $t('required_field') }}</p>
        </div>
        <div>
          <template>
            <MstTextField v-model="price" type="number" width="100px" :error="error.price" @input="handleChangePrice($event)" >
              <span class="unit-margin">{{ $t("yen") }}</span>
            </MstTextField>
          </template>
        </div>
      </div>
      <div class="description-form-wrapper">
        <div class="label-wrapper">
          <p class="form-label">{{ $t("product_description") }}</p>
        </div>
        <div>
          <template>
            <MstTextArea v-model="note" @input="handleChangeNote($event)" />
          </template>
        </div>
      </div>

      <div class="px-8 color-red my-2">
        <span>{{ error_confirmed_count }}</span>
      </div>
      <div :class="mobile ? 'button-wrapper' : 'pc-button-wrapper'">
        <button class="cancel-button" @click="onClickCancelButton()">{{ $t("cancel") }}</button>
        <button
          :disabled="!isValid()"
          @click="submit()"
          class="save-button">
          {{  $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { MstTextArea, MstTextField } from "@/components/master";

const STEM_SIZE_LIMIT_DIGIT = 7;
const SET_COUNT_LIMIT_DIGIT = 10;
const MIN_COUNT_LIMIT_DIGIT = 10;

export default {
  components: {
    MstTextArea,
    MstTextField,
  },
  data() {
    return {
      isLoading: false,
      stemSize: "",
      setCount: "",
      minCount: "",
      price: "",
      note: "",
      flowerHarvestIds: [],
      error_confirmed_count: "",
      error: {
        stemSize: "",
        minCount: "",
        setCount: "",
        price: "",
        harvestDate: "",
      },
      mobile: this.$vuetify.breakpoint.xs,
    };
  },
  async created() {
    this.isLoading = true;
    this.flowerHarvestIds = this.$route.params.flower_harvest_ids.split(",");
    this.isLoading = false;
  },
  methods: {
    handleChangeStemSize(value) {
      if (Number(value) < 0) {
        this.error.stemSize = this.$t("harvest_number_error_msg");
        return;
      }

      if (value.length >= STEM_SIZE_LIMIT_DIGIT) {
        this.error.stemSize = this.$t("harvest_over_float_error_msg");
        this.stemSize = (10 ** STEM_SIZE_LIMIT_DIGIT).toString();
        return;
      }

      this.error.stemSize = "";
      this.stemSize = value;
    },
    handleChangeSetCount(value) {
      if (Number(value) < 0) {
        this.error.stemSize = this.$t("harvest_number_error_msg");
        return;
      }

      if (value.length >= SET_COUNT_LIMIT_DIGIT) {
        this.error.stemSize = this.$t("harvest_over_int_error_msg");
        this.stemSize = (10 ** SET_COUNT_LIMIT_DIGIT).toString();
        return;
      }

      this.error.setCount = "";
      this.setCount = value;
    },
    handleChangeMinCount(value) {
      if (Number(value) < 0) {
        this.error.stemSize = this.$t("harvest_number_error_msg");
        return;
      }

      if (value.length >= MIN_COUNT_LIMIT_DIGIT) {
        this.error.stemSize = this.$t("harvest_over_int_error_msg");
        this.minCount = (10 ** MIN_COUNT_LIMIT_DIGIT).toString();
        return;
      }

      this.error.minCount = "";
      this.minCount = value;
    },
    handleChangePrice(value) {
      if (Number(value) < 0) {
        this.error.price = this.$t("harvest_number_error_msg");
        return;
      }

      this.price = value;
    },
    handleChangeNote(value) {
      this.note = value;
    },
    onClickCancelButton() {
      this.$router.go(-1);
    },
    async submit() {
      const body = {
        ids: this.flowerHarvestIds,
        confirmed_count: this.setCount * this.minCount,
        min_count: Number(this.minCount),
        stem_size: Number(this.stemSize),
        price: Number(this.price),
        note: this.note
      };

      this.isLoading = true;
      const res = await this.$http2("put", "farm/harvests/update_many", body);
      this.isLoading = false;

      if (res.status !== 200) {
        alert("更新に失敗しました");
        return;
      }

      // TODO: 更新に失敗した出品のアラート
      // if (res.failure.length > 0) {
      //   const alertMsg = res.failure.map(failure => `${failure.harvest_date}: ${failure.message}`).join("\n");
      //   alert(alertMsg);
      // }

      this.$router.push("/farm/flower_harvests");
    },
    isValid() {
      return Number(this.minCount) > 0
        && Number(this.setCount) > 0
        && Number(this.price) > 0
        && Number(this.stemSize) > 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  margin: 48px auto 0 auto;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: #191D38;
  margin-left: 15px;
  margin-bottom: 32px;
}

.required-form-wrapper {
  display: flex;
  height: 33px;
  margin-bottom: 25px;
}

.description-form-wrapper {
  display: flex;
}

.label-wrapper {
  padding: 0 16px;
  display: flex;
  width: 170px;

  .form-label {
    line-height: 33px;
    color: #727272;
    margin-right: 12px;
  }

  .required-label {
    line-height: 33px;
    color: #E52235;
    font-size: 12px;
    font-weight: 700;
  }
}

.description-wrapper {
  width: calc(100% - 200px);
  overflow-wrap: break-word;
}

.label {
  margin: 4px auto 4px 0;
  color: #727272;
}
.unit-margin {
  margin: 4px 8px;
  width: 15px;
}

.button-wrapper {
  position: fixed;
  left: 0;
  z-index: 10;
  bottom: 75px;
  width: 100%;
  display: flex;
  gap: 6px;
  justify-content: center;
  background: #ffffff;
  padding-bottom: 16px;

  .cancel-button {
    width: 179px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: #191D38;
    border: 1px solid #191D38;
    border-radius: 4px;
  }

  .save-button {
    width: 179px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    background-color: #191D38;
    border-radius: 4px;
  }
  .save-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
.pc-button-wrapper {
  margin: 40px;
  z-index: 10;
  width: calc(100vw - 175px);
  display: flex;
  gap: 6px;
  justify-content: center;

  .cancel-button {
    width: 179px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: #191D38;
    border: 1px solid #191D38;
    border-radius: 4px;
  }

  .save-button {
    width: 179px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    background-color: #191D38;
    border-radius: 4px;
  }
  .save-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
</style>
