<template>
  <v-date-picker
    v-model="picker"
    @click="menu = false"
    color="secondary"
    no-title
    full-width
    locale="jp-ja"
    :day-format="date => new Date(date).getDate()"
    :events="events"
  />
</template>

<script>
import { formatDate } from "@/plugins/i18n";

export default {
  props: {
    value: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    deliveryDateList: {
      type: Array
    }
  },
  data() {
    return {
      menu: false
    };
  },
  computed: {
    picker: {
      get() {
        return this.value;
      },
      set(val) {
        this.menu = false;
        this.$emit("input", val);
      }
    },
    events() {
      return this.deliveryDateList.map(date => formatDate(date, "yyyy-mm-dd"));
    }
  }
};
</script>

<style scoped>
</style>
