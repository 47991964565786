const obj = {
  ア: ["ア", "イ", "ウ", "ヴ", "エ", "オ"],
  カ: ["カ", "キ", "ク", "ケ", "コ"],
  サ: ["サ", "シ", "ス", "セ", "ソ"],
  タ: ["タ", "チ", "ツ", "テ", "ト"],
  ナ: ["ナ", "ニ", "ヌ", "ネ", "ノ"],
  ハ: ["ハ", "ヒ", "フ", "ヘ", "ホ"],
  マ: ["マ", "ミ", "ム", "メ", "モ"],
  ヤ: ["ヤ", "ユ", "ヨ"],
  ラ: ["ラ", "リ", "ル", "レ", "ロ"],
  ワ: ["ワ", "ヲ", "ン"],

  ガ: ["ガ", "ギ", "グ", "ゲ", "ゴ"],
  ザ: ["ザ", "ジ", "ズ", "ゼ", "ゾ"],
  ダ: ["ダ", "ヂ", "ヅ", "デ", "ド"],
  バ: ["バ", "ビ", "ブ", "ベ", "ボ"],

  パ: ["パ", "ピ", "プ", "ペ", "ポ"],

};

const reverse = {};
Object.keys(obj).forEach(x => {
  obj[x].forEach(y => {
    reverse[y] = x;
  });
});

export default {
  obj,
  reverse
};
