<template>
  <div class="product-container" :class="{ 'pc-only': pcOnly }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    pcOnly: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.product-container {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;

  &.pc-only {
    @media screen and (max-width: 960px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
