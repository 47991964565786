<template>
  <component
    :is="closable ? 'button' : 'i'"
    :type="closable ? 'button' : undefined"
    :class="styledClasses"
    class="mstTextTag"
    @click="handleClick"
  >
    {{ text }}
    <MstIcon
      v-if="closable"
      :size="size === 'md' ? '18px' : '14px'"
      type="close"
      class="mstTextTag__closeIcon"
    />
  </component>
</template>

<script>
import { MstIcon } from "..";

export default {
  name: "MstTextTag",
  components: { MstIcon },
  props: {
    text: { type: String, required: true },
    size: { type: String, validator: value => ["sm", "md"].includes(value), default: "sm" },
    color: {
      type: String,
      validator: value => ["blue-900", "blue-700", "blue-500", "gray-50", "red-500"].includes(value),
      default: "blue-700",
    },
    outlined: { type: Boolean },
    rounded: { type: Boolean },
    circle: { type: Boolean },
    closable: { type: Boolean },
  },
  computed: {
    styledClasses() {
      const classes = [];
      classes.push(`-${this.size}`);
      classes.push(`-${this.color}`);

      if (this.outlined) {
        classes.push("-outlined");
      } else {
        classes.push("-filled");
      }
      if (this.rounded) classes.push("-rounded");
      if (this.circle && !this.closable) classes.push("-circle");
      if (this.closable) classes.push("-closable");
      return classes;
    },
  },
  methods: {
    handleClick(event) {
      if (!this.closable) return;
      this.$emit("click", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.mstTextTag {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  line-height: 1;
  vertical-align: top;

  &.-filled {
    color: variables.$color-white;

    &.-blue-900 {
      background: variables.$color-blue-900;
    }

    &.-blue-700 {
      background: variables.$color-blue-700;
    }

    &.-blue-500 {
      background: variables.$color-blue-500;
    }

    &.-gray-50 {
      background: variables.$color-gray-50;
      color: variables.$color-black-500;
    }

    &.-red-500 {
      background: variables.$color-red-500;
    }
  }

  &.-outlined {
    border: 1px solid currentColor;
    background: variables.$color-white;

    &.-blue-900 {
      color: variables.$color-blue-900;
    }

    &.-blue-700 {
      color: variables.$color-blue-700;
    }

    &.-blue-500 {
      color: variables.$color-blue-500;
    }

    &.-gray-50 {
      border-color: variables.$color-gray-50;
      color: variables.$color-gray-500;
    }
  }

  &.-sm {
    border-radius: 1px;
    padding: 0 3px;
    height: 18px;
    font-size: variables.$font-size-xs;

    &.-rounded {
      border-radius: 9px;
      padding: 0 6px;
    }

    &.-closable {
      padding-right: 18px;
    }
  }

  &.-md {
    border-radius: 2px;
    padding: 0 14px;
    height: 24px;
    font-size: variables.$font-size-md;
    font-weight: bold;

    &.-rounded {
      border-radius: 12px;
    }

    &.-closable {
      padding-right: 36px;
    }
  }

  &.-circle {
    border-radius: 50%;
    padding: 0;

    &.-sm {
      width: 18px;
      height: 18px;
    }

    &.-md {
      width: 24px;
      height: 24px;
    }
  }
}

.mstTextTag__closeIcon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);

  .-sm & {
    right: 3px;
  }
}
</style>
