<template>
  <div :class="styledClasses" class="mstDataTable">
    <div class="mstDataTable__headGroup">
      <component :is="title ? `h${level}` : 'div'" class="mstDataTable__title text-xl">{{ title }}</component>
      <button v-if="checkable" type="button" class="mstDataTable__toggle text-md" @click="isVisibleCheckboxes = !isVisibleCheckboxes">
        {{ isVisibleCheckboxes ? 'キャンセル' : '選択' }}
      </button>
    </div>
    <div class="mstDataTable__table">
      <table class="mstDataTable__inner">
        <colgroup>
          <col v-for="header in headers" :key="header.key" :style="{ width: header.width || 'auto' }" />
        </colgroup>

        <thead v-if="!noHeader">
          <tr class="mstDataTable__header">
            <component
              v-for="header in headers"
              :key="header.key"
              :is="header.label ? 'th' : 'td'"
              :class="getHeadClasses(header.key)"
              class="mstDataTable__head"
            >
              <component
                :is="sortable && header.sortable ? 'button' : 'span'"
                :class="sortable && header.sortable ? '-sortable' : ''"
                class="mstDataTable__headInner"
                @click="handleClickSort(header)"
              >
                {{ header.label }}
              </component>
            </component>

            <td v-if="checkable && isVisibleCheckboxes" class="mstDataTable__check">
              <MstCheckbox
                :checked="checkStatus === 'all'"
                :indeterminate="checkStatus === 'indeterminate'"
                @change="handleAllCheck"
              />
            </td>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="item in sortedItems"
            :key="item.id"
            class="mstDataTable__item"
            @click="handleClick(item)"
          >
            <td v-for="header in headers" :key="header.key" :class="header.align ? `-${header.align}` : '-left'" class="mstDataTable__cell">
              <slot :name="`cell-${header.key}`" :item="item" :value="item[header.key]">
                {{ item[header.key] }}
              </slot>
            </td>

            <td v-if="checkable && isVisibleCheckboxes">
              <MstCheckbox :checked="!!value.find((v) => v === item[checkValueKey])" @change="handleCheck(item[checkValueKey])" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { MstCheckbox, MstIcon } from "@/components/master";

export default {
  name: "MstDataTable",
  model: {
    prop: "value",
    event: "change",
  },
  components: { MstCheckbox, MstIcon },
  props: {
    headers: { type: Array, required: true },
    items: { type: Array, required: true },
    level: { type: Number, default: 2 },
    title: { type: String },
    noHeader: { type: Boolean },
    checkable: { type: Boolean },
    value: { type: Array, default: () => [] },
    checkValueKey: { type: String, default: "id" },
    sortable: { type: Boolean },
    defaultSortKey: { type: String, default: "id" },
    clickable: { type: Boolean },
    scrollable: { type: Boolean },
  },
  data() {
    return {
      sortKey: "",
      orderBy: "asc",
      isVisibleCheckboxes: false,
    };
  },
  computed: {
    styledClasses() {
      const classes = [];
      if (this.checkable) classes.push("-checkable");
      if (this.sortable) classes.push("-sortable");
      if (this.clickable) classes.push("-clickable");
      if (this.scrollable) classes.push("-scrollable");
      return classes;
    },
    sortedItems() {
      if (!this.sortable || !this.sortKey) return this.items;
      const items = this.items;
      return items.sort((before, next) => {
        if (this.orderBy === "asc") return before[this.sortKey] < next[this.sortKey] ? -1 : 1;
        return before[this.sortKey] < next[this.sortKey] ? 1 : -1;
      });
    },
    checkStatus() {
      if (this.value.length) {
        if (this.value.length === this.items.length) return "all";
        return "indeterminate";
      }
      return "none";
    },
  },
  created() {
    this.sortKey = this.defaultSortKey;
  },
  methods: {
    getHeadClasses(key) {
      const classes = [];
      if (this.sortKey === key) classes.push(`-${this.orderBy}`);
      return classes;
    },
    handleClickSort(header) {
      if (!this.sortable || !header.sortable) return;
      if (this.sortKey === header.key) {
        this.orderBy = this.orderBy === "asc" ? "desc" : "asc";
      } else {
        this.sortKey = header.key;
        this.orderBy = "asc";
      }
    },
    handleClick(item) {
      if (!this.clickable) return;
      this.$emit("click", item);
    },
    handleCheck(value) {
      if (this.value.find(v => v === value)) {
        this.$emit("change", this.value.filter(v => v !== value));
      } else {
        this.$emit("change", this.value.concat([value]));
      }
    },
    handleAllCheck() {
      if (this.checkStatus === "all") {
        this.$emit("change", []);
      } else {
        this.$emit("change", this.items.map(item => item[this.checkValueKey]));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mstDataTable__headGroup {
  display: grid;
  grid-template-columns: 1fr 90px;
  gap: 0 12px;
  margin-bottom: 14px;
}

.mstDataTable__title {
  color: variables.$color-black-500;
}

.mstDataTable__toggle {
  text-decoration: underline;
  text-align: right;
  color: variables.$color-brand-secondary;

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }
}

.mstDataTable__table {
  .-scrollable & {
    overflow-x: auto;
  }
}

.mstDataTable__inner {
  border-collapse: collapse;
  width: 100%;
}

.mstDataTable__head {
  padding: 8px 15px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  text-align: left;
  color: variables.$color-black-500;
}

.mstDataTable__headInner {
  position: relative;
  padding-right: 12px;
  text-align: left;

  &.-sortable {
    &::after {
      position: absolute;
      right: 0;
      top: 50%;
      content: "";
      margin-top: -3px;
      border-width: 7px 4px;
      border-style: solid;
      border-color: variables.$color-gray-200 transparent transparent;
    }
  }

  .-desc & {
    &::after {
      border-color: variables.$color-black-500 transparent transparent;
    }
  }

  .-asc & {
    &::after {
      margin-top: -10px;
      border-color: transparent transparent variables.$color-black-500;
    }
  }
}

.mstDataTable__item {
  &:nth-child(2n + 1) {
    background: variables.$color-gray-50;
  }

  .-clickable & {
    position: relative;
    cursor: pointer;

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      content: "\F0142";
      font-family: "Material Design Icons";
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      color: variables.$color-gray-800;
      transform: translateY(-50%);
    }

    th, td {
      &:last-child {
        padding-right: 28px;
      }
    }
  }
}

.mstDataTable__cell {
  padding: 13px 15px;
  font-size: 16px;
  line-height: 1.2;
  color: variables.$color-black-500;

  &.-left {
    text-align: left;
  }

  &.-center {
    text-align: center;
  }

  &.-right {
    text-align: right;
  }
}

.mstDataTable__check {
  padding-right: 12px;
  width: 18px;
}
</style>
