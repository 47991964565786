<template>
  <Edit/>
</template>

<script>
import Edit from "@/components/farm/flower_harvests/edit.vue";

export default {
  components: {
    Edit
  }
};
</script>
